<template>
    <div class="mid-term-adjustments position-relative mt-20px">
        <nav-layout
            :heading="'Audit Logs'"
            :description="''"
            :options="adjustmentOptions"
            :selectedOption="selectedOption"
            :type="'left'"
            class="shadow-none"
        >
            <template v-slot:content>
                <div
                    class="loading-content position-relative w-100"
                    v-if="
                        adjustmentLoading ||
                        adjustments.length === 0 ||
                        adjustmentError
                    "
                >
                    <bev-loading-block
                        :loading="adjustmentLoading"
                        :error="adjustmentError"
                        :errorMessage="errorMessage"
                    />
                </div>
                <div
                    class="mt-20px"
                    v-if="
                        adjustments.length &&
                        !adjustmentLoading &&
                        !adjustmentError
                    "
                >
                    <Adjustments
                        :adjustments="adjustments"
                        :selectedOption="selectedOption"
                    />
                    <Pagination
                        v-if="totalPages > 1"
                        class="mx-auto py-20px"
                        :totalRecords="totalElements"
                        :recordsPerPage="size"
                        :pageNumber="pageNumber"
                        @pageChanged="pageChangeHandler"
                    />
                </div>
            </template>
        </nav-layout>
    </div>
</template>

<script>
import PolicyService from "@/services/PolicyService";
import Pagination from "@/components/Common/Pagination.vue";
import Adjustments from "@/components/Views/Details/MTA/Adjustments/Main.vue";
import NavLayout from "@/components/Layout/LayoutWithNav";
export default {
    props: {
        endorsementUpdateCounter: Number,
    },
    data() {
        return {
            adjustments: [],
            pageNumber: 0,
            size: 500,
            totalElements: 0,
            totalPages: 0,
            adjustmentLoading: false,
            adjustmentError: false,
            adjustmentOptions: [
                {
                    name: "Mid term adjustments",
                    key: "policy",
                    clickHandler: this.changeSelectedOption.bind(
                        this,
                        "policy"
                    ),
                },
                {
                    name: "Endorsements",
                    key: "endorsements",
                    clickHandler: this.changeSelectedOption.bind(
                        this,
                        "endorsements"
                    ),
                },
                {
                    name: "Status Changes",
                    key: "policyStatus",
                    clickHandler: this.changeSelectedOption.bind(
                        this,
                        "policyStatus"
                    ),
                },
            ],
            selectedOption: "policy",
        };
    },
    components: {
        Pagination,
        Adjustments,
        NavLayout,
    },
    mounted() {
        this.getAdjustments(0, true);
    },
    computed: {
        errorMessage() {
            if (this.adjustmentError) {
                return this.$messages.errorMessages.loadFailError("changes");
            } else {
                return this.$messages.errorMessages.notFoundError("change");
            }
        },
    },
    methods: {
        changeSelectedOption(option) {
            this.selectedOption = option;
        },
        async getAdjustments(page, initial) {
            if (initial) {
                this.totalElements = 0;
                this.totalPages = 0;
                this.adjustments = [];
            }
            this.adjustmentLoading = true;
            this.adjustmentError = false;
            this.pageNumber = page;
            try {
                const response = await PolicyService.getPolicyMTA(
                    this.pageNumber,
                    this.size,
                    this.$route.params.id,
                    this.selectedOption === "policy" ? "" : this.selectedOption
                );
                this.totalElements = response.data.totalElements;
                this.totalPages = response.data.totalPages;
                this.adjustments = response.data.content;
            } catch (err) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.loadFailError("adjustments")
                );
                this.adjustmentError = true;
            } finally {
                this.adjustmentLoading = false;
            }
        },
    },
    watch: {
        selectedOption: function () {
            this.getAdjustments(0, true);
        },
        endorsementUpdateCounter: function () {
            this.getAdjustments(0, true);
        },
    },
};
</script>

<style scoped>
.mid-term-adjustments.minHeight {
    min-height: 200px;
}
.heading {
    font-size: 20px;
    font-weight: 600;
}
.loading-content {
    min-height: 200px;
}
</style>

<style>
.mid-term-adjustments .layout-with-nav .heading {
    padding-top: 0;
}
.mid-term-adjustments .layout-with-nav.left .navigation-items {
    margin-top: 10px;
}
.mid-term-adjustments .layout-with-nav {
    height: auto;
}
.mid-term-adjustments .layout-with-nav.left .heading-block {
    padding: 0;
}
</style>
