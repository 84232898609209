import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
// import { createMetaManager } from "vue-meta";
import { store } from "@/store/index";
import "@/assets/sass/main.scss";

import { globalComponentsSetup } from "./globalComponents";
import CommonMixin from "@/mixin/common.js";
import ColorMixin from "@/mixin/colors.js";
import { globalPluginsSetup } from "@/globalPlugins";

// Global CSS
import "leaflet/dist/leaflet.css";

const app = createApp(App);

app.use(router);
app.use(store);
// app.use(createMetaManager());
app.mixin(CommonMixin);
app.mixin(ColorMixin);
globalPluginsSetup(app);
globalComponentsSetup(app);
app.config.productionTip = false;
app.config.devtools = true;

app.mount("#app");

export default app;
