<template>
    <div class="product-dashboard-info px-6% position-relative">
        <bev-loading-block
            class="loading-block"
            v-if="productInfoLoading || productInfoError"
            :loading="productInfoLoading"
            :error="productInfoError"
            :showRetry="true"
            :retryHandler="getProductInfo"
            :errorMessage="'Failed to load Product Info'"
        />
        <div class="row" v-if="!productInfoLoading && !productInfoError">
            <div class="col-sm-12 col-lg-8 d-flex align-items-start">
                <div class="row w-100">
                    <div class="col-sm-12 col-lg-4" v-if="productDetails.image">
                        <bev-image
                            :addBackground="false"
                            :imageUrl="productDetails.image"
                            width="100%"
                            height="auto"
                            :imageAlt="'product_image'"
                        ></bev-image>
                    </div>
                    <div
                        class="col-sm-12"
                        :class="[
                            productDetails.image
                                ? 'col-lg-8 pl-40px'
                                : 'col-lg-12',
                        ]"
                    >
                        <div>
                            <p
                                class="fw-bold font-22 mt-20px text-uppercase text-activeColorV1"
                            >
                                {{ productDetails.name || "" }}
                            </p>
                            <p class="fw-600 font-18 mt-20px text-uppercase">
                                {{ productDetails.eventLocation || "" }}
                            </p>
                            <p class="fw-600 font-18 mt-10px">
                                {{ eventDates }}
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-20px">
                            <div class="flex-1 mr-5px">
                                <p class="font-16 mb-10px">Aggregate Limit</p>
                                <p
                                    class="fw-bold font-20 mb-10px text-activeColorV1"
                                >
                                    {{
                                        thousandMask(
                                            totalInsuredAmount,
                                            "currency"
                                        )
                                    }}
                                </p>
                            </div>
                            <div class="flex-1">
                                <p class="font-16 mb-10px">
                                    Aggregate Limit Used
                                </p>
                                <p
                                    class="fw-bold font-20 mb-10px text-activeColorV1"
                                >
                                    {{
                                        thousandMask(
                                            sumOfInsuredAmount,
                                            "currency"
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mt-20px">
                            <div class="flex-1 mr-5px">
                                <p class="font-16 mb-10px">
                                    Total Gross Written Premium
                                </p>
                                <p
                                    class="fw-bold font-20 mb-10px text-activeColorV1"
                                >
                                    {{
                                        thousandMask(
                                            sumOfPremiumOfAllPolicies,
                                            "currency"
                                        )
                                    }}
                                </p>
                            </div>
                            <div class="flex-1">
                                <p class="font-16 mb-10px">
                                    Total Policies Sold
                                </p>
                                <p
                                    class="fw-bold font-20 mb-10px text-activeColorV1"
                                >
                                    {{ totalPoliciesSold }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-4 text-center">
                <div class="pie-chart-container">
                    <PieChart
                        chartId="aggregate_percentage_used"
                        graphColor="white"
                        :labels="chartLabels"
                        :bgColors="chartBgColors"
                        chartLabel="Aggregate Limit Used (%)"
                        :graphData="chartData"
                        showPerentage
                        class="w-auto m-auto position-relative z-index-1"
                    />
                </div>
                <p class="mt-15px fw-600">Aggregate Limit Used (%)</p>
            </div>
        </div>
    </div>
</template>

<script>
import { format } from "date-fns";
import PolicyService from "@/services/PolicyService";
import PieChart from "@/components/Common/Graph/PieChart.vue";
export default {
    props: {
        productDetails: Object,
        productName: String,
        totalInsuredAmount: Number,
    },
    computed: {
        eventDates() {
            try {
                let dates = this.productDetails.eventDates.split("/");
                return `${format(
                    new Date(dates[0].replace(/-/g, "/")),
                    "do"
                )} - ${format(
                    new Date(dates[1].replace(/-/g, "/")),
                    "do MMMM yyyy"
                )}`;
            } catch (err) {
                return "";
            }
        },
        chartData() {
            if (this.sumOfInsuredAmount >= this.totalInsuredAmount) {
                return [100, 0];
            } else {
                let limitUsed = (
                    (this.sumOfInsuredAmount / this.totalInsuredAmount) *
                    100
                ).toFixed(1);
                return [limitUsed, 100 - limitUsed];
            }
        },
        chartBgColors() {
            return [
                this.$colors.bevColors.activeColorV3,
                this.$colors.bevColors.baseDark,
            ];
        },
    },
    components: {
        PieChart,
    },
    data() {
        return {
            // chartData: [75, 25],
            chartLabels: ["Limit Used", ""],
            productInfoLoading: false,
            productInfoError: false,
            sumOfInsuredAmount: 0,
            sumOfPremiumOfAllPolicies: 0,
            totalPoliciesSold: 0,
        };
    },
    mounted() {
        this.getProductInfo();
    },
    methods: {
        async getProductInfo() {
            this.productInfoLoading = true;
            this.productInfoError = false;
            try {
                const response = await PolicyService.getProductInfo(
                    this.productName
                );
                this.sumOfInsuredAmount = response.data.sumOfInsuredAmount;
                this.sumOfPremiumOfAllPolicies =
                    response.data.sumOfPremiumOfAllPolicies;
                this.totalPoliciesSold = response.data.totalPoliciesSold;
            } catch (err) {
                this.productInfoError = true;
            } finally {
                this.productInfoLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.pie-chart-container {
    position: relative;
    height: 300px;
}
.loading-block {
    min-height: 200px;
}
</style>
