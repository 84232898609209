export default {
    metricKeyValueType: {
        "Total GWP": "currency",
        "Total Policies Sold": "number",
        "Total Active Policies": "number",
        "Total Active Quotes": "number",
        "No. Of Claims": "number",
        "Total Number of Quotes NTUd": "number",
        "Aggregate Limit Used": "currency",
        "Claims Paid (GBP)": "currency",
        "Claims Made (GBP)": "currency",
        "Average Premium Size": "currency",
        "Loss Adjustment Expenses": "currency",
    },
};
