export default {
    errorMessages: {
        firstNameError: "Please enter first name",
        lastNameError: "Please enter last name",
        dobError: "Please enter date of birth",
        landlineNumberError: "Please enter landline number",
        mobileNumberError: "Please enter mobile number",
        addressError: "Please enter your address",
        emailError: "Please enter a valid email address",
        countryError: "Please select a country",
        cityError: "Please select a city",
        zipcodeError: "Please enter a postcode",
        idProofError: "Please choose an ID proof",
        idNumberError: "Please enter ID number",
        frontViewError: "Please upload front side view",
        backViewError: "Please upload back side view",
        addressLineError: "Please provide address",
        payoutError: "Please select a Payout Type",
        getLocationError: "Failed to get location details",
        countrySupportError:
            "Currently ArchIQ only supports locations in Europe. Please contact our underwriting team if your event location is outside Europe.",
        invalidCountryError: "Please enter a valid country",
        pinLocationError: "Please pin your location",
        registrationError: "Failed to Register",
        userCreationError: "Failed to Create User",
        userUpdateError: "Failed to Update User",
        termsAcceptError: "Faild to accept terms",
        coverNotAvailable:
            "Currently not able to get cover in this location. Please try again later",
        triggerError: "Trigger Point cannot be  empty",
        triggerMaxError: "Trigger value should be greater than",
        triggerMinError: "Trigger value should be less than",
        selectedTriggerLevelError: "Please select a trigger level",
        premiumAmountError: "Premium cannot be empty",
        payoutCapError: "Payout Cap cannot be empty",
        averageRainfallError: "Average Rainfall cannot be empty",
        selectPayoutDescription: "Please select description",
        paymentIntentCreationError: "Failed to create payment intent",
        overLimitError:
            "The entered limit exceeds the system threshold, this quote will be referred to Arch.",
        paymentIntentDeleteError:
            "Failed to delete payment intent. Please contact admin",
        claimFailError: "Failed to make claim",
        zeroValueError: "Please enter a value >0",
        invalidEventStartDate:
            "Unable to quote as the selected start date must fall between 14 days and 18 months from today.",
        invalidEventEndDate:
            "Unable to quote as the selected end date must be within 18 months from today.",
        postcodeError: "Please enter a valid postcode",
        invalidEventCancellationV2CoverTypes: (email) => {
            return `This is not within "Event Cancellation" underwriting appetite for Arch IQ. Please contact underwriting team at <a href="mailto:${email}">${email}</a>.`;
        },
        quoteError: (type) => {
            return `Failed to ${type} quote`;
        },
        quoteMarkError: (type) => {
            return `Failed to mark quote ${type}`;
        },
        loadFailError: (type) => {
            return `Failed to load ${type}`;
        },
        notFoundError: (type) => {
            return `No ${type} found`;
        },
        notFoundWithFiltersError: (type) => {
            return `No ${type} found with applied filters`;
        },
        fieldCannotEmptyError: (type) => {
            return `${type} cannot be empty`;
        },
        dropdownSelectError: (type) => {
            return `Please select ${type}`;
        },
        calculateFailError: (type) => {
            return `Failed to calculate ${type}`;
        },
        minAmountError: (amount) => {
            return `Value should be at least ${amount}`;
        },
        metricError: (type) => {
            return `Failed to ${type} metric`;
        },
    },
    infoMessages: {
        saveBank: "Save the bank for future purpose",
        saveCard: "Save card for future purpose",
        bankWebsiteRedirection:
            "We will redirect you to your bank’s website to authorise the payment",
        policyInfoDescription:
            "Thank you for purchasing a policy. Below you can find all the information relating to your cover.",
        quoteInfoDescription:
            "Thank you for requesting a quote. If you accept the offer of insurance, the information below will be used to form your policy.",
        eventDateInfo:
            "Selected date must be between 14 days and 18 months from today.",
        cancellationRequestCreated: "A Cancellation Request has been made",
        documentGenerating: "New documentation is being generated",
        underwriterReviewMessage:
            "*This change is waiting to be approved or declined by the underwriter.",
        underwriterDeclineMessage:
            "*The MTA made was underwriter declined. The policy has been reverted to its original state.",
        brokerReviewMessage:
            "*This change is waiting to be approved or declined by the broker.",
        brokerDeclineMessage:
            "*The MTA made was broker declined. The policy has been reverted to its original state.",
        quoteNtuStatus: "Quote has been marked as NTU",
    },
    successMessages: {
        registrationSuccess: "Registration successfully completed",
        userCreationSuccess: "User created successfully",
        userUpdationSuccess: "User updated successfully",
        underwriterDeclineSuccess: "MTA Underwriter Declined Successfully",
        ntuStatusSuccess: "This Quote has now been marked as NTU",
        referralSuccess: (isPolicy) => {
            return `This ${
                isPolicy ? "Policy" : "Quote"
            } has now been referred to the Underwriter`;
        },
        quoteSuccess: (type) => {
            return `Quote ${type} Successfully`;
        },
        policySuccess: (type) => {
            return `Policy ${type} Successfully`;
        },
        metricSuccess: (type) => {
            return `Metric ${type} Successfully`;
        },
    },
};
