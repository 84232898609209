<template>
    <div class="bev-card layout-with-nav fixed-height-container" :class="type">
        <div
            class="d-flex justify-content-between align-items-center flex-wrap"
        >
            <heading-block
                :align="type"
                :heading="heading"
                :description="description"
                :headingSizeClass="'font-22 sm-font-16'"
                :descriptionSizeClass="'font-16 sm-font-12'"
                :class="{ 'w-100': !haveExtraHeaderItem }"
                class="sm-mb-10px"
            ></heading-block>
            <slot name="header-extra" v-if="haveExtraHeaderItem"></slot>
        </div>
        <div
            class="navigation-items position-sticky top-0 z-index-2"
            v-if="showNavigationItems"
        >
            <div
                v-for="option in options"
                :key="option.key"
                @click="
                    () =>
                        selectedOption !== option.key &&
                        option.clickHandler(option)
                "
                class="list-item font-16 sm-font-12"
                :class="{ active: selectedOption === option.key }"
            >
                {{ option.name }}
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>
<script>
/**
 * Layout with navigation component
 * @param {String} heading heading text for layout
 * @param {String} description description text for layout
 * @param {String} selectedOption selected navigation item
 * @param {Array} options navigation options list
 * @param {Boolean} showNavigationItems check if need to show navigation items
 * @param {String} type alignment for the layout
 **/
export default {
    props: {
        heading: String,
        description: String,
        selectedOption: String,
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        showNavigationItems: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: "center",
        },
        haveExtraHeaderItem: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
