<template>
    <div class="position-relative table-responsive">
        <div
            class="table-container"
            :style="{ height: `calc(${tableHeight})` }"
        >
            <div
                class="inner-content"
                :style="[
                    allowPagination && totalPages > 1
                        ? { height: 'calc(100% - 60px)' }
                        : { height: '100%' },
                ]"
            >
                <table class="bev-table table" v-if="!tableError">
                    <caption class="d-none">
                        {{
                            subDomainDetail.name
                        }}
                        Table
                    </caption>
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                v-for="(header, index) in headers"
                                :key="index"
                            >
                                <div
                                    class="d-flex align-items-center cursor-pointer"
                                    @click="
                                        applySort(
                                            header.key,
                                            header.sorting,
                                            header.noSort
                                        )
                                    "
                                >
                                    <p>{{ header.label }}</p>
                                    <bev-image
                                        v-if="
                                            header.sorting &&
                                            appliedSortColumn === header.key
                                        "
                                        :addBackground="true"
                                        :backgroundColor="
                                            $colors.bevColors.baseDark
                                        "
                                        :imageAlt="'arrowdown'"
                                        :imageUrl="$imagePath.arrowdown"
                                        width="7px"
                                        height="12px"
                                        class="sort-arrow ml-10px"
                                        :class="{
                                            up: header.sorting === 'ASC',
                                        }"
                                    ></bev-image>
                                </div>
                            </th>
                            <th v-if="tableItemClickHandler"></th>
                        </tr>
                    </thead>
                    <tbody v-if="data.length > 0 && !tableLoading">
                        <tr v-for="(item, index) in data" :key="index">
                            <td
                                v-for="(value, property) in item"
                                :key="property"
                                :data-label="property"
                                v-show="property !== 'id'"
                                :class="property"
                            >
                                <span
                                    v-if="isValObject(value)"
                                    :class="value.type + ' ' + value.class"
                                >
                                    {{ value.data }}
                                </span>
                                <span
                                    :class="
                                        'ml-5px ' +
                                        value.type +
                                        ' ' +
                                        value.extraBadge.class
                                    "
                                    v-if="
                                        isValObject(value) && value.extraBadge
                                    "
                                    >{{ value.extraBadge.data }}</span
                                >
                                <span v-if="!isValObject(value)">{{
                                    value
                                }}</span>
                            </td>
                            <td v-if="tableItemClickHandler">
                                <span
                                    class="text-linkcolor font-12 cursor-pointer"
                                    @click="tableItemClickHandler(item.id)"
                                    >{{ tableActionText }}</span
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
                <bev-loading-block
                    class="table-loading"
                    v-if="data.length === 0 || tableLoading || tableError"
                    :loading="tableLoading"
                    :error="tableError"
                    :retryHandler="retryHandler"
                    :errorMessage="errorMessage"
                    :errorImage="
                        tableError ? $imagePath.alert : $imagePath.covers
                    "
                />
            </div>
            <Pagination
                v-if="allowPagination && totalPages > 1"
                class="mx-auto"
                :totalRecords="totalData"
                :recordsPerPage="paginationSize"
                :pageNumber="pageNumber"
                @pageChanged="pageChangeHandler"
            />
        </div>
    </div>
</template>

<script>
/**
 * BEV Table component
 *
 * @param {String} tableHeight for setting height of the table
 * @param {Array} headers for setting headers of the table
 * @param {Array} data for setting body data of the table
 * @param {Boolean} tableLoading for showing loader in the table
 * @param {Boolean} tableError for showing error block in the table
 * @param {Function} retryHandler function to trigger retry call for the table
 * @param {Number} totalData to set totalRecords for the table
 * @param {Number} paginationSize to set size of the paginatin for the table data
 * @param {Number} pageNumber to set the pageNumber for the table data
 * @param {Number} totalPages to set the total pages for the table data
 * @param {Function} pageChangeHandler handler to change page for the table data
 * @param {Boolean} allowPagination to set if pagination is to be set for the table data
 * @param {Function} tableItemClickHanlder handler to be used on table item click
 * @param {String} errorMessage error message to be shown if tableError is true
 */
import Pagination from "@/components/Common/Pagination.vue";
export default {
    props: {
        tableHeight: String,
        headers: Array,
        data: Array,
        tableLoading: {
            type: Boolean,
            default: false,
        },
        tableError: {
            type: Boolean,
            default: false,
        },
        retryHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        totalData: {
            type: Number,
            default: 0,
        },
        paginationSize: {
            type: Number,
            default: 10,
        },
        pageNumber: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 0,
        },
        pageChangeHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        allowPagination: {
            type: Boolean,
            default: false,
        },
        tableItemClickHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        errorMessage: String,
        appliedSortColumn: String,
        tableActionText: {
            type: String,
            default: "View Details",
        },
    },
    data() {
        return {
            sortColumn: "",
            sortOrder: "ASC",
        };
    },
    emits: ["sortingChanged"],
    components: {
        Pagination,
    },
    methods: {
        applySort(headerKey, sorting, noSort) {
            if (!noSort) {
                if (sorting) {
                    this.$emit("sortingChanged", {
                        appliedColumn: sorting === "ASC" ? headerKey : "",
                        column: headerKey,
                        order: sorting === "ASC" ? "DESC" : "",
                    });
                } else {
                    this.$emit("sortingChanged", {
                        appliedColumn: headerKey,
                        column: headerKey,
                        order: "ASC",
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.table-container {
    min-height: 400px;
    overflow: hidden;
}
table {
    font-family: inherit;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
}

table thead {
    color: var(--tableHeaderColor);
}

table tr {
    background-color: transparent;
    height: max-content;
    transition: 0.3s all ease-in-out;
}

/* table tr:hover {
    cursor: pointer;
    background: var(--baseDark);
} */

table td {
    color: var(--tableDataColor);
    border-bottom: 1px solid var(--tableDataBorder);
}

table td span {
    max-width: 300px;
    display: block;
    white-space: normal;
}

table td span.badge {
    display: unset;
}

table th {
    letter-spacing: normal;
    background: var(--tableHeaderBg);
}

.pagination {
    height: 60px;
}

.inner-content {
    height: calc(100% - 100px);
    overflow: auto;
    border-bottom: 1px solid var(--tableDataBorder);
}
.up {
    transform: rotate(180deg);
}
</style>
