<template>
    <div
        class="svg-image"
        :class="{ 'cursor-pointer': clickHandler }"
        :style="setImageStyle()"
        @click="clickHandler"
        v-tooltip="{ content: tooltipText }"
    >
        <img
            :src="imageUrl"
            :alt="imageAlt"
            v-if="!addBackground"
            class="w-100 h-100"
        />
    </div>
</template>

<script>
/**
 *
 * @param {Boolean} addBackground if true adds default background to div
 * @param {String} backgroundColor for adding background color if addBackground is true
 * @param {String} imageUrl for image source
 * @param {String} height image height
 * @param {String} width image width
 * @param {String} imageAlt image alt tag
 * @param {Function} clickHandler click handler for the image
 * @param {String} tooltipText text to show in tooltip
 */

export default {
    props: {
        addBackground: Boolean,
        backgroundColor: String,
        imageUrl: String,
        height: String,
        width: String,
        imageAlt: String,
        clickHandler: { type: Function, default: () => {} },
        tooltipText: {
            type: String,
            default: () => {
                return "";
            },
        },
    },
    methods: {
        setImageStyle() {
            if (this.addBackground) {
                return {
                    background: this.backgroundColor,
                    "-webkit-mask-image": `url(${this.imageUrl})`,
                    "mask-image": `url(${this.imageUrl})`,
                    height: this.height,
                    width: this.width,
                    "min-width": this.width,
                };
            } else {
                return {
                    height: this.height,
                    width: this.width,
                    "min-width": this.width,
                };
            }
        },
    },
};
</script>
