<template>
    <div
        class="main-container"
        :class="{ 'sidebar-visible': showNavbarSidebar }"
    >
        <navbar v-if="showNavbarSidebar"></navbar>
        <div id="main-content" class="main-content-target"></div>
        <div
            class="main-section d-flex align-items-center"
            :class="{
                'navbar-visible': showNavbarSidebar,
                'sidebar-hidden': !showNavbarSidebar || !isAuthenticated,
            }"
        >
            <global-notification
                :style="[
                    showNavbarSidebar
                        ? { top: '95px !important' }
                        : { top: '40px !important' },
                ]"
            ></global-notification>
            <sidebar
                class="my-auto z-index-1"
                v-if="showNavbarSidebar && isAuthenticated"
            ></sidebar>
            <div
                class="main-content mt-auto position-relative z-index-1"
                :class="{
                    'sidebar-visible': showNavbarSidebar && isAuthenticated,
                    'sidebar-collapsed': sidebarCollapsed,
                }"
            >
                <router-view
                    :class="{ 'my-40px sm-my-20px': showNavbarSidebar }"
                ></router-view>
            </div>
            <img
                v-if="subDomainDetail.contentBgImage"
                :src="subDomainDetail.contentBgImage"
                alt="card-bg"
                class="position-absolute bottom-0 end-0 w-100 h-100 opacity-2"
            />
        </div>
        <footer class="d-flex align-items-center justify-content-between">
            <div
                class="d-flex align-items-center h-100 flex-1"
                v-if="!subDomainDetail.hidePoweredBy"
            >
                <bev-image
                    :addBackground="false"
                    :backgroundColor="'white'"
                    imageAlt="PoweredBy"
                    :imageUrl="$imagePath.logo"
                    width="22px"
                    height="22px"
                    class="mr-5px"
                ></bev-image>
                <p class="font-14">Powered By BirdsEyeView</p>
            </div>
            <div
                class="d-flex align-items-center justify-content-end h-100 w-100 flex-1"
                v-if="subDomainDetail.termsUrl || subDomainDetail.privacyUrl"
            >
                <a
                    class="cursor-pointer text-activeColorV1 mx-5px font-14"
                    :href="subDomainDetail.privacyUrl"
                    target="_blank"
                    v-if="subDomainDetail.privacyUrl"
                    >Privacy Policy</a
                >
                <a
                    class="cursor-pointer text-activeColorV1 mx-5px font-14"
                    :href="subDomainDetail.termsUrl"
                    target="_blank"
                    v-if="subDomainDetail.termsUrl"
                    >Terms of Use</a
                >
            </div>
        </footer>
    </div>
</template>

<script>
/**
    Main Container for the application, every route is being rendered in this component
**/
import Navbar from "@/components/Layout/Navbar/MainNav";
import Sidebar from "@/components/Layout/Sidebar/MainSidebar";
import GlobalNotification from "@/components/Common/GlobalNotification.vue";
import { mapMutations, mapState } from "vuex";
export default {
    data() {
        return {
            secureRoutes: ["register", "terms"],
        };
    },
    components: {
        Navbar,
        Sidebar,
        GlobalNotification,
    },
    computed: {
        ...mapState("auth", ["isAuthenticated"]),
        ...mapState(["sidebarCollapsed"]),
        // Computed property to check if we need to show sidebar, if route is secure we don't need to show sidebar (register, terms)
        showNavbarSidebar() {
            let secureRouteIndex = this.secureRoutes.findIndex((routeName) => {
                return this.findInString(this.$route.path, routeName);
            });
            return secureRouteIndex < 0;
        },
    },
    mounted() {
        this.$store.commit("hideLoader");
    },
    methods: {
        ...mapMutations("buyPolicy", ["clearPolicyValues", "setProductsJSON"]),
    },
    watch: {
        $route: function (to) {
            // If user navigate to any page except QuoteDetail and BuyPolicy we need to clear BuyPolicy store values
            if (!to?.meta?.keepPolicyValues) {
                this.clearPolicyValues("SelectProduct");
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    background: var(--baseBg);
}
.main-container.sidebar-visible {
    background: var(--baseBg);
}
.main-content-target {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);
}
footer {
    position: relative;
    z-index: 2;
    height: 40px;
    padding: 0 40px;
    background: transparent;
    color: var(--footerColor);
}
</style>
