<template>
    <div class="sidebar-list d-flex flex-column justify-content-between h-100">
        <div class="sidebar-list-items position-relative z-index-1">
            <router-link
                :to="item.route"
                v-for="(item, index) in filteredSidebarItems"
                :key="index"
                v-slot="{ isActive, navigate }"
                custom
            >
                <sidebar-list-item
                    :itemInfo="item"
                    :active="isActive"
                    :navigate="navigate"
                    :sidebarCollapsed="sidebarCollapsed"
                ></sidebar-list-item>
            </router-link>
            <sidebar-list-item
                v-if="$store.state.subDomainDetail.waUrl && waEnabled"
                :itemInfo="weatherAnalyticLink"
                :navigate="weatherAnalyticLink.navigate"
                :sidebarCollapsed="sidebarCollapsed"
            >
            </sidebar-list-item>
            <div
                class="active-bar position-absolute"
                :style="{
                    top: checkActiveBar.top,
                    display: checkActiveBar.display,
                }"
            ></div>
        </div>
        <div class="z-index-1 sidebar-list-items bottom">
            <sidebar-list-item
                class="logout"
                :itemInfo="{
                    iconUrl: $imagePath.logout,
                    text: 'Logout',
                    dimension: {
                        width: '22px',
                        height: '22px',
                    },
                }"
                :sidebarCollapsed="sidebarCollapsed"
                :navigate="$auth.logout"
            ></sidebar-list-item>
            <sidebar-list-item
                class="collapse"
                :itemInfo="{
                    iconUrl: $imagePath.caretDown,
                    text: 'Collapse Sidebar',
                    dimension: {
                        width: '22px',
                        height: '12px',
                    },
                    tooltipText: 'Expand Sidebar',
                }"
                :sidebarCollapsed="sidebarCollapsed"
                :navigate="collapseSidebar"
            ></sidebar-list-item>
        </div>
    </div>
</template>

<script>
/**
 * Sidebar List Component
 **/
import SidebarListItem from "@/components/Layout/Sidebar/SidebarListItem";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
    data() {
        return {
            sidebarItems: [
                {
                    iconUrl: this.$imagePath.dashboard,
                    route: "/dashboard",
                    text: "Dashboard",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isClaims"],
                },
                {
                    iconUrl: this.$imagePath.covers,
                    route: "/policies",
                    text: "Policies",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                },
                {
                    iconUrl: this.$imagePath.quotes,
                    route: "/quotes",
                    text: "Quotes",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isNormalUser", "isClaims"],
                },
                {
                    iconUrl: this.$imagePath.covers,
                    route: "/referrals",
                    text: "Referrals",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isNormalUser", "isClaims", "isBroker"],
                },
                {
                    iconUrl: this.$imagePath.productManager,
                    route: "/product-dashboard",
                    text: "Product Dashboard",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isNormalUser", "isClaims"],
                },
                {
                    iconUrl: this.$imagePath.analyticsDashboard,
                    route: "/analytics",
                    text: "Analytics",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isNormalUser", "isClaims", "isBroker"],
                },
                {
                    iconUrl: this.$imagePath.clientManagement,
                    route: "/client-management",
                    text: "Client Management",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isNormalUser", "isClaims"],
                },
                {
                    iconUrl: this.$imagePath.clientManagement,
                    route: "/user-management",
                    text: "User Management",
                    dimension: {
                        width: "22px",
                        height: "22px",
                    },
                    rolesHiddenOn: ["isNormalUser", "isClaims", "isBroker"],
                },
            ],
            weatherAnalyticLink: {
                iconUrl: this.$imagePath.weatherAnalytix,
                text: "WEATHER ANALYTIX™",
                dimension: {
                    width: "22px",
                    height: "22px",
                },
                navigate: () => {
                    let a = document.createElement("a");
                    a.href = this.$store.state.subDomainDetail.waUrl;
                    a.target = "_blank";
                    a.click();
                },
            },
        };
    },
    components: {
        SidebarListItem,
    },
    computed: {
        ...mapState(["sidebarCollapsed", "subDomainDetail"]),
        ...mapGetters("auth", [
            "isNormalUser",
            "isBroker",
            "isAdmin",
            "isUnderwriter",
            "isClaims",
        ]),
        waEnabled() {
            return this.isUnderwriter;
        },
        // Computed property to check active item and set a green active bar on right of the item
        checkActiveBar() {
            let index = this.filteredSidebarItems.findIndex((item) => {
                return (
                    this.$route.fullPath.includes(item.route + "/") ||
                    this.$route.path === item.route
                );
            });

            if (index > -1) {
                return {
                    top: `${60 * index}px`,
                    display: "block",
                };
            } else {
                return {
                    top: "0px",
                    display: "none",
                };
            }
        },
        filteredSidebarItems() {
            return this.sidebarItems.filter((item) => {
                let hasAccess = true;
                if (item.rolesHiddenOn) {
                    item.rolesHiddenOn.forEach((role) => {
                        if (this[role]) {
                            hasAccess = false;
                        }
                    });
                }
                if (item.route === "/product-dashboard") {
                    if (this.subDomainDetail.hideProductDashboard) {
                        hasAccess = false;
                    }
                }
                return hasAccess;
            });
        },
    },
    methods: {
        ...mapMutations(["setSidebarCollapsed"]),
        collapseSidebar() {
            this.setSidebarCollapsed(!this.sidebarCollapsed);
        },
    },
};
</script>

<style scoped>
/* .visibilityHidden {
    opacity: 0;
    transition: all 0.5s ease-in-out;
} */
</style>
