import config from "@/envConfig.js";
import APIService from "./APIService";

export default class PolicyService extends APIService {
    static getPolicies(page, size, requestBody, sorting) {
        return this.post(
            `${config.policyServiceUrl}/policies?page=${page}&size=${size}&sort=${sorting}`,
            requestBody
        );
    }

    static getSinglePolicy(policyId, params) {
        return this.get(
            `${config.policyServiceUrl}/policies/${policyId}${params}`
        );
    }

    static updatePolicy(policyId, requestBody) {
        return this.put(
            `${config.policyServiceUrl}/policies/${policyId}`,
            requestBody
        );
    }

    static claimPolicy(policyId, requestBody) {
        return this.put(
            `${config.policyServiceUrl}/policies/${policyId}/claim`,
            requestBody
        );
    }

    static getProductInfo(productName) {
        return this.get(
            `${config.policyServiceUrl}/productmanager/${productName}`
        );
    }

    static getPolicyMTA(page, size, policyId, type) {
        return this.get(
            `${config.policyServiceUrl}/policies/mta/${policyId}?page=${page}&size=${size}&type=${type}`
        );
    }

    static clonePolicy(quoteId) {
        return this.get(`${config.policyServiceUrl}/policies/clone/${quoteId}`);
    }

    static downloadBordereuReport(requestBody) {
        return this.post(
            config.bordereauDownloadUrl,
            requestBody,
            null,
            "blob"
        );
    }

    static getPolicyMTAReferrals(data) {
        return this.post(
            `${config.policyServiceMtaReferralUrl}/referrals`,
            data
        );
    }
}
