<template>
    <div
        class="call-us mb-10px mt-30px d-flex sm-flex-column align-items-center sm-align-items-start justify-content-between"
    >
        <heading-block
            :align="'left'"
            :heading="`Have any questions?`"
            :description="`<p>If you have any questions about the policy, then don’t hesitate to contact us at <a href='mailto:${subDomainDetail.clientEmail}'>${subDomainDetail.clientEmail}</a></p>`"
            :headingSizeClass="'font-18 sm-font-14'"
            :descriptionSizeClass="'font-14 sm-font-10'"
        ></heading-block>
        <!-- <a
            href="tel:+442074594104"
            class="d-flex align-items-center bev-btn bev-btn-white small sm-mt-20px"
        >
            Call Us
        </a> -->
    </div>
</template>

<script></script>

<style scoped>
.call-us {
    background-color: var(--baseShade1);
    padding: 16px 24px;
    border-radius: 6px;
}
a {
    color: var(--baseLight);
}
.bev-btn-light {
    width: max-content;
}
.pdf-button {
    padding: 8px 14px;
    border: 0.5px solid var(--activeColorV1);
    border-radius: 4px;
    font-size: 12px;
}
</style>
