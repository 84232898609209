<template>
    <div class="d-flex flex-column justify-content-between list-container">
        <div
            class="row ml-0 mr-0 mt-20px px-5px"
            v-if="quotes.length > 0 && !quoteLoading && !quoteError"
        >
            <div
                v-for="(quote, index) in quotes"
                :key="index"
                class="col-sm-12 col-md-4 col-lg-4 px-5px"
            >
                <QuoteCard
                    :products="
                        quote.products
                            ? formatProductsString(quote.products)
                            : '----'
                    "
                    :quoteAppliedOn="
                        formatDateValue(
                            quote.creationTime,
                            'normal',
                            'dd MMMM yyyy'
                        )
                    "
                    :id="quote.id"
                    :quoteNumber="quote.quoteNumber"
                />
            </div>
        </div>
        <bev-loading-block
            v-if="quotes.length === 0 || quoteLoading || quoteError"
            :loading="quoteLoading"
            :error="quoteError"
            :retryHandler="getQuotes.bind(this, pageNumber)"
            :errorMessage="
                quoteError ? 'Failed to laod quotes' : 'No quotes yet'
            "
            :errorImage="quoteError ? $imagePath.alert : $imagePath.covers"
        />
        <Pagination
            class="mx-auto mt-20px mb-20px"
            :totalRecords="totalData"
            :recordsPerPage="paginationSize"
            :pageNumber="pageNumber"
            @pageChanged="pageChangeHandler"
            v-if="totalPages > 1"
        />
    </div>
</template>

<script>
import Pagination from "@/components/Common/Pagination.vue";
import QuoteCard from "@/components/Views/Quotes/QuoteCard.vue";
export default {
    components: {
        Pagination,
        QuoteCard,
    },
    props: {
        quotes: Array,
        quoteLoading: {
            type: Boolean,
            default: false,
        },
        quoteError: {
            type: Boolean,
            default: false,
        },
        toggleDialog: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        getQuotes: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        totalData: {
            type: Number,
            default: 0,
        },
        paginationSize: {
            type: Number,
            default: 10,
        },
        pageNumber: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 0,
        },
        pageChangeHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
    },
    methods: {
        formatProductsString(products) {
            return Object.keys(JSON.parse(products))
                .map((p) => {
                    return this.$policyValues.productLabels[p];
                })
                .join(", ");
        },
    },
};
</script>
