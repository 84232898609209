<template>
    <div class="bev-container buy-policy">
        <div
            class="pb-30px pt-50px px-20px w-100 h-100 overflow-auto d-flex justify-content-between flex-column"
        >
            <heading-block
                :align="'center'"
                :heading="'Product Dashboard'"
                :description="'Get an overview of your associated products and track important metrics'"
                :headingSizeClass="'font-22 sm-font-16'"
                :descriptionSizeClass="'font-16 sm-font-12'"
            />
            <bev-dialog
                :title="'Link Generated'"
                :description="'You can copy and share the link below'"
                :closeDialog="toggleLinkGenerationPopup"
                :state="generationSuccessPopup"
                :width="'auto'"
                :actions="{
                    show: false,
                }"
            >
                <template v-slot:dialog-content>
                    <CopyLink :createdLink="createdLink" />
                </template>
            </bev-dialog>

            <bev-loading-block
                v-if="fetchingGroupId || fetchGroupIdError"
                :loading="fetchingGroupId"
                :error="fetchGroupIdError"
                :retryHandler="getUserGroupDetail"
                errorMessage="Failed to fetch broker organization"
            />

            <bev-form
                class="product-dashboard-form"
                :submitData="createLinkHandler.bind(this, true)"
                :submitButton="{
                    text: 'Generate Link',
                    buttonType: 'submit',
                    loading: creatingLink,
                }"
                showSingleButton
                :showActions="renderCreateQuickLink"
                v-if="!fetchingGroupId && !fetchGroupIdError"
            >
                <template v-slot:input-fields>
                    <div class="product-dashboard-form-content pt-20px mt-30px">
                        <div class="form-fields mb-40px">
                            <div class="dropdown-container mb-20px">
                                <bev-dropdown
                                    :containerClass="'brokers'"
                                    :dropDownLabel="'Select a Broker'"
                                    :haveError="
                                        v$.selectedBroker.$error &&
                                        v$.selectedBroker.required.$invalid
                                    "
                                    :ajax="true"
                                    :apiUrl="`${config.userServiceUrl}/group/org_type/BROKER`"
                                    :responseInfo="{
                                        resultKey: 'items',
                                        totalResultKey: 'total',
                                        pagination: true,
                                        uniqueKey: 'id',
                                    }"
                                    :errorMessage="'Please choose a Broker'"
                                    v-model="selectedBroker"
                                    :optionTextHandler="
                                        (option) => {
                                            return brokerNameHandler(option);
                                        }
                                    "
                                    v-if="isAdmin || isUnderwriter"
                                    class="m-auto"
                                ></bev-dropdown>
                            </div>
                            <div class="dropdown-container">
                                <bev-dropdown
                                    :containerClass="'products'"
                                    :dropDownLabel="'Select a product'"
                                    :haveError="
                                        v$.selectedProduct.$error &&
                                        v$.selectedProduct.required.$invalid
                                    "
                                    :ajax="true"
                                    :apiUrl="`${config.productServiceUrl}/product/search`"
                                    :apiRequestBody="
                                        () => {
                                            return {
                                                brokerId: selectedBrokerId,
                                            };
                                        }
                                    "
                                    :apiMethod="'post'"
                                    :responseInfo="{
                                        resultKey: 'content',
                                        totalResultKey: 'total',
                                        pagination: true,
                                        uniqueKey: 'id',
                                    }"
                                    :errorMessage="'Please choose a product'"
                                    v-model="selectedProduct"
                                    :optionTextHandler="
                                        (option) => {
                                            return option.label;
                                        }
                                    "
                                    :disabled="
                                        (isAdmin || isUnderwriter) &&
                                        !selectedBroker
                                    "
                                    class="m-auto"
                                ></bev-dropdown>
                            </div>
                        </div>
                        <ProductInfo
                            :productDetails="selectedProduct.whiteLabellingData"
                            :productName="selectedProduct.name"
                            :totalInsuredAmount="
                                selectedProduct?.totalInsuredAmount || 0
                            "
                            v-if="selectedProduct"
                        />
                    </div>
                </template>
            </bev-form>
        </div>
    </div>
</template>

<script>
/**
 * Create Quick Buy Quote sharabel link component
 */
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapState } from "vuex";
import UserService from "@/services/UserService";
import config from "@/envConfig.js";
import CopyLink from "@/components/Views/ProductDashboard/CopyLink.vue";
import ProductInfo from "@/components/Views/ProductDashboard/ProductInfo.vue";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    validations: {
        selectedProduct: {
            required,
        },
        selectedBroker: {
            required,
        },
    },
    data() {
        return {
            selectedProduct: null,
            selectedBroker: null,
            config: null,
            fetchingGroupId: false,
            fetchGroupIdError: false,
            generationSuccessPopup: false,
            daysOptions: [],
            creatingLink: false,
            createdLink: "",
        };
    },
    components: {
        CopyLink,
        ProductInfo,
    },
    computed: {
        ...mapGetters("auth", ["isAdmin", "isBroker", "isUnderwriter"]),
        ...mapState("auth", ["userGroups", "userUnderwriterId"]),
        renderCreateQuickLink() {
            if (this.subDomainDetail.renderDirectBuyLink) {
                return this.isAdmin || this.isBroker;
            }
            return false;
        },
        selectedBrokerId() {
            if (
                this.selectedBroker &&
                this.selectedBroker.attributes?.brokerId
            ) {
                return this.selectedBroker.attributes.brokerId[0];
            } else {
                return null;
            }
        },
        productRequestBody() {
            return {
                ...(this.isUnderwriter && {
                    underwriterId:
                        this.userUnderwriterId ||
                        this.subDomainDetail.underwriterId,
                }),
                ...((this.isBroker || this.isAdmin) && {
                    brokerId: this.selectedBrokerId,
                }),
            };
        },
    },
    created() {
        this.config = config;
        this.daysOptions = [...Array(18).keys()].map((i) => (i + 1) * 10);
        if (!this.isAdmin && !this.isUnderwriter) {
            this.getUserGroupDetail();
        }
    },
    methods: {
        createLinkHandler() {
            this.v$.$touch();
            if (!this.v$.$invalid && this.renderCreateQuickLink) {
                this.createQuickBuyLink();
            }
        },
        toggleLinkGenerationPopup() {
            this.generationSuccessPopup = !this.generationSuccessPopup;
        },
        async getUserGroupDetail() {
            let userGroup = this.userGroups[0];
            this.fetchingGroupId = true;
            this.fetchGroupIdError = false;
            try {
                let response = await UserService.getGroupDetailByName(
                    userGroup
                );
                this.selectedBroker = response.data[0];
                this.fetchingGroupId = false;
            } catch (err) {
                this.fetchingGroupId = false;
                this.fetchGroupIdError = true;
            }
        },
        async createQuickBuyLink() {
            this.creatingLink = true;
            this.createdLink = "";
            try {
                const response = await UserService.createQuickBuyLink({
                    product_name: this.selectedProduct.name,
                    expiry_time_sec:
                        this.selectedProduct.whiteLabellingData
                            .insuranceBufferTime || null,
                    product_domain:
                        this.selectedProduct?.whiteLabellingData?.domain ||
                        "directinsurance.birdseyeviewtechnologies.com:8080",
                });
                this.creatingLink = false;
                this.createdLink = response.data.link_url;
                this.toggleLinkGenerationPopup();
            } catch (err) {
                this.creatingLink = false;
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to create link"
                );
            }
        },
        brokerNameHandler(option) {
            return option?.attributes?.displayName?.[0] || option.name;
        },
    },
    watch: {
        selectedBroker: function (p, n) {
            if (p && n && p.id !== n.id) {
                this.selectedProduct = null;
            }
        },
    },
};
</script>

<style scoped>
.product-dashboard-form {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.form-fields {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
/* .product-dashboard-form-content {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
} */
</style>
