import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import Auth from "@/store/modules/Auth.js";
import BuyPolicy from "@/store/modules/BuyPolicy.js";
import Comment from "@/store/modules/Comment.js";
import Analytics from "@/store/modules/Analytics";
import ReferenceData from "@/store/modules/ReferenceData.js";
import UserService from "@/services/UserService.js";

const vuexLocalStorage = new VuexPersist({
    key: "vuex",
    storage: window.localStorage,
    reducer: (state) => ({
        auth: state.auth,
        buyPolicy: state.buyPolicy,
        referenceData: state.referenceData,
        subDomainDetail: state.subDomainDetail,
        productSubDomainDetail: state.productSubDomainDetail,
    }),
});

export const store = createStore({
    state: {
        isLoading: true,
        subDomainDetail: {},
        productSubDomainDetail: {},
        sidebarCollapsed: false,
        directBuyLoading: false,
        directBuyError: false,
        directBuyErrorMessage: "",
    },
    getters: {
        productRequestBody(state, getters, rootState) {
            return {
                underwriterId:
                    rootState.auth.userUnderwriterId ||
                    state.subDomainDetail.underwriterId,
            };
        },
        getTenantType(state) {
            let underwriterId = state.subDomainDetail.underwriterId;
            if (underwriterId === "arch" || underwriterId === "howden") {
                return underwriterId;
            }
            return "all";
        },
    },
    mutations: {
        showLoader(state) {
            state.isLoading = true;
        },
        hideLoader(state) {
            state.isLoading = false;
        },
        setSubDomainDetail(state, value) {
            localStorage.setItem("appName", value?.name || "");
            state.subDomainDetail = value;
        },
        setProductSubDomainDetail(state, value) {
            state.productSubDomainDetail = value;
        },
        setSidebarCollapsed(state, value) {
            state.sidebarCollapsed = value;
        },
        setDirectBuyLoading(state, value) {
            state.directBuyLoading = value;
        },
        setDirectBuyError(state, value) {
            state.directBuyError = value;
        },
        setDirectBuyErrorMessage(state, value) {
            state.directBuyErrorMessage = value;
        },
    },
    actions: {
        logoutUser({ commit, dispatch }) {
            dispatch("auth/logout");
            commit("buyPolicy/clearPolicyValues");
            commit("setSubDomainDetail", {});
            commit("setProductSubDomainDetail", {});
        },
        async getToken({ state }, callback) {
            state.directBuyLoading = true;
            state.directBuyError = false;
            state.directBuyErrorMessage = "";
            try {
                const urlParams = new URLSearchParams(window.location.search);
                let tokenId = urlParams.get("tokenId");
                const response = await UserService.quickBuyKeycloakToken(
                    tokenId
                );
                localStorage.setItem(
                    "quick_buy_access_token",
                    response.data.access_token
                );
                if (callback) {
                    callback();
                } else {
                    state.directBuyError = false;
                    state.directBuyErrorMessage = "";
                    state.directBuyLoading = false;
                }
            } catch (err) {
                state.directBuyError = true;
                state.directBuyErrorMessage =
                    "Failed to authenticate link. This link may be expired.";
                state.directBuyLoading = false;
            }
        },
    },
    modules: {
        auth: Auth,
        buyPolicy: BuyPolicy,
        comment: Comment,
        analytics: Analytics,
        referenceData: ReferenceData,
    },
    plugins: [vuexLocalStorage.plugin],
});
