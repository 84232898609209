<template>
    <div class="d-flex align-items-center mt-20px">
        <div class="flex-1 mr-5px">
            <p class="font-16 mb-10px">Total Gross Written Premium</p>
            <p class="fw-bold font-20 mb-10px text-activeColorV1">
                {{ thousandMask(sumOfPremiumOfAllPolicies, "currency") }}
            </p>
        </div>
        <div class="flex-1">
            <p class="font-16 mb-10px">Total Policies Sold</p>
            <p class="fw-bold font-20 mb-10px text-activeColorV1">
                {{ totalPoliciesSold }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        metrics: Object,
    },
    computed: {
        sumOfPremiumOfAllPolicies() {
            return this.metrics?.sumOfPremiumOfAllPolicies;
        },
        totalPoliciesSold() {
            return this.metrics?.totalPoliciesSold;
        },
    },
};
</script>
