<template>
    <div class="direct-buy">
        <bev-loading-block
            class="loading-block"
            v-if="directBuyLoading || directBuyError"
            :loading="directBuyLoading"
            :error="directBuyError"
            :showRetry="false"
            :errorMessage="directBuyErrorMessage"
        />
        <BuyPolicy
            isPublic
            :class="{ 'd-none': directBuyLoading || directBuyError }"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UserService from "@/services/UserService";
import ProductService from "@/services/ProductService";
import WhiteLabelling from "@/services/WhiteLabelling";
import BuyPolicy from "@/components/Views/BuyPolicy/Main.vue";

export default {
    computed: {
        ...mapState("buyPolicy", [
            "productsJSON",
            "currentStepIndex",
            "productsFlow",
            "policyBroker",
        ]),
        ...mapState("auth", ["isAuthenticated"]),
        ...mapState([
            "subDomainDetail",
            "productSubDomainDetail",
            "directBuyLoading",
            "directBuyError",
            "directBuyErrorMessage",
        ]),
        ...mapGetters(["productRequestBody"]),
    },
    components: {
        BuyPolicy,
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setProductsJSON",
            "setBuyPolicyStep",
            "setPolicyProduct",
            "clearPolicyValues",
            "setProductsFlow",
            "setCurrentStepIndex",
            "setIsDirectBuyFlow",
            "setPolicyBroker",
        ]),
        ...mapMutations([
            "setSubDomainDetail",
            "setProductSubDomainDetail",
            "setDirectBuyErrorMessage",
            "setDirectBuyError",
            "setDirectBuyLoading",
        ]),
        ...mapActions(["getToken"]),
        ...mapActions("referenceData", ["getProductKeyMap", "getCurrencyList"]),
        async getTokenCallBack() {
            await this.getAllProducts();
            await this.getProductKeyMap();
            await this.getCurrencyList();
        },
        async getAllProducts() {
            this.setDirectBuyLoading(true);
            this.setDirectBuyError(false);
            try {
                var response = await ProductService.searchProducts(
                    0,
                    1000,
                    this.productRequestBody
                );
                this.setProductsJSON(response.data.content);
                this.saveSelectedProduct();
            } catch (err) {
                this.setDirectBuyLoading(false);
                this.setDirectBuyError(true);
                this.setDirectBuyErrorMessage(
                    "Failed to get product information"
                );
            }
        },
        async getBrokerDetails(brokerId) {
            this.setDirectBuyLoading(true);
            try {
                let response = await UserService.getGroupDetailByName(brokerId);
                this.setPolicyBroker(response.data[0]);
            } catch (err) {
                this.setDirectBuyLoading(false);
                this.setDirectBuyError(true);
                this.setDirectBuyErrorMessage(
                    "Failed o fetch broker information"
                );
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        "Failed to get Broker Organization Details"
                );
            }
        },
        async saveSelectedProduct() {
            WhiteLabelling.getSubDomain();
            let selectedProductData = this.productsJSON.find((p) => {
                return p.name === this.$route.params.product;
            });
            if (selectedProductData) {
                await this.getBrokerDetails(selectedProductData.brokerId);
                this.setProductsFlow({
                    flow: selectedProductData.questionBundleSequence,
                    productName: this.$route.params.product,
                });
                this.setCurrentStepIndex(0);
                this.setPolicyProduct(this.$route.params.product);
                this.setBuyPolicyStep({
                    step: this.productsFlow[this.currentStepIndex].name,
                    back: false,
                });
                this.setSubDomainDetail({
                    ...this.subDomainDetail,
                    ...selectedProductData.whiteLabellingData,
                    image: this.subDomainDetail.image,
                });
                this.setProductSubDomainDetail(
                    selectedProductData.whiteLabellingData
                );
                this.setDirectBuyLoading(false);
            } else {
                this.setDirectBuyLoading(false);
                this.setDirectBuyError(true);
                this.setDirectBuyErrorMessage("Product not found");
            }
        },
        resetDirectBuyFlow() {
            this.setDirectBuyLoading(true);
        },
    },
    mounted() {
        if (!this.isAuthenticated) {
            this.setSubDomainDetail({
                ...this.subDomainDetail,
                name: "Loading..",
                image: "",
            });
            this.setProductSubDomainDetail({});
        }
        this.clearPolicyValues();
        this.setIsDirectBuyFlow(true);
        this.getToken(this.getTokenCallBack);
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.resetDirectBuyFlow);
        // this.$once("hook:beforeDestroy", () => {
        //   this.resetDirectBuyFlow();
        //   window.removeEventListener("beforeunload", this.resetDirectBuyFlow);
        // });
    },
};
</script>
