import { _curry } from "./function";
export const _get = _curry((path, object, defaultValue = undefined) => {
    if (!path) return defaultValue;
    const keys = path.split(".");
    const attribute = keys.reduce(
        (obj, prop) => (obj && obj[prop] != null ? obj[prop] : undefined),
        object
    );
    return attribute === undefined ? defaultValue : attribute;
});
export const _set = _curry((path, object, value) => {
    const keys = path.split(".");
    keys.reduce((obj, prop, index) => {
        if (!obj) {
            obj = {};
        }
        if (obj[prop] == null) {
            obj[prop] = {};
        }
        if (index === keys.length - 1) {
            obj[prop] = value;
        }
        return obj[prop];
    }, object);
    return object;
});
