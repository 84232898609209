<template>
    <form @submit.prevent="submitData">
        <slot name="input-fields"></slot>
        <div
            class="w-100 text-center mt-30px form-actions"
            :class="{ 'pb-30px': !noActionBottomPadding }"
            v-if="showActions"
        >
            <div
                class="d-flex align-items-center w-100 justify-content-center"
                v-if="!showSingleButton"
            >
                <actions
                    :buttonLightProperties="resetButton"
                    :buttonDarkProperties="submitButton"
                    :buttonLightDisabled="resetButton.disabled"
                    :buttonDarkDisabled="submitButton.disabled"
                    :align="'center'"
                    :class="buttonSize"
                    :switchButtonPlace="switchButtonPlace"
                ></actions>
            </div>
            <bev-button
                v-else
                :themeType="'dark'"
                :clickHandler="submitButton.clickHandler"
                :loading="submitButton.loading"
                :disabled="submitButton.disabled"
                :buttonType="'submit'"
                :class="buttonSize"
            >
                {{ submitButton.text }}
            </bev-button>
        </div>
    </form>
</template>

<script>
/**
 * 
 * @param {Function} submitData on form submit action
 * @param {Boolean} showSingleButton to hide/show reset button (secondary button)
 * @param {Object<{
                    text: "Cancel",
                    clickHandler: () => {},
                    buttonType: "button",
                }>} resetButton  for secondary button props
 * @param {Object<
                    text: "Submit",
                    clickHandler: () => {},
                    buttonType: "button",
                >} submitButton for primary button props
 * @param {Boolean} showActions check to show action button or not
 **/

export default {
    props: {
        submitData: {
            type: Function,
            default: () => {
                return () => {};
            },
            required: true,
        },
        showSingleButton: {
            type: Boolean,
            default: false,
        },
        submitButton: {
            type: Object,
            default: () => {
                return {
                    text: "Submit",
                    clickHandler: () => {},
                    buttonType: "submit",
                };
            },
        },
        resetButton: {
            type: Object,
            default: () => {
                return {
                    text: "Cancel",
                    clickHandler: () => {},
                    buttonType: "button",
                };
            },
        },
        showActions: {
            type: Boolean,
            default: true,
        },
        noActionBottomPadding: {
            type: Boolean,
            default: false,
        },
        buttonSize: {
            type: String,
            default: "",
        },
        switchButtonPlace: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
