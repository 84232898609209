<template>
    <div
        class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Event Cancellation : Financial Information and Event History`"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="
                    setEventCancellation.bind(this, false, false, true)
                "
            />
        </buy-policy-heading-block>
        <bev-form
            class="cancellation-form"
            :submitData="setEventCancellation.bind(this, true, false, true)"
            :showSingleButton="currentStepIndex === 0 || showSingleButton"
            :submitButton="submitButtonConfig"
            :resetButton="resetButtonConfig"
        >
            <template v-slot:input-fields>
                <div class="cancellation-form-content pt-20px mt-30px">
                    <div class="row">
                        <div class="col-sm-12">
                            <currency-dropdown
                                :haveError="
                                    v$.currency.$error &&
                                    v$.currency.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                v-model="v$.currency.$model"
                                :disabled="isCurrencyInputDisabled"
                            />
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <option-selector
                                :question="
                                    productLabelHandler(
                                        'eventHeldBeforeV2',
                                        'label'
                                    ) + '*'
                                "
                                :selectHandler="setEventHeldBeforeV2"
                                :error="
                                    v$.eventHeldBeforeV2.$error &&
                                    v$.eventHeldBeforeV2.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :selectedOption="eventHeldBeforeV2"
                                :options="['Yes', 'No']"
                            ></option-selector>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 mt-30px">
                            <bev-currency-input
                                :inputLabel="
                                    productLabelHandler(
                                        'insuredTotalExpensesV2',
                                        'label'
                                    ) + '*'
                                "
                                :haveError="
                                    checkError('insuredTotalExpensesV2')
                                        .haveError
                                "
                                :errorMessage="
                                    checkError('insuredTotalExpensesV2')
                                        .errorMessage
                                "
                                :inputClass="'insuredTotalExpensesV2'"
                                v-model="v$.insuredTotalExpensesV2.$model"
                                :currencySymbol="currentCurrencyCode"
                            ></bev-currency-input>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 mt-30px">
                            <bev-currency-input
                                :inputLabel="
                                    productLabelHandler(
                                        eventHeldBeforeV2 === 'Yes'
                                            ? 'insuredTotalRevenueV2'
                                            : 'insuredTotalRevenueV2:EvtNotHeldBefore',
                                        'label'
                                    )
                                "
                                :haveError="
                                    checkError('insuredTotalRevenueV2')
                                        .haveError
                                "
                                :errorMessage="
                                    checkError('insuredTotalRevenueV2')
                                        .errorMessage
                                "
                                :inputClass="'insuredTotalRevenueV2'"
                                v-model="v$.insuredTotalRevenueV2.$model"
                                :currencySymbol="currentCurrencyCode"
                            ></bev-currency-input>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-30px">
                            <bev-input
                                :modelValue="projectedNetProfit"
                                :inputLabel="
                                    productLabelHandler(
                                        eventHeldBeforeV2 === 'Yes'
                                            ? 'projectedNetProfit'
                                            : 'projectedNetProfit:EvtNotHeldBefore',
                                        'label'
                                    ) + '*'
                                "
                                disabled
                                class="projectedNetProfit"
                            ></bev-input>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <bev-dropdown
                                :options="masterCoverTypeV2Options"
                                :dropDownLabel="
                                    productLabelHandler(
                                        'coverTypeV2',
                                        'label'
                                    ) + '*'
                                "
                                :containerClass="'coverTypeV2'"
                                :haveError="
                                    v$.coverTypeV2.$error &&
                                    v$.coverTypeV2.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :infoImage="{
                                    show: true,
                                    info: coverTypeV2Tooltip,
                                }"
                                v-model="v$.coverTypeV2.$model"
                                :fullWidthInput="false"
                            ></bev-dropdown>
                        </div>
                        <div
                            class="col-sm-12 mt-30px"
                            v-if="coverTypeV2 === 'Agreed Value'"
                        >
                            <bev-dropdown
                                :options="insureLimitV2Options"
                                :dropDownLabel="
                                    productLabelHandler(
                                        'insureLimitV2',
                                        'label'
                                    ) + '*'
                                "
                                :containerClass="'insureLimitV2'"
                                :haveError="
                                    v$.insureLimitV2.$error &&
                                    v$.insureLimitV2.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :optionTextHandler="
                                    insureLimitV2OptionTextHandler
                                "
                                v-model="v$.insureLimitV2.$model"
                                :fullWidthInput="false"
                            ></bev-dropdown>
                        </div>
                        <div
                            class="col-sm-12 mt-30px"
                            v-if="insureLimitV2 === 'Custom Limit'"
                        >
                            <bev-currency-input
                                :inputLabel="
                                    productLabelHandler(
                                        'customLimitV2',
                                        'label'
                                    ) + '*'
                                "
                                :haveError="
                                    v$.customLimitV2.$error &&
                                    v$.customLimitV2.required.$invalid
                                "
                                :errorMessage="
                                    this.$messages.errorMessages.fieldCannotEmptyError(
                                        'Field'
                                    )
                                "
                                :inputClass="'customLimitV2'"
                                v-model="v$.customLimitV2.$model"
                                :currencySymbol="currentCurrencyCode"
                                :infoImage="{
                                    show: true,
                                    info: customLimitShouldBeLess,
                                }"
                            ></bev-currency-input>
                        </div>
                        <div
                            class="col-sm-12 mt-30px"
                            v-if="eventHeldBeforeV2 === 'Yes'"
                        >
                            <bev-dropdown
                                :options="eventHeldBeforeTimesV2Options"
                                :optionTextHandler="
                                    moreThanFiveOptionalTextHandler
                                "
                                :dropDownLabel="
                                    productLabelHandler(
                                        'eventHeldBeforeTimesV2',
                                        'label'
                                    ) + '*'
                                "
                                :containerClass="'eventHeldBeforeTimesV2'"
                                :haveError="
                                    v$.eventHeldBeforeTimesV2.$error &&
                                    v$.eventHeldBeforeTimesV2.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                v-model="v$.eventHeldBeforeTimesV2.$model"
                                :fullWidthInput="false"
                            ></bev-dropdown>
                        </div>
                        <div
                            class="col-sm-12 mt-30px"
                            v-if="eventHeldBeforeV2 === 'Yes'"
                        >
                            <bev-dropdown
                                :disabled="eventHeldBeforeTimesV2 === null"
                                :options="lossCountAfterCovidV2Options"
                                :optionTextHandler="
                                    moreThanFiveOptionalTextHandler
                                "
                                :dropDownLabel="
                                    productLabelHandler(
                                        'lossCountAfterCovidV2',
                                        'label'
                                    ) + '*'
                                "
                                :containerClass="'lossCountAfterCovidV2'"
                                :haveError="
                                    v$.lossCountAfterCovidV2.$error &&
                                    v$.lossCountAfterCovidV2.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                v-model="v$.lossCountAfterCovidV2.$model"
                                :fullWidthInput="false"
                            ></bev-dropdown>
                        </div>
                        <div
                            class="col-sm-12 mt-30px"
                            v-if="
                                eventHeldBeforeV2 === 'Yes' &&
                                showSettingDependent
                            "
                        >
                            <option-selector
                                :question="
                                    productLabelHandler(
                                        'affectedByWeatherV2',
                                        'label'
                                    )
                                "
                                :selectHandler="setAffectedByWeatherV2"
                                :error="
                                    v$.affectedByWeatherV2.$error &&
                                    v$.affectedByWeatherV2.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :selectedOption="affectedByWeatherV2"
                                :options="['Yes', 'No']"
                            ></option-selector>
                        </div>
                        <div
                            class="col-sm-12 mt-30px"
                            v-if="showSettingDependent"
                        >
                            <option-selector
                                :question="
                                    productLabelHandler(
                                        'proceedInSustainWindSpeedV2',
                                        'label'
                                    )
                                "
                                :selectHandler="setProceedInSustainWindSpeedV2"
                                :error="
                                    v$.proceedInSustainWindSpeedV2.$error &&
                                    v$.proceedInSustainWindSpeedV2.required
                                        .$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :selectedOption="proceedInSustainWindSpeedV2"
                                :options="['Yes', 'No']"
                                :infoImage="{
                                    show: true,
                                    info: moderateRainFallTooltip,
                                }"
                            ></option-selector>
                        </div>
                    </div>
                </div>
            </template>
        </bev-form>
        <SaveAsDraftButton :dataSaveHandler="setEventCancellation" />
    </div>
</template>

<script>
/**
 * QuestionBundle component for EventCancellationNew
 */
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import LivePricing from "@/services/LivePricing";
import customHtml from "@/constant/customHtml";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import OptionSelector from "@/components/Views/BuyPolicy/OptionSelector";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
import debounce from "lodash.debounce";
import currencyMixin from "@/mixin/currency";
import CurrencyDropdown from "@/components/Views/BuyPolicy/CurrencyDropdown.vue";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        CoverSummaryButton,
        OptionSelector,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
        CurrencyDropdown,
    },
    mixins: [currencyMixin],
    validations() {
        // Validations for every form field
        return {
            coverTypeV2: {
                required,
            },
            insuredTotalExpensesV2: {
                required,
                minValue: minValue(1),
            },
            insuredTotalRevenueV2: {
                required: this.editingFor === "policy" ? required : false,
            },
            eventHeldBeforeV2: {
                required,
            },
            insureLimitV2: {
                required,
            },
            eventHeldBeforeTimesV2: {
                required: this.eventHeldBeforeV2 === "Yes" ? required : false,
            },
            lossCountAfterCovidV2: {
                required: this.eventHeldBeforeV2 === "Yes" ? required : false,
            },
            customLimitV2: {
                required:
                    this.insureLimitV2 === "Custom Limit" ? required : false,
            },
            affectedByWeatherV2: {
                required:
                    this.eventHeldBeforeV2 === "Yes" &&
                    this.showSettingDependent
                        ? required
                        : false,
            },
            proceedInSustainWindSpeedV2: {
                required: this.showSettingDependent ? required : false,
            },
            currency: {
                required,
            },
        };
    },
    created() {
        this.coverTypeV2 =
            this.questionBundles["EventCancellationNew"]?.coverTypeV2 || "";
        this.insuredTotalExpensesV2 =
            this.questionBundles["EventCancellationNew"]
                ?.insuredTotalExpensesV2 || "";
        this.insuredTotalRevenueV2 =
            this.questionBundles["EventCancellationNew"]
                ?.insuredTotalRevenueV2 || "";
        this.eventHeldBeforeV2 =
            this.questionBundles["EventCancellationNew"]?.eventHeldBeforeV2 ||
            "";
        this.insureLimitV2 =
            this.questionBundles["EventCancellationNew"]
                ?.selectedInsureLimitV2Option || "";
        this.customLimitV2 =
            this.questionBundles["EventCancellationNew"]
                ?.selectedInsureLimitV2Option === "Custom Limit"
                ? this.questionBundles["EventCancellationNew"]?.insureLimitV2
                : "";
        this.eventHeldBeforeTimesV2 =
            this.questionBundles["EventCancellationNew"]
                ?.eventHeldBeforeTimesV2 || null;
        this.lossCountAfterCovidV2 =
            this.questionBundles["EventCancellationNew"]
                ?.lossCountAfterCovidV2 !== undefined
                ? this.questionBundles["EventCancellationNew"]
                      ?.lossCountAfterCovidV2
                : "";
        this.affectedByWeatherV2 =
            this.questionBundles["EventCancellationNew"]?.affectedByWeatherV2 ||
            "";
        this.proceedInSustainWindSpeedV2 =
            this.questionBundles["EventCancellationNew"]
                ?.proceedInSustainWindSpeedV2 || "";
        this.setInitialCurrency();
    },
    mounted() {
        this.getCoverTypes();
    },
    computed: {
        ...mapState("buyPolicy", [
            "questionBundles",
            "currentStepIndex",
            "editingFor",
        ]),
        ...mapGetters("buyPolicy", [
            "checkFlowComplete",
            "isCurrencyInputDisabled",
        ]),
        showSingleButton() {
            return (
                !this.masterCoverTypeV2Options.length ||
                this.gettingMasterCoverTypeOptions
            );
        },
        submitButtonConfig() {
            return this.showSingleButton
                ? this.resetButtonConfig
                : {
                      text: "Next",
                      buttonType: "submit",
                  };
        },
        resetButtonConfig() {
            return {
                text: "Back",
                clickHandler: this.setEventCancellation.bind(
                    this,
                    false,
                    true,
                    false
                ),
                buttonType: "button",
            };
        },
        currentCurrencyCode() {
            return this.currency.value;
        },
        insureLimtsWatchProperty() {
            if (this.eventHeldBeforeV2 && this.insuredTotalExpensesV2) {
                return (
                    this.eventHeldBeforeV2 +
                    this.insuredTotalExpensesV2 +
                    this.insuredTotalRevenueV2
                );
            }
            return "";
        },
        insureLimitValueV2() {
            if (this.insureLimitV2 === "Custom Limit") {
                return this.customLimitV2;
            }
            return this.insureLimitV2;
        },
        insureLimitV2Options() {
            let options = ["Custom Limit"];
            if (this.insuredTotalExpensesV2 >= this.insuredTotalRevenueV2) {
                options.unshift(this.insuredTotalExpensesV2);
            } else {
                options.unshift(this.insuredTotalRevenueV2);
            }
            return options;
        },
        eventHeldBeforeTimesV2Options() {
            return [1, 2, 3, 4, 6];
        },
        lossCountAfterCovidV2Options() {
            if (this.eventHeldBeforeTimesV2 === null) {
                return [];
            }
            return Array.from(
                Array(this.eventHeldBeforeTimesV2 + 1).keys()
            ).filter((opt) => opt !== 5);
        },
        eventSetting() {
            return this.questionBundles["EventDetails"]?.eventSetting;
        },
        showSettingDependent() {
            return (
                this.eventSettingCheck.findIndex(
                    (setting) => this.eventSetting === setting
                ) > -1
            );
        },
        projectedNetProfit() {
            if (this.insuredTotalExpensesV2 && this.insuredTotalRevenueV2) {
                let value = Number(
                    this.insuredTotalRevenueV2 - this.insuredTotalExpensesV2
                );
                if (value < 0) {
                    return "Loss Making Event";
                }
                return (
                    this.currentCurrencyCode + " " + this.thousandMask(value)
                );
            }
            return "----";
        },
        customLimitShouldBeLess() {
            return `Custom Limit should be less than ${this.productLabelHandler(
                "insuredTotalExpensesV2",
                "label"
            )} and ${this.productLabelHandler(
                this.eventHeldBeforeV2 === "Yes"
                    ? "insuredTotalRevenueV2"
                    : "insuredTotalRevenueV2:EvtNotHeldBefore",
                "label"
            )}`;
        },
        higherCustomLimitErrorMessage() {
            let higherLimit =
                this.insuredTotalExpensesV2 > this.insuredTotalRevenueV2
                    ? this.insuredTotalExpensesV2
                    : this.insuredTotalRevenueV2;
            if (
                this.insureLimitV2 === "Custom Limit" &&
                this.customLimitV2 >= higherLimit
            ) {
                return `Please enter a custom limit lower than “${this.productLabelHandler(
                    "insuredTotalExpensesV2",
                    "label"
                )}” or “${this.productLabelHandler(
                    this.eventHeldBeforeV2 === "Yes"
                        ? "insuredTotalRevenueV2"
                        : "insuredTotalRevenueV2:EvtNotHeldBefore",
                    "label"
                )}”, whichever is higher.`;
            }
            return "";
        },
    },
    data() {
        return {
            coverTypeV2: "",
            insuredTotalExpensesV2: "",
            insuredTotalRevenueV2: "",
            eventHeldBeforeV2: "",
            insureLimitV2: "",
            customLimitV2: "",
            eventHeldBeforeTimesV2: null,
            lossCountAfterCovidV2: "",
            affectedByWeatherV2: "",
            eventSettingCheck: ["Outdoor", "Under Temporary Structure"],
            proceedInSustainWindSpeedV2: "",
            gettingMasterCoverTypeOptions: false,
            masterCoverTypeV2Options: [],
            coverTypeV2Tooltip: customHtml.coverTypeV2Tooltip,
            moderateRainFallTooltip: customHtml.moderateRainFallTooltip,
            currency: {},
            coverTypesErrorMessage: "",
        };
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setQuestionBundle",
            "setBuyPolicyStep",
            "addIsInvalidData",
            "removeIsInvalidData",
        ]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        insureLimitV2OptionTextHandler(option) {
            if (option === "Custom Limit") {
                return option;
            }
            return this.thousandMask(option, "currency");
        },
        currencyRequestBody(searchVal) {
            return {
                metaDataFilters: [
                    {
                        key: "code",
                        value: searchVal,
                        mandatory: false,
                    },
                    {
                        key: "name",
                        value: searchVal,
                        mandatory: false,
                    },
                ],
            };
        },
        setInitialCurrency() {
            let initialCurrency = this.findCurrencyByCode(
                this.getCurrencyCode()
            );
            if (initialCurrency) {
                this.currency = {
                    value: initialCurrency.code,
                    metaData: initialCurrency,
                };
            }
        },
        getCoverTypeHandler: debounce(function () {
            this.getCoverTypes();
        }, 500),
        async getCoverTypes() {
            this.gettingMasterCoverTypeOptions = true;
            try {
                let res = await LivePricing.livePricingCore({
                    product: "arch_contingency",
                    query: "partial_rating",
                    productConfig: {
                        productName: "event_cancellation",
                        eventDetails: {
                            eventStartDate:
                                this.questionBundles["EventDetails"]
                                    ?.eventStartDate,
                            eventType:
                                this.questionBundles["EventDetails"]?.eventType,
                            eventSetting:
                                this.questionBundles["EventDetails"]
                                    ?.eventSetting,
                        },
                        specifics: {
                            eventHeldBeforeV2: this.eventHeldBeforeV2,
                            insuredTotalExpensesV2: this.insuredTotalExpensesV2,
                            insuredTotalRevenueV2: this.insuredTotalRevenueV2,
                        },
                    },
                });
                if (res.data?.body?.keys) {
                    this.masterCoverTypeV2Options = res.data.body;
                }
                if (!this.masterCoverTypeV2Options.length) {
                    this.coverTypesErrorMessage =
                        this.$messages.errorMessages.invalidEventCancellationV2CoverTypes(
                            this.subDomainDetail.clientEmail
                        );
                }
                if (this.coverTypeV2) {
                    if (
                        this.masterCoverTypeV2Options.indexOf(
                            this.coverTypeV2
                        ) < 0
                    ) {
                        this.coverTypeV2 = null;
                        this.insureLimitV2 = null;
                    }
                }
            } catch (error) {
                this.displayErrorMessage(
                    "Failed to fetch cover types. Please try reloading"
                );
            } finally {
                this.gettingMasterCoverTypeOptions = false;
            }
        },
        setEventHeldBeforeV2(choice) {
            this.eventHeldBeforeV2 = choice;
        },
        setAffectedByWeatherV2(choice) {
            this.affectedByWeatherV2 = choice;
        },
        setProceedInSustainWindSpeedV2(choice) {
            this.proceedInSustainWindSpeedV2 = choice;
        },
        moreThanFiveOptionalTextHandler(option) {
            return option === 6 ? "5+" : option;
        },
        checkError(key) {
            if (
                this.v$[key]?.$error &&
                this.v$[key]?.required &&
                this.v$[key]?.required.$invalid
            ) {
                return {
                    haveError: true,
                    errorMessage:
                        this.$messages.errorMessages.fieldCannotEmptyError(
                            "Field"
                        ),
                };
            } else if (
                this.v$[key]?.$error &&
                this.v$[key].minValue &&
                this.v$[key].minValue.$invalid
            ) {
                return {
                    haveError: true,
                    errorMessage: this.$messages.errorMessages.zeroValueError,
                };
            }
            return {
                haveError: false,
                errorMessage: "",
            };
        },
        showOverLimitWarning() {
            this.$notify({
                group: "BEV",
                title: "Alert",
                text: this.$messages.errorMessages.overLimitError,
                type: "warning",
            });
        },
        setEventCancellation(forNext, forBack, notSaveForDraft) {
            // Handler to take every filled data and set into EventCancellationNew questionBundle
            this.updateDataBundleWithValidations({
                bundleName: "EventCancellationNew",
                bundleData: {
                    coverTypeV2: this.coverTypeV2,
                    insuredTotalExpensesV2: this.insuredTotalExpensesV2,
                    insuredTotalRevenueV2: this.insuredTotalRevenueV2,
                    eventHeldBeforeV2: this.eventHeldBeforeV2,
                    selectedInsureLimitV2Option: this.insureLimitV2,
                    insureLimitV2: this.insureLimitValueV2,
                    insuredLimitValueV2: this.insureLimitValueV2,
                    eventHeldBeforeTimesV2: this.eventHeldBeforeTimesV2,
                    lossCountAfterCovidV2: this.lossCountAfterCovidV2,
                    affectedByWeatherV2: this.affectedByWeatherV2,
                    proceedInSustainWindSpeedV2:
                        this.proceedInSustainWindSpeedV2,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.gettingMasterCoverTypeOptions) {
                        this.$notify({
                            group: "BEV",
                            title: "Info",
                            text: "Please wait we are checking the quote details.",
                            type: "warning",
                        });
                        return;
                    }
                    if (this.higherCustomLimitErrorMessage) {
                        this.displayErrorMessage(
                            this.higherCustomLimitErrorMessage
                        );
                        return;
                    }
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
        },
        setInsureLimitAsPerCoverType(setEmptyOnElse) {
            if (this.coverTypeV2 === "Expenses - Indemnity") {
                this.insureLimitV2 = this.insuredTotalExpensesV2;
            } else if (
                this.coverTypeV2 === "Gross Revenue - Indemnity" ||
                this.coverTypeV2 === "Pre-contracted Gross Revenue - Indemnity"
            ) {
                this.insureLimitV2 = this.insuredTotalRevenueV2;
            } else {
                if (setEmptyOnElse) {
                    this.insureLimitV2 = "";
                }
            }
        },
    },
    watch: {
        coverTypesErrorMessage: function (val) {
            if (val) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.invalidEventCancellationV2CoverTypes(
                        this.subDomainDetail.clientEmail
                    )
                );
            }
        },
        higherCustomLimitErrorMessage: function (value) {
            if (value) {
                this.displayErrorMessage(this.higherCustomLimitErrorMessage);
            }
        },
        coverTypeV2: function (value) {
            if (value) {
                this.removeIsInvalidData("eventCancellationV2CoverTypes");
                this.setInsureLimitAsPerCoverType();
            }
        },
        insureLimitV2Options: function () {
            if (!this.insureLimitV2Options.includes(this.insureLimitV2)) {
                this.setInsureLimitAsPerCoverType(true);
            }
        },
        eventHeldBeforeTimesV2: function () {
            if (this.eventHeldBeforeTimesV2 < this.lossCountAfterCovidV2) {
                this.lossCountAfterCovidV2 = null;
            }
        },
        insureLimtsWatchProperty: function () {
            this.getCoverTypeHandler();
        },
        insureLimitV2: function (val) {
            if (val !== "Custom Limit") {
                this.customLimitV2 = "";
                if (val > 5000000) {
                    this.showOverLimitWarning();
                }
            }
        },
        eventHeldBeforeV2: function (val) {
            if (val !== "Yes") {
                this.eventHeldBeforeTimesV2 = null;
                this.lossCountAfterCovidV2 = null;
                this.affectedByWeatherV2 = null;
            }
        },
        showSettingDependent: function (val) {
            if (!val) {
                this.affectedByWeatherV2 = null;
                this.proceedInSustainWindSpeedV2 = null;
            }
        },
    },
};
</script>

<style scoped>
.cancellation-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cancellation-form-content {
    width: 700px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>

<style>
.projectedNetProfit.disabled fieldset {
    opacity: 1;
}
</style>
