<template>
    <div
        class="pt-50px pb-20px sm-pt-30px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="'Event Details'"
            :description="'What event would you like to cover?'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="
                    setEventDetailsData.bind(this, false, false, true)
                "
            />
        </buy-policy-heading-block>
        <bev-form
            class="detail-form"
            :submitData="setEventDetailsData.bind(this, true, false, true)"
            :showSingleButton="false"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
                loading: false,
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setEventDetailsData.bind(
                    this,
                    false,
                    true,
                    false
                ),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <div class="detail-form-content pt-20px mt-30px">
                    <div class="row">
                        <div class="col-sm-12">
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="
                                    productLabelHandler('eventName', 'label') +
                                    '*'
                                "
                                :haveError="
                                    v$.eventName.$error &&
                                    v$.eventName.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Field'
                                    )
                                "
                                :inputClass="'event_name'"
                                v-model="v$.eventName.$model"
                            ></bev-input>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <date-input
                                :inputLabel="'Event Start Date*'"
                                :haveError="
                                    v$.startDate.$error &&
                                    v$.startDate.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Event Start Date'
                                    )
                                "
                                :disabledDate="disbaleDates"
                                :defaultDate="defaultStartDate"
                                :dateValueType="'timestamp'"
                                v-model="v$.startDate.$model"
                                :infoImage="{
                                    show: true,
                                    info: $messages.infoMessages.eventDateInfo,
                                }"
                                :higlightDates="higlightDates"
                            ></date-input>
                            <p
                                class="text-input-info-message font-12 ml-5px mt-5px fst-italic"
                                v-if="parametricMinimumStartError"
                            >
                                Note: Parametric Rain cannot be added later for
                                these Event Dates, as it needs to be purchased
                                14 days in advance.
                            </p>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <date-input
                                :haveError="
                                    v$.endDate.$error &&
                                    v$.endDate.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Event End Date'
                                    )
                                "
                                :inputLabel="'Event End Date*'"
                                :disabledDate="disbaleDates"
                                :defaultDate="defaultEndDate"
                                :dateValueType="'timestamp'"
                                v-model="v$.endDate.$model"
                                :infoImage="{
                                    show: true,
                                    info: $messages.infoMessages.eventDateInfo,
                                }"
                                :higlightDates="higlightDates"
                            ></date-input>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <bev-dropdown
                                :dropDownLabel="'Event Type*'"
                                :containerClass="'event_type'"
                                :ajax="true"
                                :apiUrl="config.livePricingUrl + '/pricing'"
                                :apiMethod="'post'"
                                :apiRequestBody="eventTypeDropdownRequestBody"
                                :responseInfo="eventTypeDropdownResponseInfo"
                                :haveError="
                                    v$.eventType.$error &&
                                    v$.eventType.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'Event Type'
                                    )
                                "
                                :optionTextHandler="eventTypeOptionHandler"
                                :searchKey="'searchVal'"
                                :searchInParam="false"
                                :otherOptionKey="''"
                                allowOther
                                v-model="v$.eventType.$model"
                                :fullWidthInput="false"
                                :customMaxHeight="customEventTypeMaxHeight"
                            ></bev-dropdown>
                        </div>
                        <div
                            class="col-sm-12 mt-20px"
                            v-if="otherEventTypeSelected"
                        >
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="`Enter Event Type*`"
                                :inputClass="'event_type_input'"
                                :haveError="
                                    v$.customEventType.$error &&
                                    v$.customEventType.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Event Type'
                                    )
                                "
                                v-model="v$.customEventType.$model"
                            ></bev-input>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <bev-dropdown
                                :options="eventSettings"
                                :dropDownLabel="
                                    productLabelHandler(
                                        'eventSetting',
                                        'label'
                                    ) + '*'
                                "
                                :containerClass="'event_state'"
                                :haveError="
                                    v$.eventSetting.$error &&
                                    v$.eventSetting.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :infoImage="{
                                    show: true,
                                    info: 'Is the event roughly held 75% indoors, outdoors, or under a temporary structure?',
                                }"
                                v-model="v$.eventSetting.$model"
                                :fullWidthInput="false"
                            ></bev-dropdown>
                        </div>
                    </div>
                </div>
            </template>
        </bev-form>
        <SaveAsDraftButton :dataSaveHandler="setEventDetailsData" />
    </div>
</template>

<script>
/**
 * QuestionBundle component for EventDetails
 */
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import add from "date-fns/add";
import config from "@/envConfig.js";
import DateInput from "@/components/Common/Input/DateInput";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
import validateProductAndDataMixin from "@/mixin/validateProductAndData";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        DateInput,
        CoverSummaryButton,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
    },
    mixins: [validateProductAndDataMixin],
    created() {
        this.config = config;
        this.eventName = this.questionBundles["EventDetails"]?.eventName || "";
        this.startDate =
            this.questionBundles["EventDetails"]?.eventStartDate || null;
        this.endDate =
            this.questionBundles["EventDetails"]?.eventEndDate || null;
        this.eventSetting =
            this.questionBundles["EventDetails"]?.eventSetting || "";
        this.initialiseEventType();
    },
    computed: {
        ...mapState("buyPolicy", [
            "products",
            "questionBundles",
            "productsJSON",
        ]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        ...mapGetters("auth", ["isNormalUser", "isBroker"]),
        eventTypeDropdownResponseInfo() {
            return { resultKey: "body" };
        },
        currentDate() {
            // Compute minimum start date. It should be at least 2 days after today's date
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        },
        higlightDates() {
            if (this.isBroker || this.isNormalUser) {
                return [this.currentDate, add(this.currentDate, { days: 14 })];
            }
            return [];
        },
        defaultStartDate() {
            if (!this.endDate) {
                return add(this.currentDate, { days: 14 });
            }
            let d = new Date(this.endDate);
            let expectedStartDate = new Date();
            expectedStartDate.setMonth(d.getMonth());
            expectedStartDate.setFullYear(d.getFullYear());
            return expectedStartDate;
        },
        defaultEndDate() {
            if (!this.startDate) {
                return add(this.currentDate, { days: 14 });
            }
            let d = new Date(this.startDate);
            let expectedEndDate = new Date();
            expectedEndDate.setMonth(d.getMonth());
            expectedEndDate.setFullYear(d.getFullYear());
            return expectedEndDate;
        },
        invalidStartDate() {
            if (this.startDate) {
                let minimumStartDate = this.formatToUtcDate(
                    add(this.currentDate, { days: 14 })
                );
                let maximumStartDate = this.formatToUtcDate(
                    add(this.currentDate, { months: 18 })
                );
                return (
                    this.startDate <= minimumStartDate ||
                    this.startDate > maximumStartDate
                );
            }
            return false;
        },
        invalidEndDate() {
            if (this.endDate) {
                let minimumEndDate = this.formatToUtcDate(
                    add(this.currentDate, { days: 14 })
                );
                let maximumEndDate = this.formatToUtcDate(
                    add(this.currentDate, { months: 18 })
                );
                return (
                    this.endDate > maximumEndDate ||
                    this.endDate <= minimumEndDate
                );
            }
            return false;
        },
        invalidDateErrorMessage() {
            let messages = [];
            if (this.invalidStartDate) {
                messages.push(
                    this.$messages.errorMessages.invalidEventStartDate
                );
            }
            if (this.invalidEndDate) {
                messages.push(this.$messages.errorMessages.invalidEventEndDate);
            }
            return messages;
        },
        showDateErrorMessage() {
            return (
                this.invalidDateErrorMessage.length &&
                (this.isNormalUser || this.isBroker)
            );
        },
        otherEventTypeSelected() {
            return this.eventTypeOptionHandler(this.eventType) === "Other";
        },
        dateValidation() {
            return this.startDate + this.endDate;
        },
        eventCancellationV2Validation() {
            if (
                this.startDate &&
                this.endDate &&
                this.eventType &&
                this.eventSetting
            ) {
                return (
                    this.startDate +
                    this.endDate +
                    this.eventType +
                    this.eventSetting
                );
            }
            return "";
        },
    },
    validations() {
        return {
            // Validations for every form field
            eventName: {
                required,
            },
            startDate: {
                required,
            },
            endDate: {
                required,
            },
            eventType: {
                required,
            },
            customEventType: {
                required: this.otherEventTypeSelected ? required : false,
            },
            eventSetting: {
                required,
            },
        };
    },
    data() {
        return {
            config: null,
            eventName: "",
            startDate: null,
            endDate: null,
            eventType: null,
            customEventType: null,
            eventSettings: ["Indoor", "Outdoor", "Under Temporary Structure"],
            eventSetting: "",
            parametricMinimumStartError: false,
            parametricMaximumEndError: false,
            customEventTypeMaxHeight: "260px",
            selectedEventCategories: [],
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setBuyPolicyStep"]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        initialiseEventType() {
            const { eventType, customEventType } =
                this.questionBundles["EventDetails"] || {};
            this.eventType = eventType || "";
            this.customEventType = customEventType || "";
        },
        changeDropHeight(open) {
            if (open) {
                this.customEventTypeMaxHeight = "150px";
            } else {
                this.customEventTypeMaxHeight = "260px";
            }
        },
        onSelectEventCategory(categories) {
            this.selectedEventCategories = categories;
        },
        eventTypeOptionHandler(option) {
            return option;
        },
        disbaleDates(date) {
            // Disabled dates for Start Date
            return date < this.currentDate;
        },
        eventTypeDropdownRequestBody(searchVal) {
            return {
                product: "arch_contingency",
                query: "keys",
                productConfig: {
                    returnKeys: "key_1",
                    productName: "event_cancellation",
                    filterByKeys1: searchVal,
                    filterByKeys2: this.selectedEventCategories.join(","),
                },
            };
        },
        displayDateErrors() {
            this.invalidDateErrorMessage.forEach((message) => {
                this.displayErrorMessage(message);
            });
        },
        async setEventDetailsData(forNext, forBack, notSaveForDraft) {
            // Handler to take every filled data and set into EventDetails questionBundle
            this.updateDataBundleWithValidations({
                bundleName: "EventDetails",
                bundleData: {
                    eventName: this.eventName,
                    eventStartDate: this.startDate,
                    eventEndDate: this.endDate,
                    eventType: this.eventTypeOptionHandler(this.eventType),
                    customEventType: this.otherEventTypeSelected
                        ? this.customEventType
                        : "",
                    eventSetting: this.eventSetting,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.showDateErrorMessage) {
                        this.displayDateErrors();
                        return;
                    }
                    await this.validateProducts();
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
        },
    },
    watch: {
        eventCancellationV2Validation: function () {
            this.getEventCancellationV2CoverTypes();
        },
        startDate: function (value) {
            // Whenever startDate changes
            if (value) {
                // If startDate is not nul;
                let minimumStartDate = add(this.currentDate, { days: 14 });
                let isParametricWeatherEnabled = this.productsJSON.find(
                    (p) => p.name === "parametric_weather"
                )?.isEnabled;
                if (value < minimumStartDate && isParametricWeatherEnabled) {
                    // If startDate is less than minimum parametric start date, show a warning flag
                    this.parametricMinimumStartError = true;
                } else {
                    this.parametricMinimumStartError = false;
                }
                if (this.endDate && value > this.endDate) {
                    this.endDate = null;
                }
            } else {
                this.parametricMinimumStartError = false;
            }
        },
        endDate: function (value) {
            if (value) {
                if (this.startDate && value < this.startDate) {
                    this.startDate = null;
                }
            }
        },
        dateValidation: function () {
            if (this.showDateErrorMessage) {
                this.displayDateErrors();
            }
        },
    },
};
</script>

<style scoped>
.detail-form {
    flex-direction: column;
    justify-content: space-between;
}
.detail-form-content {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>
