<template>
    <canvas :id="chartId" class="w-100 h-100"></canvas>
</template>

<script>
/**
 * Render a Pie Graph
 *
 * @param {String} chartId Id for the chart canvas
 * @param {String} graphColor Font Color for the graph text
 * @param {Array} labels Labels for the Graph
 * @param {Array} graphData Data plot points for the graph
 * @param {String} chartLabel label for the pie chart
 * @param {Array} bgColors background colors for pire chart
 * @param {Number} borderWidth border width for pie chart bars
 * @param {Array} borderColors border colors for pire chart
 */
import { markRaw } from "vue";
import Chart from "chart.js/auto";
export default {
    props: {
        chartId: String,
        graphColor: String,
        labels: {
            type: Array,
            default: () => {
                return [];
            },
        },
        graphData: {
            type: Array,
            default: () => {
                return [];
            },
        },
        chartLabel: {
            type: String,
            default: "",
        },
        bgColors: {
            type: Array,
            default: () => {
                return ["black", ""];
            },
        },
        borderColors: {
            type: Array,
            default: () => {
                return ["black", ""];
            },
        },
        borderWidth: {
            type: Number,
            default: 0,
        },
        showPerentage: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        Chart.defaults.font.family = "'Open Sans', sans-serif";
        Chart.defaults.color = this.graphColor;
        Chart.defaults.font.size = 14;
        this.renderChart();
    },
    data() {
        return {
            chart: null,
        };
    },
    methods: {
        renderChart() {
            let ctx = document.getElementById(this.chartId).getContext("2d");
            if (this.chart !== null) {
                this.chart.destroy();
            }
            let data = {
                datasets: [
                    {
                        label: this.chartLabel,
                        data: this.graphData,
                        backgroundColor: this.bgColors,
                        borderColor: this.borderColors,
                        borderWidth: this.borderWidth,
                        cutout: "85%",
                    },
                ],
                labels: [...this.labels],
            };
            const chart = new Chart(ctx, {
                type: "doughnut",
                data: data,
                cutoutPercentage: 200,
                options: {
                    onResize: (context) => {
                        if (this.showPerentage) {
                            let percentText = document.getElementById(
                                `percentText_${this.chartId}`
                            );
                            if (!percentText) {
                                percentText = document.createElement("p");
                                percentText.id = `percentText_${this.chartId}`;
                                percentText.setAttribute(
                                    "class",
                                    "percentText"
                                );
                                percentText.innerText =
                                    context.data.datasets[0].data[0] + "%";
                                context.canvas.parentNode.appendChild(
                                    percentText
                                );
                            }

                            percentText.style.left = "50%";
                            percentText.style.top = "50%";
                            percentText.style.transform =
                                "translate(-50%, -50%)";
                        }
                    },
                    maintainAspectRatio: true,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                },
            });
            this.chart = markRaw(chart);

            for (let i = 0; i <= data.datasets[0].data.length - 2; i++) {
                this.chart.data.labels[this.chart.data.labels.length - 1] = "";
            }

            this.chart.update();
            let titleHandler = (data, tooltipItem) => {
                return (
                    "Aggregate Limit Used: " +
                    data[tooltipItem[0].dataIndex] +
                    "%"
                );
            };
            let tooltipFilter = (tooltipItem) => {
                return tooltipItem.dataIndex < 1;
            };
            this.setChartProperties(
                this.chart,
                titleHandler,
                () => {},
                {
                    bgColor: this.$colors.bevColors.baseDark,
                    borderColor: this.$colors.bevColors.activeColorV3,
                    shadowColor: this.$colors.bevColors.shadowColor,
                },
                true,
                tooltipFilter,
                16
            );
        },
    },
};
</script>

<style>
.percentText {
    font-size: 30px;
    position: absolute;
    font-weight: bold;
}
</style>
