<template>
    <bev-form
        class="backdate-bind-form"
        :submitData="bindWithBackdate"
        :showSingleButton="false"
        :submitButton="{
            text: 'Bind',
            buttonType: 'submit',
        }"
        :resetButton="{
            text: 'Cancel',
            clickHandler: cancelBind,
            buttonType: 'button',
        }"
    >
        <template v-slot:input-fields>
            <div class="backdate-bind-form-content">
                <div class="row">
                    <div class="col-sm-12 mt-30px">
                        <date-input
                            :inputLabel="'Backdate Day*'"
                            :haveError="
                                v$.backdate.$error &&
                                v$.backdate.required.$invalid
                            "
                            :errorMessage="
                                $messages.errorMessages.fieldCannotEmptyError(
                                    'Back Date'
                                )
                            "
                            :dateValueType="'timestamp'"
                            :disabledDate="disbaleDatesBind"
                            v-model="v$.backdate.$model"
                            appendToBody
                        ></date-input>
                    </div>
                </div>
            </div>
        </template>
    </bev-form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import sub from "date-fns/sub";
import DateInput from "@/components/Common/Input/DateInput";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        cancelBind: Function,
        bindQuote: Function,
        details: Object,
    },
    components: {
        DateInput,
    },
    validations: {
        backdate: {
            required,
        },
    },
    computed: {
        formattedBackdate() {
            if (this.backdate) {
                let backDate = new Date(this.backdate).setHours(0, 0, 0, 0);
                let currentDate = new Date();
                let last72hr = sub(currentDate, { hours: 72 });
                let quoteCreation = new Date(this.details.creationTime);
                let earliestDate =
                    quoteCreation < last72hr ? last72hr : quoteCreation;
                if (backDate < earliestDate) {
                    return new Date(backDate).setHours(
                        earliestDate.getHours(),
                        earliestDate.getMinutes(),
                        earliestDate.getSeconds(),
                        earliestDate.getMilliseconds()
                    );
                }
                return backDate;
            }
            return null;
        },
    },
    data() {
        return {
            backdate: null,
        };
    },
    methods: {
        disbaleDatesBind(date) {
            // Disabled dates for Start Date
            let currentDate = new Date().setHours(0, 0, 0, 0);
            let last72hr = sub(currentDate, { hours: 72 }).setHours(0, 0, 0, 0);
            let quoteCreation = new Date(this.details.creationTime).setHours(
                0,
                0,
                0,
                0
            );

            if (last72hr > quoteCreation) {
                return date < last72hr || date > currentDate;
            } else {
                return date < quoteCreation || date > currentDate;
            }
        },
        async bindWithBackdate() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.bindQuote(this.formattedBackdate);
            }
        },
    },
};
</script>
