<template>
    <div class="adjustment-details">
        <template v-for="(message, index) in statusMessages" :key="index">
            <p
                v-if="message.condition"
                :class="['mr-20px mb-10px', message.class]"
            >
                <i>{{ message.text }}</i>
            </p>
        </template>

        <p class="font-16 mb-10px fw-600" v-if="selectedOption === 'policy'">
            Reason:
            <span class="text-activeColorV1">{{ reason || "----" }}</span>
        </p>

        <BevTable
            class="adjustment-table"
            :tableHeight="'auto'"
            :headers="adjustmentHeaders"
            :data="formattedAdjustmentChanges"
            :appliedSortColumn="null"
            :tableItemClickHandler="null"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import BevTable from "@/components/Common/Table/Main.vue";
export default {
    props: {
        adjustmentChanges: Array,
        reason: {
            type: String,
            default: "",
        },
        selectedOption: String,
        isReferredMTA: {
            type: Boolean,
            default: false,
        },
        isDeclinedMTA: {
            type: Boolean,
            default: false,
        },
        isMTAUnderReview: {
            type: Boolean,
            default: false,
        },
        isMTAReviewDeclined: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BevTable,
    },
    data() {
        return {
            adjustmentHeaders: [
                {
                    key: "fieldName",
                    label: "Field Name",
                },
                {
                    key: "oldValue",
                    label: "Old Value",
                },
                {
                    key: "newValue",
                    label: "New Value",
                },
            ],
        };
    },
    computed: {
        ...mapState("buyPolicy", ["addedQuote"]),
        statusMessages() {
            return [
                {
                    condition: this.isReferredMTA,
                    class: "text-yellow",
                    text: this.$messages.infoMessages.underwriterReviewMessage,
                },
                {
                    condition: this.isDeclinedMTA,
                    class: "text-danger",
                    text: this.$messages.infoMessages.underwriterDeclineMessage,
                },
                {
                    condition: this.isMTAUnderReview,
                    class: "text-yellow",
                    text: this.$messages.infoMessages.brokerReviewMessage,
                },
                {
                    condition: this.isMTAReviewDeclined,
                    class: "text-danger",
                    text: this.$messages.infoMessages.brokerDeclineMessage,
                },
            ];
        },
        formattedAdjustmentChanges() {
            return this.adjustmentChanges.map((change) => {
                let splittedName = change.fieldName.split(".");
                if (splittedName[0] === "products") {
                    if (splittedName.length > 1) {
                        if (splittedName[2]) {
                            if (splittedName[2] === "addOns") {
                                return this.addonsKeyFormatter(
                                    change,
                                    splittedName
                                );
                            } else if (splittedName[2] === "premium") {
                                return this.premiumKeyFormatter(
                                    change,
                                    splittedName
                                );
                            } else if (splittedName[2] === "quickExtensions") {
                                return this.quickExtensionsFormatter(
                                    change,
                                    splittedName
                                );
                            } else if (
                                splittedName[2] === "premiumBySubProduct"
                            ) {
                                return this.premiumBySubProductFormatter(
                                    change,
                                    splittedName
                                );
                            }
                        }
                        let dataKey = splittedName[2];
                        let oldValue = change.oldValue;
                        let newValue = change.newValue;
                        if (
                            dataKey === "eventHeldBeforeTimesV2" ||
                            dataKey === "lossCountAfterCovidV2"
                        ) {
                            oldValue = this.handleMoreThanFiveValue(oldValue);
                            newValue = this.handleMoreThanFiveValue(newValue);
                        }
                        return {
                            fieldName:
                                this.$policyValues.productLabels[
                                    splittedName[1]
                                ] +
                                " - " +
                                this.productLabelHandler(
                                    splittedName[2],
                                    "label"
                                ),
                            oldValue: this.valueFormatter(dataKey, oldValue),
                            newValue: this.valueFormatter(dataKey, newValue),
                        };
                    } else {
                        return {
                            fieldName: "Products",
                            oldValue: this.valueFormatter(
                                "products",
                                change.oldValue
                            ),
                            newValue: this.valueFormatter(
                                "products",
                                change.newValue
                            ),
                        };
                    }
                } else if (splittedName[0] === "endorsements") {
                    return {
                        fieldName: this.productLabelHandler(
                            change.fieldName,
                            "label"
                        ),
                        oldValue: this.valueFormatter(
                            change.fieldName,
                            change.oldValue.replace(/[\[\]]/g, "")
                        ),
                        newValue: this.valueFormatter(
                            change.fieldName,
                            change.newValue.replace(/[\[\]]/g, "")
                        ),
                    };
                }
                return {
                    fieldName: this.productLabelHandler(
                        change.fieldName,
                        "label"
                    ),
                    oldValue: this.valueFormatter(
                        change.fieldName,
                        change.oldValue
                    ),
                    newValue: this.valueFormatter(
                        change.fieldName,
                        change.newValue
                    ),
                };
            });
        },
    },
    methods: {
        getRiskCode(productName, addOnName) {
            if (this.addedQuote) {
                let riskCode = "";
                if (addOnName) {
                    riskCode = JSON.parse(this.addedQuote.products)[productName]
                        .addOns[addOnName].riskCode;
                } else {
                    riskCode = JSON.parse(this.addedQuote.products)[productName]
                        .riskCode;
                }
                return riskCode ? " - " + riskCode : "";
            }
            return "";
        },
        premiumKeyFormatter(change, splittedName) {
            if (splittedName[4]) {
                let dataKey = splittedName[4];
                return {
                    fieldName:
                        this.$policyValues.productLabels[splittedName[1]] +
                        " - " +
                        this.$policyValues.productLabels[splittedName[3]] +
                        " - " +
                        "Net Premium By Risk Code" +
                        this.getRiskCode(splittedName[1], splittedName[3]),
                    oldValue: this.valueFormatter(dataKey, change.oldValue),
                    newValue: this.valueFormatter(dataKey, change.newValue),
                };
            } else {
                let dataKey = splittedName[2];
                return {
                    fieldName:
                        this.$policyValues.productLabels[splittedName[1]] +
                        " - " +
                        "Net Premium By Risk Code" +
                        this.getRiskCode(splittedName[1]),
                    oldValue: this.valueFormatter(dataKey, change.oldValue),
                    newValue: this.valueFormatter(dataKey, change.newValue),
                };
            }
        },
        addonsKeyFormatter(change, splittedName) {
            if (splittedName[3] && splittedName[4]) {
                let dataKey = splittedName[4];
                if (dataKey === "premium") {
                    return this.premiumKeyFormatter(change, splittedName);
                }
                return {
                    fieldName:
                        this.$policyValues.productLabels[splittedName[1]] +
                        " - " +
                        this.$policyValues.productLabels[splittedName[3]] +
                        " - " +
                        this.productLabelHandler(splittedName[4], "label"),
                    oldValue: this.valueFormatter(dataKey, change.oldValue),
                    newValue: this.valueFormatter(dataKey, change.newValue),
                };
            } else {
                let dataKey = splittedName[2];
                return {
                    fieldName:
                        this.$policyValues.productLabels[splittedName[1]] +
                        " - " +
                        this.productLabelHandler("addOns", "label"),
                    oldValue: this.valueFormatter(dataKey, change.oldValue),
                    newValue: this.valueFormatter(dataKey, change.newValue),
                };
            }
        },
        quickExtensionsFormatter(change, splittedName) {
            if (splittedName[3] && splittedName[5]) {
                let dataKey = splittedName[5];
                return {
                    fieldName:
                        this.$policyValues.productLabels[splittedName[1]] +
                        " - " +
                        this.$policyValues.productLabels[splittedName[3]] +
                        " - " +
                        this.productLabelHandler(splittedName[5], "label"),
                    oldValue: this.valueFormatter(dataKey, change.oldValue),
                    newValue: this.valueFormatter(dataKey, change.newValue),
                };
            } else {
                let dataKey = splittedName[2];
                return {
                    fieldName:
                        this.$policyValues.productLabels[splittedName[1]] +
                        " - " +
                        this.productLabelHandler(splittedName[2], "label"),
                    oldValue: this.valueFormatter(dataKey, change.oldValue),
                    newValue: this.valueFormatter(dataKey, change.newValue),
                };
            }
        },
        premiumBySubProductFormatter(change, splittedName) {
            let dataKey = splittedName[4];
            return {
                fieldName:
                    this.$policyValues.productLabels[splittedName[1]] +
                    " - " +
                    this.productLabelHandler(splittedName[3], "label") +
                    " - " +
                    this.productLabelHandler(splittedName[4], "label"),
                oldValue: this.valueFormatter(dataKey, change.oldValue),
                newValue: this.valueFormatter(dataKey, change.newValue),
            };
        },
        valueFormatter(fieldName, value) {
            switch (this.productLabelHandler(fieldName, "type")) {
                case "text":
                    return value || "----";
                case "text_capitalise":
                    return this.toTitleCase(value) || "----";
                case "currency":
                    return this.thousandMask(value, "currency") || "----";
                case "percentage":
                    return value ? value + "%" : "----";
                case "number":
                    return this.thousandMask(value) || "----";
                case "date":
                    return (
                        this.formatDateValue(
                            Number(value),
                            "normal",
                            "d MMMM yyyy"
                        ) || "----"
                    );
                case "boolean_text":
                    return value === "true" ? "Yes" : "No";
                case "boolean":
                    return value ? "Yes" : "No";
                case "policyStatus":
                    return (
                        this.$policyValues.policyStatus[value]?.text ||
                        value ||
                        "----"
                    );
                case "keySpeakerValue":
                    return this.keySpeakerFormatter(value);
                case "products":
                    return this.productsValueFormatter(value) || "----";
                case "array":
                    return (
                        JSON.parse(value)
                            .map((k) => this.productLabelHandler(k, "label"))
                            .join(", ") || "----"
                    );
                default:
                    return value || "----";
            }
        },
        keySpeakerFormatter(value) {
            let returnVal = "";
            if (!value) {
                return "----";
            }
            let valList = JSON.parse(value);
            for (let i = 0; i < valList.length; i++) {
                returnVal += `Name: ${valList[i].name}, Age: ${valList[i].age}\n`;
            }
            return returnVal;
        },
        productsValueFormatter(value) {
            let products = value?.split(",") || [];
            return products.reduce((acc, curr) => {
                if (acc) {
                    acc = acc + ", " + this.$policyValues.productLabels[curr];
                } else {
                    acc = this.$policyValues.productLabels[curr];
                }
                return acc;
            }, "");
        },
        handleMoreThanFiveValue(value) {
            if (value > 5) {
                return "5+";
            }
            return value;
        },
    },
};
</script>

<style scoped>
.adjustment-details {
    padding: 20px;
}
</style>

<style lang="scss">
.adjustment-table .table-container {
    min-height: auto !important;
    tr {
        td {
            &.oldValue,
            &.newValue {
                span {
                    white-space: pre-line;
                }
            }
        }
    }
}
</style>
