<template>
    <input-wrapper
        :inputLabel="inputLabel"
        :haveError="haveError"
        :errorMessage="errorMessage"
        :fullWidth="fullWidthInput"
        :class="{ disabled: disabled }"
        :infoImage="infoImage"
        class="textarea"
    >
        <textarea
            :class="inputClass"
            :value="modelValue"
            :placeholder="inputPlaceholder"
            :disabled="disabled"
            @blur="inputBlur($event)"
            @input="updateSelf($event)"
        ></textarea>
        <spinner
            class="center-positioned"
            v-if="loading"
            :width="'12px'"
            :height="'12px'"
        ></spinner>
    </input-wrapper>
</template>

<script>
/**
 * This is BEV custom input which will be used wherever there is form data
 *
 * @param {String} inputPlaceholder for input placeholder
 * @param {String} inputLabel for input label prop
 * @param {String} modelValue for input value prop
 * @param {Boolean} disabled for input disabled prop
 * @param {String} inputClass for custom input classes
 * @param {Boolean} haveError for showing error with the input
 * @param {String} errorMessage error message to show if haveError is true
 * @param {Boolean} fullWidthInput for showing a full width input (used in InputWrapper)
 * @param {Boolean} loading for showing a loader on the input box
 * @param {Object} infoImage image props for showing info icon (used in InputWrapper)
 **/
import InputWrapper from "@/components/Common/Input/InputWrapper";
export default {
    props: {
        inputPlaceholder: {
            type: String,
            default: "",
        },
        inputLabel: {
            type: String,
            default: "",
        },
        modelValue: null,
        disabled: {
            type: Boolean,
            default: false,
        },
        inputClass: String,
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        fullWidthInput: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    emits: ["update:modelValue", "blur"],
    components: {
        InputWrapper,
    },
    methods: {
        updateSelf(event) {
            this.$emit("update:modelValue", event.target.value);
        },
        inputBlur() {
            this.$emit("blur");
        },
        inputFocus(event) {
            if (this.validationType === "number") {
                this.$nextTick(() =>
                    this.$emit(
                        "update:modelValue",
                        this.removeCommaFromNumber(event.target.value)
                    )
                );
            }
        },
    },
};
</script>
