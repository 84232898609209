<template>
    <div class="search-input-container" v-click-away="closeDrop">
        <div
            :class="[
                'bev-input',
                inputClass,
                inputText !== '' ? 'active' : '',
                borderLight ? 'light-border' : '',
            ]"
        >
            <bev-image
                class="search-icon"
                :addBackground="true"
                :backgroundColor="'var(--searchBarPlaceholderColor)'"
                :imageUrl="$imagePath.search"
                :imageAlt="'search-icon'"
                :width="'15px'"
                :height="'15px'"
            ></bev-image>
            <input
                v-model="inputText"
                :type="inputType"
                @change="onChangeListener"
                @input="inputFunction($event)"
                @focus="showDrop = true"
                :placeholder="inputPlaceholder"
            />
        </div>
        <transition name="slideDown">
            <div class="search-results" v-if="inputText !== '' && showDrop">
                <div class="search-result-item text-center" v-if="resultErr">
                    Failed to load results
                </div>
                <div
                    class="search-result-item text-center"
                    v-if="resultProcessing"
                >
                    Loading
                </div>
                <div
                    class="search-result-item text-center"
                    v-if="
                        results.length === 0 && !resultProcessing && !resultErr
                    "
                >
                    No results found
                </div>
                <div
                    class="search-result-item"
                    v-for="(result, index) in results"
                    :key="index"
                    @click="resultClick(result)"
                >
                    {{ result.label }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
/**
 * This input is used wherever search functionality is needed to be implemented
 *
 * @param {String} inputType use to check type of input
 * @param {String} inputPlaceholder for input placeholder
 * @param {String} inputLabel for input label prop
 * @param {String} inputValue for input value prop
 * @param {Boolean} disabled for input disabled prop
 * @param {String} validationType custom validation of currency or number
 * @param {String} inputClass for custom input classes
 * @param {Function} onChangeListener for lisiting to changes whenever there is change in input text
 * @param {Function} keyupListener for keyup event
 * @param {Function} resultClickHandler
 * @param {Function} resultFormatter result data formatter function
 */

import debounce from "lodash.debounce";
import { mixin as clickaway } from "vue3-click-away";
export default {
    props: {
        inputType: String,
        inputClass: String,
        onChangeListener: {
            type: Function,
            default: function () {
                return () => {};
            },
        },
        keyupListener: {
            type: Function,
            default: function () {
                return () => {};
            },
        },
        inputPlaceholder: {
            type: String,
            default: "",
        },
        inputLabel: {
            type: String,
            default: "",
        },
        resultClickHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        resultFormatter: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        borderLight: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [clickaway],
    data() {
        return {
            inputText: "",
            results: [],
            resultErr: false,
            resultProcessing: false,
            showDrop: false,
        };
    },
    methods: {
        async getResults() {
            try {
                let response = await this.keyupListener(this.inputText);
                this.results = [];
                response.data.suggestions.forEach((item) => {
                    this.results.push(this.resultFormatter(item));
                });
                this.resultProcessing = false;
            } catch (err) {
                this.results = [];
                this.resultProcessing = false;
                this.resultErr = true;
            }
        },
        inputFunction(e) {
            this.resultErr = false;
            this.resultProcessing = true;
            if (e.target.value !== "") {
                this.showDrop = true;
            }
            this.searchResults();
        },
        searchResults: debounce(function () {
            this.getResults();
        }, 500),
        resultClick(result) {
            this.inputText = result.label;
            this.showDrop = false;
            this.resultClickHandler(result);
        },
        closeDrop() {
            this.showDrop = false;
        },
    },
};
</script>

<style scoped lang="scss">
.search-input-container {
    .bev-input.light-border input {
        border: solid 0.5px var(--searchBarLightBorder);
        &:focus {
            border: solid 0.5px var(--activeColorV1);
        }
    }
    .bev-input.active input {
        background-color: var(--searchBarBgColor);
    }
    .bev-input.have-bg-light input {
        background-color: var(--searchBarBgColor);
    }
    .bev-input input {
        box-shadow: var(--searchBarBoxShadow);
        color: var(--searchBarColor);
    }
    .bev-input input::placeholder {
        color: var(--searchBarPlaceholderColor);
    }
    .search-results {
        box-shadow: 0 1px 16px 0 var(--dropdownBoxShadow);
        background-color: var(--dropdownBgColor);
        .search-result-item {
            &:hover {
                color: var(--activeColorV1);
            }
            &.text-center:hover {
                color: var(--activeColorV1);
            }
        }
    }
}
</style>
