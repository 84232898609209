<template>
    <bev-form
        :submitData="completeRegistration"
        :showSingleButton="false"
        :submitButton="{
            text: 'Submit',
            buttonType: 'submit',
            loading: updatingDocuments,
        }"
        :resetButton="{
            text: 'Back',
            clickHandler: backTo,
            buttonType: 'button',
        }"
    >
        <template v-slot:input-fields>
            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <bev-dropdown
                        :options="acceptedIds"
                        :dropDownLabel="'ID Proof*'"
                        :containerClass="'idproofs_drop'"
                        :ajax="false"
                        :haveError="
                            v$.selectedIdProof.$error &&
                            v$.selectedIdProof.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.idProofError"
                        v-model="v$.selectedIdProof.$model"
                    ></bev-dropdown>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'ID Number*'"
                        :haveError="
                            v$.idNumber.$error && v$.idNumber.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.idNumberError"
                        :inputClass="'last-name'"
                        v-model="v$.idNumber.$model"
                    ></bev-input>
                </div>
            </div>
            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <file-input
                        :inputLabel="'Front View*'"
                        :haveError="
                            v$.idProofFrontView.$error &&
                            v$.idProofFrontView.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.frontViewError"
                        v-model="v$.idProofFrontView.$model"
                    ></file-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <file-input
                        :inputLabel="'Back View*'"
                        :haveError="
                            v$.idProofBackView.$error &&
                            v$.idProofBackView.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.backViewError"
                        v-model="v$.idProofBackView.$model"
                    ></file-input>
                </div>
            </div>

            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <bev-dropdown
                        :options="acceptedAddressProofs"
                        :containerClass="'addressproofs_drop'"
                        :dropDownLabel="'Address Proof*'"
                        :ajax="false"
                        v-model="v$.selectedAddressProof.$model"
                        :haveError="
                            v$.selectedAddressProof.$error &&
                            v$.selectedAddressProof.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.addressProofError
                        "
                    ></bev-dropdown>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'ID Number*'"
                        :haveError="
                            v$.addressNumber.$error &&
                            v$.addressNumber.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.idNumberError"
                        :inputClass="'last-name'"
                        v-model="v$.addressNumber.$model"
                    ></bev-input>
                </div>
            </div>
            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <file-input
                        :inputLabel="'Front View*'"
                        :haveError="
                            v$.addressProofFrontView.$error &&
                            v$.addressProofFrontView.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.frontViewError"
                        v-model="v$.addressProofFrontView.$model"
                    ></file-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <file-input
                        :inputLabel="'Back View*'"
                        :haveError="
                            v$.addressProofBackView.$error &&
                            v$.addressProofBackView.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.backViewError"
                        v-model="v$.addressProofBackView.$model"
                    ></file-input>
                </div>
            </div>
            <div class="row mt-30px">
                <div class="col-sm-12">
                    <bev-checkbox
                        :inputLabel="`I permit the ${subDomainDetail.name} to show my ID proofs to the underwriter for verification process`"
                        :modelValue="IAccept"
                        @update:modelValue="updateAcceptClause"
                    ></bev-checkbox>
                </div>
            </div>
        </template>
    </bev-form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState, mapMutations } from "vuex";
import UserService from "@/services/UserService";
import FileInput from "@/components/Common/Input/FileInput";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            acceptedIds: ["Aadhar Card"],
            selectedIdProof: "",
            idNumber: "",
            idProofFrontView: null,
            idProofBackView: null,
            acceptedAddressProofs: ["Aadhar Card"],
            selectedAddressProof: "",
            addressNumber: "",
            addressProofFrontView: null,
            addressProofBackView: null,
            IAccept: false,
            updatingDocuments: false,
        };
    },
    validations: {
        selectedIdProof: {
            required,
        },
        idNumber: {
            required,
        },
        idProofFrontView: {
            required,
        },
        idProofBackView: {
            required,
        },
        addressProofFrontView: {
            required,
        },
        addressProofBackView: {
            required,
        },
        selectedAddressProof: {
            required,
        },
        addressNumber: {
            required,
        },
    },
    computed: {
        ...mapState("auth", ["userId"]),
    },
    components: {
        FileInput,
    },
    mounted() {
        if (this.checkPersonalDetails()) {
            this.mapDataOnMount();
        } else {
            this.backTo();
        }
    },
    methods: {
        ...mapMutations("auth", ["setUser"]),
        mapDataOnMount() {
            this.selectedIdProof = this.userAttributes?.idProof || "";
            this.selectedAddressProof = this.userAttributes?.addressProof || "";
            this.idNumber = this.userAttributes?.idProof_idNumber || "";
            this.addressNumber =
                this.userAttributes?.addressProof_idNumber || "";
        },
        backTo() {
            this.$router.push({ name: "Personal Details" });
        },
        updateAcceptClause(value) {
            this.IAccept = value;
        },
        async updateDocuments() {
            this.updatingDocuments = true;
            let user = JSON.parse(JSON.stringify(this.user));
            user.firstName = this.firstName;
            user.lastName = this.lastName;
            let attributes = JSON.parse(JSON.stringify(this.userAttributes));
            attributes.idProof = this.selectedIdProof;
            attributes.idProof_idNumber = this.idNumber;
            attributes.addressProof = this.selectedAddressProof;
            attributes.addressProof_idNumber = this.addressNumber;
            attributes.first_login_completed = true;
            try {
                let response = await UserService.updateUserDetails(
                    this.userId,
                    {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        username: user.username,
                        emailVerified: true,
                        enabled: true,
                        attributes: attributes,
                    }
                );
                this.setUser({
                    ...user,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    attributes: response.data.attributes,
                });
                this.updatingDocuments = false;
                this.$router.push({ name: "Dashboard" });
                this.$notify({
                    group: "BEV",
                    title: "",
                    text: this.$messages.successMessages.registrationSuccess,
                    type: "success",
                });
            } catch (err) {
                this.updatingDocuments = false;
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to upload documents"
                );
            }
        },
        completeRegistration() {
            this.v$.$touch();
            if (!this.v$.$invalid && this.IAccept) {
                this.updateDocuments();
            }
        },
    },
};
</script>
