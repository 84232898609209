import { VTooltip, VClosePopper } from "floating-vue";
// import PortalVue from "portal-vue";
import Notifications from "@kyvg/vue3-notification";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import "floating-vue/dist/style.css";
import auth from "@/auth";
// Constants

import messages from "@/constant/messages.js";
import imagePath from "@/constant/imagePath.js";
import policyValues from "@/constant/policy.js";
import dateTime from "@/constant/dateTime.js";
import infiniteScroll from "vue-infinite-scroll";
import MasonryWall from "@yeger/vue-masonry-wall";
import { IMaskDirective } from "vue-imask";
import axios from "axios";
import { store } from "@/store/index";

export const globalPluginsSetup = (app) => {
    app.config.globalProperties.$messages = messages;
    app.config.globalProperties.$colors = app.$colors;
    app.config.globalProperties.$imagePath = imagePath;
    app.config.globalProperties.$policyValues = policyValues;
    app.config.globalProperties.$dateTime = dateTime;
    app.use(auth);
    app.use(MasonryWall);
    app.use(Notifications);
    app.use(VueTelInput);
    app.use(infiniteScroll);
    app.directive("imask", IMaskDirective);
    app.directive("tooltip", VTooltip);
    app.directive("close-popover", VClosePopper);
    axios.defaults.params = {};
    axios.interceptors.request.use(
        (config) => {
            let token =
                store.state.buyPolicy.isDirectBuyFlow &&
                !store.state.auth.isAuthenticated
                    ? localStorage.getItem("quick_buy_access_token")
                    : store.state.auth.access_token;
            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            if (err.response && err.response.status === 401) {
                if (
                    store.state.buyPolicy.isDirectBuyFlow &&
                    !store.state.auth.isAuthenticated
                ) {
                    store.dispatch("getToken");
                } else {
                    app.config.globalProperties.$auth.updateTokenSingle(true);
                }
            }
            return Promise.reject(err);
        }
    );
};
