<template>
    <input-wrapper
        :inputLabel="inputLabel"
        :haveError="haveError"
        :errorMessage="errorMessage"
        :fullWidth="fullWidthInput"
        :class="{ disabled: disabled }"
        :infoImage="infoImage"
        :inputActive="inputActive"
        :extraInfoText="extraInfoText"
    >
        <input
            :class="inputClass"
            :value="modelValue"
            :type="inputType"
            :placeholder="inputPlaceholder"
            :disabled="disabled"
            @blur="inputBlur($event)"
            @focus="inputActive = true"
            v-imask="mask"
            @input="updateSelf($event)"
        />
        <spinner
            class="center-positioned"
            v-if="loading"
            :width="'12px'"
            :height="'12px'"
        ></spinner>
    </input-wrapper>
</template>

<script>
/**
 * This is BEV custom input which will be used wherever there is form data
 *
 * @param {String} inputType use to check type of input
 * @param {String} inputPlaceholder for input placeholder
 * @param {String} inputLabel for input label prop
 * @param {String} modelValue for input value prop
 * @param {Boolean} disabled for input disabled prop
 * @param {String} validationType custom validation of currency or number
 * @param {String} inputClass for custom input classes
 * @param {Boolean} haveError for showing error with the input
 * @param {String} errorMessage error message to show if haveError is true
 * @param {Boolean} fullWidthInput for showing a full width input (used in InputWrapper)
 * @param {Boolean} loading for showing a loader on the input box
 * @param {Object} infoImage image props for showing info icon (used in InputWrapper)
 * @param {String} extraInfoText extra info to be shown in the input label (used in InputWrapper)
 * @param model for two way binding data to input
 **/

import InputWrapper from "@/components/Common/Input/InputWrapper";
export default {
    props: {
        inputType: String,
        inputPlaceholder: {
            type: String,
            default: "",
        },
        modelValue: null,
        inputLabel: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validationType: String,
        inputClass: String,
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        fullWidthInput: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        extraInfoText: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue", "blur"],
    data() {
        return {
            inputActive: false,
        };
    },
    computed: {
        mask() {
            switch (this.validationType) {
                case "currency":
                    return {
                        mask: Number,
                        thousandsSeparator: ",",
                        radix: ".",
                        normalizeZeros: false,
                        min: 0,
                    };
                case "whole_number":
                    return {
                        mask: Number,
                        thousandsSeparator: ",",
                        normalizeZeros: false,
                        min: 0,
                    };
                case "cvv":
                    return {
                        mask: "000",
                    };
                case "cardDate":
                    return {
                        mask: "00/00",
                    };
                case "cardNo":
                    return {
                        mask: "0000-0000-0000-0000",
                    };
                case "time":
                    return {
                        mask: "00:00",
                    };
                default:
                    return {
                        mask: String,
                    };
            }
        },
    },
    components: {
        InputWrapper,
    },
    methods: {
        formattedInputValue(event) {
            let value = event.target.value;
            if (
                (this.validationType === "whole_number" ||
                    this.validationType === "currency") &&
                event.target.value
            ) {
                value = Number(event.target.value.replaceAll(",", ""));
            }
            return value;
        },
        updateSelf(event) {
            this.$emit("update:modelValue", this.formattedInputValue(event));
        },
        inputBlur(e) {
            this.$emit("blur", this.formattedInputValue(e));
            this.inputActive = false;
        },
    },
};
</script>
