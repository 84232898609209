<template>
    <div class="w-100 h-100" id="maps">
        <FormSidebar
            @panmap="panMapToLocation"
            :selectedLatlng="selectedLatlng"
            :reverseGeocodingLoading="reverseGeocodingLoading"
            :setReverseGeocodingLoader="setReverseGeocodingLoader"
        />
        <Map
            :currentLocation="location"
            @searchLatLng="setLatLng"
            :parentMarkerLatLng="parentMarkerLatLng"
            :reverseGeocodingLoading="reverseGeocodingLoading"
            :setReverseGeocodingLoader="setReverseGeocodingLoader"
        />
        <div id="one-to"></div>
    </div>
</template>

<script>
import Map from "@/components/Views/BuyPolicy/SelectLocation/Map";
import FormSidebar from "@/components/Views/BuyPolicy/SelectLocation/FormSidebar";
export default {
    data() {
        return {
            location: null,
            selectedLatlng: null,
            parentMarkerLatLng: null,
            reverseGeocodingLoading: false,
        };
    },
    components: {
        Map,
        FormSidebar,
    },
    methods: {
        panMapToLocation(location) {
            this.location = location;
            this.parentMarkerLatLng = {
                lng: location.lng,
                lat: location.lat,
            };
        },
        setLatLng(latlng) {
            this.selectedLatlng = latlng;
        },
        setReverseGeocodingLoader(value) {
            this.reverseGeocodingLoading = value;
        },
    },
};
</script>
