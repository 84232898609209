<template>
    <div class="d-flex justify-content-center">
        <bev-button
            :themeType="buttonThemeType"
            :clickHandler="saveQuoteAsDraft"
            :class="buttonSize"
            :loading="savingDraft"
            v-if="showSaveDraftButton"
        >
            Save as draft
        </bev-button>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    props: {
        dataSaveHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        buttonThemeType: {
            type: String,
            default: "white-bordered",
        },
        buttonSize: {
            type: String,
            default: "small",
        },
    },
    computed: {
        ...mapState("buyPolicy", [
            "isDirectBuyFlow",
            "savedAsDraftId",
            "productDataMap",
            "questionBundles",
            "products",
            "isEditingOld",
            "addedQuote",
            "policyProduct",
            "policyProductAddon",
            "summarySnapshot",
            "quickExtensions",
            "quickExtensionsPremium",
            "inValidatedData",
        ]),
        showSaveDraftButton() {
            if (!this.isDirectBuyFlow) {
                if (this.isEditingOld) {
                    if (
                        this.addedQuote.quoteStatus === "INCOMPLETE_SUBMISSION"
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
    },
    data() {
        return {
            savingDraft: false,
        };
    },
    methods: {
        ...mapActions("buyPolicy", ["addQuote"]),
        ...mapMutations("buyPolicy", [
            "setProductDataMap",
            "setQuestionBundle",
            "setSummarySnapshot",
        ]),
        refreshAllProductData() {
            // Handler to update all product data in productDataMap
            for (const key in this.$policyValues.productLabels) {
                const productIndex = this.products.indexOf(key);
                if (productIndex > -1) {
                    if (key === "event_cancellation_terrorism") {
                        if (
                            this.questionBundles["EventCancellationTerrorism"]
                                .terrorism === "Yes"
                        ) {
                            this.formatProductDataMap(key, productIndex);
                        }
                    } else {
                        this.formatProductDataMap(key, productIndex);
                    }
                }
            }
        },
        formatProductDataMap(product, productSequence) {
            // handler to update single productDataMap
            // get data bundles for the product from productDataBundle constant
            let productDataBundle =
                this.$policyValues.productDataBundle[product];
            // get existing data bundle for the product from the productDataMaps
            let currentProductMap = this.productDataMap[product] || {};
            productDataBundle.forEach((bundle) => {
                // For every product bundle for the product update particular product's data map
                currentProductMap = {
                    ...currentProductMap,
                    sequenceNumber: productSequence,
                    ...(this.policyProduct === product &&
                        this.policyProductAddon && {
                            addonFor: this.policyProductAddon,
                        }),
                    ...(bundle === "SelectPeril"
                        ? { peril: this.questionBundles[bundle] }
                        : this.questionBundles[bundle]),
                };
            });
            // Set editable attribute value if any
            let editableAttributes = this.getEditableAttributes(product);
            editableAttributes.forEach((attribute) => {
                currentProductMap[attribute.key] =
                    this.setEditableAttributeDefault(
                        attribute,
                        currentProductMap
                    );
            });
            // Set productDataMap in BuyPolicy store
            this.setProductDataMap({
                ...this.productDataMap,
                [product]: {
                    ...currentProductMap,
                    grossPremium: !this.inValidatedData.length
                        ? currentProductMap.grossPremium
                        : null,
                    grossPremiumPlusIpt: !this.inValidatedData.length
                        ? currentProductMap.grossPremiumPlusIpt
                        : null,
                    grossPremiumWithAddon: !this.inValidatedData.length
                        ? currentProductMap.grossPremiumWithAddon
                        : null,
                    ipt: !this.inValidatedData.length
                        ? currentProductMap.ipt
                        : null,
                    brokerageAbs: !this.inValidatedData.length
                        ? currentProductMap.brokerageAbs
                        : null,
                },
            });
        },
        saveQuoteAsDraft() {
            this.dataSaveHandler();
            this.refreshAllProductData();
            this.savingDraft = true;
            this.addQuote({
                productsData: this.formatProductDataForRequest(
                    this.productDataMap,
                    this.quickExtensions,
                    this.quickExtensionsPremium,
                    true
                ),
                checkAllSelected: true,
                paymentStatus: "INVOICED",
                ...(this.isEditingOld && { id: this.addedQuote.id }),
                ...(this.savedAsDraftId &&
                    !this.isEditingOld && { id: this.savedAsDraftId }),
                draft: true,
            }).then((res) => {
                this.savingDraft = false;
                if (!this.isObjectEmpty(this.summarySnapshot)) {
                    this.setSummarySnapshot();
                }
                if (res.error) {
                    this.displayErrorMessage("Failed to save draft");
                } else {
                    this.$notify({
                        group: "BEV",
                        title: "Success",
                        text: "Draft Quote saved successfully",
                        type: "success",
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.step-count {
    border: 1px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--baseLight);
}
</style>
