<template>
    <bev-form
        :submitData="confirmUserAction"
        :showSingleButton="true"
        :submitButton="{
            text: userAction === 'create' ? 'Submit' : 'Update',
            loading: userActionLoading,
            buttonType: 'submit',
        }"
    >
        <template v-slot:input-fields>
            <div class="row mt-30px justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-6 mt-20px">
                    <bev-dropdown
                        :options="customerTypes"
                        :dropDownLabel="'Insured Type'"
                        :containerClass="'customer_type_drop'"
                        :haveError="
                            v$.customerType.$error &&
                            v$.customerType.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'Insured Type'
                            )
                        "
                        :infoImage="{
                            show: true,
                            info: getCustomerTypeTooltipInfo,
                        }"
                        v-model="v$.customerType.$model"
                    ></bev-dropdown>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px mt-20px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Insured Name*'"
                        :haveError="
                            v$.customerName.$error &&
                            v$.customerName.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'Name'
                            )
                        "
                        :inputClass="'first-name'"
                        v-model="v$.customerName.$model"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mt-20px">
                    <bev-input
                        :inputType="'email'"
                        :inputLabel="'Email'"
                        :haveError="
                            v$.clientEmail.$error && v$.clientEmail.$invalid
                        "
                        :errorMessage="$messages.errorMessages.emailError"
                        :inputClass="'email'"
                        v-model="v$.clientEmail.$model"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px mt-20px">
                    <tel-input
                        :inputLabel="'Contact Number'"
                        @checkValid="(value) => (contactNumberValid = value)"
                        @setCountryDetails="handleContactNumberCountryDetails"
                        :selectedCountryCode="
                            contactNumberCountryDetails?.countryCode
                        "
                        v-model="contactNumber"
                    >
                    </tel-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mt-20px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Address Line 1*'"
                        :haveError="
                            v$.address1.$error && v$.address1.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.addressError"
                        :inputClass="'address-1'"
                        v-model="v$.address1.$model"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px mt-20px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Address Line 2'"
                        :inputClass="'address-2'"
                        v-model="address2"
                    ></bev-input>
                </div>
                <div
                    class="col-sm-12 col-md-6 col-lg-6 position-relative mt-20px"
                >
                    <bev-dropdown
                        containerClass="countries_drop"
                        dropDownLabel="Country*"
                        :ajax="true"
                        :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                        :apiRequestParam="`entityType=country&tenant=${getTenantType}`"
                        apiMethod="post"
                        :responseInfo="{
                            resultKey: 'content',
                            totalResultKey: 'totalElements',
                            pagination: true,
                        }"
                        searchKey="searchVal"
                        :optionTextHandler="
                            (option) => {
                                return option.value;
                            }
                        "
                        :haveError="
                            v$.selectedCountry.$error &&
                            v$.selectedCountry.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.countryError"
                        v-model="v$.selectedCountry.$model"
                    ></bev-dropdown>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px mt-20px">
                    <bev-dropdown
                        containerClass="cities_drop"
                        dropDownLabel="City*"
                        :ajax="true"
                        :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                        apiRequestParam="entityType=city"
                        apiMethod="post"
                        :apiRequestBody="cityDropRequestBody"
                        :responseInfo="{
                            resultKey: 'content',
                            totalResultKey: 'totalElements',
                            pagination: true,
                        }"
                        searchKey="searchVal"
                        :optionTextHandler="
                            (option) => {
                                return option.value;
                            }
                        "
                        :haveError="
                            v$.selectedCity.$error &&
                            v$.selectedCity.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.cityError"
                        :disabled="!selectedCountry.value"
                        otherOptionKey="value"
                        allowOther
                        v-model="v$.selectedCity.$model"
                    ></bev-dropdown>
                </div>
                <div
                    class="col-sm-12 col-md-6 col-lg-6 mt-20px"
                    v-if="selectedCity.value === 'Other'"
                >
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="`Enter City*`"
                        :inputClass="'city_input'"
                        :haveError="
                            v$.otherCityValue.$error &&
                            v$.otherCityValue.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'City'
                            )
                        "
                        v-model="v$.otherCityValue.$model"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mt-20px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Postcode*'"
                        :haveError="postcodeErrorHandler.haveError"
                        :errorMessage="postcodeErrorHandler.errorMessage"
                        :inputClass="'zipcode'"
                        :disabled="
                            !selectedCountry.value || !selectedCity.value
                        "
                        v-model="v$.zipcode.$model"
                    ></bev-input>
                </div>
            </div>
        </template>
    </bev-form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import UserService from "@/services/UserService";
import config from "@/envConfig.js";
import customHtml from "@/constant/customHtml";
import { mapGetters } from "vuex";
import TelInput from "@/components/Common/Input/TelInput";
import ComplyAdvantageService from "@/services/ComplyAdvantageService";
export default {
    name: "ClientDetailForm",
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        selectedClient: Object,
        userAction: String,
        selectedBrokerGroupId: String,
        closeCreateClientDialog: Function,
        getBrokerClients: Function,
        pageNumber: Number,
        setSelectedClient: Function,
        policyFlow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            config: null,
            customerName: "",
            contactNumber: null,
            address1: "",
            address2: "",
            zipcode: "",
            selectedCity: {},
            selectedCountry: {},
            contactNumberValid: false,
            contactNumberCountryDetails: null,
            userActionLoading: false,
            email: "",
            clientEmail: "",
            customerType: "",
            customerTypes: [
                // "Consumer", // Disable Temporarily
                "Sole Trader",
                "Micro",
                "Small",
                "Medium",
                "Large",
            ],
            customerTypeTooltipInfo: "",
            otherCityValue: "",
        };
    },
    components: {
        TelInput,
    },
    validations() {
        return {
            customerType: {
                required,
            },
            customerName: {
                required,
            },
            clientEmail: {
                email,
            },
            address1: {
                required,
            },
            selectedCity: {
                required,
            },
            selectedCountry: {
                required,
            },
            zipcode: {
                required,
                customPostCodeValidation: helpers.withAsync(async (value) => {
                    if (!value) {
                        return true;
                    } else {
                        const isValid = await this.validatePostalCode(
                            this.selectedCountry.value,
                            this.selectedCity.value === "Other"
                                ? this.otherCityValue
                                : this.selectedCity.value,
                            value
                        );
                        return isValid;
                    }
                }),
            },
            otherCityValue: {
                required:
                    this.selectedCity.value === "Other" ? required : false,
            },
        };
    },
    created() {
        this.config = config;
        this.customerTypeTooltipInfo = customHtml.customerTypeTooltip;
        if (this.userAction === "update") {
            this.setClientDataForUpdate();
        }
    },
    computed: {
        ...mapGetters(["getTenantType"]),
        getCustomerTypeTooltipInfo() {
            return this.customerTypeTooltipInfo.replaceAll(
                "$(currency)",
                this.getCurrencyCode()
            );
        },
        postcodeErrorHandler() {
            if (this.v$.zipcode.$error) {
                let errorMessage = "";
                if (this.v$.zipcode.required.$invalid) {
                    errorMessage = this.$messages.errorMessages.zipcodeError;
                } else if (this.v$.zipcode.customPostCodeValidation.$invalid) {
                    errorMessage = this.$messages.errorMessages.postcodeError;
                }
                return {
                    haveError: true,
                    errorMessage: errorMessage,
                };
            }
            return {
                haveError: false,
                errorMessage: "",
            };
        },
    },
    methods: {
        setClientDataForUpdate() {
            this.customerType = this.getValueFromAttributes("customerType");
            this.customerName = `${this.selectedClient?.firstName || ""}${
                this.selectedClient?.lastName
                    ? ` ${this.selectedClient?.lastName}`
                    : ""
            }`;
            this.email = this.selectedClient?.email || "";
            this.clientEmail = this.getValueFromAttributes("clientEmail") || "";
            this.contactNumber =
                this.getValueFromAttributes("mobileNumber") || null;
            this.contactNumberCountryDetails = {
                countryCode:
                    this.getValueFromAttributes("contactNumberCountryCode") ||
                    null,
                dialCode:
                    this.getValueFromAttributes("contactNumberDialCode") ||
                    null,
            };
            this.address1 = this.getValueFromAttributes("addressLine1");
            this.address2 = this.getValueFromAttributes("addressLine2");
            this.selectedCountry = {
                value: this.getValueFromAttributes("country"),
            };
            this.selectedCity = {
                value: this.getValueFromAttributes("city"),
            };
            this.$nextTick(() => {
                this.zipcode = this.getValueFromAttributes("zipCode");
            });
        },
        getValueFromAttributes(key) {
            return this.selectedClient?.attributes?.[key]?.[0];
        },
        cityDropRequestBody() {
            return {
                metaDataFilters: [
                    {
                        key: "country",
                        value: this.selectedCountry.value,
                        mandatory: true,
                    },
                ],
            };
        },
        generateDummyEmail(firstName, lastName) {
            let randomNumber =
                Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
            return `${(
                firstName + lastName
            ).toLowerCase()}-${randomNumber}@bev-dummy.com`;
        },
        async userActionHandler() {
            this.userActionLoading = true;
            let [firstName, ...lastName] = this.customerName.trim().split(" ");
            let data = {
                firstName: firstName,
                lastName: lastName.join(" "),
                email:
                    this.userAction === "create"
                        ? this.email ||
                          this.generateDummyEmail(firstName, lastName)
                        : this.email,
                username: this.email,
                enabled: true,
                emailVerified: true,
                attributes: {
                    mobileNumber: this.contactNumber,
                    contactNumberCountryCode:
                        this.contactNumberCountryDetails?.countryCode,
                    contactNumberDialCode:
                        this.contactNumberCountryDetails?.dialCode,
                    addressLine1: this.address1,
                    ...(this.address2 && { addressLine2: this.address2 }),
                    country: this.selectedCountry.value,
                    city:
                        this.selectedCity.value === "Other"
                            ? this.otherCityValue
                            : this.selectedCity.value,
                    zipCode: this.zipcode,
                    first_login_completed: false,
                    terms_and_conditions_accepted: false,
                    customerType: this.customerType,
                    brokerId: this.selectedBrokerGroupId,
                    clientEmail: this.clientEmail,
                },
            };
            try {
                let successMessageText = "";
                if (this.userAction === "create") {
                    const res = await UserService.addUser(data);
                    successMessageText =
                        this.$messages.successMessages.userCreationSuccess;
                    if (this.policyFlow) {
                        this.setSelectedClient(res.data);
                    }
                } else {
                    await UserService.updateUserDetails(
                        this.selectedClient.id,
                        data
                    );
                    successMessageText =
                        this.$messages.successMessages.userUpdationSuccess;
                }
                this.userActionLoading = false;
                this.closeCreateClientDialog();
                this.$notify({
                    group: "BEV",
                    title: "",
                    text: successMessageText,
                    type: "success",
                });
                if (!this.policyFlow) {
                    await this.getBrokerClients(this.pageNumber, false);
                }
            } catch (err) {
                this.userActionLoading = false;
                let errorMessageText = "";
                if (this.userAction === "create") {
                    errorMessageText =
                        this.$messages.errorMessages.userCreationError;
                } else {
                    errorMessageText =
                        this.$messages.errorMessages.userUpdateError;
                }
                this.displayErrorMessage(
                    err?.response?.data?.message || errorMessageText
                );
            }
        },
        handleContactNumberCountryDetails(value) {
            this.contactNumberCountryDetails = JSON.parse(
                JSON.stringify(value)
            );
        },

        async confirmUserAction() {
            this.v$.$touch();
            if (this.contactNumber === null) {
                this.contactNumberValid = true;
                this.contactNumberCountryDetails = null;
            }
            if (!this.v$.$invalid && this.contactNumberValid) {
                if (this.userAction === "create") {
                    this.checkComplyAdvantage();
                } else {
                    this.userActionHandler();
                }
            }
        },
        async checkComplyAdvantage() {
            this.userActionLoading = true;
            try {
                const response =
                    await ComplyAdvantageService.checkComplyAdvantageStatus(
                        this.customerName
                    );
                const data = JSON.parse(response.data.rawBody)?.content.data;
                if (data.total_matches > 0) {
                    this.userActionLoading = false;
                    this.displayErrorMessage(
                        `<p>We are unable to process your request as we may require more information. Please contact <a href="mailto:${this.subDomainDetail.clientEmail}">${this.subDomainDetail.clientEmail}</a> for futher assistance.</p>`
                    );
                } else {
                    this.userActionHandler();
                }
            } catch (err) {
                this.userActionLoading = false;
                this.displayErrorMessage(
                    err?.response?.data?.message || "Something went wrong"
                );
            }
        },
    },
    watch: {
        selectedCountry: function (country) {
            if (country.value) {
                this.zipcode = "";
            }
        },
        selectedCity: function (city) {
            if (city.value) {
                this.zipcode = "";
            }
        },
        otherCityValue: function (value) {
            if (value) {
                this.zipcode = "";
            }
        },
    },
};
</script>
