import config from "@/envConfig.js";
import APIService from "./APIService";

export default class DocumentService extends APIService {
    static downloadDocument(
        name,
        version,
        prodCount,
        isProposalDoc,
        isStatementOfFact
    ) {
        return this.get(
            `${
                config.documentManagerServiceUrl
            }/download?name=${name}&version=${version}${
                prodCount ? "&prodCount=" + prodCount : ""
            }${isProposalDoc ? "&isMTAProposalSpecific=true" : ""}${
                isStatementOfFact ? "&isSofSpecific=true" : ""
            }`,
            null,
            "blob"
        );
    }

    static sendQuote(docName, email) {
        return this.post(
            `${config.documentGeneratorServiceUrl}/document/sendMail/${docName}`,
            [email]
        );
    }

    static getUserInfoCsv() {
        return this.get(
            `${config.documentGeneratorServiceUrl}/users/downloadCsv`,
            null,
            "blob"
        );
    }
}
