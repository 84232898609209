<template>
    <div class="pb-30px pt-50px px-20px w-100 h-100 overflow-y-auto">
        <bev-dialog
            :title="'Payment Options'"
            :description="'You can pay for your policy online in either full or monthly instalments'"
            :closeDialog="cancelPayment"
            :state="paymentPopup"
            :width="'60%'"
            :actions="{
                show: true,
                secondaryButton: { text: 'Back', clickHandler: cancelPayment },
                primaryButton: {
                    text: 'Next',
                    clickHandler: confirmSelectPayment,
                },
            }"
            v-if="subDomainDetail.allowPayment"
        >
            <template v-slot:dialog-content>
                <div
                    class="d-flex w-100 align-items-stretch justify-content-between mt-50px mb-50px"
                >
                    <selectable-card
                        :clicked="selectedPayment === 'full'"
                        :clickHandler="selectPayment.bind(this, 'full')"
                        class="mx-10px w-50"
                    >
                        <PaymentCardContent
                            :imageUrl="$imagePath.fullPayment"
                            :selected="selectedPayment === 'full'"
                            :heading="'Pay Premium in Full'"
                            :description="''"
                        />
                    </selectable-card>
                    <selectable-card
                        :clicked="selectedPayment === 'emi'"
                        :clickHandler="selectPayment.bind(this, 'emi')"
                        class="mx-10px w-50"
                    >
                        <PaymentCardContent
                            :imageUrl="$imagePath.installment"
                            :selected="selectedPayment === 'emi'"
                            :heading="'Pay Premium in instalments'"
                            :description="''"
                        />
                    </selectable-card>
                </div>
            </template>
        </bev-dialog>
        <ConfirmationDeclarations
            :products="products"
            :undertakings="undertakings"
            :checkAllSelected="checkAllSelected"
            :acknowledgeAll="acknowledgeAll"
            confirmationMessage="We just need to confirm a few final things before you can purchase your cover"
            @acknowledge="(e) => (acknowledgeAll = e)"
        />
        <div
            class="d-flex align-items-center w-100 justify-content-center mt-40px"
        >
            <actions
                :align="'center'"
                :buttonScale="'normal'"
                :buttonLightProperties="{
                    text: 'Back',
                    clickHandler: setBuyPolicyStep.bind(this, {
                        step: 'CoverSummary',
                        back: true,
                    }),
                }"
                :buttonDarkProperties="{
                    text: saveButtonText,
                    clickHandler: submitButtonHandler.bind(this, false),
                    loading: policyAddLoading,
                }"
                :buttonDarkDisabled="!checkAllSelected"
                class="w-auto"
            ></actions>
        </div>
    </div>
</template>

<script>
/**
    Buy Policy Confirmation step component
**/
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import customHtml from "@/constant/customHtml";
import SelectableCard from "@/components/Common/Card/SelectableCard";
import PaymentCardContent from "@/components/Views/BuyPolicy/Confirmation/PaymentCardContent";
import ConfirmationDeclarations from "@/components/Common/ConfirmationDeclarations/Main";
export default {
    components: {
        SelectableCard,
        PaymentCardContent,
        ConfirmationDeclarations,
    },
    mounted() {
        this.setPolicyUsersIfDirectFlow();
        // Setting policyProduct property null as we need to set new value if user want to add a new product
        this.setPolicyProduct(null);
        this.selectedPayment = this.policyPaymentType;
        // Setting undertakings value according to the predefined undertakings in policy.js constant
        this.products.forEach((product) => {
            if (this.$policyValues.declarations[product]) {
                this.undertakings[product] = JSON.parse(
                    JSON.stringify(this.$policyValues.declarations[product])
                );
            }
        });
        // Changing undertakings value if undertakings already being set and added to buy policy store
        this.undertakings = {
            ...this.undertakings,
            ...JSON.parse(JSON.stringify(this.policyUnderTakings)),
        };
        this.acknowledgeAll = this.checkAllSelected;
    },
    data() {
        return {
            paymentPopup: false,
            selectedPayment: "full",
            masterUndertaking: customHtml.masterUndertaking,
            undertakings: {
                common: this.$policyValues.declarations.common,
            },
            policyAddLoading: false,
            buyingPolicy: false,
            acknowledgeAll: false,
        };
    },
    computed: {
        ...mapState("buyPolicy", [
            "policyUnderTakings",
            "policyPaymentType",
            "products",
            "policyPremiumData",
            "productDataMap",
            "addedQuote",
            "isDirectBuyFlow",
            "editingFor",
            "savedAsDraftId",
            "quickExtensions",
            "quickExtensionsPremium",
        ]),
        ...mapState("auth", ["userId", "user", "userGroups"]),
        ...mapGetters("auth", ["userAttributes", "isNormalUser"]),
        checkAllSelected() {
            let notSelected = -1;
            Object.keys(this.undertakings).forEach((undertakingGroup) => {
                notSelected = this.undertakings[undertakingGroup].findIndex(
                    (u) => {
                        return u.selected === false;
                    }
                );
            });
            return notSelected < 0;
        },
        saveButtonText() {
            if (this.editingFor) {
                return "Save Changes";
            }
            return "Generate Quote";
        },
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setBuyPolicyStep",
            "setPolicyProduct",
            "setPolicyPaymentType",
            "setPolicyUnderTakings",
            "setPolicyStatus",
            "setAddedQuote",
            "setRecommendedPremium",
            "setPolicyUser",
            "setPolicyBroker",
        ]),
        ...mapActions("buyPolicy", ["addQuote", "updatePolicy"]),
        showPaymentPopup() {
            if (this.checkAllSelected) {
                this.paymentPopup = true;
            }
        },
        cancelPayment() {
            this.paymentPopup = false;
        },
        confirmSelectPayment() {
            // Setting policy payment type and undertaking to the buy policy store
            this.setPolicyPaymentType(this.selectedPayment);
            this.setPolicyUnderTakings(this.undertakings);
            if (this.policyPaymentType === "full") {
                this.setBuyPolicyStep({ step: "FullPayment", back: false });
            } else {
                this.setBuyPolicyStep({ step: "Installment", back: false });
            }
        },
        selectPayment(option) {
            this.selectedPayment = option;
        },
        quoteHandler(forEdit, forBuy) {
            this.addQuote({
                productsData: this.formatProductDataForRequest(
                    this.productDataMap,
                    this.quickExtensions,
                    this.quickExtensionsPremium
                ),
                checkAllSelected: this.checkAllSelected,
                ...(!this.subDomainDetail.allowPayment && {
                    paymentStatus: "INVOICED",
                }),
                ...(forEdit && { id: this.addedQuote.id }),
                ...(this.savedAsDraftId &&
                    !forEdit && { id: this.savedAsDraftId }),
                editing: forEdit,
            }).then((res) => {
                this.policyAddLoading = false;
                this.setRecommendedPremium(this.policyPremiumData.totalPremium);
                if (res.error) {
                    this.displayErrorMessage("Failed to save quote");
                    this.buyingPolicy = false;
                } else {
                    // Set addedQuote value in buy Policy store
                    let oldAddedQuote = this.addedQuote
                        ? { ...this.addedQuote }
                        : null;
                    this.setAddedQuote(res.data);
                    // If method is for payment and user is a normal user
                    if (forBuy && this.isNormalUser) {
                        this.buyingPolicy = false;
                        this.selectedPayment = "full";
                        this.confirmSelectPayment();
                    } else {
                        this.setPolicyStatus({
                            status: this.$policyValues.paymentStatus.invoiced,
                            heading: "Application Successful…!",
                            description: `You have successfully applied for the cover with ${this.subDomainDetail.name}. You will receive the further updates on your cover through your ${this.subDomainDetail.name} application.`,
                            quoteNumber: res.data.quoteNumber,
                            retry: false,
                        });
                        let isReferred =
                            res.data.quoteStatus ===
                            this.$policyValues.quoteStatus.REFERRED.value;
                        if (res.data.docs && !isReferred) {
                            if (oldAddedQuote) {
                                if (oldAddedQuote.docs !== res.data.docs) {
                                    this.$notify({
                                        group: "BEV",
                                        title: "Success",
                                        text: this.$messages.infoMessages
                                            .documentGenerating,
                                        type: "success",
                                    });
                                }
                            } else {
                                this.$notify({
                                    group: "BEV",
                                    title: "Success",
                                    text: this.$messages.infoMessages
                                        .documentGenerating,
                                    type: "success",
                                });
                            }
                        }
                        this.$router.push({ path: `/quotes/${res.data.id}` });
                    }
                }
            });
        },
        async editPolicyHandler() {
            this.updatePolicy({
                id: this.addedQuote.id,
                productsData: this.formatProductDataForRequest(
                    this.productDataMap,
                    this.quickExtensions,
                    this.quickExtensionsPremium
                ),
                checkAllSelected: this.checkAllSelected,
            }).then((res) => {
                this.policyAddLoading = false;
                this.setRecommendedPremium(this.policyPremiumData.totalPremium);
                if (res.error) {
                    this.displayErrorMessage("Failed to update policy");
                } else {
                    let isReferred =
                        res.data.policyStatus ===
                        this.$policyValues.policyStatus.REFERRED.value;
                    if (res.data.docs && !isReferred) {
                        if (this.addedQuote) {
                            if (this.addedQuote.docs !== res.data.docs) {
                                this.$notify({
                                    group: "BEV",
                                    title: "Success",
                                    text: this.$messages.infoMessages
                                        .documentGenerating,
                                    type: "success",
                                });
                            }
                        } else {
                            this.$notify({
                                group: "BEV",
                                title: "Success",
                                text: this.$messages.infoMessages
                                    .documentGenerating,
                                type: "success",
                            });
                        }
                    }
                    this.setAddedQuote(res.data);
                    this.$router.push({ path: `/policies/${res.data.id}` });
                }
            });
        },
        submitButtonHandler(forBuy) {
            if (forBuy) {
                this.buyingPolicy = true;
            } else {
                this.policyAddLoading = true;
            }
            if (this.editingFor === "policy") {
                this.editPolicyHandler();
            } else {
                if (this.editingFor === "quote" && this.addedQuote.id) {
                    this.quoteHandler(true, forBuy);
                } else {
                    this.quoteHandler(false, forBuy);
                }
            }
        },
        setPolicyUsersIfDirectFlow() {
            if (this.isDirectBuyFlow) {
                this.setPolicyUser(this.user);
                this.setPolicyBroker({
                    attributes: {
                        brokerId: [this.userGroups[0]],
                    },
                });
            }
        },
    },
    watch: {
        acknowledgeAll: function (val) {
            // If value is not same as checkAllSelected in buyPolicy store
            if (val !== this.checkAllSelected) {
                if (val) {
                    // If value is true set all undertakings to selected
                    Object.keys(this.undertakings).forEach((undertaking) => {
                        this.undertakings[undertaking].forEach((u) => {
                            u.selected = true;
                        });
                    });
                } else {
                    // If value is false set all undertakings to not selected
                    Object.keys(this.undertakings).forEach((undertaking) => {
                        this.undertakings[undertaking].forEach((u) => {
                            u.selected = false;
                        });
                    });
                }
            }
        },
    },
};
</script>
