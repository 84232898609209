<template>
    <div class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 overflow-y-auto">
        <buy-policy-heading-block
            :align="'center'"
            :heading="'Select Insured'"
            :description="'Select an existing insured or create a new insured to generate a quote.'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
        >
            <CoverSummaryButton
                class="z-index-1 opacity-low"
                :clickHandler="saveAndGoToSummary"
            />
        </buy-policy-heading-block>
        <bev-dialog
            :title="'Create Insured'"
            :description="'Please provide below information to create an insured'"
            :closeDialog="closeCreateClientDialog"
            :state="createClientDialog"
            :width="'60%'"
            :actions="{
                show: false,
            }"
        >
            <template v-slot:dialog-content>
                <ClientDetailForm
                    userAction="create"
                    :selectedBrokerGroupId="selectedBrokerGroupId"
                    :closeCreateClientDialog="closeCreateClientDialog"
                    :policyFlow="true"
                    :setSelectedClient="setSelectedClient"
                />
            </template>
        </bev-dialog>
        <bev-loading-block
            v-if="fetchingGroupId || fetchGroupIdError"
            :loading="fetchingGroupId"
            :error="fetchGroupIdError"
            :retryHandler="getUserGroupDetail"
            errorMessage="Failed to fetch broker organization"
        />

        <bev-form
            :submitData="setUserForPolicy.bind(this, true)"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
                loading: false,
                disabled: !selectedClient,
            }"
            :resetButton="{
                text: 'Continue without Insured',
                buttonType: 'button',
                loading: false,
                disabled: (isAdmin || isUnderwriter) && !selectedBroker,
                clickHandler: () => continueWithoutClient(),
            }"
            v-if="!fetchingGroupId && !fetchGroupIdError"
        >
            <template v-slot:input-fields>
                <div>
                    <div
                        class="select-user-options baseDark d-flex flex-column justify-content-center"
                    >
                        <div class="dropdown-container">
                            <bev-dropdown
                                :containerClass="'brokers'"
                                :dropDownLabel="'Select Broker'"
                                :haveError="
                                    v$.selectedBroker.$error &&
                                    v$.selectedBroker.required.$invalid
                                "
                                :ajax="true"
                                :apiUrl="`${config.userServiceUrl}/group/org_type/BROKER`"
                                :responseInfo="{
                                    resultKey: 'items',
                                    totalResultKey: 'total',
                                    pagination: true,
                                    uniqueKey: 'id',
                                }"
                                :errorMessage="'Please choose a Broker'"
                                v-model="v$.selectedBroker.$model"
                                :optionTextHandler="
                                    (option) => {
                                        return brokerNameHandler(option);
                                    }
                                "
                                class="m-auto"
                                v-if="isAdmin || isUnderwriter"
                            ></bev-dropdown>
                        </div>
                        <div class="dropdown-container mt-20px">
                            <bev-dropdown
                                :containerClass="'users'"
                                :dropDownLabel="'Select Insured'"
                                :haveError="
                                    v$.selectedClient.$error &&
                                    v$.selectedClient.required.$invalid
                                "
                                :ajax="true"
                                :apiUrl="`${config.userServiceUrl}/broker/${selectedBrokerGroupId}/users`"
                                apiRequestParam="role=client"
                                :responseInfo="{
                                    resultKey: 'items',
                                    totalResultKey: 'total',
                                    pagination: true,
                                    uniqueKey: 'id',
                                }"
                                :errorMessage="'Please choose a User'"
                                v-model="v$.selectedClient.$model"
                                :optionTextHandler="
                                    (option) => {
                                        return (
                                            option.firstName +
                                            ' ' +
                                            (option.lastName || '')
                                        );
                                    }
                                "
                                :showItemCheck="showNoBroker"
                                :searchKey="'search_filter'"
                                :disabled="
                                    (isAdmin || isUnderwriter) &&
                                    !selectedBrokerGroupId
                                "
                                sorting="firstName&order=asc"
                                v-if="!createClientDialog"
                                class="m-auto"
                            ></bev-dropdown>
                            <div
                                class="create-insured-button font-14 sm-font-12"
                                :class="{ disabled: disabledCreateInsured }"
                                @click="showCreateClientDialog"
                            >
                                <span>+</span>
                                <span>Create Insured</span>
                            </div>
                            <ClientInfo
                                v-if="selectedClient"
                                :client="selectedClient"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </bev-form>
    </div>
</template>

<script>
/**
 * SelectClient step in BuyPolicy flow
 */
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import UserService from "@/services/UserService";
import config from "@/envConfig.js";
import ClientDetailForm from "@/components/Views/ClientManagement/ClientDetailForm";
import ClientInfo from "@/components/Views/BuyPolicy/SelectClient/ClientInfo";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        ClientDetailForm,
        ClientInfo,
        CoverSummaryButton,
        BuyPolicyHeadingBlock,
    },
    computed: {
        ...mapState("buyPolicy", [
            "policyUser",
            "policyBroker",
            "questionBundles",
        ]),
        ...mapState("auth", ["userGroups"]),
        ...mapGetters("auth", ["isAdmin", "isUnderwriter", "isBroker"]),
        selectedBrokerGroupId() {
            if (this.selectedBroker && this.selectedBroker.id) {
                return this.selectedBroker.id;
            } else {
                return null;
            }
        },
        disabledCreateInsured() {
            return (this.isAdmin || this.isUnderwriter) && !this.selectedBroker;
        },
    },
    created() {
        this.config = config;
        if (this.isAdmin || this.isUnderwriter) {
            this.selectedBroker = this.policyBroker || null;
            this.selectedClient = this.policyUser;
        } else {
            this.getUserGroupDetail();
            if (this.isBroker) {
                this.selectedClient = this.policyUser;
            }
        }
    },
    data() {
        return {
            config: null,
            selectedClient: null,
            createClientDialog: false,
            selectedBroker: null,
            fetchingGroupId: false,
            fetchGroupIdError: false,
        };
    },
    validations: {
        selectedClient: {
            required,
        },
        selectedBroker: {
            required,
        },
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setBuyPolicyStep",
            "setPolicyUser",
            "setPolicyBroker",
            "setQuestionBundle",
        ]),
        ...mapActions("buyPolicy", ["takeToNextStep"]),
        showNoBroker(user) {
            return (
                this.user.email !== user.email &&
                !this.checkRole(user.roles, "broker")
            );
        },
        closeCreateClientDialog() {
            this.createClientDialog = false;
        },
        showCreateClientDialog() {
            if (this.selectedBroker) {
                this.createClientDialog = true;
            }
        },
        setSelectedClient(user) {
            this.selectedClient = user;
        },
        saveAndGoToSummary() {
            this.setPolicyBroker(this.selectedBroker);
            this.setPolicyUser(this.selectedClient);
            this.setQuestionBundle({
                ...this.questionBundles,
                SelectClient: {
                    ...this.questionBundles["SelectClient"],
                    userData: this.selectedClient,
                },
            });
            this.setBuyPolicyStep({ step: "CoverSummary", back: false });
        },
        setUserForPolicy(notSaveForDraft) {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.setPolicyBroker(this.selectedBroker);
                this.setPolicyUser(this.selectedClient);
                this.setQuestionBundle({
                    ...this.questionBundles,
                    SelectClient: {
                        ...this.questionBundles["SelectClient"],
                        userData: this.selectedClient,
                    },
                });
                if (notSaveForDraft) {
                    this.takeToNextStep();
                }
            }
        },
        continueWithoutClient() {
            this.setPolicyBroker(this.selectedBroker);
            this.selectedClient = null;
            this.setPolicyUser(this.selectedClient);
            this.setQuestionBundle({
                ...this.questionBundles,
                SelectClient: {
                    ...this.questionBundles["SelectClient"],
                    userData: this.selectedClient,
                },
            });
            this.takeToNextStep();
        },
        async getUserGroupDetail() {
            let userGroup = this.userGroups[0];
            this.fetchingGroupId = true;
            this.fetchGroupIdError = false;
            try {
                let response = await UserService.getGroupDetailByName(
                    userGroup
                );
                this.selectedBroker = {
                    id: response.data[0].id,
                    attributes: {
                        brokerId: [
                            response.data[0]?.attributes?.brokerId?.[0] ||
                                this.subDomainDetail.brokerId,
                        ],
                    },
                };
                this.selectedClient = this.policyUser;
                this.fetchingGroupId = false;
            } catch (err) {
                this.fetchingGroupId = false;
                this.fetchGroupIdError = true;
            }
        },
        brokerNameHandler(option) {
            return option?.attributes?.displayName?.[0] || option.name;
        },
    },
    watch: {
        selectedBroker: function (p, n) {
            if (p && n && p.id !== n.id) {
                this.selectedClient = null;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.loading-block {
    height: calc(100% - 70px);
    position: relative;
}
.select-user-options {
    width: 600px;
    margin: 30px auto 0;
    max-width: 100%;
    padding: 30px 30px 0;
}
.create-new-user-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    padding: 30px 0px;
    display: flex;
    align-items: center;
}

.create-new-user-card .option {
    width: 49%;
    margin: 0 0.5%;
    text-align: center;
    padding: 0 25px;
}

.create-new-user-card .option:first-child {
    border-right: 1px solid var(--veryLightSecondary);
}

.create-new-user-card.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.create-insured-button {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--activeColorV1);
    margin: 10px 5px;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
</style>
