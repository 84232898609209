<template>
    <div
        class="pb-30px pt-50px px-20px w-100 h-100 overflow-auto d-flex justify-content-between flex-column"
    >
        <heading-block
            class="fix-width"
            :align="'center'"
            :heading="'Pay in instalments'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            :description="'Lorem ipsum dolor sit amet, consectetur adipiscing el it, sed do eiusmod dolor sit amet, consectetur adipiscing elit, sed do eiusmod'"
        ></heading-block>
        <bev-form
            class="installment-form h-100 pt-50px"
            :submitData="setInstallmentDetails"
            :showSingleButton="false"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
                loading: false,
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setBuyPolicyStep.bind(this, {
                    step: 'FullPayment',
                    back: true,
                }),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <bev-currency-input
                    :inputLabel="'Total Premium*'"
                    :inputClass="'total_premium'"
                    :disabled="true"
                    v-model="totalPremium"
                    :fullWidthInput="false"
                    class="input-fix-width"
                    :currencySymbol="currencySymbol"
                ></bev-currency-input>
                <bev-dropdown
                    :options="intallmentsOptions"
                    :dropDownLabel="`No. of instalments (${currencySymbol})*`"
                    :containerClass="'no_of_installment_drop'"
                    :haveError="
                        v$.installments.$error &&
                        v$.installments.required.$invalid
                    "
                    :errorMessage="$messages.errorMessages.installmentsError"
                    v-model="v$.installments.$model"
                    :fullWidthInput="false"
                    class="input-fix-width"
                ></bev-dropdown>
                <bev-currency-input
                    :inputLabel="`EMI (${currencySymbol})*`"
                    :disabled="true"
                    :inputClass="'emi_amount'"
                    v-model="emiAmount"
                    :fullWidthInput="false"
                    class="input-fix-width"
                    :currencySymbol="currencySymbol"
                ></bev-currency-input>
                <bev-currency-input
                    :inputLabel="`Premium payable now (${currencySymbol})*`"
                    :disabled="true"
                    :inputClass="'pemium_payable_amount'"
                    v-model="payableNow"
                    :fullWidthInput="false"
                    :currencySymbol="currencySymbol"
                    class="input-fix-width"
                ></bev-currency-input>
                <div
                    class="text-center fix-width font-16 py-20px"
                    v-if="payableNow"
                >
                    <span class="text-activeColorV1">
                        {{ payableNow }}
                    </span>
                    of the total premium amount must be paid now. Remaining
                    amount can be paid in regular instalments
                </div>
            </template>
        </bev-form>
    </div>
</template>

<script>
/**
 * BEV component for Installment payment
 */
import { mapMutations, mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    computed: {
        ...mapState("buyPolicy", ["addedQuote", "policyInstallmentDetails"]),
        currencySymbol() {
            return this.getCurrencyCode();
        },
    },
    mounted() {
        this.totalPremium =
            this.policyInstallmentDetails?.totalPremium ?? "8100.22";
        this.installments = this.policyInstallmentDetails?.installments ?? "";
        this.emiAmount = this.policyInstallmentDetails?.emiAmount ?? "";
        this.payableNow = this.policyInstallmentDetails?.payableNow ?? "";
    },
    data() {
        return {
            totalPremium: "8100.22",
            installments: "",
            emiAmount: "",
            payableNow: "",
            intallmentsOptions: ["3", "6", "9", "12"],
        };
    },
    validations: {
        installments: {
            required,
        },
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setBuyPolicyStep",
            "setPolicyInstallmentDetails",
        ]),
        setInstallmentDetails() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.setPolicyInstallmentDetails({
                    totalPremium: this.totalPremium,
                    installments: this.installments,
                    emiAmount: this.emiAmount,
                    payableNow: this.payableNow,
                });
                this.$router.push({ path: `/quotes/${this.addedQuote.id}` });
                this.setBuyPolicyStep({ step: "SelectProduct", back: false });
            }
        },
        checkEMI(emi) {
            if (this.totalPremium !== "") {
                this.emiAmount = String(
                    Number(this.removeCommaFromNumber(this.totalPremium)) / emi
                );
                this.payableNow = String(
                    Number(this.removeCommaFromNumber(this.totalPremium)) / emi
                );
            }
        },
    },
    watch: {
        installments: function (value) {
            this.checkEMI(Number(value));
        },
    },
};
</script>

<style scoped>
.installment-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.installment-form .input-fix-width {
    width: 400px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px;
}
</style>
