<template>
    <div class="extension">
        <div
            class="d-flex align-items-center justify-content-between extension-heading"
        >
            <p class="font-16 fw-500">
                {{ productLabelHandler(extension.key, "label") }}
            </p>
            <div
                class="d-flex align-items-center justify-content-end"
                v-if="isReferred && isEntityStatusReferred"
            >
                <p class="fst-italic font-14 mr-10px text-recommendedValue">
                    <span v-if="isUnderwriter">
                        (Recommended:
                        {{ getCurrencyCode() }}
                        {{ extension.premium.recommendedGrossPremium }})
                    </span>
                    <span v-else>*Referred</span>
                </p>
                <bev-currency-input
                    :inputLabel="''"
                    :inputClass="'grossPremium'"
                    :modelValue="extension.premium.grossPremium"
                    @update:modelValue="
                        (value) =>
                            $emit('extensionPremiumUpdated', {
                                extension,
                                value,
                            })
                    "
                    :fullWidthInput="false"
                    :currencySymbol="getCurrencyCode()"
                    v-if="isUnderwriter"
                ></bev-currency-input>
            </div>
            <p v-else>
                <span class="text-activeColorV1">{{
                    thousandMask(
                        extension.premium?.grossPremium || 0,
                        "currency"
                    )
                }}</span>
            </p>
        </div>
        <div
            class="extension-info font-14"
            v-if="extension.additionalFields.length"
        >
            <div
                class="mb-10px"
                :class="[
                    checkIfExistInReferred(field.key) && 'text-yellow',
                    field.type !== 'list' &&
                        'd-flex align-items-center justify-content-between',
                ]"
                v-for="(field, index) in extension.additionalFields"
                :key="index"
            >
                <p class="fst-italic">
                    {{ productLabelHandler(field.key, "label") }}
                    <span
                        v-if="checkIfExistInReferred(field.key)"
                        class="font-10 text-activeColorV2"
                        >*referred</span
                    >
                </p>
                <p class="fw-bold" v-if="field.type !== 'list'">
                    {{ field.value }}
                </p>
                <div v-else class="mt-10px border-bottom-light">
                    <ul
                        class="additional-field-list"
                        v-for="(addField, i) in field.value"
                        :key="i"
                    >
                        <li>
                            {{ nestedFieldRenderer(addField) }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Extension",
    props: {
        productHeading: String,
        extension: {
            type: Object,
            required: true,
        },
        checkIfExistInReferred: Function,
        isReferred: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        isEntityStatusReferred: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("auth", ["isUnderwriter"]),
    },
    methods: {
        nestedFieldRenderer(field) {
            return field
                .map((f) => {
                    return `${this.productLabelHandler(f.key, "label")}: ${
                        f.value
                    }`;
                })
                .join(", ");
        },
    },
};
</script>

<style scoped lang="scss">
.extension {
    border-bottom: 1px solid var(--baseShade2);
    .extension-heading {
        padding: 10px 20px;
        .extension-checkbox {
            width: 30px;
            height: 30px;
            ::deep {
                .checkmark {
                    top: 4px;
                }
            }
        }
    }
    .extension-info {
        padding: 10px 20px 20px;
        .border-bottom-light {
            padding-bottom: 10px;
            border-bottom: 1px solid var(--baseShade2);
        }
        .additional-field-list {
            list-style: circle;
            padding-left: 20px;
        }
    }
}
</style>
