import { startOfYear, endOfDay } from "date-fns";

let currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

const state = {
    totalUserMetrics: 0,
    totalMetricPages: 0,
    userMetrices: [],
    refreshAllMetricCounter: 0,
    analyseFilters: {
        startTime: startOfYear(currentDate).getTime(),
        endTime: endOfDay(currentDate).getTime(),
        brokerIds: "",
        productNames: "",
        countries: "",
    },
};

const getters = {};

const mutations = {
    setUserMetrices(state, metrices) {
        state.userMetrices = metrices;
    },
    setAnalyseFilters(state, filters) {
        state.analyseFilters = filters;
    },
    incrementRefreshAllMetricCounter(state) {
        state.refreshAllMetricCounter = state.refreshAllMetricCounter + 1;
    },
    setTotalUserMetrics(state, count) {
        state.totalUserMetrics = count;
        state.totalMetricPages = state.totalUserMetrics / 4;
    },
    resetMetricFilter(state) {
        state.analyseFilters = {
            startTime: startOfYear(currentDate).getTime(),
            endTime: endOfDay(currentDate).getTime(),
            brokerIds: "",
            productNames: "",
            countries: "",
        };
    },
};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
