export default {
    alert: "/img/icons/alert.svg",
    info: "/img/icons/info.svg",
    broker: "/img/icons/broker.svg",
    calender: "/img/icons/calender.svg",
    call: "/img/icons/call.svg",
    caretDown: "/img/icons/caret_down.svg",
    checkMark: "/img/icons/check_mark.svg",
    check: "/img/icons/check.svg",
    claims: "/img/icons/claims.svg",
    covers: "/img/icons/covers.svg",
    cross: "/img/icons/cross.svg",
    dashboard: "/img/icons/dashboard.svg",
    delete: "/img/icons/delete.svg",
    details: "/img/icons/details.svg",
    drought: "/img/icons/drought.svg",
    documentDownload: "/img/icons/document_download.svg",
    download: "/img/icons/download.svg",
    edit: "/img/icons/edit.svg",
    email: "/img/icons/email.svg",
    farmer: "/img/icons/farmer.svg",
    forgotPassword: "/img/icons/forgot_password.svg",
    fullPayment: "/img/icons/full_payment.svg",
    inProcess: "/img/icons/in_process.svg",
    installment: "/img/icons/installment.svg",
    insurance: "/img/icons/insurance.svg",
    locations: "/img/icons/locations.svg",
    logout: "/img/icons/logout.svg",
    logo: "/img/logo.svg",
    notificationRead: "/img/icons/notification_mark_read.svg",
    notification: "/img/icons/notifications.svg",
    policyExpired: "/img/icons/policy_expired.svg",
    policyInProgress: "/img/icons/policy_in_progress.svg",
    policyOffCover: "/img/icons/policy_off_cover.svg",
    policyOnCover: "/img/icons/policy_on_cover.svg",
    premium: "/img/icons/premium.svg",
    rainfall: "/img/icons/rainfall.svg",
    snow: "/img/icons/snow.svg",
    wind: "/img/icons/wind.svg",
    temperature: "/img/icons/temperature.svg",
    search: "/img/icons/search.svg",
    termsConditions: "/img/icons/terms_conditions.svg",
    underwriter: "/img/icons/underwriter.svg",
    userData: "/img/icons/user_data.svg",
    viewMore: "/img/icons/view_more.svg",
    view: "/img/icons/view.svg",
    applicationSuccess: "/img/illustrations/application_success.svg",
    paymentPending: "/img/illustrations/payment_pending.svg",
    agriculture: "/img/icons/agriculture.svg",
    longTerm: "/img/icons/long_term.svg",
    otherBusiness: "/img/icons/other_business.svg",
    pdfIcon: "/img/icons/pdf.svg",
    shortTerm: "/img/icons/short_term.svg",
    locationBlue: "/img/icons/locations_blue.svg",
    locationWhite: "/img/icons/locations_white.svg",
    quotes: "/img/icons/quotes.svg",
    summary: "/img/icons/summary.svg",
    parametricWeather: "/img/icons/parametric.svg",
    liabilityProperty: "/img/icons/liability_property.svg",
    eventCancellation: "/img/icons/event_cancellation.svg",
    settings: "/img/icons/settings.svg",
    add: "/img/icons/add.svg",
    createLink: "/img/icons/create_link.svg",
    copy: "/img/icons/copy.svg",
    comment: "/img/icons/comments.svg",
    productManager: "/img/icons/product_manager.svg",
    analyticsDashboard: "/img/icons/analytics.svg",
    arrowdown: "/img/icons/arrowdown.svg",
    moreDots: "/img/icons/more_dots.svg",
    goToArrow: "/img/icons/go_to_arrow.svg",
    clientManagement: "/img/icons/client_management.svg",
    weatherAnalytix: "/img/icons/weather-analytix.svg",
    cards: {
        visa: "/img/card/visa.png",
        mastercard: "/img/card/master_card.png",
        amex: "/img/card/amex.png",
        discover: "/img/card/discover.png",
        diners: "/img/card/diners.png",
        jcb: "/img/card/jcb.png",
        unknown: "/img/card/card.svg",
    },

    // Weather Icon with codes

    weatherIcon: {
        1000: "/img/illustrations/weather/clear.svg",
        1003: "/img/illustrations/weather/partly_cloudy.svg",
        1006: "/img/illustrations/weather/cloudy.svg",
        1009: "/img/illustrations/weather/overcast.svg",
        1030: "/img/illustrations/weather/mist.svg",
        1063: "/img/illustrations/weather/patchy_rain_possible.svg",
        1066: "/img/illustrations/weather/patchy_snow_possible.svg",
        1069: "/img/illustrations/weather/patchy_sleet_possible.svg",
        1072: "/img/illustrations/weather/patchy_freezing_drizzel_possible.svg",
        1087: "/img/illustrations/weather/thundery_outbreaks_possible.svg",
        1114: "/img/illustrations/weather/blowing_snow.svg",
        1117: "/img/illustrations/weather/blizzard.svg",
        1135: "/img/illustrations/weather/fog.svg",
        1147: "/img/illustrations/weather/freezing_fog.svg",
        1150: "/img/illustrations/weather/patchy_light_drizzle.svg",
        1153: "/img/illustrations/weather/light_drizzle.svg",
        1168: "/img/illustrations/weather/freezing_drizzle.svg",
        1171: "/img/illustrations/weather/heavy_freezing_drizzle.svg",
        1180: "/img/illustrations/weather/patchy_light_rain.svg",
        1183: "/img/illustrations/weather/light_rain.svg",
        1186: "/img/illustrations/weather/moderate_rain_at_times.svg",
        1189: "/img/illustrations/weather/moderate_rain.svg",
        1192: "/img/illustrations/weather/heavy_rain_at_times.svg",
        1195: "/img/illustrations/weather/heavy_rain.svg",
        1198: "/img/illustrations/weather/light_freezing_rain.svg",
        1201: "/img/illustrations/weather/moderate_or_heavy_freezing_rain.svg",
        1204: "/img/illustrations/weather/light_sleet.svg",
        1207: "/img/illustrations/weather/moderate_or_heavy_sleet.svg",
        1210: "/img/illustrations/weather/patchy_light_snow.svg",
        1213: "/img/illustrations/weather/light_snow.svg",
        1216: "/img/illustrations/weather/patchy_moderate_snow.svg",
        1219: "/img/illustrations/weather/moderate_snow.svg",
        1222: "/img/illustrations/weather/patchy_heavy_snow.svg",
        1225: "/img/illustrations/weather/heavy_snow.svg",
        1237: "/img/illustrations/weather/ice_pellets.svg",
        1240: "/img/illustrations/weather/light_rain_shower.svg",
        1243: "/img/illustrations/weather/moderate_or_heavy_rain_shower.svg",
        1246: "/img/illustrations/weather/torrential_rain_shower.svg",
        1249: "/img/illustrations/weather/light_sleet_showers.svg",
        1252: "/img/illustrations/weather/moderate_or_heavy_sleet_showers.svg",
        1255: "/img/illustrations/weather/light_snow_showers.svg",
        1258: "/img/illustrations/weather/moderate_or_heavy_snow_showers.svg",
        1261: "/img/illustrations/weather/light_snow_showers_of_ice_pellets.svg",
        1264: "/img/illustrations/weather/moderte_or_heavy_showers_of_ice_pellets.svg",
        1273: "/img/illustrations/weather/patchy_light_rain_with_thunder.svg",
        1276: "/img/illustrations/weather/moderate_or_heavy_rain_with_thunder.svg",
        1279: "/img/illustrations/weather/patchy_light_snow_with_thunder.svg",
        1282: "/img/illustrations/weather/moderate_or_heavy_snow_with_thunder.svg",
    },
};
