<template>
    <div
        class="suggestion-container"
        :class="{ 'd-none': suggestions.length === 0 }"
    >
        <div class="suggestion-list-heading font-20 sm-font-16">
            Would you like to add…
        </div>
        <Suggestions
            v-for="(suggestion, key) in suggestions"
            :suggestion="suggestion"
            :addSuggestion="redirectToAddStep"
            :key="key"
        />
    </div>
</template>

<script>
/**
    Cover Summary Product Add Suggestions Block
**/
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Suggestions from "@/components/Views/BuyPolicy/CoverSummary/Suggestions/Suggestion";
export default {
    components: {
        Suggestions,
    },
    data() {
        return {
            excludedProducts: [],
            suggestions: [],
        };
    },
    computed: {
        ...mapState("buyPolicy", [
            "productsFlow",
            "products",
            "productDataMap",
            "productsJSON",
            "inValidatedData",
            "invalidProducts",
        ]),
        ...mapGetters("auth", ["isNormalUser"]),
    },
    mounted() {
        this.findExcludedProductSuggestion();
        this.formatSuggestions();
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setPolicyProduct",
            "addToProductsFlow",
            "addProduct",
            "setPolicyProductAddon",
        ]),
        ...mapActions("buyPolicy", ["takeToStep"]),
        findInProducts(product) {
            return this.products.indexOf(product) > -1;
        },
        checkIndexAndAddSuggestion(currentProduct) {
            let findCurrentProductIndex = Object.keys(
                this.productDataMap
            ).findIndex((p) => {
                return currentProduct === p;
            });
            // Check if suggestion product is not present in productDataMap OR suggestion exists but not present in products
            return (
                findCurrentProductIndex < 0 ||
                (findCurrentProductIndex >= 0 &&
                    !this.findInProducts(currentProduct))
            );
        },
        // Format Suggestion to be shown like what product we can purchase according to the current flow
        formatSuggestions() {
            let suggestionProducts = [];
            this.products.forEach((product) => {
                // Check for every product if exists in productsJSON
                let indexInProductJSON = this.productsJSON.findIndex((p) => {
                    return p.name === product;
                });
                if (indexInProductJSON >= 0) {
                    let currentProduct = this.productsJSON[indexInProductJSON];
                    // If product has addons show every suggestion to suggestion
                    if (
                        currentProduct.addOns &&
                        currentProduct.addOns.length > 0
                    ) {
                        this.addToSuggestion(
                            currentProduct.addOns,
                            suggestionProducts,
                            "addonFor",
                            product
                        );
                    }

                    // If product has associatedProducts show every product to suggestion
                    if (
                        currentProduct.associatedProducts &&
                        currentProduct.associatedProducts.length > 0
                    ) {
                        this.addToSuggestion(
                            currentProduct.associatedProducts,
                            suggestionProducts
                        );
                    }
                }
            });

            // For every suggestion product
            suggestionProducts.forEach((suggestion) => {
                let findCurrentSuggestionIndex = this.suggestions.findIndex(
                    (s) => {
                        return s.name === suggestion.name;
                    }
                );
                let checkIfCurrentIndex = this.checkIndexAndAddSuggestion(
                    suggestion.name
                );
                // Check if excluded product has current suggestion product
                let excludedIndex = this.excludedProducts.findIndex((ep) => {
                    return ep.name === suggestion.name;
                });
                if (excludedIndex < 0 && findCurrentSuggestionIndex < 0) {
                    // Check if excluded product not exist not suggestion is not there
                    if (checkIfCurrentIndex) {
                        // If checkIndexAndAddSuggestion add product to suggestions
                        this.suggestions.push({
                            name: suggestion.name,
                            ...(suggestion.addonFor && {
                                addonFor: suggestion.addonFor,
                            }),
                        });
                    }
                }
            });
        },
        addToSuggestion(products, target, addonType, addonValue) {
            // Add every product (addon or associated product) to suggestion data used in format suggestions handler
            products.forEach((product) => {
                if (product.isEnabled) {
                    target.push({
                        ...product,
                        ...(addonType && { [addonType]: addonValue }),
                    });
                }
            });
        },
        findExcludedProductSuggestion() {
            // For every product json check if product has excluded product like a product can't be purchased with other
            this.productsJSON.forEach((product) => {
                if (
                    product.excluded_products &&
                    product.excluded_products.length > 0
                ) {
                    this.excludedProducts = [
                        ...this.excludedProducts,
                        ...product.excluded_products,
                    ];
                }
            });
        },
        // Check the product exist in invalid product list
        checkInvalidProducts(productName) {
            if (
                this.inValidatedData.length ||
                this.invalidProducts.includes(productName)
            ) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.invalidEventCancellationV2CoverTypes(
                        this.subDomainDetail.clientEmail
                    )
                );
                return true;
            }
            return false;
        },
        // Handler to render respective questionBundle component according to the flow
        redirectToAddStep(suggestion) {
            const invalidProduct = this.checkInvalidProducts(suggestion.name);
            if (invalidProduct) return;

            // Set current product as choose suggestion
            this.setPolicyProduct(suggestion.name);
            // Add product to the products in store
            this.addProduct(suggestion.name);
            const currentFlowLength = this.productsFlow.length;
            let suggestionProduct = this.productsJSON.find((p) => {
                return p.name === suggestion.name;
            });
            // Add product questionBundles to the productsFlow
            let questionBundleSequence =
                suggestionProduct.questionBundleSequence;
            if (this.isNormalUser || this.$route.query?.type === "quick") {
                questionBundleSequence = questionBundleSequence.filter(
                    (seq) => seq.name !== "SelectClient"
                );
            }
            this.addToProductsFlow({
                flow: questionBundleSequence,
                productName: suggestion.name,
            });
            // If suggestion is an addon set policyProductAddon in store to check if an addon is to be added
            if (suggestion["addonFor"]) {
                this.setPolicyProductAddon(suggestion["addonFor"]);
            }
            // If after adding suggestion question bundle to the flow, new flow length in greater than existing flow then render next questionBundle component
            if (currentFlowLength < this.productsFlow.length) {
                this.takeToStep(currentFlowLength);
            }
        },
    },
};
</script>

<style scoped>
.suggestion-container {
    margin-top: 30px;
    height: auto;
    padding: 32px;
    background: var(--baseDark);
    border-radius: 8px;
}

.suggestion-list-heading {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: var(--white);
    margin-bottom: 8px;
}
</style>
