<template>
    <bev-button
        :themeType="buttonThemeType"
        :clickHandler="discardChangesHandler"
        :class="buttonSize"
        v-if="showDiscardButton"
    >
        Discard All Changes
    </bev-button>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    props: {
        buttonThemeType: {
            type: String,
            default: "light",
        },
        buttonSize: {
            type: String,
            default: "small",
        },
    },
    computed: {
        ...mapState("buyPolicy", [
            "isDirectBuyFlow",
            "isEditingOld",
            "currentStep",
            "summarySnapshot",
            "inValidatedData",
        ]),
        showDiscardButton() {
            if (
                this.isDirectBuyFlow ||
                this.currentStep === "CoverSummary" ||
                this.isObjectEmpty(this.summarySnapshot)
            ) {
                return false;
            }
            return true;
        },
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "restoreFromSummarySnapshot",
            "setBuyPolicyStep",
        ]),
        discardChangesHandler() {
            this.restoreFromSummarySnapshot();
            this.setBuyPolicyStep({ step: "CoverSummary", back: false });
        },
    },
};
</script>
