import axios from "axios";

export default class APIService {
    static get(url, cancelToken, responseType) {
        return axios({
            url: url,
            responseType,
            method: "GET",
            cancelToken: cancelToken,
        });
    }

    static post(url, postBody, cancelToken, responseType) {
        return axios({
            url: url,
            method: "POST",
            responseType,
            data: postBody,
            cancelToken,
        });
    }

    static put(url, putBody) {
        return axios({
            url: url,
            method: "PUT",
            data: putBody,
        });
    }

    static patch(url, data) {
        return axios({
            url: url,
            method: "PATCH",
            data,
        });
    }

    static delete(url) {
        return axios({
            url: url,
            method: "DELETE",
        });
    }
}
