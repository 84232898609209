<template>
    <p class="d-flex align-items-center">
        <span
            class="fst-italic font-14 mr-10px text-recommendedValue"
            v-if="isReferred && isUnderwriter && !showReferredText"
            >(Recommended:
            {{ thousandMask(recommendedValue, "currency") }})</span
        >
        <span v-if="(isReferred && !isUnderwriter) || showReferredText"
            >*Referred</span
        >
        <span
            v-if="
                !loading &&
                ((isReferred && isUnderwriter) || !isReferred) &&
                !showReferredText
            "
        >
            {{ thousandMask(value, "currency") }}
        </span>
        <spinner
            v-if="loading"
            :width="'20px'"
            :height="'20px'"
            :class="imageClass"
        ></spinner>
    </p>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "MonetaryFieldWithLoader",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            default: "",
        },
        imageClass: {
            type: String,
            default: "",
        },
        isReferred: {
            type: Boolean,
            default: false,
        },
        recommendedValue: {
            type: [String, Number],
            default: "",
        },
        showReferredText: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("auth", ["isUnderwriter"]),
    },
};
</script>
