import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store/index";
import UserService from "@/services/UserService";
import WhiteLabelling from "@/services/WhiteLabelling";
import app from "@/main.js";
import MainContainer from "@/components/Layout/MainContainer.vue";
import Registration from "@/components/Views/Registration/Main.vue";
import PersonalDetail from "@/components/Views/Registration/PersonalDetail";
import UploadDocument from "@/components/Views/Registration/UploadDocument";
import Terms from "@/components/Views/Terms/Main.vue";
import Dashboard from "@/components/Views/Dashboard/Main.vue";
import BuyPolicy from "@/components/Views/BuyPolicy/Main.vue";
import CoverSummary from "@/components/Views/BuyPolicy/CoverSummary/Main.vue";
import Policies from "@/components/Views/Policies/Main.vue";
import PolicyList from "@/components/Views/Policies/PolicyList.vue";
import Details from "@/components/Views/Details/Main.vue";
import FullPayment from "@/components/Common/Payment/FullPayment/Main.vue";
import Quotes from "@/components/Views/Quotes/Main.vue";
import QuoteList from "@/components/Views/Quotes/QuoteList.vue";
import ProductDashboard from "@/components/Views/ProductDashboard/Main.vue";
import Analytics from "@/components/Views/Analytics/Main.vue";
import DirectBuy from "@/components/Views/DirectBuy/Main.vue";
import ClientManagement from "@/components/Views/ClientManagement/Main.vue";
import Referrals from "@/components/Views/Referrals/Main.vue";
import ReferralList from "@/components/Views/Referrals/ReferralList.vue";
import UserManagement from "@/components/Views/UserManagement/Main.vue";

WhiteLabelling.getSubDomain();

var routes = [
    {
        path: "/:catchAll(.*)",
        redirect: "/",
    },
    {
        path: "/",
        component: MainContainer,
        redirect: "/dashboard",
        children: [
            {
                path: "register",
                component: Registration,
                redirect: "personal-detail",
                children: [
                    {
                        path: "personal-detail",
                        component: PersonalDetail,
                        name: "Personal Details",
                    },
                    {
                        path: "upload-document",
                        component: UploadDocument,
                        name: "Upload Documents",
                    },
                ],
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                },
            },
            {
                path: "terms",
                component: Terms,
                name: "Terms",
                meta: {
                    requireTerms: false,
                    requireAuth: true,
                },
            },
            {
                path: "dashboard",
                component: Dashboard,
                name: "Dashboard",
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireEditRights: true,
                },
            },
            {
                path: "buy-policy",
                component: BuyPolicy,
                name: "BuyPolicy",
                meta: {
                    keepPolicyValues: true,
                    requireAuth: true,
                    requireTerms: true,
                    requireEditRights: true,
                },
            },
            {
                path: "cover-summary",
                component: CoverSummary,
                name: "CoverSummary",
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireEditRights: true,
                },
            },
            {
                path: "referrals",
                component: Referrals,
                children: [
                    {
                        path: "quotes",
                        name: "ReferredQuotes",
                        props: {
                            referralType: "quotes",
                        },
                        component: ReferralList,
                    },
                    {
                        path: "policies",
                        name: "ReferredPolicies",
                        props: {
                            referralType: "policies",
                        },
                        component: ReferralList,
                    },
                ],
                redirect: () => {
                    return {
                        path: "/referrals/quotes",
                    };
                },
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireUnderwriter: true,
                },
            },
            {
                path: "policies",
                component: Policies,
                children: [
                    {
                        path: "in-progress",
                        name: "PolicyInProgress",
                        props: {
                            policyStatus: "INPROGRESS",
                        },
                        component: PolicyList,
                    },
                    {
                        path: "bound",
                        name: "PolicyGenerated",
                        props: {
                            policyStatus: "GENERATED",
                        },

                        component: PolicyList,
                    },
                    {
                        path: "broker-review",
                        name: "PolicyBrokerReview",
                        props: {
                            policyStatus: "BROKER_REVIEW",
                        },

                        component: PolicyList,
                    },
                    {
                        path: "referred",
                        name: "PolicyReferred",
                        props: {
                            policyStatus: "REFERRED",
                        },

                        component: PolicyList,
                    },
                    {
                        path: "on-cover",
                        name: "PolicyOnCover",
                        props: {
                            policyStatus: "ONCOVER",
                        },
                        component: PolicyList,
                    },
                    {
                        path: "expired",
                        name: "PolicyExpired",
                        props: {
                            policyStatus: "EXPIRED",
                        },
                        component: PolicyList,
                    },
                    {
                        path: "claimed",
                        name: "PolicyClaimed",
                        props: {
                            policyStatus: "CLAIMED",
                        },
                        component: PolicyList,
                    },
                    {
                        path: "cancelled",
                        name: "PolicyCancelled",
                        props: {
                            policyStatus: "CANCELLED",
                        },
                        component: PolicyList,
                    },
                    {
                        component: Details,
                        path: ":id",
                        name: "PolicyDetail",
                        props: {
                            detailFor: "policy",
                        },
                    },
                    {
                        component: FullPayment,
                        path: ":id/payment",
                        name: "PolicyPayment",
                        props: {
                            paymentFrom: "policy",
                        },
                    },
                ],
                redirect: () => {
                    return {
                        path: `/policies/${
                            store.getters["auth/isNormalUser"]
                                ? "in-progress"
                                : "bound"
                        }`,
                    };
                },
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                },
            },
            {
                path: "quotes",
                component: Quotes,
                children: [
                    {
                        path: "active",
                        name: "QuoteActive",
                        props: {
                            quoteStatus: "ACTIVE",
                        },
                        component: QuoteList,
                    },
                    {
                        path: "inactive",
                        name: "QuoteInactive",
                        props: {
                            quoteStatus: "INACTIVE",
                        },
                        component: QuoteList,
                    },
                    {
                        path: ":id",
                        component: Details,
                        name: "QuoteDetail",
                        props: {
                            detailFor: "quote",
                        },
                        meta: {
                            requireAuth: true,
                            requireTerms: true,
                            requireNotNormal: false,
                            keepPolicyValues: true,
                            requireEditRights: true,
                        },
                    },
                    {
                        path: ":id/payment",
                        name: "QuotePayment",
                        component: FullPayment,
                        props: {
                            paymentFrom: "quote",
                        },
                        meta: {
                            keepPolicyValues: true,
                            requireAuth: true,
                            requireTerms: true,
                            requireNotNormal: false,
                            requireEditRights: true,
                        },
                    },
                ],
                redirect: () => {
                    return {
                        path: "/quotes/active",
                    };
                },
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireNotNormal: true,
                    requireEditRights: true,
                },
            },
            ...(!store.state.subDomainDetail.hideProductDashboard
                ? [
                      {
                          path: "product-dashboard",
                          name: "ProductDashboard",
                          component: ProductDashboard,
                          meta: {
                              requireAuth: true,
                              requireTerms: true,
                              requireNotNormal: true,
                              requireEditRights: true,
                          },
                      },
                  ]
                : []),
            {
                path: "analytics",
                name: "Analytics",
                component: Analytics,
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireUnderwriter: true,
                },
            },
            {
                path: "client-management",
                name: "ClientManagement",
                component: ClientManagement,
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireNotNormal: true,
                    requireEditRights: true,
                },
            },
            {
                path: "user-management",
                name: "UserManagement",
                component: UserManagement,
                meta: {
                    requireAuth: true,
                    requireTerms: true,
                    requireUnderwriter: true,
                    requireEditRights: true,
                },
            },
            ...(store.state.subDomainDetail.renderDirectBuy
                ? [
                      {
                          path: "direct-buy/:product",
                          component: DirectBuy,
                          name: "DirectBuyPolicy",
                          meta: {
                              requireAuth: false,
                              requireTerms: false,
                              requireEditRights: true,
                          },
                      },
                  ]
                : []),
        ],
    },
];

const router = createRouter({
    routes,
    linkActiveClass: "active",
    mode: "history",
    history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((route) => route.meta.requireAuth)) {
        if (store.state.auth.isAuthenticated) {
            UserService.checkPermissionAndRoute(next, to);
        } else {
            app.config.globalProperties.$auth.login(next, to);
        }
    } else {
        next();
    }
});

export default router;
