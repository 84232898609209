<template>
    <div
        class="application-status-container d-flex flex-column justify-content-between align-items-center"
    >
        <div class="w-100 mb-20px">
            <bev-image
                :addBackground="false"
                :imageUrl="imageUrl"
                :width="'330px'"
                :height="'auto'"
                class="mw-100 m-auto"
                :imageAlt="paymentStatus"
            ></bev-image>
            <heading-block
                class="mt-30px"
                :align="'center'"
                :heading="heading"
                :description="description"
                :linkInDescription="
                    showRetry
                        ? {
                              text: 'Retry payment',
                              clickHandler: retryHandler,
                          }
                        : null
                "
                :headingSizeClass="'font-40'"
                :descriptionSizeClass="'font-14'"
            ></heading-block>
        </div>
        <div class="quote-number gradient_background mb-20px">
            <span>Quote number: </span>
            <span class="fw-bold" :style="{ color: statusColor }">{{
                quoteNumber
            }}</span>
        </div>
        <div class="font-14 text-center w-75 mb-20px">
            {{ extraInfo }}
        </div>
        <bev-button
            :themeType="'dark'"
            :clickHandler="goToDashboard"
            :class="'large mb-20px'"
        >
            Go to dashboard
        </bev-button>
    </div>
</template>

<script>
/**
 * BEV Application (Quote or Policy) status page after buying
 *
 * @param {String} paymentStatus status for the Quote or Policy Payment
 * @param {String} heading heading for the Application status
 * @param {String} description description for the Application status
 * @param {String} quoteNumber quoteNumber for the Application
 * @param {String} extraInfo extra info to be shown with the Application status
 * @param {Boolean} showRetry if the retry link to be shown or not
 * @param {Function} retryHandler if the showRetry, handler to be used on retry link click
 * @param {String} statusColor color for the status for the quote
 */

export default {
    props: {
        paymentStatus: {
            type: String,
            required: true,
        },
        heading: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        quoteNumber: {
            type: String,
            required: true,
        },
        extraInfo: {
            type: String,
            required: true,
        },
        showRetry: {
            type: Boolean,
            default: false,
        },
        retryHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        statusColor: {
            type: String,
            required: true,
        },
    },
    computed: {
        imageUrl() {
            switch (this.paymentStatus) {
                case this.$policyValues.paymentStatus.successful:
                case this.$policyValues.paymentStatus.invoiced:
                    return this.$imagePath.applicationSuccess;
                case this.$policyValues.paymentStatus.pending:
                    return this.$imagePath.paymentPending;
                case this.$policyValues.paymentStatus.failed:
                    return this.$imagePath.paymentPending;
                default:
                    return "";
            }
        },
    },
    methods: {
        goToDashboard() {
            this.$router.push({ path: "/dashboard" });
        },
    },
};
</script>

<style scoped>
.application-status-container {
    overflow: auto;
    max-width: 100%;
    height: 100%;
    padding: 20px 12%;
    /* overflow: auto; */
}
/* .application-status-container .content {
    max-width: 100%;
    height: 100%;
    padding: 20px 4%;
} */
.quote-number {
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
}
</style>
