<template>
    <div class="d-flex align-items-center justify-content-center">
        <Logo
            :width="logoWidth"
            :maxWidth="logoMaxWidth"
            :showPoweredBy="showPoweredBy"
        />
        <hr
            class="additional-logo-divider"
            v-if="subDomainDetail.additionalLogo"
        />
        <AdditionalLogo
            :width="logoWidth"
            :maxWidth="logoMaxWidth"
            v-if="subDomainDetail.additionalLogo"
        />
    </div>
</template>

<script>
import AdditionalLogo from "@/components/Common/AdditionalLogo.vue";
import Logo from "@/components/Common/Logo.vue";
export default {
    name: "MergedLogo",
    props: {
        logoWidth: {
            type: String,
            default: "100px",
        },
        logoMaxWidth: {
            type: String,
            default: "100px",
        },
        showPoweredBy: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AdditionalLogo,
        Logo,
    },
};
</script>

<style scoped>
.additional-logo-divider {
    transform: rotate(180deg);
    height: 60px;
    margin: 0 10px;
}
</style>
