import ReferenceDataService from "@/services/ReferenceDataService";

const state = {
    productKeyMap: {},
    currencyList: [],
};

const mutations = {
    setProductKeyMap(state, value) {
        state.productKeyMap = value.reduce((result, k) => {
            return { ...result, [k.value]: k.metaData };
        }, {});
    },
    setCurrencyList(state, value) {
        state.currencyList = value.reduce((result, k) => {
            return { ...result, [k.value]: k.metaData };
        }, {});
    },
};

const actions = {
    async getProductKeyMap({ commit }) {
        try {
            const res = await ReferenceDataService.getProductKeyMap();
            commit("setProductKeyMap", res.data.content);
        } catch (error) {
            return error;
        }
    },
    async getCurrencyList({ commit }) {
        try {
            const res = await ReferenceDataService.getCurrencyList();
            commit("setCurrencyList", res.data.content);
        } catch (error) {
            return error;
        }
    },
};

export default {
    state,
    mutations,
    actions,
    namespaced: true,
};
