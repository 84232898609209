export default {
    productHeadingBgColor: "#021b33",
    productHeadingColor: "white",
    gradientShade1: "#112943",
    gradientShade2: "#021b33",
    loaderColor: "#e1ebf7",
    loadingBlockColor: "rgba(255, 255, 255, 0.3",
    navigationItemsBgColor: "transparent",
    navigationItemsColor: "white",
    cardColorV1: "white",
    cardBgColorV1: "#112943",
    cardBoxShadowV1: "none",
    cardColorV2: "white",
    cardBgColorV2: "#021b33",
    cardBoxShadowV2: "none",
    dashboardColor: "white",
    headerColor: "white",
    headerBgColor: "#112943",
    headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
    sidebarBg: "#112943",
    sidebarItemColor: "white",
    sidebarItemActiveColor: "#3cd1ba",
    sidebarItemActiveBgColor: "transparent",
    baseBg: "#021b33",
    baseDark: "#021b33",
    white: "#ffffff",
    baseLight: "#112943",
    "pale-salmon": "#fbb39e",
    activeColorV2: "#67e8ff",
    "sea-green": "#4dfa91",
    "dodger-blue": "#3ebcff",
    activeColorV3: "#3ebcff",
    summaryHeader: "#3ebcff",
    "very-light-brown": "#e2b47f",
    "input-info-message": "#e2b47f",
    buttonPrimary: "#2e4864",
    buttonPrimaryHover: "#1a324d",
    buttonPrimaryColor: "#ffff",
    buttonSecondary: "#e1ebf7",
    buttonSecondaryHover: "#ffff",
    buttonSecondaryHoverColor: "#112943",
    buttonSecondaryColor: "#112943",
    "modal-bg": "#1e3855",
    modalColor: "white",
    danger: "#ff7777",
    veryLightSecondary: "#e1ebf7",
    activeColorV1: "#3cd1ba",
    baseShade1: "#193553",
    "black-pearl": "#011a32",
    cardBgColorV3: "#011a32",
    cardColorV3: "#ffffff",
    baseShade2: "#2d4b6c",
    "light-orange": "#ffb6a0",
    yellow: "#ffcc00",
    datePickerBg: "#021b33",
    datePickerBorder: "#ffffff",
    datePickerDefaultColor: "#ffffff",
    datePickerDisabledColor: "gray",
    datePickerButtonColor: "#ffffff",
    datePickerCellActiveColor: "#ffffff",
    datePickerCellHover: "#ffffff",
    datePickerCellInRange: "rgba(60, 209, 186, 0.51)",
    tableHeaderBg: "#ffffff",
    tableHeaderColor: "#021b33",
    tableBorder: "#e1ebf7",
    tableDataColor: "#ffffff",
    tableDataBorder: "rgba(255, 255, 255, 0.2)",
    inputInfoIcon: "#4dfa91",
    cardError: "#ffffff",
    generatedStatus: "#3ebcff",
    inprogressStatus: "#e1ebf7",
    incompleteStatus: "#ffb6a0",
    referredStatus: "#ffcc00",
    underwriterApprovedStatus: "#4dfa91",
    underwriterDeclinedStatus: "#ff7777",
    brokerReviewStatus: "#ffcc00",
    brokerReviewApprovedStatus: "#4dfa91",
    brokerReviewDeclinedStatus: "#ff7777",
    rejectedStatus: "#ff7777",
    expiredStatus: "#ff7777",
    ntuStatus: "#ff7777",
    boundStatus: "#5bc2e77",
    cancelledStatus: "#ff7777",
    claimedStatus: "#4dfa91",
    generatedStatusText: "#000000",
    incompleteStatusText: "#000000",
    referredStatusText: "#000000",
    underwriterApprovedStatusText: "#000000",
    underwriterDeclinedStatusText: "#000000",
    brokerReviewStatusText: "#000000",
    brokerReviewApprovedStatusText: "#000000",
    brokerReviewDeclinedStatusText: "#000000",
    rejectedStatusText: "#000000",
    expiredStatusText: "#000000",
    ntuStatusText: "#000000",
    boundStatusText: "#000000",
    cancelledStatusText: "#000000",
    claimedStatusText: "#000000",
    inprogressStatusText: "#000000",
    recommendedValue: "#ffb6a0",
    footerColor: "#ffffff",
    linkColor: "#67e8ff",
    endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
    premiumListBorderColor: "white",
    commentActionBoxBgColor: "white",
    commentActionBoxHeadingBorderColor: "rgba(255, 255, 255, 0.13)",
    dropdownBgColor: "#193553",
    dropdownColor: "white",
    borderColorOverCardV1: "white",
    borderColorOverCardV2: "hsla(0,0%,100%,.14)",
    inputColor: "white",
    contactUsTextColor: "white",
    tooltipTextColor: "#112943",
    selectableRadioTagBgColor: "#021b33",
    selectableRadioTagColor: "white",
    selectableRadioTagHoverColor: "white",
    selectableRadioTagActiveColor: "#021b33",
    checkBoxInputTextColor: "white",
    checkBoxInputBorderColor: "white",
    checkBoxInputCheckmarkColor: "#112943",
    checkBoxInputActiveBgColor: "#3cd1ba",
    analyticsMetricHeadingColor: "white",
    whiteBorderedButtonBgColor: "white",
    rangleSliderValueColor: "#112943",
    paginationTextColor: "white",
    paginationControllerTextColor: "white",
    paginationHoverTextColor: "#112943",
    summarySuggestionDescriptionTextColor: "white",
    searchBarColor: "white",
    searchBarLightBorder: "#fff",
    searchBarBgColor: "#021b33",
    searchBarBoxShadow: "#021b33",
    searchBarPlaceholderColor: "#77828e",
    selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
    notificationPopColor: "white",
    notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    notificationPopBgColor: "#193553",
    messageTitle: "#193553",
    cardBgV1: "#112943",
    quickExtensionCardBgColor: "rgba(25, 53, 83, 0.7)",
    topActionDisabledText: "rgba(255, 255, 255, 0.5)",
    mapZoomControlBgColor: "#112943",
    mapZoomControlColor: "white",
    navigationItemBottomBorderColor: "rgba(255, 255, 255, 0.2)",
};
