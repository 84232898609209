import Keycloak from "keycloak-js";
import { createApp } from "vue";
import keycloakConfig from "../keycloak.json";
import { store } from "@/store/index";
import UserService from "@/services/UserService";
import WhiteLabelling from "@/services/WhiteLabelling";
import { mapState, mapActions, mapMutations } from "vuex";

let config = keycloakConfig[process.env.VUE_APP_ENV];
config.url = WhiteLabelling.getAuthUrl();

let auth = createApp({
    data() {
        return {
            keycloak: null,
        };
    },
    mounted() {
        this.keycloak = new Keycloak(config);
        if (this.isAuthenticated) {
            this.keycloak.init({
                token: this.access_token,
                refreshToken: this.refresh_token,
                idToken: this.id_token,
                timeSkew: 0,
            });
        }
    },
    computed: {
        // Access Token

        ...mapState("auth", [
            "access_token",
            "refresh_token",
            "id_token",
            "isAuthenticated",
            "user",
            "roles",
        ]),
        ...mapState("buyPolicy", ["currentStep"]),
    },
    methods: {
        ...mapActions("auth", ["setTokens", "setUserInfo"]),
        ...mapActions(["logoutUser"]),
        ...mapActions("referenceData", ["getProductKeyMap", "getCurrencyList"]),
        ...mapMutations("auth", ["setAuthenticated", "setUserId"]),
        ...mapMutations("buyPolicy", ["setBuyPolicyStep", "setProductsJSON"]),
        ...mapMutations(["showLoader", "hideLoader"]),
        // Keycloak Login

        login(next = Function, to) {
            this.keycloak
                .init({
                    onLoad: "login-required",
                    checkLoginIframe: false,
                    redirectUri: window.location.origin + "/" + to.path,
                })
                .then((auth) => {
                    this.setTokens({
                        accessToken: this.keycloak.token,
                        refreshToken: this.keycloak.refreshToken,
                        idToken: this.keycloak.idToken,
                    });
                    this.setAuthenticated(auth);
                    this.keycloak.loadUserProfile().then((profile) => {
                        this.keycloak.loadUserInfo().then((info) => {
                            this.setUserInfo({
                                user: profile,
                                roles: this.keycloak.realmAccess.roles,
                                ...(info.groups && { groups: info.groups }),
                                ...(info.underwriterId &&
                                    info.underwriterId[0] && {
                                        underwriterId: info.underwriterId[0],
                                    }),
                            });
                            if (!this.currentStep) {
                                this.setBuyPolicyStep({
                                    step: "SelectProduct",
                                    back: false,
                                });
                            }
                            this.setUserId();
                            UserService.checkPermissionAndRoute(next, to);
                            this.hideLoader();
                            this.setProductsJSON([]);
                            this.getProductKeyMap();
                            this.getCurrencyList();
                        });
                    });
                })
                .catch((err) => {
                    return err;
                });
        },

        // Keycloak Logout

        logout() {
            window.onbeforeunload = null;
            this.showLoader();
            this.keycloak.logout({
                redirectUri: window.location.origin + "/",
            });
            this.logoutUser();
            localStorage.clear();
        },

        async updateTokenSingle(reloadOnSuccess) {
            try {
                const refreshed = await this.keycloak.updateToken(30);
                if (refreshed) {
                    this.setTokens({
                        accessToken: this.keycloak.token,
                        refreshToken: this.keycloak.refreshToken,
                        idToken: this.keycloak.idToken,
                    });
                    if (reloadOnSuccess) {
                        window.location.reload();
                    }
                }
            } catch (err) {
                localStorage.clear();
                window.location.reload();
            }
        },

        // Keycloak refresh token

        updateToken() {
            setInterval(() => {
                this.updateTokenSingle();
            }, 20000);
        },
    },
});

export default {
    install: (app) => {
        auth.use(store);
        app.config.globalProperties.$auth = auth.mount(
            document.createElement("div")
        );
    },
};
