<template>
    <div class="bev-card-v3 w-100 terms-data">
        <heading-block
            :align="'center'"
            :heading="`${subDomainDetail.name} Terms & Conditions`"
            :description="`Please read all the terms and conditions of ${subDomainDetail.name} related to the applying covers, its usage, claims etc., and accept to continue your registration`"
            :headingSizeClass="'font-26 sm-font-12'"
            :descriptionSizeClass="'font-16 sm-font-10'"
        ></heading-block>
        <terms-text class="sm-mt-20px mt-40px"></terms-text>
    </div>
</template>

<script>
import TermsText from "@/components/Common/TermsText";
export default {
    components: {
        TermsText,
    },
};
</script>

<style scoped>
p {
    line-height: 1.88;
}
</style>
