<template>
    <div class="product-card-content h-100">
        <div class="d-flex flex-column align-items-center">
            <circle-image
                :imageInfo="{
                    imageColor: imageColor,
                    iconUrl: imageInfo.imageUrl,
                    width: imageInfo.width,
                    height: imageInfo.height,
                }"
                :width="'65px'"
                :height="'65px'"
                class="mb-15px"
            ></circle-image>
            <div class="heading text-center sm-font-16">{{ heading }}</div>
            <p v-if="!isEnabled && heading === 'Liability'">( Coming Soon )</p>
        </div>
        <ul>
            <li v-for="(point, index) in points" :key="index">
                <bev-image
                    :addBackground="true"
                    :backgroundColor="imageColor"
                    :imageAlt="'tick-icon'"
                    :imageUrl="$imagePath.checkMark"
                    :width="'20px'"
                    :height="'20px'"
                    class="mr-10px"
                ></bev-image>
                <p class="font-16 sm-font-12">{{ point }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        imageInfo: {
            type: Object,
            default: function () {
                return {
                    imageUrl: "",
                    width: "20px",
                    height: "20px",
                };
            },
        },
        heading: String,
        description: String,
        isEnabled: Boolean,
        points: {
            type: Array,
            default: () => [],
        },
        selected: Boolean,
    },
    computed: {
        imageColor() {
            if (this.selected) {
                return this.$colors.bevColors.activeColorV1;
            } else {
                return "var(--cardColorV2)";
            }
        },
    },
};
</script>

<style scoped lang="scss">
.product-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .description {
        margin-top: 12px;
    }
    ul {
        list-style: none;
        padding: 20px 0 0;
        width: 100%;
        text-align: left;
        margin: 0;
        li {
            display: flex;
            align-items: center;
            padding: 6px 0;
            justify-content: center;
            p {
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }
        }
    }
}
</style>
