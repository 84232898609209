<template>
    <div class="d-flex align-items-center justify-content-end">
        <bev-dialog
            :title="''"
            :description="''"
            :closeDialog="closeConfirmationPopup"
            :state="confirmationPopup"
            :width="'40%'"
            :actions="{
                show: false,
            }"
        >
            <template v-slot:dialog-content>
                <ActionWithReason
                    :closeHandler="closeConfirmationPopup"
                    :actionLoading="cancellingPolicy"
                    :policyAction="cancelPolicy"
                    :actionText="'cancel'"
                    :inputLabel="'Cancellation'"
                    :rejectStatus="$policyValues.policyStatus.CANCELLED.value"
                    v-if="confirmationPopFor === 'actionWithReason'"
                />
                <ReopenQuoteConfirm
                    :policyAction="reopenQuote"
                    :closeHandler="closeConfirmationPopup"
                    :actionLoading="reopeningQuote"
                    v-if="confirmationPopFor === 'reopen'"
                />
            </template>
        </bev-dialog>
        <div
            class="topActions position-relative d-flex align-items-center"
            v-if="showTopActions"
            v-click-away="topActionsClickAway"
        >
            <div
                class="d-flex align-items-center drop-target text-activeColorV1 mr-15px cursor-pointer"
                @click="editQuotePolicy(false)"
                v-if="showEditButton"
            >
                <bev-image
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.activeColorV1"
                    :imageUrl="$imagePath.edit"
                    :imageAlt="'caret-down'"
                    :width="'15px'"
                    :height="'15px'"
                    class="cursor-pointer mr-5px caretImage"
                ></bev-image>
                <p class="font-14">
                    Amend <span class="capitalize">{{ detailFor }}</span>
                </p>
            </div>
            <v-popover
                distance="5"
                :container="'.topActions'"
                :shown="downloadOptionOpen"
                @update:shown="(val) => (downloadOptionOpen = val)"
                :placement="'bottom-end'"
                :boundary="'.topActions'"
                :triggers="[]"
                :autoHide="false"
                class="action-popoer"
            >
                <div
                    class="tooltip-target cursor-pointer"
                    @click="downloadOptionOpen = !downloadOptionOpen"
                >
                    <div
                        class="d-flex align-items-center drop-target text-activeColorV1 mr-15px"
                    >
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="$colors.bevColors.activeColorV1"
                            :imageUrl="$imagePath.download"
                            :imageAlt="'caret-down'"
                            :width="'15px'"
                            :height="'15px'"
                            class="cursor-pointer mr-5px"
                        ></bev-image>
                        <p class="font-14">Download Documents</p>
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="$colors.bevColors.activeColorV1"
                            :imageUrl="$imagePath.caretDown"
                            :imageAlt="'caret-down'"
                            :width="'12px'"
                            :height="'7px'"
                            class="cursor-pointer ml-5px caretImage"
                            :class="{ open: downloadOptionOpen }"
                        ></bev-image>
                    </div>
                </div>
                <template #popper>
                    <div class="popover-content">
                        <ul class="popover-list">
                            <li
                                class="drop-item px-0"
                                @click="
                                    details.prodCount &&
                                        downloadDocuments('doc')
                                "
                                :class="{ disabled: !details.prodCount }"
                                v-tooltip="{
                                    content: documentDownloadDisabledTooltip,
                                    disabled: !!details.prodCount,
                                }"
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="documentDownloadLoading"
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>
                                        {{
                                            detailFor === "quote"
                                                ? "Quote"
                                                : "Policy"
                                        }}
                                        Info
                                    </p>
                                </div>
                            </li>
                            <li
                                class="drop-item px-0"
                                @click="
                                    details.invoiceVersion &&
                                        downloadDocuments('invoice')
                                "
                                :class="{ disabled: !details.invoiceVersion }"
                                v-tooltip="{
                                    content:
                                        'Invoices are being generated - please wait or try reloading',
                                    disabled: !!details.invoiceVersion,
                                }"
                                v-if="!isNormalUser && detailFor === 'policy'"
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="invoiceDownloadLoading"
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>Invoice</p>
                                </div>
                            </li>
                            <li
                                class="drop-item px-0"
                                @click="downloadDocuments('proposalDoc')"
                                v-if="
                                    !isNormalUser &&
                                    detailFor === 'policy' &&
                                    details.proposalDocVersion
                                "
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="proposalDocumentDownloadLoading"
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>MTA Quote Documents</p>
                                </div>
                            </li>
                            <li
                                class="drop-item px-0"
                                @click="downloadDocuments('statementOfFact')"
                                v-if="!isNormalUser && details.sofVersion"
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="statementOfFactDownloadLoading"
                                        :spinnerColor="
                                            $colors.bevColors.veryLightSecondary
                                        "
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>Statement of Fact</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>
            </v-popover>
            <v-popover
                distance="5"
                :container="'.topActions'"
                :shown="showOtherOptions && optionOpen"
                @update:shown="(val) => (optionOpen = val)"
                :placement="'bottom-end'"
                :boundary="'.topActions'"
                :triggers="[]"
                :autoHide="false"
                class="action-popoer"
                v-show="showOtherOptions"
            >
                <div
                    class="tooltip-target cursor-pointer"
                    @click="optionOpen = !optionOpen"
                >
                    <div
                        class="d-flex align-items-center drop-target text-activeColorV1"
                    >
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="$colors.bevColors.activeColorV1"
                            :imageUrl="$imagePath.moreDots"
                            :imageAlt="'more-option'"
                            :width="'20px'"
                            :height="'20px'"
                            class="cursor-pointer caretImage"
                            :class="{ open: optionOpen }"
                        ></bev-image>
                    </div>
                </div>
                <template #popper>
                    <div class="popover-content">
                        <ul class="popover-list">
                            <li
                                class="drop-item px-0"
                                @click="editQuotePolicy(true)"
                                v-if="checkAction($policyValues.actions.CLONE)"
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="cloningQuote"
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>
                                        Clone
                                        <span class="capitalize">{{
                                            detailFor
                                        }}</span>
                                    </p>
                                </div>
                            </li>
                            <li
                                class="drop-item px-0"
                                @click="
                                    openConfirmtionPopup('actionWithReason')
                                "
                                v-if="
                                    checkAction($policyValues.actions.CANCEL) ||
                                    checkAction(
                                        $policyValues.actions.CANCEL_WITH_REASON
                                    )
                                "
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="cancellingPolicy"
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>Cancel Policy</p>
                                </div>
                            </li>
                            <li
                                class="drop-item px-0"
                                @click="handleReferredAction"
                                v-if="
                                    checkAction(
                                        $policyValues.actions
                                            .REFER_TO_UNDERWRITER
                                    )
                                "
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="referringQuoteOrPolicy"
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>Refer to Underwriter</p>
                                </div>
                            </li>
                            <li
                                class="drop-item px-0"
                                @click="onMarkQuoteAsNTU"
                                v-if="
                                    checkAction(
                                        $policyValues.actions.MARK_AS_NTU
                                    )
                                "
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="markingQuoteAsNTU"
                                        :spinnerColor="
                                            $colors.bevColors.veryLightSecondary
                                        "
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>Mark as NTU</p>
                                </div>
                            </li>
                            <!-- Not required for now. Will Uncomment in future to support this. -->
                            <!-- <li
                                class="drop-item px-0"
                                @click="reopenButtonClickHandler"
                                v-if="
                                    detailFor === 'quote' &&
                                    details.quoteStatus ===
                                        $policyValues.quoteStatus.EXPIRED.value
                                "
                            >
                                <div
                                    class="d-flex align-items-center drop-text"
                                >
                                    <spinner
                                        v-if="reopeningQuote"
                                        :spinnerColor="
                                            $colors.bevColors.veryLightSecondary
                                        "
                                        :width="'15px'"
                                        :height="'15px'"
                                        class="mr-5px"
                                    ></spinner>
                                    <p>Reopen Quote</p>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </template>
            </v-popover>
        </div>
    </div>
</template>

<script>
/**
 * Status bar to show status of the quote/policy
 *
 * @param {Object} details details for Quote or Policy
 * @param {String} detailFor quote or policy for which the Details are being shown
 * @param {Function} setDetails funtion to set details
 */

import PolicyService from "@/services/PolicyService";
import QuoteService from "@/services/QuoteService";
import DocumentService from "@/services/DocumentService";
import WebsocketService from "@/services/WebsocketService";
import fileDownload from "js-file-download";
import { mapGetters, mapMutations, mapState } from "vuex";
import ActionWithReason from "@/components/Views/Details/ActionWithReason";
import ReopenQuoteConfirm from "@/components/Views/Details/ReopenQuoteConfirm";
import { mixin as clickaway } from "vue3-click-away";
import policy from "@/constant/policy";
export default {
    props: {
        details: Object,
        detailFor: String,
        setDetails: Function,
        userDetails: {
            type: Object,
            default: () => {
                return {};
            },
        },
        brokerDetails: {
            type: Object,
            default: () => {
                return {};
            },
        },
        actions: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ActionWithReason,
        ReopenQuoteConfirm,
    },
    mixins: [clickaway],
    data() {
        return {
            cancellingPolicy: false,
            confirmationPopup: false,
            optionOpen: false,
            downloadOptionOpen: false,
            downloadLoading: [],
            docWebhookHelper: null,
            policyWebhookHelper: null,
            commentWebhookHelper: null,
            confirmationPopFor: "",
            reopeningQuote: false,
            cloningQuote: false,
            referringQuoteOrPolicy: false,
            markingQuoteAsNTU: false,
        };
    },
    computed: {
        ...mapState("auth", ["userId"]),
        ...mapState("referenceData", ["productKeyMap"]),
        ...mapGetters("auth", [
            "isAdmin",
            "isBroker",
            "isUnderwriter",
            "isNormalUser",
            "userAttributes",
        ]),
        ...mapState("buyPolicy", [
            "productsJSON",
            "questionBundles",
            "productDataMap",
            "quickExtensions",
            "quickExtensionsPremium",
            "productsFlow",
        ]),
        documentDownloadLoading() {
            return this.downloadLoading.includes("doc");
        },
        invoiceDownloadLoading() {
            return this.downloadLoading.includes("invoice");
        },
        proposalDocumentDownloadLoading() {
            return this.downloadLoading.includes("proposalDoc");
        },
        statementOfFactDownloadLoading() {
            return this.downloadLoading.includes("statementOfFact");
        },
        showEditButton() {
            // Getter to check if need to show edit quote/policy button
            return (
                ((this.isBroker &&
                    (this.details.creatorUserId === this.userId ||
                        this.details.brokerId ===
                            this.userAttributes.brokerId)) ||
                    (this.isNormalUser &&
                        this.details.userId === this.userId) ||
                    this.isUnderwriter) &&
                this.checkAction(this.$policyValues.actions.AMEND) &&
                this.productsJSON.length > 0
            );
        },
        showTopActions() {
            return (
                this.details.prodCount ||
                this.showEditButton ||
                this.showOtherOptions ||
                this.details.quoteReopenDate
            );
        },
        documentDownloadDisabledTooltip() {
            if (
                this.details.quoteStatus ===
                this.$policyValues.quoteStatus.INCOMPLETE_SUBMISSION.value
            ) {
                return "Quote is incomplete - no document available";
            }
            return "Documents are being generated - please wait or try reloading";
        },
        showOtherOptions() {
            return (
                this.checkAction(this.$policyValues.actions.CANCEL) ||
                this.checkAction(
                    this.$policyValues.actions.CANCEL_WITH_REASON
                ) ||
                this.checkAction(this.$policyValues.actions.CLONE) ||
                this.checkAction(
                    this.$policyValues.actions.REFER_TO_UNDERWRITER
                ) ||
                (this.detailFor === "quote" &&
                    this.details?.quoteStatus ===
                        this.$policyValues.quoteStatus.EXPIRED.value) ||
                this.checkAction(this.$policyValues.actions.MARK_AS_NTU)
            );
        },
        formattedMTQDocName() {
            return this.details.policyNumber.replace("-P-", "-MTQ-");
        },
    },
    created() {
        this.docWebhookHelper = new WebsocketService();
        this.policyWebhookHelper = new WebsocketService();
        this.commentWebhookHelper = new WebsocketService();
    },
    mounted() {
        this.docWebhookHelper.webhookConnect(
            () =>
                this.webhookHandler(
                    "docWebhookHelper",
                    "/topic/docUploaded/",
                    this.documentUploadedWebhookSuccessHandler
                ),
            "documentManagerServiceUrl"
        );
        this.policyWebhookHelper.webhookConnect(
            () =>
                this.webhookHandler(
                    "policyWebhookHelper",
                    "/topic/userUpdated/",
                    this.userUpdateWebhookSuccessHandler
                ),
            "policyServiceUrl"
        );
        this.commentWebhookHelper.webhookConnect(
            () =>
                this.webhookHandler(
                    "commentWebhookHelper",
                    "/topic/commentAdded/",
                    this.commentUpdatedHelper
                ),
            "commentServiceUrl"
        );
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setBuyPolicyStep",
            "setEditingFor",
            "setPolicyUser",
            "setPolicyBroker",
            "setIsEditingOld",
            "setQuestionBundle",
            "addToProductsFlow",
            "addProduct",
            "setBrokerage",
            "setProductDataMap",
            "setQuickExtensions",
            "setQuickExtensionsPremium",
            "setCellId",
            "setCurrentStepIndex",
            "addIsInvalidData",
            "setCloneFrom",
            "addInvalidProducts",
        ]),
        async onMarkQuoteAsNTU() {
            this.markingQuoteAsNTU = true;
            try {
                const res = await QuoteService.updateQuote(
                    this.$route.params.id,
                    {
                        ...this.details,
                        quoteStatus: this.$policyValues.quoteStatus.NTU.value,
                    }
                );

                await this.setDetails(res.data, false, true);

                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: this.$messages.successMessages.ntuStatusSuccess,
                    type: "success",
                });
            } catch (error) {
                this.displayErrorMessage(
                    error?.response?.data?.message ||
                        "Failed to mark quote as NTU"
                );
            } finally {
                this.markingQuoteAsNTU = false;
            }
        },
        async handleReferredAction() {
            this.referringQuoteOrPolicy = true;
            const data = {
                ...this.details,
                referred: true,
                ...(this.detailFor === "policy"
                    ? {
                          policyStatus:
                              this.$policyValues.policyStatus.REFERRED.value,
                      }
                    : {
                          quoteStatus:
                              this.$policyValues.quoteStatus.REFERRED.value,
                      }),
            };
            try {
                const res = await this.updateQuoteOrPolicy(data);

                await this.setDetails(res.data, false, true);

                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: this.$messages.successMessages.referralSuccess(
                        this.detailFor === "policy"
                    ),
                    type: "success",
                });
            } catch (error) {
                this.displayErrorMessage(
                    error?.response?.data?.message ||
                        `Failed to refer  ${
                            this.detailFor === "policy" ? "policy" : "quote"
                        }`
                );
            } finally {
                this.referringQuoteOrPolicy = false;
            }
        },
        async updateQuoteOrPolicy(data) {
            return this.detailFor === "policy"
                ? await PolicyService.updatePolicy(this.$route.params.id, data)
                : await QuoteService.updateQuote(this.$route.params.id, data);
        },
        topActionsClickAway() {
            this.optionOpen = false;
            this.downloadOptionOpen = false;
        },
        openConfirmtionPopup(type) {
            this.confirmationPopFor = type;
            this.confirmationPopup = true;
            this.optionOpen = false;
        },
        reopenButtonClickHandler() {
            if (this.details.quoteReopenDate) {
                this.openConfirmtionPopup("reopen");
            } else {
                this.displayErrorMessage(
                    "This quote can not be reopened. The event start date for this quote is in less than 10 days or this quote has already been reopened."
                );
            }
        },
        closeConfirmationPopup() {
            this.confirmationPopup = false;
            this.confirmationPopFor = "";
        },
        checkAction(action) {
            return this.actions?.findIndex((a) => a.action === action) > -1;
        },
        documentUploadedWebhookSuccessHandler(data) {
            let response = JSON.parse(data.body)?.payload;
            let id =
                this.detailFor === "quote"
                    ? response.quoteId
                    : response.policyId;
            if (this.$route.params.id === id) {
                this.handleDocumentUpdate(response);
                this.handleInvoiceUpdate(response);
                this.handleProposalDocumentUpdate(response);
                this.handleStatementOfFactDocumentUpdate(response);
            }
        },
        userUpdateWebhookSuccessHandler(data) {
            let response = JSON.parse(data.body);
            if (this.details.userId === response.id) {
                this.$emit("userUpdated");
            }
        },
        commentUpdatedHelper(data) {
            let response = JSON.parse(data.body);
            if (
                this.userId !== response.commentedById &&
                ((this.detailFor === "quote" &&
                    this.details.quoteNumber === response.commentQuoteNumber) ||
                    (this.detailFor === "policy" &&
                        this.details.policyNumber ===
                            response.commentPolicyNumber))
            ) {
                this.$emit("newCommentAdded");
            }
        },
        async handleDocumentUpdate(response) {
            if (response.version) {
                await this.setDetails({
                    ...this.details,
                    [this.detailFor === "quote"
                        ? "quoteVersion"
                        : "policyVersion"]: response.version,
                    docCount: response.docCount,
                    customerName: response.customerName,
                });
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: "Your updated document is ready to be downloaded.",
                    type: "success",
                });
            }
        },
        async handleInvoiceUpdate(response) {
            if (
                this.detailFor === "policy" &&
                !this.isNormalUser &&
                response.invoiceVersion &&
                response.version
            ) {
                await this.setDetails({
                    ...this.details,
                    invoiceVersion: response.invoiceVersion,
                    policyVersion: response.version,
                    customerName: response.customerName,
                });
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: "Your updated invoice is ready to be downloaded.",
                    type: "success",
                });
            }
        },
        async handleProposalDocumentUpdate(response) {
            if (
                this.detailFor === "policy" &&
                !this.isNormalUser &&
                response.proposalDocVersion
            ) {
                await this.setDetails({
                    ...this.details,
                    proposalDocVersion: response.proposalDocVersion,
                    customerName: response.customerName,
                });
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: "Your updated proposal document is ready to be downloaded.",
                    type: "success",
                });
            }
        },
        async handleStatementOfFactDocumentUpdate(response) {
            if (response.sofVersion) {
                await this.setDetails({
                    ...this.details,
                    sofVersion: response.sofVersion,
                    customerName: response.customerName,
                });
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: "Your updated statement of fact document is ready to be downloaded.",
                    type: "success",
                });
            }
        },
        async downloadDocuments(downloadType) {
            // Handler to download document for every product to given userId in quote/policy info
            let documentName = null;
            let version = null;
            this.downloadLoading.push(downloadType);
            if (downloadType === "doc") {
                documentName =
                    this.detailFor === "quote"
                        ? this.details.quoteNumber
                        : this.details.policyNumber;
                version =
                    this.detailFor === "quote"
                        ? this.details.quoteVersion
                        : this.details.policyVersion;
            } else if (downloadType === "invoice") {
                documentName = `Invoice_${
                    this.detailFor === "quote"
                        ? this.details.quoteNumber
                        : this.details.policyNumber
                }`;
                version = this.details.invoiceVersion;
            } else if (downloadType === "proposalDoc") {
                documentName = this.formattedMTQDocName;
                version = this.details.proposalDocVersion;
            } else if (downloadType === "statementOfFact") {
                documentName = `Statement of Fact_${
                    this.detailFor === "quote"
                        ? this.details.quoteNumber
                        : this.details.policyNumber
                }`;
                version = this.details.sofVersion;
            }

            try {
                let result = await DocumentService.downloadDocument(
                    documentName,
                    version,
                    downloadType === "doc" ? this.details.prodCount : null,
                    downloadType === "proposalDoc",
                    downloadType === "statementOfFact"
                );
                fileDownload(result.data, `${documentName}.zip`);
                this.downloadLoading = this.downloadLoading.filter(
                    (d) => d !== downloadType
                );
            } catch (err) {
                this.downloadLoading = this.downloadLoading.filter(
                    (d) => d !== downloadType
                );
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        "Failed to download requested document"
                );
            } finally {
                this.downloadOptionOpen = false;
            }
        },
        setCommonFields(data) {
            let updatedBundles = {};
            let commonFields = JSON.parse(data.products)?.common_fields;
            Object.keys(policy.isCommonField).forEach((field) => {
                updatedBundles[policy.isCommonField[field]] = {
                    ...updatedBundles[policy.isCommonField[field]],
                    [field]: commonFields[field],
                };
            });
            Object.keys(updatedBundles).forEach((bundle) => {
                this.setQuestionBundle({
                    ...this.questionBundles,
                    [bundle]: {
                        ...(this.questionBundles?.[bundle] || {}),
                        ...updatedBundles[bundle],
                    },
                });
            });
        },
        // Handler to fill details in BuyPolicy state for edit quote/policy flow
        // It will redirect the user to BuyPolicy flow
        editQuotePolicy(forClone = false) {
            // Set isEditingFor in BuyPolicy state for edit flow
            if (!forClone) {
                this.setEditingFor(this.detailFor);
                this.setIsEditingOld(true);
            }
            const products = JSON.parse(this.details.products);
            delete products.common_fields;
            // Set policyUser in BuyPolicy state
            this.setPolicyUser(this.userDetails);
            // Set policyBroker in BuyPolicy state;
            this.setPolicyBroker(this.brokerDetails);
            // Set isEditingOld in BuyPolicy state
            // set Invalid Fileds for a quote
            let inValidatedData = this.details.inValidatedData;
            if (inValidatedData && inValidatedData.length) {
                inValidatedData.forEach((invalidField) => {
                    this.addIsInvalidData(invalidField);
                });
            }

            // Set Invalid Products for a quote
            let invalidProducts = this.details.invalidProducts;
            if (invalidProducts && invalidProducts.length) {
                invalidProducts.forEach((invalidProduct) => {
                    this.addInvalidProducts(invalidProduct);
                });
            }

            // Set questionBundles data according to the product in the quote/policy
            this.setQuestionBundle({
                ...this.questionBundles,
                SelectLocation: {
                    addressLine1: this.details.addressLine1,
                    venueName: this.details.addressLine2,
                    country: this.details.country,
                    city: this.details.city,
                    zipcode: this.details.zipcode,
                    region: this.details.region,
                },
                SelectClient: {
                    userData: this.userDetails,
                },
            });
            this.productHandler(products);
            this.setBrokerage(this.details.brokerage);
            this.setCellId(this.details.cellId);
            this.setCommonFields(this.details);

            if (!forClone) {
                // Set currentStep as CoverSUmmary in BuyPolicy flow
                if (this.details.lastPage) {
                    let stepIndex = this.productsFlow.findIndex(
                        (step) => step.name === this.details.lastPage
                    );
                    if (stepIndex !== -1) {
                        this.setCurrentStepIndex(stepIndex);
                    } else {
                        this.setCurrentStepIndex(0);
                    }
                    this.setBuyPolicyStep({
                        step: this.details.lastPage,
                        back: false,
                    });
                } else if (
                    this.detailFor === "quote" &&
                    this.details.quoteStatus ===
                        this.$policyValues.quoteStatus.INCOMPLETE_SUBMISSION
                            .value
                ) {
                    this.setBuyPolicyStep({
                        step: "SelectClient",
                        back: false,
                    });
                } else {
                    this.setBuyPolicyStep({
                        step: "CoverSummary",
                        back: false,
                    });
                }
            } else {
                this.setCloneFrom({
                    from: this.detailFor,
                    id: this.$route.params.id,
                });
                this.setBuyPolicyStep({
                    step: "CoverSummary",
                    back: false,
                });
            }
            this.$router.push({
                path: "/buy-policy",
                query: { type: "amend" },
            });
        },
        getSortedProductsBySequence(products) {
            // Return products early if any product is missing the sequenceNumber
            if (
                Object.values(products).some(
                    (product) =>
                        product.sequenceNumber !== 0 && !product.sequenceNumber
                )
            ) {
                return products;
            }

            const sortedProductEntries = Object.entries(products).sort(
                (a, b) => a[1].sequenceNumber - b[1].sequenceNumber
            );
            return Object.fromEntries(sortedProductEntries);
        },
        productHandler(products, addonFor) {
            const sortedProductsBySequence =
                this.getSortedProductsBySequence(products);
            Object.keys(sortedProductsBySequence).forEach((product) => {
                // For each product for the quote/policy
                let currentProductJSON = this.productsJSON.find((p) => {
                    return p.name === product;
                });
                // Add product to the products list in BuyPolicy state
                this.addProduct(product);
                // Add product questionBundles to the productsFlow in BuyPolicy state
                this.addToProductsFlow({
                    flow: currentProductJSON.questionBundleSequence,
                    productName: currentProductJSON.name,
                });
                // Fill data in respective questionBundle in BuyPolicy state according to the product
                this.mapKeyWithQuestionBundle(products[product]);
                // Set productDataMap according to the questionBundle
                this.formatProductDataMap(products[product], product, addonFor);
                this.setQuickExtensionsData(products[product], product);
                if (
                    products[product].addOns &&
                    !this.isObjectEmpty(products[product].addOns)
                ) {
                    // Recursive is addons exist
                    this.productHandler(products[product].addOns, product);
                }
            });
        },
        mapKeyWithQuestionBundle(productData) {
            Object.keys(productData).forEach((key) => {
                if (this.productKeyMap[key]) {
                    this.setQuestionBundle({
                        ...this.questionBundles,
                        // Check component/questionBundle for every field of questionBundle from the productKeyMap in constants
                        [this.productKeyMap[key].component]:
                            this.productKeyMap[key].component === "SelectPeril"
                                ? productData[key]
                                : {
                                      ...this.questionBundles[
                                          this.productKeyMap[key].component
                                      ],
                                      [key]: productData[key],
                                  },
                    });
                }
            });
        },
        setQuickExtensionsData(productData, product) {
            let productQuickExtensions = [];
            let productQuickExtensionsPremium = {};
            productData.quickExtensions?.forEach((ext) => {
                let extCopy = { ...ext };
                delete extCopy.premium;
                productQuickExtensions.push(extCopy);
                productQuickExtensionsPremium[ext.key] = {
                    ...ext.premium,
                    oldData: ext.premium,
                };
            });
            this.setQuickExtensions({
                ...this.quickExtensions,
                [product]: productQuickExtensions,
            });
            this.setQuickExtensionsPremium({
                ...this.quickExtensionsPremium,
                [product]: productQuickExtensionsPremium,
            });
        },
        formatProductDataMap(productData, product, addonFor) {
            let currentDataMap = this.productDataMap[product] || {};
            currentDataMap = {
                ...currentDataMap,
                ...productData,
                ...(addonFor && { addonFor: addonFor }),
            };
            delete currentDataMap.quickExtensions;
            delete currentDataMap.addOns;
            this.setProductDataMap({
                ...this.productDataMap,
                [product]: {
                    ...currentDataMap,
                    oldData: currentDataMap,
                },
            });
        },
        async cancelPolicy(status, reason) {
            // Handler for cancel policy
            this.cancellingPolicy = true;
            try {
                const res = await PolicyService.updatePolicy(this.details.id, {
                    ...this.details,
                    policyStatus: status,
                    statusChangeReason: reason,
                });
                this.cancellingPolicy = false;
                await this.setDetails(res.data, false, true);
                this.closeConfirmationPopup();
            } catch (err) {
                this.cancellingPolicy = false;
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to cancel policy"
                );
            }
        },
        async reopenQuote() {
            this.reopeningQuote = true;
            try {
                const res = await QuoteService.reopenQuote(
                    this.$route.params.id
                );
                this.$router
                    .push({
                        name: this.$router.currentRoute.name,
                        params: { id: res.data.id },
                    })
                    .catch(() => {});
                this.reopeningQuote = false;
                this.closeConfirmationPopup();
            } catch (err) {
                this.reopeningQuote = false;
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to reopen quote"
                );
            }
        },
    },
    beforeUnmount() {
        this.docWebhookHelper.socketCloseFunction();
        this.policyWebhookHelper.socketCloseFunction();
        this.commentWebhookHelper.socketCloseFunction();
    },
    watch: {
        showOtherOptions: function (val) {
            if (!val) {
                this.optionOpen = false;
            }
        },
    },
};
</script>

<style>
.topActions .v-popper--theme-dropdown {
    width: auto;
}
.topActions .v-popper--theme-dropdown .v-popper__wrapper {
    width: 200px !important;
    max-width: 100vw;
}
.caretImage {
    transition: 0.2s all ease-in-out;
}
.caretImage.open {
    transform: rotate(180deg);
}
.popover-list {
    padding: 10px !important;
    margin: 0;
}
.popover-list .drop-item.disabled {
    position: relative;
    cursor: not-allowed !important;
    color: var(--topActionDisabledText) !important;
}
.popover-list .drop-item.disabled:hover {
    color: var(--topActionDisabledText) !important;
}
.cancel-policy-popup {
    max-width: 100%;
    width: 500px;
}
</style>
