<template>
    <div class="metrices-container-wrapper position-relative">
        <bev-loading-block
            v-if="userMetrices.length === 0 || gettingMetrices || metricesError"
            :loading="gettingMetrices"
            :error="metricesError"
            :retryHandler="getUserMetrices.bind(this, 0, true)"
            :errorMessage="errorMessage"
        />
        <keep-alive>
            <MetricFilters v-if="showMetrices" />
        </keep-alive>
        <masonry-wall
            :items="userMetrices"
            :column-width="500"
            :gap="16"
            class="metrics-container"
        >
            <template #default="{ item }">
                <MetricsWrapper
                    v-if="showMetrices"
                    class="metrics-element"
                    :metrics="item"
                    :deleteMetric="deleteMetric"
                    :deletingMetricId="deletingMetricId"
                />
            </template>
        </masonry-wall>
        <div v-if="showMetrices">
            <Pagination
                v-if="totalPages > 1"
                class="mx-auto py-20px"
                :totalRecords="totalElements"
                :recordsPerPage="size"
                :pageNumber="pageNumber"
                @pageChanged="pageChangeHandler"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AnalyticsService from "@/services/AnalyticsService";
import MetricsWrapper from "@/components/Views/Analytics/Metrics/MetricsWrapper";
import Pagination from "@/components/Common/Pagination.vue";
import MetricFilters from "@/components/Views/Analytics/Metrics/MetricsFilters/Main";
export default {
    components: {
        MetricsWrapper,
        Pagination,
        MetricFilters,
    },
    props: {
        updateGettingUserMetricsCounter: Number,
    },
    data() {
        return {
            gettingMetrices: false,
            metricesError: false,
            pageNumber: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            deletingMetricId: null,
        };
    },
    computed: {
        ...mapState("auth", ["userId"]),
        ...mapState("analytics", ["userMetrices"]),
        errorMessage() {
            if (this.userMetrices.length === 0) {
                return "No metrices added";
            } else {
                return this.$messages.errorMessages.loadFailError(
                    "existing metrices"
                );
            }
        },
        showMetrices() {
            return (
                !this.gettingMetrices &&
                !this.metricesError &&
                this.userMetrices.length > 0
            );
        },
    },
    mounted() {
        this.getUserMetrices(0, true);
    },
    methods: {
        ...mapMutations("analytics", [
            "setUserMetrices",
            "incrementRefreshAllMetricCounter",
        ]),
        initializeMasonry() {},
        async getUserMetrices(page, initial) {
            if (initial) {
                this.totalElements = 0;
                this.totalPages = 0;
                this.setUserMetrices([]);
            }
            this.gettingMetrices = true;
            this.metricesError = false;
            this.pageNumber = page;
            try {
                const response = await AnalyticsService.getUserMetrices(
                    this.pageNumber,
                    this.size,
                    this.userId
                );
                this.totalElements = response.data.totalElements;
                this.totalPages = response.data.totalPages;
                this.setUserMetrices(response.data.content);
            } catch (err) {
                this.metricesError = true;
            } finally {
                this.gettingMetrices = false;
            }
        },
        pageChangeHandler(number) {
            this.getUserMetrices(number, false);
        },
        async deleteMetric(metricId) {
            this.deletingMetricId = metricId;
            try {
                await AnalyticsService.deleteUserMetric(this.userId, metricId);
                this.setUserMetrices(
                    this.userMetrices.filter((metric) => {
                        return metric.id !== metricId;
                    })
                );
                this.incrementRefreshAllMetricCounter();
                this.getUserMetrices(0, true);
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: this.$messages.successMessages.metricSuccess(
                        "deleted"
                    ),
                    type: "success",
                });
            } catch (err) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.metricError("delete")
                );
            } finally {
                this.deletingMetricId = null;
            }
        },
    },
    watch: {
        updateGettingUserMetricsCounter: function () {
            this.getUserMetrices(0, true);
        },
    },
};
</script>

<style scoped>
.metrices-container-wrapper {
    min-height: 200px;
}
.metrics-container {
    padding: 20px 0;
}
</style>
