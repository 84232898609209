<template>
    <div class="row mt-30px">
        <div
            class="col-sm-12"
            v-for="(step, index) in steps"
            :key="index"
            :class="{ active: $route.path === step.path || index === 0 }"
        >
            <div class="d-flex align-items-center justify-content-center">
                <bev-image
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.activeColorV1"
                    :imageUrl="$imagePath.checkMark"
                    :imageAlt="'tick-icon'"
                    width="20px"
                    height="20px"
                ></bev-image>
                <p class="ml-10px font-18 ss-font-14">{{ step.title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            steps: [
                {
                    title: "Personal Details",
                    active: true,
                    path: "/register/personal-detail",
                },
            ],
        };
    },
};
</script>

<style scoped>
.active {
    color: var(--activeColorV1);
}
span {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
}
</style>
