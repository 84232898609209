import { mapState } from "vuex";

export default {
    data() {
        return {
            // Event liability limit options by currency
            standardLimitsByCurrency: {
                GBP: [1000000, 2000000, 5000000, 10000000],
                USD: [1000000, 2000000, 5000000, 10000000],
                EUR: [1000000, 3000000, 6500000, 13000000],
            },
        };
    },
    computed: {
        ...mapState("buyPolicy", ["products", "currentStep"]),
        hasEventLiability() {
            return this.products.indexOf("event_liability") > -1;
        },
        currentEventLiabilityIndemnityLimit() {
            if (this.currentStep === "EventLiability") {
                return this.limitOfIndemnity;
            }
            return (
                this.questionBundles["EventLiability"].limitOfIndemnity || null
            );
        },
    },
    methods: {
        // Update event liability indemnity limit when the currency changes
        async updateIndemnityLimit(oldCurrency, newCurrency) {
            if (
                !this.hasEventLiability ||
                !this.currentEventLiabilityIndemnityLimit ||
                !oldCurrency ||
                oldCurrency === newCurrency
            ) {
                return;
            }

            const currentLimits =
                this.standardLimitsByCurrency[oldCurrency] || [];
            const newLimits = this.standardLimitsByCurrency[newCurrency] || [];
            const currentLimitIndex = currentLimits.indexOf(
                this.currentEventLiabilityIndemnityLimit
            );

            if (currentLimitIndex === -1) return;

            const newLimit = newLimits[currentLimitIndex] || "";

            if (this.currentStep === "EventLiability") {
                this.limitOfIndemnity = newLimit;
            }

            this.setQuestionBundle({
                ...this.questionBundles,
                EventLiability: {
                    ...this.questionBundles["EventLiability"],
                    limitOfIndemnity: newLimit,
                },
            });
        },
    },
    watch: {
        // Watch for currency changes and update the indemnity limit accordingly
        currentCurrencyCode: function (newCurrency, oldCurrency) {
            this.updateIndemnityLimit(oldCurrency, newCurrency);
        },
    },
};
