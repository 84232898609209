<template>
    <div no-gutters class="row pagination justify-content-center">
        <paginate
            v-model="currentPage"
            @update:modelValue="pageChange"
            :pages="Math.ceil(totalRecords / recordsPerPage)"
            :rangeSize="windowSize"
            hideFirstButton
            hideLastButton
        >
        </paginate>
    </div>
</template>

<script>
/**
 * BEV Pagination component
 * @param {Number} totalRecords to set totalRecords for the pagination
 * @param {Number} recordsPerPage to set page size for the pagination
 * @param {Number} pageNumber to page number for the pagination
 **/

import Paginate from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";
export default {
    data() {
        return {
            loading: false,
            currentPage: 0,
        };
    },
    props: {
        totalRecords: Number,
        recordsPerPage: Number,
        pageNumber: {
            type: Number,
            default: 0,
        },
        windowSize: { type: Number, default: 1 },
    },
    emits: ["pageChanged"],
    mounted() {
        this.currentPage = this.pageNumber + 1;
    },
    watch: {
        totalRecords: function () {
            this.currentPage = this.pageNumber + 1;
        },
        pageNumber: function (value) {
            this.currentPage = value + 1;
        },
    },
    components: {
        Paginate,
    },
    methods: {
        htmlRenderer(button) {
            if (button.title === "next") {
                return "Next";
            } else if (button.title === "previous") {
                return "Prev";
            } else {
                return button.html;
            }
        },
        pageChange(page) {
            this.currentPage = page;
            this.$emit("pageChanged", this.currentPage - 1);
        },
    },
};
</script>

<style lang="scss">
.Pagination {
    .Page {
        width: auto;
        height: auto;
        margin: 0 2.5px;
        line-height: 1.36;
        font-size: 14px;
        padding: 2px 8px;
        font-weight: 600;
        border-radius: 3px;
        border: solid 0.5px rgba(255, 255, 255, 0.2);
        color: var(--paginationTextColor);
        &:hover {
            background-color: var(--activeColorV1) !important;
            color: var(--paginationHoverTextColor);
            border: solid 0.5px transparent;
            border-radius: 4px;
        }
        &.Page-active {
            border-radius: 4px;
            border: none;
            background-color: var(--activeColorV1) !important;
            color: var(--paginationHoverTextColor);
            border: solid 0.5px transparent;
        }
    }
    .PaginationControl {
        margin: 0 6.5px;
        font-weight: 600;
        position: relative;
        svg {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
    .PaginationControl:first-child {
        &::after {
            content: "Prev";
            font-size: 14px;
            color: var(--paginationControllerTextColor);
        }
    }
    .PaginationControl:last-child {
        &::after {
            content: "Next";
            font-size: 14px;
            color: var(--paginationControllerTextColor);
        }
    }
}

.vue-ads-flex-grow button[title="previous"],
.vue-ads-flex-grow button[title="next"] {
    border: none;
    background-color: transparent;
    color: white;
}

.vue-ads-flex-grow button[title="previous"]:disabled,
.vue-ads-flex-grow button[title="next"]:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
</style>
