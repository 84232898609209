<template>
    <div
        class="link-container d-flex align-items-center mt-20px"
        @click="setLinkCopied"
    >
        <p ref="generatedUrl">{{ createdLink }}</p>
        <bev-image
            :addBackground="true"
            :backgroundColor="$colors.bevColors.baseDark"
            :imageUrl="$imagePath.copy"
            :imageAlt="'copy'"
            width="20px"
            height="20px"
            class="cursor-pointer ml-10px"
        ></bev-image>
    </div>
</template>

<script>
/**
 * Copy link block for the popup
 * @param {String} createdLink: created link
 */
export default {
    props: {
        createdLink: String,
    },
    methods: {
        setLinkCopied() {
            this.copyToClipboard(this.createdLink);
            this.$notify({
                group: "BEV",
                title: "Success",
                text: "Link copied successfully",
                type: "success",
            });
        },
    },
};
</script>

<style scoped lang="scss">
.link-container {
    background-color: white;
    border-radius: 10px;
    padding: 10px 15px;
    color: var(--baseDark);
    width: max-content;
    max-width: 100%;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    &:hover {
        background-color: var(--veryLightSecondary);
    }
}
</style>
>
