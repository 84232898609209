<template>
    <div class="center-positioned text-center">
        <bev-image
            v-if="messageType === 'error'"
            :addBackground="true"
            :backgroundColor="$colors.bevColors.danger"
            :imageUrl="$imagePath.alert"
            :imageAlt="'error-icon'"
            width="24px"
            height="24px"
            class="mx-auto flex-1"
        ></bev-image>
        <p
            class="flex-1 mt-10px font-18 sm-font-14"
            :style="[
                messageType === 'error'
                    ? { color: $colors.bevColors.danger }
                    : { color: $colors.bevColorWithOpacity.white(0.15) },
            ]"
        >
            {{ message }}
        </p>
        <bev-button
            v-if="messageType === 'error'"
            :themeType="'light'"
            :clickHandler="retryData"
            class="small mt-10px flex-1"
        >
            Retry
        </bev-button>
    </div>
</template>

<script>
/**
    Live Pricing Graph Message for Parametric Weather COver Details in Buy Policy Flow
    * @param {Function} retryData retry graph data fetch handler
    * @param {String} message message to be shown
    * @param {String} messageType type of message (error or success)
**/
export default {
    props: {
        retryData: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        message: {
            type: String,
            required: true,
        },
        messageType: {
            type: String,
            required: true,
        },
    },
};
</script>
