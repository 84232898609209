<template>
    <div class="bev-card" :class="cardType">
        <card-content
            :imageInfo="imageInfo"
            :showNotificationIcon="cardType === 'notification'"
        >
            <template v-slot:card-header-info>
                <slot name="card-header-info"></slot>
            </template>
            <template v-slot:card-content>
                <slot name="card-content"></slot>
            </template>
        </card-content>
        <div class="card-footer">
            <slot name="card-footer"></slot>
        </div>
    </div>
</template>

<script>
/**
 * BEV Card component for showing info
 * @param {String} footerText currently not in use
 * @param {String} cardType type of card like notification card, selectable card
 * @param {Object<{
                    imageColor: 'white',
                    imageUrl: '',
                    width: '20px',
                    height: '20px'
                }>} imageInfo for circle-image props
 */
import CardContent from "@/components/Common/Card/InfoCard/CardContent.vue";
export default {
    props: {
        imageInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        footerText: String,
        cardType: String,
    },
    components: {
        CardContent,
    },
};
</script>
