<template>
    <div class="status-change-details">
        <div class="d-flex align-items-center font-14" v-if="change.oldValue">
            Status changed from
            <span :class="`badge ${oldStatusData.statusColor} mx-10px`">{{
                oldStatusData?.text || ""
            }}</span>
            to
            <span :class="`badge ${newStatusData.statusColor} mx-10px`">{{
                newStatusData?.text || ""
            }}</span>
            {{ reason ? `Because of ${reason}` : "" }}
        </div>
        <div class="d-flex align-items-center font-14" v-else>
            Policy has been
            <span :class="`badge ${newStatusData.statusColor} mx-5px`">{{
                newStatusData?.text || ""
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        change: Object,
        reason: String,
    },
    computed: {
        oldStatusData() {
            return this.$policyValues.policyStatus[this.change.oldValue] || {};
        },
        newStatusData() {
            return this.$policyValues.policyStatus[this.change.newValue] || {};
        },
    },
};
</script>

<style scoped>
.status-change-details {
    padding: 20px;
}
</style>
