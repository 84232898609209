import config from "@/envConfig.js";
import APIService from "./APIService";

export default class GeocodingService extends APIService {
    static getLocationSuggestions(address) {
        return this.get(
            `${config.gridManagerServiceUrl}/location/auto-complete?input=${address}`
        );
    }

    static searchLocationByPlaceId(placeId) {
        return this.get(
            `${config.gridManagerServiceUrl}/location/place-details-by-place-id?placeId=${placeId}`
        );
    }

    static searchLocationByLatLng(lat, lng) {
        return this.get(
            `${config.gridManagerServiceUrl}/location/place-details-by-lat-long?lat=${lat}&lng=${lng}`
        );
    }

    static searchLatLngByLocation(address) {
        return this.get(
            `${config.gridManagerServiceUrl}/location/coordinate-details-by-address?address=${address}`
        );
    }
}
