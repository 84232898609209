<template>
    <div
        class="mt-40px cursor-pointer d-flex align-items-center"
        @click="valueChange"
    >
        <span class="text-activeColorV2">
            <span v-if="modelValue">Show less</span>
            <span v-else>Show more</span>
        </span>

        <bev-image
            :addBackground="true"
            :backgroundColor="$colors.bevColors.activeColorV2"
            :imageUrl="$imagePath.caretDown"
            :imageAlt="'caret-down-icon'"
            :width="'12px'"
            :height="'7px'"
            class="ml-10px dropdown-caret-icon"
            :class="{ open: modelValue }"
        ></bev-image>
    </div>
</template>
<script>
/**
 * BEV Text toggle component
 * @param {model} modelValue value if toggler is open or not
 **/

export default {
    props: {
        modelValue: null,
    },
    emits: ["update:modelValue"],
    methods: {
        valueChange() {
            this.$emit("update:modelValue", !this.modelValue);
        },
    },
};
</script>
