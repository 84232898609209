<template>
    <div
        class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Event Cancellation`"
            :description="'Be sure to safeguard yourself against the unexpected.'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="
                    setEventCancellation.bind(this, false, false, true)
                "
            />
        </buy-policy-heading-block>
        <bev-form
            class="cancellation-form"
            :submitData="setEventCancellation.bind(this, true, false, true)"
            :showSingleButton="currentStepIndex === 0"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setEventCancellation.bind(
                    this,
                    false,
                    true,
                    false
                ),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <div class="cancellation-form-content pt-20px mt-30px">
                    <div class="row">
                        <div class="col-sm-12">
                            <label class="font-16 sm-font-12 fw-bold"
                                >{{
                                    productLabelHandler("insureType", "label")
                                }}*</label
                            >
                            <div
                                class="d-flex align-items-center mt-20px flex-wrap"
                            >
                                <bev-selectable-tag
                                    v-for="(
                                        type, index
                                    ) in $policyValues.insureType"
                                    :key="index"
                                    :tagText="type.text"
                                    :clickHandler="
                                        setInsureType.bind(this, type.key)
                                    "
                                    :selected="insureType === type.key"
                                    class="mr-10px"
                                >
                                    <p>{{ type.text }}</p>
                                </bev-selectable-tag>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <bev-currency-input
                                :inputLabel="
                                    productLabelHandler(
                                        'totalExpensesOrRevenue',
                                        'label'
                                    ) + '*'
                                "
                                :haveError="
                                    v$.totalExpensesOrRevenue.$error &&
                                    v$.totalExpensesOrRevenue.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Field'
                                    )
                                "
                                :inputClass="'expenses_revenue'"
                                :infoImage="{
                                    show: true,
                                    info: totalExpensesOrRevenueTooltip,
                                }"
                                v-model="v$.totalExpensesOrRevenue.$model"
                                :currencySymbol="getCurrencyCode()"
                            ></bev-currency-input>
                        </div>
                        <option-selector
                            class="col-sm-12 mt-30px"
                            :question="
                                productLabelHandler('eventHeldBefore', 'label')
                            "
                            :selectHandler="setEventHeldBefore"
                            :error="
                                v$.eventHeldBefore.$error &&
                                v$.eventHeldBefore.required.$invalid
                            "
                            :errorMessage="
                                $messages.errorMessages.dropdownSelectError(
                                    'an option'
                                )
                            "
                            :selectedOption="eventHeldBefore"
                            :options="['Yes', 'No']"
                        ></option-selector>
                        <option-selector
                            class="col-sm-12 mt-30px"
                            :question="
                                productLabelHandler(
                                    'lossInLastFiveYears',
                                    'label'
                                )
                            "
                            :selectHandler="setLossInLastFiveYears"
                            :error="
                                v$.lossInLastFiveYears.$error &&
                                v$.lossInLastFiveYears.required.$invalid
                            "
                            :errorMessage="
                                $messages.errorMessages.dropdownSelectError(
                                    'an option'
                                )
                            "
                            :selectedOption="lossInLastFiveYears"
                            :infoImage="{
                                show: true,
                                info: 'At the to be insured event, have any incidents occurred that could have resulted in, or did result in, a loss which would have been covered by this insurance - during the past 5 years?',
                            }"
                            :options="['Yes', 'No']"
                        ></option-selector>
                    </div>
                </div>
            </template>
        </bev-form>
        <SaveAsDraftButton :dataSaveHandler="setEventCancellation" />
    </div>
</template>

<script>
/**
 * QuestionBundle component for EventCancellation
 */
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import customHtml from "@/constant/customHtml";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import OptionSelector from "@/components/Views/BuyPolicy/OptionSelector";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        CoverSummaryButton,
        OptionSelector,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
    },
    validations() {
        return {
            // Validations for every form field
            insureType: {
                required,
            },
            totalExpensesOrRevenue: {
                required,
            },
            eventHeldBefore: {
                required,
            },
            lossInLastFiveYears: {
                required,
            },
        };
    },
    created() {
        this.insureType =
            this.questionBundles["EventCancellation"]?.insureType || "expenses";
        this.totalExpensesOrRevenue =
            this.questionBundles["EventCancellation"]?.totalExpensesOrRevenue ||
            "";
        this.eventHeldBefore =
            this.questionBundles["EventCancellation"]?.eventHeldBefore || "";
        this.lossInLastFiveYears =
            this.questionBundles["EventCancellation"]?.lossInLastFiveYears ||
            "";
        this.totalExpensesOrRevenueTooltip =
            customHtml.totalExpenseOrRevenueTooltip;
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles", "currentStepIndex"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
    },
    data() {
        return {
            insureType: "expenses",
            totalExpensesOrRevenue: "",
            totalExpensesOrRevenueTooltip: null,
            eventHeldBefore: "",
            lossInLastFiveYears: "",
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setQuestionBundle", "setBuyPolicyStep"]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        setInsureType(type) {
            this.insureType = type;
        },
        setEventHeldBefore(choice) {
            this.eventHeldBefore = choice;
        },
        setLossInLastFiveYears(choice) {
            this.lossInLastFiveYears = choice;
        },
        setEventCancellation(forNext, forBack, notSaveForDraft) {
            // Handler to take every filled data and set into EventCancellation questionBundle
            this.updateDataBundleWithValidations({
                bundleName: "EventCancellation",
                bundleData: {
                    insureType: this.insureType,
                    totalExpensesOrRevenue: this.totalExpensesOrRevenue,
                    eventHeldBefore: this.eventHeldBefore,
                    lossInLastFiveYears: this.lossInLastFiveYears,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.cancellation-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cancellation-form-content {
    width: 700px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>
