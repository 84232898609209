<template>
    <div class="add-endorsement-container mt-20px">
        <div class="endorsement-form-input">
            <bev-dropdown
                :containerClass="'endorsement_drop'"
                :dropDownLabel="'Choose Endorsement'"
                :haveError="
                    v$.selectedEndorsement.$error &&
                    v$.selectedEndorsement.required.$invalid
                "
                :ajax="true"
                :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                apiRequestParam="entityType=endorsements&tenant=all"
                apiMethod="post"
                :responseInfo="{
                    resultKey: 'content',
                    totalResultKey: 'totalElements',
                    pagination: true,
                }"
                :optionTextHandler="
                    (option) => {
                        return option.value;
                    }
                "
                v-model="v$.selectedEndorsement.$model"
            ></bev-dropdown>
        </div>
        <p class="text-center my-20px">OR</p>
        <basic-textarea
            class="endorsement-form-input custom-endorsement"
            :inputLabel="'Endorsement'"
            :inputClass="'endorsement'"
            v-model="customEndorsement"
        ></basic-textarea>
        <bev-button
            :themeType="'dark'"
            :clickHandler="addEndorsementHandler"
            :buttonType="'button'"
            :loading="false"
            class="small mt-20px"
            :disabled="!selectedEndorsement && !customEndorsement"
        >
            Add
        </bev-button>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import config from "@/envConfig.js";
import BasicTextarea from "@/components/Common/Input/BasicTextarea";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        addEndorsement: Function,
    },
    data() {
        return {
            availableEndorsements: [],
            selectedEndorsement: null,
            customEndorsement: "",
        };
    },
    components: {
        BasicTextarea,
    },
    validations: {
        selectedEndorsement: {
            required,
        },
    },
    created() {
        this.config = config;
    },
    methods: {
        addEndorsementHandler() {
            if (this.selectedEndorsement?.value) {
                this.addEndorsement(this.selectedEndorsement.value);
            }
            if (this.customEndorsement) {
                this.addEndorsement(this.customEndorsement);
            }
            this.selectedEndorsement = null;
            this.customEndorsement = "";
        },
    },
};
</script>

<style scoped>
.add-endorsement-container {
    width: 700px !important;
    margin: auto;
    max-width: 100%;
    text-align: center;
}
</style>

<style>
.endorsement-form-input.input-wrapper.textarea .normal-input {
    height: 200px;
}
</style>
