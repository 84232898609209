<template>
    <div class="half-circle-spinner" :style="{ width: width, height: height }">
        <div
            class="circle circle-1"
            :style="{
                border: `calc(${width} / 10) solid transparent`,
                'border-top-color': spinnerColor,
            }"
        ></div>
        <div
            class="circle circle-2"
            :style="{
                border: `calc(${height} / 10) solid transparent`,
                'border-bottom-color': spinnerColor,
            }"
        ></div>
    </div>
</template>

<script>
/**
 * BEV Spinner component
 * @param {String} width width of the Spinner
 * @param {String} height height of the Spinner
 * @param {String} spinnerColor color of the Spinner
 **/

export default {
    props: {
        width: String,
        height: String,
        spinnerColor: {
            type: String,
            default: "var(--loaderColor)",
        },
    },
};
</script>

<style scoped>
.half-circle-spinner,
.half-circle-spinner * {
    box-sizing: border-box;
}

.half-circle-spinner {
    border-radius: 100%;
    position: relative;
}

.half-circle-spinner .circle {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.half-circle-spinner .circle.circle-1 {
    animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
    animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
