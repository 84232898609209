import config from "@/envConfig.js";
import APIService from "./APIService";

export default class CommentService extends APIService {
    static addComment(requestBody, cancelToken) {
        return this.post(
            `${config.commentServiceUrl}/comment`,
            requestBody,
            cancelToken
        );
    }

    static getComments(page, size, commentOn) {
        return this.get(
            `${config.commentServiceUrl}/comment?commentOn=${commentOn}&page=${page}&size=${size}&sort=creationTime,DESC`
        );
    }
}
