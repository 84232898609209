<template>
    <div
        v-for="(field, index) in extension.additionalFields"
        :class="[
            field.type === 'list'
                ? 'col-sm-12'
                : 'col-sm-12 col-md-12 col-lg-6',
        ]"
        :key="index"
    >
        <component
            :is="fieldRendererComponentName(field)"
            v-bind="fieldRendererComponentProps(field)"
            v-on="fieldRendererComponentEvents(field)"
        />
    </div>
</template>

<script>
import AdditionalFieldGroup from "./AdditionalFieldGroup.vue";
export default {
    name: "AdditionalFieldRenderer",
    props: {
        extension: {
            type: Object,
            required: true,
        },
        handleInputFunction: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        parentHandleAddFunction: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        parentHandleDeleteFunction: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        checkIfExistInReferred: Function,
    },
    components: {
        AdditionalFieldGroup,
    },
    methods: {
        fieldRendererComponentName(field) {
            switch (field.type) {
                case "currency":
                    return "bev-currency-input";
                case "list":
                    return "additional-field-group";
                default:
                    return "bev-input";
            }
        },
        fieldRendererComponentProps(field) {
            switch (field.type) {
                case "number":
                    return {
                        modelValue: field.value,
                        inputLabel: this.productLabelHandler(
                            field.key,
                            "label"
                        ),
                        ...(this.checkIfExistInReferred(field.key) && {
                            extraInfoText: "*referred",
                            extraInfoTextClass: "text-yellow fst-italic",
                            class: "referred",
                        }),
                        validationType: "whole_number",
                    };
                case "currency":
                    return {
                        modelValue: field.value,
                        inputLabel: this.productLabelHandler(
                            field.key,
                            "label"
                        ),
                        ...(this.checkIfExistInReferred(field.key) && {
                            extraInfoText: "*referred",
                            extraInfoTextClass: "text-yellow fst-italic",
                            class: "referred",
                        }),
                        validationType: "currency",
                    };
                case "list":
                    return {
                        extensionField: field,
                        fieldRendererComponentName:
                            this.fieldRendererComponentName,
                        fieldRendererComponentProps:
                            this.fieldRendererComponentProps,
                        parentHandleInputFunction: this.handleInputFunction,
                        parentHandleAddFunction: this.parentHandleAddFunction,
                        parentHandleDeleteFunction:
                            this.parentHandleDeleteFunction,
                    };
                default:
                    return {
                        modelValue: field.value,
                        inputLabel: this.productLabelHandler(
                            field.key,
                            "label"
                        ),
                        ...(this.checkIfExistInReferred(field.key) && {
                            extraInfoText: "*referred",
                            extraInfoTextClass: "text-yellow fst-italic",
                            class: "referred",
                        }),
                    };
            }
        },
        fieldRendererComponentEvents(field) {
            return {
                "update:modelValue": (value) =>
                    this.handleInputFunction(field, value),
            };
        },
    },
};
</script>
