// This file provides urls for the services to be used
export default {
    userServiceUrl: process.env.VUE_APP_USER_SERVICE_URL,
    policyServiceUrl: process.env.VUE_APP_POLICY_SERVICE_URL,
    policyServiceMtaReferralUrl: process.env.VUE_APP_POLICY_MTA_REFERRAL_URL,
    livePricingUrl: process.env.VUE_APP_LIVE_PRICING_SERVICE_URL,
    gridManagerServiceUrl: process.env.VUE_APP_GRID_SERVICE_URL,
    paymentServiceUrl: process.env.VUE_APP_PAYMENT_SERVICE_URL,
    productServiceUrl: process.env.VUE_APP_PRODUCT_SERVICE_URL,
    documentManagerServiceUrl: process.env.VUE_APP_DOCUMENT_MANAGER_SERVICE_URL,
    documentGeneratorServiceUrl:
        process.env.VUE_APP_DOCUMENT_GENERATOR_SERVICE_URL,
    commentServiceUrl: process.env.VUE_APP_COMMENT_SERVICE_URL,
    analyticsServiceUrl: process.env.VUE_APP_ANALYTICS_SERVICE_URL,
    geocodingUrl:
        "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer",
    weatherKey: process.env.VUE_APP_WEATHER_API_KEY,
    weatherUrl: "https://api.weatherapi.com/v1",
    complyAdvantageUrl: process.env.VUE_APP_COMPLY_ADVANTAGE_URL,
    referenceDataServiceUrl: process.env.VUE_APP_REFERENCE_DATA_URL,
    bordereauDownloadUrl: process.env.VUE_APP_BORDEREAU_DOWNLOAD_URL,
};
