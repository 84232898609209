<template>
    <div
        class="dashboard-banner d-flex flex-column justify-content-between"
        :style="backStyles"
    >
        <img
            v-for="(img, index) in dashboardBannerImages"
            :key="index"
            :src="img.url"
            :style="img.styles"
            class="banner-image"
        />
        <template v-if="!subDomainDetail?.dashboardBannerImages">
            <div class="bottom-left-circle-1"></div>
            <div class="shape-1"></div>
            <div class="bottom-left-circle-2"></div>
            <div class="shape-2"></div>
            <div class="text-right p-20px">
                <img
                    :src="subDomainDetail.image"
                    alt="bevlogo"
                    class="bev-logo mr-auto"
                />
            </div>
            <div class="text">
                <img
                    v-if="subDomainDetail.bannerProductLogo"
                    :src="subDomainDetail.bannerProductLogo.url"
                    :style="subDomainDetail.bannerProductLogo.styles"
                />
                <p class="fw-bold" v-else>
                    {{ subDomainDetail.bannerProductName || "RAPTOR™" }}
                </p>
                <p class="fw-200">AUTONOMOUS UNDERWRITING</p>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    computed: {
        dashboardBannerImages() {
            return this.subDomainDetail?.dashboardBannerImages || [];
        },
    },
    methods: {
        backStyles(image) {
            return {
                url: image.url,
                backgroundPosition: `${image.position}`,
                backgroundSize: `${image.size}`,
                backgroundRepeat: "no-repeat",
            };
        },
    },
};
</script>

<style scoped lang="scss">
.dashboard-banner {
    width: 100%;
    min-height: 300px;
    height: 100%;
    background: var(--cardBgColorV1);
    color: var(--cardColorV1);
    box-shadow: var(--cardBoxShadowV1);
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    .banner-image {
        position: absolute;
        z-index: 1;
    }
    .bottom-left-circle-1 {
        position: absolute;
        width: 400px;
        height: 400px;
        left: -50px;
        top: 40px;
        background: var(--baseShade1);
        border-radius: 1000px;
    }
    .bottom-left-circle-2 {
        position: absolute;
        width: 300px;
        height: 300px;
        left: -10px;
        top: 100px;
        background: var(--baseShade2);
        border-radius: 1000px;
    }

    .shape-1 {
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: 0;
        background: var(--baseShade1);
    }
    .shape-2 {
        position: absolute;
        width: 100%;
        height: 20px;
        bottom: 0;
        background: var(--baseShade2);
    }
    .text {
        font-size: 200%;
        position: relative;
        z-index: 1;
        padding: 20px;
        padding-bottom: 50px;
    }
    .bev-logo {
        width: 170px;
        position: relative;
        z-index: 1;
        max-width: 100%;
    }
}
</style>
