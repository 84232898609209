<template>
    <div
        class="bev-card buy-cover text-center h-100 d-flex flex-column justify-content-around buy-new-card"
    >
        <bev-image
            :addBackground="false"
            :imageAlt="'buy-cover'"
            :imageUrl="
                subDomainDetail?.getQuoteImage?.url ||
                '/img/illustrations/insurance.svg'
            "
            :width="subDomainDetail?.getQuoteImage?.width || '256px'"
            :height="'auto'"
            class="mx-auto getQuoteImage"
        ></bev-image>
        <div>
            <bev-button
                :themeType="'dark'"
                class="mt-10px"
                :clickHandler="cardButtonHandler"
            >
                {{ subDomainDetail.hideNormalBuy ? "Policies" : "Get Quote" }}
            </bev-button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        cardButtonHandler() {
            if (this.subDomainDetail.hideNormalBuy) {
                this.$router.push({ path: "/policies" });
            } else {
                this.$router.push({ path: "/buy-policy" });
            }
        },
    },
};
</script>

<style scoped>
.getQuoteImage {
    max-width: 256px;
}
</style>
