<template>
    <div>
        <!-- Temporarily excluding the UI endorsement add functionality (isEditable: false) -->
        <Endorsements
            :endorsements="endorsements"
            :isEditable="
                checkAction($policyValues.actions.ADD_ENDORSEMENT) && false
            "
            :details="details"
            :deleteEndorsement="deleteEndorsement"
            :addEndorsement="addEndorsement"
            :updateQuote="updateQuote.bind(this, this.details, true)"
            :updatingQuote="updatingQuote"
            class="mb-20px"
        />
        <div
            class="d-flex align-items-center justify-content-center detail-actions"
        >
            <bev-button
                :themeType="'light'"
                :clickHandler="
                    openDialog.bind(this, $policyValues.actions.QUOTE_REJECT)
                "
                :buttonType="'button'"
                :loading="rejecting"
                class="mx-5px"
                v-if="checkAction($policyValues.actions.QUOTE_REJECT)"
            >
                Reject Quote
            </bev-button>
            <bev-button
                v-if="showCompleteQuoteButton"
                :themeType="'light'"
                :clickHandler="handleCompleteQuoteButton"
                :buttonType="'button'"
                :loading="completQuoteButtonLoading"
                class="mx-5px"
            >
                Complete Quote
            </bev-button>
            <div
                class="bind-action"
                v-click-away="() => (bindOptionOpen = false)"
            >
                <v-popover
                    distance="5"
                    :container="'.bind-action'"
                    :placement="'top-start'"
                    :shown="bindOptionOpen"
                    @update:shown="(val) => (dropOpen = val)"
                    :boundary="'.bind-action'"
                    class="bind-action-popover mx-5px"
                    :triggers="[]"
                    :autoHide="false"
                    v-if="checkAction($policyValues.actions.BIND)"
                >
                    <div class="tooltip-target cursor-pointer">
                        <bev-button
                            :themeType="'dark'"
                            :buttonType="'button'"
                            :loading="approving"
                            v-if="details.premium"
                            @click="bindOptionOpen = !bindOptionOpen"
                        >
                            <div class="d-flex align-items-center">
                                <p>Bind Quote</p>
                                <bev-image
                                    :addBackground="true"
                                    :backgroundColor="
                                        $colors.bevColors.buttonPrimaryColor
                                    "
                                    :imageUrl="$imagePath.caretDown"
                                    :imageAlt="'caret-down'"
                                    :width="'12px'"
                                    :height="'7px'"
                                    class="cursor-pointer ml-5px caretImage"
                                    :class="{ open: bindOptionOpen }"
                                ></bev-image>
                            </div>
                        </bev-button>
                    </div>
                    <template #popper>
                        <div class="popover-content">
                            <ul class="popover-list">
                                <li
                                    class="drop-item px-0"
                                    @click.stop="
                                        () => openBindConfirmationPop()
                                    "
                                >
                                    <p>Bind without backdate</p>
                                </li>
                                <li
                                    class="drop-item px-0"
                                    @click.prevent="openBackdateDialog"
                                >
                                    <p>Bind with backdate</p>
                                </li>
                            </ul>
                        </div>
                    </template>
                </v-popover>
            </div>
            <bev-button
                v-if="checkAction($policyValues.actions.UNDERWRITER_APPROVE)"
                :themeType="'dark'"
                :clickHandler="
                    handleMtaActions.bind(
                        this,
                        $policyValues.actions.UNDERWRITER_APPROVE
                    )
                "
                :buttonType="'button'"
                :loading="approving"
                class="mx-5px"
            >
                Approve
            </bev-button>
            <bev-button
                v-if="checkAction($policyValues.actions.UNDERWRITER_DECLINE)"
                :themeType="'light'"
                :clickHandler="
                    handleMtaActions.bind(
                        this,
                        $policyValues.actions.UNDERWRITER_DECLINE
                    )
                "
                :buttonType="'button'"
                :loading="rejecting"
                class="mx-5px"
            >
                Decline
            </bev-button>
            <bev-button
                v-if="checkAction($policyValues.actions.BROKER_APPROVE)"
                :themeType="'dark'"
                :clickHandler="
                    handleMtaActions.bind(
                        this,
                        $policyValues.actions.BROKER_APPROVE
                    )
                "
                :buttonType="'button'"
                :loading="approving"
                class="mx-5px"
            >
                Approve
            </bev-button>
            <bev-button
                v-if="checkAction($policyValues.actions.BROKER_DECLINE)"
                :themeType="'light'"
                :clickHandler="
                    handleMtaActions.bind(
                        this,
                        $policyValues.actions.BROKER_DECLINE
                    )
                "
                :buttonType="'button'"
                :loading="rejecting"
                class="mx-5px"
            >
                Decline
            </bev-button>

            <bev-button
                v-if="checkAction($policyValues.actions.CANCELLATION_APPROVED)"
                :themeType="'dark'"
                :clickHandler="
                    openDialog.bind(
                        this,
                        $policyValues.actions.CANCELLATION_APPROVED
                    )
                "
                :buttonType="'button'"
                :loading="approving"
                class="mx-5px"
            >
                Approve Cancellation
            </bev-button>
            <bev-button
                v-if="checkAction($policyValues.actions.CANCELLATION_DECLINED)"
                :themeType="'light'"
                :clickHandler="
                    openDialog.bind(
                        this,
                        $policyValues.actions.CANCELLATION_DECLINED
                    )
                "
                :buttonType="'button'"
                :loading="rejecting"
                class="mx-5px"
            >
                Decline Cancellation
            </bev-button>

            <bev-button
                v-if="checkAction($policyValues.actions.SEND_DOCUMENT)"
                :themeType="'white'"
                :clickHandler="sendQuote"
                :buttonType="'button'"
                :loading="sendingQuote"
                class="mx-5px"
            >
                Send Document
            </bev-button>
            <p
                v-if="notUnderwriterApproved"
                class="font-14 fst-italic text-light-orange"
            >
                The {{ detailFor }} has been referred and is yet to be approved
                by Underwriter. Please check again later.
            </p>
        </div>

        <bev-dialog
            :closeDialog="closeDialog"
            :state="dialogProps.state"
            :width="'40%'"
            :actions="{
                show: false,
            }"
            :modalCustomClass="'overflowVisible'"
        >
            <template v-slot:dialog-content>
                <ActionWithReason
                    :closeHandler="closeDialog"
                    :actionLoading="actionConfig.loading"
                    :policyAction="actionConfig.policyAction"
                    :rejectStatus="actionConfig.rejectStatus"
                    :actionText="actionConfig.actionText"
                    :inputLabel="actionConfig.inputLabel"
                    :showDefaultReasons="actionConfig.showDefaultReasons"
                    :dropDownDetails="actionConfig.dropDownDetails"
                    :reasonRequired="actionConfig.reasonRequired"
                />
            </template>
        </bev-dialog>

        <bev-dialog
            :title="'Bind With Backdate'"
            :modalCustomClass="'overflowVisible'"
            :description="''"
            :closeDialog="closeBackdateDialog"
            :state="showBackdateDialog"
            :width="'40%'"
            :actions="{
                show: false,
            }"
        >
            <template v-slot:dialog-content>
                <BackdateBind
                    :cancelBind="closeBackdateDialog"
                    :bindQuote="openBindConfirmationPop"
                    :details="details"
                />
            </template>
        </bev-dialog>
        <bev-dialog
            :title="incompleteQuoteDialogConfig.title"
            :description="incompleteQuoteDialogConfig.description"
            :modalCustomClass="'overflowVisible'"
            :closeDialog="closeIncompleteBindPopup"
            :state="showIncompleteBindPopup"
            :width="'40%'"
            :actions="{
                show: false,
            }"
        >
            <template v-slot:dialog-content>
                <IncompleteBindPopup
                    :closeHandler="closeIncompleteBindPopup"
                    :details="incompleteQuoteDialogConfig.details"
                    :incompleteBindMandatoryFields="
                        incompleteQuoteDialogConfig.incompleteBindMandatoryFields
                    "
                    :finalStatus="incompleteQuoteDialogConfig.finalStatus"
                    :submitButtonText="
                        incompleteQuoteDialogConfig.submitButtonText
                    "
                    :submitHandler="updateQuote"
                />
            </template>
        </bev-dialog>
        <bev-dialog
            :closeDialog="closeBindDeclarationPopup"
            :state="showBindDeclarationPopup"
            :width="'70%'"
            :actions="{
                show: false,
            }"
        >
            <template v-slot:dialog-content>
                <BindDeclarations
                    :confirmHandler="confirmAndBindWithBackdate"
                    :details="details"
                    :actionLoading="approving"
                />
            </template>
        </bev-dialog>
    </div>
</template>

<script>
/**
 * Details Actions for Details Component for Quote or Policy
 *
 * @param {Object} details details for Quote or Policy
 * @param {Function} setDetails function to set or change Quote or Policy details
 * @param {Object} userDetails details of user for whom policy or quote is being generated
 * @param {String} detailsFor quote or policy for which the Details are being shown
 */

import { mapGetters, mapState } from "vuex";
import QuoteService from "@/services/QuoteService";
import DocumentService from "@/services/DocumentService";
import PolicyService from "@/services/PolicyService";
import axios from "axios";
import Endorsements from "@/components/Views/Details/Endorsements/Main.vue";
import ActionWithReason from "@/components/Views/Details/ActionWithReason";
import BackdateBind from "@/components/Views/Details/BackdateBind";
import IncompleteBindPopup from "@/components/Views/Details/IncompleteBindPopup";
import BindDeclarations from "@/components/Views/Details/BindDeclarations";
import { mixin as clickaway } from "vue3-click-away";
import config from "@/envConfig.js";
export default {
    props: {
        setDetails: Function,
        updateEndorsementCounter: Function,
        details: Object,
        userDetails: Object,
        detailFor: String,
        actions: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Endorsements,
        ActionWithReason,
        BackdateBind,
        IncompleteBindPopup,
        BindDeclarations,
    },
    data() {
        return {
            config: null,
            rejecting: false,
            approving: false,
            sendingQuote: false,
            canClaimOn: ["ONCOVER", "EXPIRED"],
            showRejectDialog: false,
            updatingQuote: false,
            endorsements: [],
            bindOptionOpen: false,
            showBackdateDialog: false,
            incompleteBindMandatoryFields: [],
            showIncompleteBindPopup: false,
            incompleteBindDetails: {},
            incompleteBindFinalStatus: "",
            completQuoteButtonLoading: false,
            bindBackDate: null,
            showBindDeclarationPopup: false,
            dialogProps: {
                state: false,
                type: "",
            },
            actionMappings: {
                UNDERWRITER_DECLINE: {
                    quote: "QuoteDecline",
                    policy: "PolicyDeclineMta",
                },
                BROKER_DECLINE: {
                    policy: "BrokerDeclineMta",
                },
            },
        };
    },
    mixins: [clickaway],
    computed: {
        ...mapState("auth", ["userId"]),
        ...mapGetters("auth", [
            "isNormalUser",
            "isUnderwriter",
            "isBroker",
            "userAttributes",
        ]),
        // Getter to check if quote is referred && user is not underwriter and quote status is not approved
        notUnderwriterApproved() {
            let statusKey =
                this.detailFor === "quote" ? "quoteStatus" : "policyStatus";
            return (
                this.details.referred &&
                !this.isUnderwriter &&
                this.details[statusKey] ===
                    this.$policyValues.quoteStatus.REFERRED.value
            );
        },
        showCompleteQuoteButton() {
            return (
                !this.isNormalUser &&
                this.details.premium &&
                this.detailFor === "quote" &&
                this.details.quoteStatus ===
                    this.$policyValues.quoteStatus.INCOMPLETE_SUBMISSION.value
            );
        },
        actionConfig() {
            const configs = {
                [this.$policyValues.actions.CANCELLATION_APPROVED]: {
                    loading: this.approving,
                    policyAction: this.changeQuoteStatus,
                    rejectStatus:
                        this.$policyValues.policyStatus.CANCELLATION_APPROVED
                            .value,
                    actionText: "approve",
                    inputLabel: "Approve",
                    showDefaultReasons: false,
                    dropDownDetails: null,
                },
                [this.$policyValues.actions.CANCELLATION_DECLINED]: {
                    loading: this.rejecting,
                    policyAction: this.changeQuoteStatus,
                    rejectStatus:
                        this.$policyValues.policyStatus.CANCELLATION_DECLINED
                            .value,
                    actionText: "decline",
                    inputLabel: "Decline",
                    showDefaultReasons: false,
                    dropDownDetails: null,
                },
                [this.$policyValues.actions.QUOTE_REJECT]: {
                    loading: this.rejecting,
                    policyAction: this.changeQuoteStatus,
                    rejectStatus: this.$policyValues.quoteStatus.REJECTED.value,
                    actionText: "reject",
                    inputLabel: "Reject",
                    showDefaultReasons: true,
                    dropDownDetails:
                        this.getDropDownDetails("quoteDeclineReason"),
                },
                QuoteDecline: {
                    loading: this.rejecting,
                    policyAction: this.changeQuoteStatus,
                    rejectStatus:
                        this.$policyValues.quoteStatus.UNDERWRITER_DECLINED
                            .value,
                    actionText: "decline",
                    inputLabel: "Decline",
                    showDefaultReasons: true,
                    dropDownDetails:
                        this.getDropDownDetails("quoteDeclineReason"),
                },
                PolicyDeclineMta: {
                    loading: this.rejecting,
                    policyAction: this.changeQuoteStatus,
                    rejectStatus:
                        this.$policyValues.policyStatus.UNDERWRITER_DECLINED
                            .value,
                    actionText: "decline",
                    inputLabel: "Decline",
                    showDefaultReasons: false,
                },
                BrokerDeclineMta: {
                    loading: this.rejecting,
                    policyAction: this.changeQuoteStatus,
                    rejectStatus:
                        this.$policyValues.policyStatus.BROKER_DECLINED.value,
                    actionText: "decline",
                    inputLabel: "Decline",
                    showDefaultReasons: false,
                },
            };
            return configs[this.dialogProps.type] || null;
        },
        incompleteQuoteDialogConfig() {
            const baseConfig = {
                details: this.incompleteBindDetails,
                incompleteBindMandatoryFields:
                    this.incompleteBindMandatoryFields,
                finalStatus: this.incompleteBindFinalStatus,
            };

            if (
                this.incompleteBindFinalStatus ===
                this.$policyValues.quoteStatus.APPROVED.value
            ) {
                return {
                    title: "Missing Information",
                    description:
                        "Please fill in the following required information to bind the quote",
                    submitButtonText: "Bind Quote",
                    ...baseConfig,
                };
            } else if (
                this.incompleteBindFinalStatus ===
                    this.$policyValues.quoteStatus.REFERRED.value ||
                this.incompleteBindFinalStatus ===
                    this.$policyValues.quoteStatus.GENERATED.value
            ) {
                return {
                    title: "Missing Information",
                    description:
                        "Please fill in the following required information to complete the quote",
                    submitButtonText: "Complete Quote",
                    ...baseConfig,
                };
            }
            return {};
        },
    },
    created() {
        this.config = config;
    },
    mounted() {
        this.endorsements = this.details.endorsements
            ? [...this.details.endorsements]
            : [];
    },
    methods: {
        getDropDownDetails(entityType) {
            return {
                ajax: true,
                apiUrl: `${config.referenceDataServiceUrl}/values/search`,
                apiRequestParam: `entityType=${entityType}&tenant=all`,
                apiMethod: "post",
                responseInfo: {
                    resultKey: "content",
                    totalResultKey: "totalElements",
                    pagination: true,
                },
                optionTextHandler: (option) => option.value,
            };
        },
        openDialog(type) {
            this.dialogProps = {
                state: true,
                type,
            };
        },
        closeDialog() {
            this.dialogProps = {
                state: false,
                type: "",
            };
        },
        handleMtaActions(action) {
            const actionResult =
                this.actionMappings[action]?.[this.detailFor] || null;

            if (actionResult) {
                this.openDialog(actionResult);
                return;
            }

            const statusMap = {
                broker: this.$policyValues.policyStatus.BROKER_APPROVED.value,
                underwriter: {
                    quote: this.$policyValues.quoteStatus.UNDERWRITER_APPROVED
                        .value,
                    policy: this.$policyValues.policyStatus.UNDERWRITER_APPROVED
                        .value,
                },
            };

            if (this.isBroker) {
                this.changeQuoteStatus(statusMap.broker);
            } else if (this.isUnderwriter) {
                this.underwriterApproveQuoteHandler(
                    statusMap.underwriter[this.detailFor]
                );
            }
        },
        checkAction(action) {
            return this.actions.findIndex((a) => a.action === action) > -1;
        },
        async handleCompleteQuoteButton() {
            this.incompleteBindMandatoryFields = [];
            let details = this.details;
            details["lastPage"] = "CoverSummary";
            let status = details.referred
                ? this.$policyValues.quoteStatus.REFERRED.value
                : this.$policyValues.quoteStatus.GENERATED.value;
            await this.changeQuoteStatus(
                status,
                "",
                details,
                this.incompleteQuoteErrorHandler
            );
        },
        async bindQuote(quoteDetails, errorCallback) {
            this.bindOptionOpen = false;
            this.incompleteBindMandatoryFields = [];
            let details = quoteDetails || this.details;
            details["lastPage"] = "CoverSummary";
            await this.changeQuoteStatus(
                this.$policyValues.quoteStatus.APPROVED.value,
                "",
                details,
                errorCallback,
                this.redirectToPolicy
            );
        },
        async changeQuoteStatus(
            status,
            reason = "",
            quoteDetails,
            errorCallback = () => {},
            successCallback = () => {}
        ) {
            let details = quoteDetails || this.details;
            this.setLoadingStates(status);
            let data = {
                ...details,
                ...(details.referred && {
                    premium: Number(details.recommendedPremium),
                }),
                ...(this.detailFor === "quote" && { quoteStatus: status }),
                ...(this.detailFor === "policy" && {
                    policyStatus: status,
                    ...(details.policyStatus ===
                        this.$policyValues.quoteStatus.REFERRED.value &&
                        status !==
                            this.$policyValues.quoteStatus.REFERRED.value && {
                            referred: false,
                        }),
                }),
                ...(this.isUnderwriter &&
                    this.endorsements.length && {
                        endorsements: this.endorsements,
                    }),
                statusChangeReason: reason,
                ...(this.isBroker && {
                    maximumCommission:
                        this.userAttributes?.maximumCommission || null,
                }),
            };

            try {
                const res = await this.updateQuoteOrPolicy(data);

                if (this.detailFor === "policy") {
                    this.updateEndorsementCounter();
                }

                const successMessage = this.getSuccessMessageText(
                    this.detailFor,
                    status
                );
                await this.setDetails(res.data, false, true);

                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: successMessage,
                    type: "success",
                });

                successCallback(res.data);
            } catch (err) {
                const errorMessage =
                    err?.response?.data.message ||
                    this.$messages.errorMessages.quoteMarkError(status);

                if (errorCallback && err?.response?.data.message) {
                    errorCallback(errorMessage, quoteDetails, status);
                } else {
                    this.displayErrorMessage(errorMessage);
                }
            } finally {
                this.resetLoadingStates();
                this.closeDialogs();
            }
        },
        setLoadingStates(status) {
            const quoteStatus = this.$policyValues.quoteStatus;
            const policyStatus = this.$policyValues.policyStatus;

            this.rejecting =
                status === quoteStatus.REJECTED.value ||
                status === quoteStatus.UNDERWRITER_DECLINED.value ||
                status === policyStatus.CANCELLATION_DECLINED.value ||
                status === policyStatus.BROKER_DECLINED.value;

            this.approving =
                status === quoteStatus.APPROVED.value ||
                status === quoteStatus.UNDERWRITER_APPROVED.value ||
                status === policyStatus.CANCELLATION_APPROVED.value ||
                status === policyStatus.BROKER_APPROVED.value;

            this.completQuoteButtonLoading =
                status === quoteStatus.REFERRED.value ||
                status === quoteStatus.GENERATED.value;
        },
        resetLoadingStates() {
            this.rejecting = false;
            this.approving = false;
            this.completQuoteButtonLoading = false;
        },
        closeDialogs() {
            this.dialogProps = {
                state: false,
                type: "",
            };
            this.showBindDeclarationPopup = false;
            this.bindBackDate = null;
            this.closeBackdateDialog();
        },
        getSuccessMessageText(detailFor, status) {
            if (detailFor === "quote") {
                return this.$messages.successMessages.quoteSuccess(
                    this.$policyValues.quoteStatus[status].text
                );
            }
            if (
                status ===
                this.$policyValues.policyStatus.UNDERWRITER_DECLINED.value
            ) {
                return this.$messages.successMessages.underwriterDeclineSuccess;
            }
            return this.$messages.successMessages.policySuccess(
                this.$policyValues.policyStatus[status].text
            );
        },
        async updateQuote(
            details,
            forEndorsement,
            messages = {},
            forApprove = false
        ) {
            this.updatingQuote = true;
            try {
                let data = {
                    ...details,
                    ...(this.isBroker && {
                        maximumCommission:
                            this.userAttributes?.maximumCommission || null,
                    }),
                    ...(forEndorsement && { endorsements: this.endorsements }),
                };
                const response = await this.updateQuoteOrPolicy(data);
                await this.setDetails(response.data, true, true);
                if (forEndorsement) {
                    this.updateEndorsementCounter();
                }
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: messages.successMessage || "Saved successfully",
                    type: "success",
                });
                if (forApprove) {
                    this.redirectToPolicy(response.data);
                }
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        messages.errorMessage ||
                        `Failed to save ${this.detailFor}`
                );
            } finally {
                this.updatingQuote = false;
            }
        },
        async updateQuoteOrPolicy(data) {
            return this.detailFor === "quote"
                ? await QuoteService.updateQuote(this.$route.params.id, data)
                : await PolicyService.updatePolicy(this.$route.params.id, data);
        },
        async sendQuote() {
            // Handler to send quote for every product to given userId in quote/policy info
            this.sendingQuote = true;
            let apiCalls = [];
            for (let i = 0; i < this.details.prodCount; i++) {
                apiCalls.push(
                    DocumentService.sendQuote(
                        (this.detailFor === "quote"
                            ? this.details.quoteNumber
                            : this.details.policyNumber) +
                            "_" +
                            (i + 1),
                        this.userDetails.email
                    )
                );
            }

            try {
                await axios.all(apiCalls);
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: this.$messages.successMessages.quoteSuccess("Sent"),
                    type: "success",
                });
                this.sendingQuote = false;
            } catch (err) {
                this.sendingQuote = false;
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        this.$messages.errorMessages.quoteError("send")
                );
            }
        },
        redirectToPayment() {
            if (this.detailFor === "quote") {
                this.$router.push({
                    path: `/quotes/${this.details.id}/payment`,
                });
            } else {
                this.$router.push({
                    path: `/policies/${this.details.id}/payment`,
                });
            }
        },
        deleteEndorsement(endorsement) {
            this.endorsements = this.endorsements.filter((e) => {
                return (
                    e.toLowerCase().replace(/\s/g, "") !==
                    endorsement.toLowerCase().replace(/\s/g, "")
                );
            });
        },
        addEndorsement(endorsement) {
            let isExising =
                this.endorsements.findIndex((e) => {
                    return (
                        e.toLowerCase().replace(/\s/g, "") ===
                        endorsement.toLowerCase().replace(/\s/g, "")
                    );
                }) > -1;
            if (!isExising) {
                this.endorsements.push(endorsement);
            }
        },
        openBackdateDialog() {
            this.bindOptionOpen = false;
            this.showBackdateDialog = true;
        },
        closeBackdateDialog() {
            this.showBackdateDialog = false;
        },
        closeIncompleteBindPopup() {
            this.showIncompleteBindPopup = false;
            this.incompleteBindMandatoryFields = [];
            this.incompleteBindDetails = {};
        },
        incompleteQuoteErrorHandler(errorMessage, details, status) {
            let mandatoryFields = errorMessage.split(
                "Missing mandatory fields in the Quote: "
            )[1];
            if (mandatoryFields) {
                this.incompleteBindMandatoryFields = mandatoryFields
                    .split(",")
                    .map((f) => f.trim());
                this.showBackdateDialog = false;
                this.incompleteBindDetails = details;
                this.incompleteBindFinalStatus = status;
                this.showIncompleteBindPopup = true;
            } else {
                this.displayErrorMessage(errorMessage);
            }
        },
        async confirmAndBindWithBackdate() {
            if (this.bindBackDate) {
                await this.bindWithBackdate();
            } else {
                await this.bindQuote(null, this.incompleteQuoteErrorHandler);
            }
        },
        closeBindDeclarationPopup() {
            this.showBindDeclarationPopup = false;
            this.bindBackDate = null;
            this.closeBackdateDialog();
        },
        openBindConfirmationPop(bindBackDate) {
            this.bindOptionOpen = false;
            this.bindBackDate = bindBackDate || null;
            this.showBindDeclarationPopup = true;
        },
        async bindWithBackdate() {
            let details = { ...this.details };
            details.bindBackDate = this.bindBackDate;
            await this.bindQuote(details, this.incompleteQuoteErrorHandler);
        },
        checkIfGrossPremiumEmpty(productsList) {
            let completed = true;
            Object.keys(productsList).forEach((product) => {
                if (product === "common_fields") return;
                if (!productsList[product].grossPremium) {
                    completed = false;
                }
                if (
                    productsList[product].quickExtensions &&
                    productsList[product].quickExtensions.length
                ) {
                    productsList[product].quickExtensions.forEach((ext) => {
                        if (!ext.premium.grossPremium) {
                            completed = false;
                        }
                    });
                }
                if (
                    productsList[product]?.premiumBySubProduct &&
                    !this.isObjectEmpty(
                        productsList[product].premiumBySubProduct
                    )
                ) {
                    Object.values(
                        productsList[product].premiumBySubProduct
                    )?.forEach((subproduct) => {
                        if (!subproduct.grossPremium) {
                            completed = false;
                        }
                    });
                }
            });
            return completed;
        },
        async underwriterApproveQuoteHandler(status, reason = "") {
            let completed = this.checkIfGrossPremiumEmpty(
                JSON.parse(this.details.products)
            );
            if (completed) {
                await this.changeQuoteStatus(status, reason, {
                    ...this.details,
                    referred: false,
                });
            } else {
                this.displayErrorMessage("Please enter premium values");
            }
        },
        redirectToPolicy(data) {
            this.$router.push(`/policies/${data.policyId}`);
        },
    },
};
</script>

<style>
.endorsement-container.disabled fieldset {
    opacity: 1 !important;
}
.endorsement-container.disabled textarea {
    cursor: not-allowed;
}
.endorsement-container .normal-input {
    height: 250px !important;
}
.bind-action {
    position: relative;
}
.bind-action .v-popper__inner {
    width: max-content !important;
}
.bind-action .v-popper--theme-dropdown {
    width: auto;
}
.caretImage {
    transition: 0.2s all ease-in-out;
}
.caretImage.open {
    transform: rotate(180deg);
}
.bind-action-popover {
    width: auto;
}
</style>
