<template>
    <div class="undertakings sm-font-12">
        <div>
            <p class="mt-10px">
                In deciding whether to accept the insurance and in setting the
                terms and premium, we have relied on the information you have
                given us.
            </p>
        </div>
        <div
            class="undertaking-section"
            v-for="(value, key) in undertakings"
            :key="key"
        >
            <div v-if="key !== 'common'">
                <p class="font-22 sm-font-16 fw-600 mb-20px">
                    {{ $policyValues.productLabels[key] }}
                </p>
                <p class="mb-10px">
                    You confirm, as agent of the Proposed Insured, that:
                </p>
            </div>
            <p v-else class="font-22 sm-font-16 fw-600 mb-10px">
                General Declaration
            </p>
            <ul class="m-0">
                <li
                    v-for="undertaking in value"
                    :key="undertaking.id"
                    class="mb-10px"
                >
                    <p class="font-14 undertaking-text">
                        {{ undertaking.text }}
                    </p>
                </li>
            </ul>
        </div>
        <slot></slot>
    </div>
</template>

<script>
/**
    Undertaking Check List Component
    * @param {Object<{
                    id: "",
                    text: "",
                    selected: false
                }>} undertakings list of undertaking which needs to be accepted by the quote buyer
**/
export default {
    props: {
        undertakings: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.undertaking-section {
    border-top: 0.5px solid white;
    padding: 24px 0 16px;
}
.undertaking-section ul {
    padding-left: 16px;
    list-style: square;
}
.undertaking-section .undertaking-text {
    line-height: 1.86;
}

.undertaking-section:nth-child(2) {
    border-top: none;
}
</style>
