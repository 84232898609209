<template>
    <div class="loading-block">
        <spinner
            v-if="loading"
            :width="'30px'"
            :height="'30px'"
            class="center-positioned"
        ></spinner>
        <div class="center-positioned text-center" v-if="!loading">
            <bev-image
                :addBackground="true"
                :backgroundColor="
                    error
                        ? $colors.bevColors.danger
                        : 'var(--loadingBlockColor)'
                "
                :imageUrl="errorImage || $imagePath.alert"
                width="30px"
                height="30px"
                :imageAlt="'error-icon'"
                class="mx-auto flex-1"
            ></bev-image>
            <p
                class="mt-10px"
                :class="[
                    !error
                        ? 'fw-bold font-26 sm-font-20'
                        : 'font-18 sm-font-14',
                ]"
                :style="{
                    color: error
                        ? $colors.bevColors.danger
                        : 'var(--loadingBlockColor)',
                }"
            >
                {{ errorMessage }}
            </p>
            <bev-button
                :themeType="'light'"
                :clickHandler="retryHandler"
                v-if="error && showRetry"
                class="small mt-10px flex-1"
            >
                Retry
            </bev-button>
        </div>
    </div>
</template>

<script>
/**
 * Component for a loading block
 * @param {Boolean} loading check if loading
 * @param {Boolean} error check if error
 * @param {Function} retryHandler handler to retry
 * @param {String} errorMessage error message to be shown when error is true
 * @param {String} errorImage error image path to show, if not provided alert image will be used
 * @param {Boolean} showRetry check if need to render retry button
 **/

export default {
    props: {
        loading: Boolean,
        error: Boolean,
        retryHandler: Function,
        errorMessage: String,
        errorImage: String,
        showRetry: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
