<template>
    <nav class="navbar d-flex align-items-center justify-content-between">
        <button type="button" class="navbar-toggle x collapsed">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
        <logo :width="'auto'" maxWidth="180px" class="h-100"></logo>
        <div>
            <options></options>
        </div>
    </nav>
</template>

<script>
/**
 * Main Navigation Component
 **/
import Options from "@/components/Layout/Navbar/Options";
export default {
    mounted() {
        this.$nextTick(() => {
            this.toggleSidebar();
        });
    },
    components: {
        Options,
    },
    watch: {
        // Close sidebar when route changed (Only on small devices)
        $route: function () {
            this.closeSidebar();
        },
    },
};
</script>
