<template>
    <div class="bev-card w-100 h-100 position-relative">
        <spinner
            class="center-positioned"
            v-if="mapLoading"
            :width="'25px'"
            :height="'25px'"
        ></spinner>
        <l-map
            v-if="!mapLoading"
            :zoom="zoom"
            :center="center"
            :maxZoom="20"
            @update:zoom="zoomUpdate"
            @ready="readyFunction"
            ref="map"
            class="z-index-1"
        >
            <l-tile-layer
                :url="url"
                :options="{
                    maxNativeZoom: 19,
                    maxZoom: 20,
                }"
                :attribution="attribution"
            />
        </l-map>
    </div>
</template>

<script>
import GeocodingService from "@/services/GeocodingService";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
import "leaflet-openweathermap";
import "leaflet-openweathermap/leaflet-openweathermap.css";
import { mapGetters } from "vuex";

export default {
    components: {
        LMap,
        LTileLayer,
    },
    mounted() {
        this.Leaflet = window.L;
        this.getCurrentLocation();
        this.weatherAPIKey = process.env.VUE_APP_WEATHER_MAP_KEY;
    },
    data() {
        return {
            weatherAPIKey: null,
            zoom: 6,
            center: null,
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            currentZoom: 4,
            attribution:
                '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            currentCenter: latLng(51.509865, -0.118092),
            Leaflet: null,
            map: null,
            mapLoading: true,
        };
    },
    computed: {
        ...mapGetters("auth", ["userAttributes"]),
    },
    methods: {
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        readyFunction(map) {
            this.map = map;
            map.zoomControl.setPosition("bottomright");
            const overlayLayers = {
                Temperature: this.Leaflet.OWM.temperature({
                    showLegend: false,
                    popup: true,
                    opacity: 0.5,
                    appId: this.weatherAPIKey,
                }),
                Rain: this.Leaflet.OWM.rainClassic({
                    showLegend: false,
                    popup: true,
                    opacity: 0.5,
                    appId: this.weatherAPIKey,
                }),
            };
            this.Leaflet.control.layers({}, overlayLayers).addTo(map);
            let controlLayerSelector = document.getElementsByClassName(
                "leaflet-control-layers-selector"
            );
            if (controlLayerSelector && controlLayerSelector[1]) {
                controlLayerSelector[1].click();
            }
        },
        async getCurrentLocation() {
            this.mapLoading = true;
            try {
                let response = await GeocodingService.searchLatLngByLocation(
                    this.userAttributes.city +
                        ", " +
                        this.userAttributes.country
                );
                if (response.data.lat && response.data.lng) {
                    this.center = latLng(response.data.lat, response.data.lng);
                } else {
                    this.center = latLng(51.509865, -0.118092);
                }
                this.mapLoading = false;
            } catch (err) {
                this.center = latLng(51.509865, -0.118092);
                this.mapLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.vue2leaflet-map {
    border-radius: 10px;
}
</style>
