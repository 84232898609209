import config from "@/envConfig.js";
import APIService from "./APIService";

export default class PaymentService extends APIService {
    static getPaymentCards(requestBody) {
        return this.post(
            `${config.paymentServiceUrl}/payments/methods`,
            requestBody
        );
    }

    static addCustomer(requestBody) {
        return this.post(
            `${config.paymentServiceUrl}/payments/customer`,
            requestBody
        );
    }

    static addPaymentIntent(requestBody) {
        return this.post(
            `${config.paymentServiceUrl}/payments/intent`,
            requestBody
        );
    }

    static removePaymentIntent(intentId, requestBody) {
        return this.post(
            `${config.paymentServiceUrl}/payments/intent/${intentId}/cancel`,
            requestBody
        );
    }
}
