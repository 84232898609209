<template>
    <div class="d-flex align-items-center">
        <div
            class="flex-1 mr-5px"
            v-for="(keyValue, index) in keyValueData"
            :key="index"
            v-show="
                keyValue[0] !== `Aggregate Limit (GBP)` &&
                keyValue[1].value !== undefined
            "
        >
            <p class="font-16 mb-10px">{{ keyValue[0] }}</p>
            <p class="fw-bold font-20 mb-10px text-activeColorV1">
                {{ valueRenderer(keyValue) }}
            </p>
        </div>
    </div>
</template>

<script>
import analytics from "@/constant/analytics";
export default {
    props: {
        metricData: Object,
    },
    computed: {
        keyValueData() {
            return this.metricData ? Object.entries(this.metricData) : [];
        },
    },
    methods: {
        valueRenderer(keyValue) {
            if (analytics.metricKeyValueType[keyValue[0]] === "currency") {
                return this.thousandMask(keyValue[1].value, "currency");
            } else if (analytics.metricKeyValueType[keyValue[0] === "number"]) {
                return this.thousandMask(keyValue[1].value);
            } else {
                return keyValue[1].value;
            }
        },
    },
};
</script>
