<template>
    <div class="bev-container user-management">
        <div class="pt-50px w-100 h-100 overflow-auto">
            <div
                class="d-flex align-items-start justify-content-between px-20px flex-wrap"
            >
                <div>
                    <heading-block
                        :align="'left'"
                        :heading="'User Management'"
                        :description="'Manage users and monitor their activity'"
                        :headingSizeClass="'font-22 sm-font-16'"
                        :descriptionSizeClass="'font-16 sm-font-12'"
                    />
                </div>
                <div class="text-right flex-1">
                    <bev-button
                        :themeType="'dark'"
                        :clickHandler="downloadUserSessionInfo"
                        :class="'large mb-20px'"
                        :loading="isLoading"
                    >
                        Download User Report
                    </bev-button>
                </div>
            </div>
            <div class="broker-list mt-20px">
                <p>COMING SOON</p>
            </div>
        </div>
    </div>
</template>

<script>
import fileDownload from "js-file-download";
import DocumentService from "@/services/DocumentService";
export default {
    name: "BrokerManagement",
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        async downloadUserSessionInfo() {
            this.isLoading = true;
            try {
                const response = await DocumentService.getUserInfoCsv();
                if (response.data) {
                    fileDownload(response.data, "users.csv");
                    this.$notify({
                        group: "BEV",
                        title: "Success",
                        text: "Users report downloaded",
                        type: "success",
                    });
                }
            } catch (err) {
                this.$notify({
                    group: "BEV",
                    title: "Error",
                    text: "Failed to download users report",
                    type: "error",
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.broker-list {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-size: 24px;
        opacity: 0.6;
        font-weight: bold;
    }
}
</style>
