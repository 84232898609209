<template>
    <div class="extension">
        <div
            class="d-flex align-items-center justify-content-between extension-heading"
        >
            <div class="d-flex align-items-center">
                <bev-checkbox
                    @update:modelValue="selectExtension"
                    :modelValue="extension.selected"
                    class="extension-checkbox"
                    extraClass="large fw-bold"
                    :disabled="quickExtensionsPremiumLoading.length > 0"
                ></bev-checkbox>
                <p class="font-16 ml-5px">
                    {{ productLabelHandler(extension.key, "label") }}
                </p>
                <bev-image
                    v-if="extensionTooltip"
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.inputInfoIcon"
                    :imageAlt="'info'"
                    :imageUrl="$imagePath.info"
                    width="16px"
                    height="16px"
                    v-tooltip="{
                        content: extensionTooltip,
                        html: true,
                        showTriggers: (events) => [...events, 'click'],
                        autoHide: true,
                        delay: 0,
                    }"
                    class="cursor-pointer mx-5px"
                ></bev-image>
            </div>
            <p>
                <span
                    v-if="
                        !isPremiumLoading &&
                        extension.selected &&
                        fillFieldValues
                    "
                    class="text-activeColorV1"
                    >{{
                        isReferred
                            ? "*Referred"
                            : thousandMask(
                                  quickExtensionsPremium[productHeading]?.[
                                      extension.key
                                  ]?.grossPremium || 0,
                                  "currency"
                              )
                    }}</span
                >
                <spinner
                    v-if="isPremiumLoading && extension.selected"
                    :width="'20px'"
                    :height="'20px'"
                ></spinner>
            </p>
        </div>
        <div
            class="extension-info font-14"
            v-if="haveFieldsToRender && extension.selected"
        >
            <p class="text-pale-salmon font-12 mb-10px" v-if="!fillFieldValues">
                Please fill the required details. Otherwise extension will not
                be considered.
            </p>
            <AdditionalFieldRenderer
                :extension="extension"
                :handleInputFunction="handleInputFunction"
                :parentHandleAddFunction="parentHandleAddFunction"
                :parentHandleDeleteFunction="parentHandleDeleteFunction"
                :checkIfExistInReferred="checkIfExistInReferred"
            />
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import debounce from "lodash.debounce";
import customHtml from "@/constant/customHtml";
import AdditionalFieldRenderer from "./AdditionalFieldRenderer.vue";
export default {
    name: "Extension",
    props: {
        productHeading: String,
        extension: {
            type: Object,
            required: true,
        },
        extensionIndex: {
            type: Number,
            required: true,
        },
        recalculatingPremiumLoading: {
            type: Boolean,
            default: false,
        },
        isReferred: {
            type: Boolean,
            default: false,
        },
        checkIfExistInReferred: Function,
    },
    components: {
        AdditionalFieldRenderer,
    },
    data() {
        return {
            extensionsInfo: {},
            extensionAdditionalLength: 0,
        };
    },
    computed: {
        ...mapState("buyPolicy", [
            "quickExtensions",
            "quickExtensionsPremiumLoading",
            "quickExtensionsPremium",
        ]),
        fillFieldValues() {
            let filled = true;
            if (this.extension.additionalFields.length) {
                this.extension.additionalFields.forEach((field) => {
                    if (field.type === "list") {
                        field.value.forEach((v) => {
                            v.forEach((f) => {
                                if (!f.mapWithQuoteData && !f.value) {
                                    filled = false;
                                }
                            });
                        });
                    } else {
                        if (!field.mapWithQuoteData && !field.value) {
                            filled = false;
                        }
                    }
                });
            }
            return filled;
        },
        isPremiumLoading() {
            return (
                this.quickExtensionsPremiumLoading.indexOf(
                    this.productHeading
                ) > -1 || this.recalculatingPremiumLoading
            );
        },
        haveFieldsToRender() {
            let haveRenderableField = false;
            if (this.extension.additionalFields?.length) {
                this.extension.additionalFields.forEach((field) => {
                    if (field.type === "list") {
                        field.value.forEach((v) => {
                            v.forEach((f) => {
                                if (!f.mapWithQuoteData) {
                                    haveRenderableField = true;
                                }
                            });
                        });
                    } else {
                        if (!field.mapWithQuoteData) {
                            haveRenderableField = true;
                        }
                    }
                });
            }
            return haveRenderableField;
        },
        extensionTooltip() {
            return this.extensionsInfo[this.extension.key] || "";
        },
    },
    created() {
        this.extensionsInfo = customHtml.extensionsInfo;
        this.extensionAdditionalLength = this.extension.additionalFields.length;
    },
    emits: [
        "setExtensionSelected",
        "handleFieldInputFunction",
        "parentHandleAddFunction",
        "parentHandleDeleteFunction",
    ],
    methods: {
        ...mapMutations("buyPolicy", [
            "setQuickExtensions",
            "setQuickExtensionsPremium",
        ]),
        selectExtension(value) {
            this.$emit("setExtensionSelected", this.extensionIndex, value);
            this.$nextTick(() => {
                if (value) {
                    this.setProductExtension();
                } else {
                    this.removeProductExtension();
                }
            });
        },
        setProductExtension() {
            let productCurrentQuickExtensions = [
                ...(this.quickExtensions[this.productHeading]?.length
                    ? this.quickExtensions[this.productHeading]
                    : []),
            ];
            let existingIndex = productCurrentQuickExtensions.findIndex((e) => {
                return e.key === this.extension.key;
            });
            if (existingIndex > -1) {
                productCurrentQuickExtensions[existingIndex] = this.extension;
            } else {
                productCurrentQuickExtensions.push(this.extension);
            }
            this.setQuickExtensions({
                ...this.quickExtensions,
                [this.productHeading]: productCurrentQuickExtensions,
            });
        },
        removeProductExtensionPremium() {
            if (!this.quickExtensionsPremium[this.productHeading]) return;
            let productCurrentQuickExtensionsPremium = JSON.parse(
                JSON.stringify(this.quickExtensionsPremium[this.productHeading])
            );
            delete productCurrentQuickExtensionsPremium[this.extension.key];
            this.setQuickExtensionsPremium({
                ...this.quickExtensionsPremium,
                [this.productHeading]: productCurrentQuickExtensionsPremium,
            });
        },
        removeProductExtension() {
            let productCurrentQuickExtensions = [
                ...(this.quickExtensions[this.productHeading]?.length
                    ? this.quickExtensions[this.productHeading]
                    : []),
            ];
            productCurrentQuickExtensions =
                productCurrentQuickExtensions.filter((e) => {
                    return e.key !== this.extension.key;
                });
            this.setQuickExtensions({
                ...this.quickExtensions,
                [this.productHeading]: productCurrentQuickExtensions,
            });
            this.removeProductExtensionPremium();
        },
        handleInputFunction(field, value, parentFieldConfig) {
            this.$emit(
                "handleFieldInputFunction",
                this.extensionIndex,
                field,
                value,
                parentFieldConfig
            );
            this.$nextTick(() => {
                this.setExtensionOnValueChange(value);
            });
        },
        parentHandleDeleteFunction(config) {
            this.$emit(
                "parentHandleDeleteFunction",
                this.extensionIndex,
                config
            );
            this.$nextTick(() => {
                this.setProductExtension();
            });
        },
        parentHandleAddFunction(config) {
            this.$emit("parentHandleAddFunction", this.extensionIndex, config);
            this.$nextTick(() => {
                this.setProductExtension();
            });
        },
        setExtensionOnValueChange: debounce(function () {
            this.setProductExtension();
        }, 500),
    },
};
</script>

<style scoped lang="scss">
.extension {
    border-bottom: 1px solid var(--baseShade2);
    .extension-heading {
        padding: 10px 20px;
        .extension-checkbox {
            width: 30px;
            height: 30px;
            ::deep {
                .checkmark {
                    top: 4px;
                }
            }
        }
    }
    .extension-info {
        padding: 10px 20px 20px;
    }
    .input-wrapper {
        &.referred {
            ::v-deep(.normal-input) {
                legend {
                    color: var(--yellow);
                }
            }
        }
    }
}
</style>
