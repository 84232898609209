<template>
    <div
        class="metrics-filter-container small-filters mt-20px d-flex align-items-end flex-wrap"
    >
        <div v-for="(field, index) in fieldsMapper" :key="index">
            <component
                :is="field.component"
                v-bind="{ ...field.props }"
                v-on="field.on"
                class="small-filter"
            />
        </div>
        <bev-button
            :themeType="'light'"
            :clickHandler="resetFilters"
            v-if="filtersApplied"
            class="small"
        >
            Reset
        </bev-button>
    </div>
</template>

<script>
import DateInput from "@/components/Common/Input/DateInput";
import Dropdown from "@/components/Common/Dropdown";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import BevInput from "@/components/Common/Input/BasicInput";
import { startOfYear, endOfDay } from "date-fns";
import { mapMutations, mapState, mapGetters } from "vuex";
import config from "@/envConfig.js";
let currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);
export default {
    components: {
        DateInput,
        Dropdown,
        MultiSelectDropdown,
        BevInput,
    },
    computed: {
        ...mapState("analytics", ["analyseFilters"]),
        ...mapState("auth", ["userUnderwriterId"]),
        ...mapState(["subDomainDetail"]),
        ...mapGetters(["getTenantType"]),
        fieldsMapper() {
            return this.fields.map((field) => {
                switch (field) {
                    case "startTime":
                        return this.dateFilterRenderer(
                            "Start Date",
                            this.defaultStartTime,
                            this.startTime,
                            (e) => {
                                this.startTime = e;
                                if (this.endTime && e && e > this.endTime) {
                                    this.endTime = e;
                                }
                            }
                        );
                    case "endTime":
                        return this.dateFilterRenderer(
                            "End Date",
                            this.defaultEndTime,
                            this.endTime,
                            (e) => (this.endTime = e),
                            (date) => {
                                if (this.startTime) {
                                    const sDate = new Date(
                                        this.startTime
                                    ).setHours(0, 0, 0, 0);
                                    return date < sDate;
                                }
                                return false;
                            }
                        );
                    case "productName":
                        return this.dropdownFilterRenderer({
                            field: field,
                            apiUrl: `${config.productServiceUrl}/product/search`,
                            apiRequestBody: () => {
                                return {
                                    underwriterId:
                                        this.userUnderwriterId ||
                                        this.subDomainDetail.underwriterId,
                                    productType: "UNDERWRITER_PRODUCT",
                                };
                            },
                            apiMethod: "post",
                            responseInfo: {
                                resultKey: "content",
                                totalResultKey: "total",
                                pagination: true,
                                uniqueKey: "id",
                                applyFilter: (p) =>
                                    p.isEnabled &&
                                    p.isParent &&
                                    p.productType !== "DIRECT",
                            },
                            optionTextHandler: (option) => {
                                return option.label;
                            },
                            label: "Select Product",
                            selectedItem: this.selectedProduct,
                            changeHandler: (e) => {
                                this.selectedProduct = e;
                                this.productNames = e;
                            },
                            changeAllSelected: (e) => {
                                this.allProductSelected = e;
                            },
                        });
                    case "brokerIds":
                        return this.dropdownFilterRenderer({
                            field: field,
                            apiUrl: `${config.userServiceUrl}/group/org_type/BROKER`,
                            apiMethod: "get",
                            responseInfo: {
                                resultKey: "items",
                                totalResultKey: "total",
                                pagination: true,
                                uniqueKey: "id",
                            },
                            optionTextHandler: (option) => {
                                return this.brokerNameHandler(option);
                            },
                            label: "Select Broker",
                            selectedItem: this.selectedBroker,
                            changeHandler: (e) => {
                                this.selectedBroker = e;
                                this.brokerIds = e;
                            },
                            changeAllSelected: (e) => {
                                this.allBrokerSelected = e;
                            },
                        });
                    case "countries":
                        return this.dropdownFilterRenderer({
                            field: field,
                            apiUrl: `${config.referenceDataServiceUrl}/values/search`,
                            apiRequestParam: `entityType=whiteLabelCountries&tenant=${this.getTenantType}`,
                            apiMethod: "post",
                            responseInfo: {
                                resultKey: "content",
                                totalResultKey: "totalElements",
                                pagination: true,
                                uniqueKey: "id",
                            },
                            optionTextHandler: (option) => {
                                return (
                                    option?.metaData?.Country || option.value
                                );
                            },
                            ajax: true,
                            label: "Country",
                            selectedItem: this.selectedCountry,
                            searchKey: "searchVal",
                            changeHandler: (e) => {
                                this.selectedCountry = e;
                                this.countries = e;
                            },
                            changeAllSelected: (e) => {
                                this.allCountriesSelected = e;
                            },
                        });
                    default:
                        return this.basicInputFilterRenderer({
                            inputType: "text",
                            inputLabel: field,
                            inputClass: field,
                            inputValue: this[field],
                            changeHandler: (e) => (this[field] = e),
                        });
                }
            });
        },
        analyseRequestFilters() {
            return this.fields.reduce((acc, curr) => {
                return {
                    [curr]: this[curr],
                    ...acc,
                };
            }, {});
        },
        allFiltersApplied() {
            return (
                Object.keys(this.analyseFilters).findIndex((filter) => {
                    return !this.analyseFilters[filter];
                }) < 0
            );
        },
        startTime: {
            get() {
                return this.analyseFilters.startTime;
            },
            set(value) {
                this.setAnalyseFilters({
                    ...this.analyseFilters,
                    startTime: value,
                });
            },
        },
        endTime: {
            get() {
                return this.analyseFilters.endTime;
            },
            set(value) {
                this.setAnalyseFilters({
                    ...this.analyseFilters,
                    endTime: endOfDay(value).getTime(),
                });
            },
        },
        brokerIds: {
            get() {
                return this.analyseFilters.brokerIds;
            },
            set(value) {
                if (this.allBrokerSelected) {
                    this.setAnalyseFilters({
                        ...this.analyseFilters,
                        brokerIds: "/*",
                    });
                } else {
                    let brokerIdsString = value
                        .map((v) => `\"${v.attributes.brokerId[0]}\"`)
                        .join(",");
                    if (value.length) {
                        this.setAnalyseFilters({
                            ...this.analyseFilters,
                            brokerIds: brokerIdsString,
                        });
                    } else {
                        this.setAnalyseFilters({
                            ...this.analyseFilters,
                            brokerIds: "",
                        });
                    }
                }
            },
        },
        productNames: {
            get() {
                return this.analyseFilters.productNames;
            },
            set(value) {
                if (this.allProductSelected) {
                    this.setAnalyseFilters({
                        ...this.analyseFilters,
                        productNames: "/*",
                    });
                } else {
                    let productsString = value
                        .map((v) => `\"${v.name}\"`)
                        .join(",");
                    if (value.length) {
                        this.setAnalyseFilters({
                            ...this.analyseFilters,
                            productNames: productsString,
                        });
                    } else {
                        this.setAnalyseFilters({
                            ...this.analyseFilters,
                            productNames: "",
                        });
                    }
                }
            },
        },
        countries: {
            get() {
                return this.analyseFilters.countries;
            },
            set(value) {
                if (this.allCountriesSelected) {
                    this.setAnalyseFilters({
                        ...this.analyseFilters,
                        countries: "/*",
                    });
                } else {
                    let countriesString = value
                        .map((v) => `\"${v?.metaData?.Country || v.value}\"`)
                        .join(",");
                    if (value.length) {
                        this.setAnalyseFilters({
                            ...this.analyseFilters,
                            countries: countriesString,
                        });
                    } else {
                        this.setAnalyseFilters({
                            ...this.analyseFilters,
                            countries: "",
                        });
                    }
                }
            },
        },
        filtersApplied() {
            let initialFilters = {
                startTime: startOfYear(currentDate).getTime(),
                endTime: endOfDay(currentDate).getTime(),
                brokerIds: "",
                productNames: "",
                countries: "",
            };
            return (
                JSON.stringify(this.analyseFilters) !==
                JSON.stringify(initialFilters)
            );
        },
    },
    created() {
        this.config = config;
    },
    data() {
        return {
            fields: [
                "brokerIds",
                "productName",
                "countries",
                "startTime",
                "endTime",
            ],
            config: null,
            defaultStartTime: startOfYear(currentDate),
            defaultEndTime: endOfDay(currentDate),
            selectedBroker: [],
            selectedProduct: [],
            selectedCountry: [],
            allBrokerSelected: false,
            allCountriesSelected: false,
            allProductSelected: false,
        };
    },
    methods: {
        ...mapMutations("analytics", [
            "setAnalyseFilters",
            "resetMetricFilter",
        ]),
        brokerNameHandler(option) {
            return option?.attributes?.displayName?.[0] || option.name;
        },
        dateFilterRenderer(
            label,
            defaultDate,
            date,
            dateUpdateHandler,
            disabledDateHandler
        ) {
            return {
                component: "DateInput",
                props: {
                    inputLabel: label,
                    defaultDate: defaultDate,
                    appendToBody: true,
                    modelValue: date,
                    dateValueType: "timestamp",
                    dateFormat: "DD MMMM YYYY",
                    showClear: false,
                    disabledDate: disabledDateHandler,
                },
                on: {
                    "update:modelValue": dateUpdateHandler,
                },
            };
        },
        dropdownFilterRenderer({
            apiUrl,
            apiRequestBody,
            apiMethod,
            apiRequestParam = "",
            responseInfo,
            optionTextHandler,
            label,
            field,
            sorting,
            selectedItem,
            changeHandler,
            changeAllSelected,
            searchKey,
            component = "MultiSelectDropdown",
            ajax = true,
            options,
        }) {
            return {
                component: component,
                props: {
                    containerClass: field,
                    dropDownLabel: label,
                    ajax: ajax,
                    options: options,
                    apiUrl: apiUrl,
                    ...(apiRequestBody && { apiRequestBody: apiRequestBody }),
                    apiRequestParam,
                    apiMethod,
                    responseInfo,
                    optionTextHandler,
                    modelValue: selectedItem,
                    sorting,
                    class: "small-filter",
                    searchKey,
                },
                on: {
                    "update:modelValue": changeHandler,
                    changeIsAllSelected: changeAllSelected,
                },
            };
        },
        basicInputFilterRenderer({
            inputType,
            inputLabel,
            inputClass,
            inputValue,
            changeHandler,
        }) {
            return {
                component: "bev-input",
                props: {
                    inputType: inputType,
                    inputLabel: inputLabel,
                    inputClass: inputClass,
                    inputValue: inputValue,
                },
                on: {
                    input: changeHandler,
                },
            };
        },
        resetFilters() {
            this.resetMetricFilter();
            this.selectedBroker = [];
            this.selectedProduct = [];
            this.selectedCountry = [];
            this.allBrokerSelected = false;
            this.allCountriesSelected = false;
            this.allProductSelected = false;
        },
    },
};
</script>

<style scoped>
.metrics-filter-container {
    gap: 10px;
}
.small-filter {
    width: 250px;
}
</style>
