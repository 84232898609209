import config from "@/envConfig.js";

import axios from "axios";

const complyAdvantageAxios = axios.create();
complyAdvantageAxios.defaults.params = {};

export default class ComplyAdvantageService {
    static checkComplyAdvantageStatus(businessName) {
        return complyAdvantageAxios.post(config.complyAdvantageUrl, {
            search_term: businessName,
            fuzziness: 0.5,
            filters: {
                types: ["sanction", "pep"],
            },
            exact_match: true,
        });
    }
}
