<template>
    <div class="bev-container client-management">
        <bev-loading-block
            v-if="fetchGroupIdError || fetchingGroupId"
            :loading="fetchingGroupId"
            :error="fetchGroupIdError"
            :retryHandler="getUserGroupDetail"
            :errorMessage="'Failed to load broker detail'"
            :showRetry="true"
        />
        <bev-dialog
            :title="userAction === 'create' ? 'Create Client' : 'Update Client'"
            :description="`Please provide below information to ${userAction} an insured`"
            :closeDialog="closeCreateClientDialog"
            :state="createClientDialog"
            :width="'60%'"
            :actions="{
                show: false,
            }"
        >
            <template v-slot:dialog-content>
                <ClientDetailForm
                    :userAction="userAction"
                    :selectedClient="selectedClient"
                    :selectedBrokerGroupId="selectedBrokerGroupId"
                    :closeCreateClientDialog="closeCreateClientDialog"
                    :getBrokerClients="getBrokerClients"
                    :pageNumber="userAction === 'create' ? 0 : pageNumber"
                />
            </template>
        </bev-dialog>
        <div
            class="pt-50px w-100 h-100 overflow-auto"
            v-if="!fetchGroupIdError && !fetchingGroupId"
        >
            <div
                class="d-flex align-items-start justify-content-between px-20px flex-wrap"
            >
                <div>
                    <heading-block
                        :align="'left'"
                        :heading="'Client Management'"
                        :description="'Manage your clients: Add, edit or get an overview of your clients'"
                        :headingSizeClass="'font-22 sm-font-16'"
                        :descriptionSizeClass="'font-16 sm-font-12'"
                    />
                    <div class="client-management-selection">
                        <div class="dropdown-container">
                            <bev-dropdown
                                :containerClass="'brokers'"
                                :dropDownLabel="'Select Broker'"
                                :ajax="true"
                                :apiUrl="`${config.userServiceUrl}/group/org_type/BROKER`"
                                :responseInfo="{
                                    resultKey: 'items',
                                    totalResultKey: 'total',
                                    pagination: true,
                                    uniqueKey: 'id',
                                }"
                                v-model="selectedBroker"
                                :optionTextHandler="
                                    (option) => {
                                        return brokerNameHandler(option);
                                    }
                                "
                                setFirstEntryAsInitial
                                class="m-auto small-filter"
                                v-if="isAdmin || isUnderwriter"
                            ></bev-dropdown>
                        </div>
                    </div>
                </div>
                <div class="text-right flex-1">
                    <bev-search-input
                        :inputType="'text'"
                        :inputClass="'have-bg'"
                        :inputPlaceholder="'Search by Client Name'"
                        class="search-input ml-auto"
                        v-model="clientSearch"
                    ></bev-search-input>
                    <bev-button
                        :themeType="'dark'"
                        :clickHandler="createClientHandler"
                        class="large mt-10px"
                    >
                        Create Client
                    </bev-button>
                </div>
            </div>
            <div
                class="client-list position-relative mt-20px"
                :style="{
                    height:
                        isAdmin || isUnderwriter
                            ? 'calc(100vh - 385px)'
                            : 'calc(100vh - 330px)',
                }"
            >
                <BevTable
                    :tableHeight="
                        isAdmin || isUnderwriter
                            ? 'calc(100vh - 385px)'
                            : 'calc(100vh - 330px)'
                    "
                    :headers="clientHeaders"
                    :data="formatClientTableData"
                    :tableLoading="clientLoading"
                    :tableError="clientError"
                    :retryHandler="
                        getBrokerClients.bind(this, pageNumber, false)
                    "
                    :allowPagination="true"
                    :totalData="totalElements"
                    :paginationSize="size"
                    :pageNumber="pageNumber"
                    :totalPages="totalPages"
                    :pageChangeHandler="pageChangeHandler"
                    :tableItemClickHandler="updateClientHandler"
                    :errorMessage="clientErrorMessage"
                    :appliedSortColumn="sortColumn"
                    @sortingChanged="sortingChanged"
                    tableActionText="Edit Client"
                />
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/envConfig.js";
import { mapGetters, mapState } from "vuex";
import UserService from "@/services/UserService";
import BevTable from "@/components/Common/Table/Main.vue";
import ClientDetailForm from "./ClientDetailForm";
import debounce from "lodash.debounce";
export default {
    name: "ClientManagement",
    components: {
        BevTable,
        ClientDetailForm,
    },
    data() {
        return {
            config: null,
            selectedClient: null,
            selectedBroker: null,
            fetchingGroupId: false,
            fetchGroupIdError: false,
            clients: [],
            clientError: false,
            clientLoading: false,
            clientHeaders: [
                {
                    key: "customerName",
                    label: "Customer Name",
                    sorting: "ASC",
                },
                {
                    key: "email",
                    label: "Email",
                    noSort: true,
                },
                {
                    key: "country",
                    label: "Country",
                    noSort: true,
                },
                {
                    key: "city",
                    label: "City",
                    noSort: true,
                },
            ],
            sortColumn: "customerName",
            sortOrder: "ASC",
            pageNumber: 0,
            size: 20,
            totalElements: 0,
            totalPages: 0,
            userAction: "create",
            createClientDialog: false,
            clientSearch: "",
        };
    },
    computed: {
        ...mapState("auth", ["userGroups"]),
        ...mapGetters("auth", ["isAdmin", "isUnderwriter", "isBroker"]),
        selectedBrokerGroupId() {
            if (this.selectedBroker && this.selectedBroker.id) {
                return this.selectedBroker.id;
            } else {
                return null;
            }
        },
        clientErrorMessage() {
            if (this.clientError) {
                return "Failed to get clients";
            } else if (!this.selectedBroker) {
                return "Please select a broker";
            } else {
                return "No clients found";
            }
        },
        formatClientTableData() {
            if (this.clients.length > 0) {
                return this.clients.map((client) => {
                    return {
                        id: client.id,
                        customerName: `${client.firstName || ""}${
                            client.lastName ? " " + client.lastName : ""
                        }`,
                        email: client.attributes?.clientEmail?.[0] || "----",
                        country: client?.attributes?.country?.[0] || "",
                        city: client?.attributes?.city?.[0] || "",
                    };
                });
            } else {
                return [];
            }
        },
    },
    created() {
        this.config = config;
        if (!this.isAdmin && !this.isUnderwriter) {
            this.getUserGroupDetail();
        }
    },
    methods: {
        sortingChanged(data) {
            let headerItemIndex = this.clientHeaders.findIndex(
                (header) => header.key === data.column
            );
            if (headerItemIndex > -1) {
                this.clientHeaders[headerItemIndex].sorting = data.order;
            }
            this.sortColumn = data.appliedColumn;
            this.sortOrder = data.order;
            this.getBrokerClients(0, true);
        },
        createClientHandler() {
            this.userAction = "create";
            this.setSelectedClient(null);
            this.createClientDialog = true;
        },
        updateClientHandler(id) {
            let client = this.clients.find((client) => client.id === id);
            if (client) {
                this.userAction = "update";
                this.setSelectedClient(client);
                this.createClientDialog = true;
            }
        },
        closeCreateClientDialog() {
            this.setSelectedClient(null);
            this.createClientDialog = false;
        },
        setSelectedClient(user) {
            this.selectedClient = user;
        },
        async getUserGroupDetail() {
            let userGroup = this.userGroups[0];
            this.fetchingGroupId = true;
            this.fetchGroupIdError = false;
            try {
                let response = await UserService.getGroupDetailByName(
                    userGroup
                );
                this.selectedBroker = {
                    id: response.data[0].id,
                    attributes: {
                        brokerId: [
                            response.data[0]?.attributes?.brokerId?.[0] ||
                                this.subDomainDetail.brokerId,
                        ],
                    },
                };
                this.fetchingGroupId = false;
            } catch (err) {
                this.fetchingGroupId = false;
                this.fetchGroupIdError = true;
            }
        },
        brokerNameHandler(option) {
            return option?.attributes?.displayName?.[0] || option.name;
        },
        async getBrokerClients(page, initial) {
            if (initial) {
                this.totalElements = 0;
                this.totalPages = 0;
            }
            let sorting = "";
            if (this.sortColumn) {
                sorting = `&sort=${
                    this.sortColumn === "customerName"
                        ? "firstName"
                        : this.sortColumn
                }&order=${this.sortOrder.toLowerCase()}`;
            }
            this.pageNumber = page;
            this.clientLoading = true;
            this.clientError = false;
            try {
                const response = await UserService.getUsersByBroker(
                    this.selectedBrokerGroupId,
                    page,
                    this.size,
                    sorting,
                    this.clientSearch,
                    "client"
                );
                this.clients = response.data.items;
                this.totalElements = response.data.total;
                this.totalPages = Math.ceil(this.totalElements / this.size);
                this.clientLoading = false;
            } catch (err) {
                this.clientError = true;
                this.clientLoading = false;
            }
        },
        pageChangeHandler(number) {
            this.getBrokerClients(number, false);
        },
        searchFilterHandler: debounce(function () {
            this.getBrokerClients(0, true);
        }, 200),
    },
    watch: {
        selectedBrokerGroupId: function () {
            this.getBrokerClients(0, true);
        },
        clientSearch: function () {
            this.clientLoading = true;
            this.searchFilterHandler();
        },
    },
};
</script>

<style scoped lang="scss">
.client-management-selection {
    width: 300px;
    max-width: 100%;
    margin-top: 20px;
}
.client-list {
    min-height: 300px;
    overflow-y: auto;
    ::v-deep(table) {
        td.email {
            word-break: break-all;
        }
    }
}
.search-input {
    width: 100%;
    max-width: 400px;
}
</style>
