<template>
    <div class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 overflow-y-auto">
        <buy-policy-heading-block
            :align="'center'"
            :heading="'Parametric Rain Cover Period'"
            :description="'Choose which specific hours, and for how many days, you need cover for. <br> Parametric Rain must be bought 14 days in advance, and covers you up to 7 consecutive days with a maximum period per day of 24 hours.'"
            class="less-width-description"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
        >
            <CoverSummaryButton
                :clickHandler="setDuration.bind(this, false, false, true)"
            />
        </buy-policy-heading-block>
        <bev-form
            :submitData="setDuration.bind(this, true, false, true)"
            :showSingleButton="false"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
                loading: false,
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setDuration.bind(this, false, true, false),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <div class="row pt-20px px-4% mt-50px">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div
                            class="d-flex align-items-center justify-content-between date-heading"
                        >
                            <label class="font-16 sm-font-12 d-block"
                                >Select Cover Start and End Dates</label
                            >
                            <bev-image
                                v-if="checkReset"
                                :addBackground="true"
                                :backgroundColor="'inherit'"
                                :imageAlt="'cross-icon'"
                                :imageUrl="$imagePath.cross"
                                :width="'20px'"
                                :height="'20px'"
                                :tooltipText="'Reset'"
                                :clickHandler="resetDates"
                            ></bev-image>
                        </div>
                        <div
                            class="date-picker-popup mt-20px mx-datepicker mx-datepicker-range mx-datepicker-inline"
                        >
                            <div class="mx-datepicker-main">
                                <div class="mx-datepicker-content">
                                    <calendar-panel
                                        :value="shortInnerValue"
                                        :default-value="startDefaultDate"
                                        :disabled-date="disabledDatesForShort"
                                        :get-classes="getClasses"
                                        @pick="handleSelect"
                                    ></calendar-panel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'First Day of Cover*'"
                            :haveError="
                                v$.startDate.$error &&
                                v$.startDate.required.$invalid
                            "
                            :errorMessage="
                                $messages.errorMessages.startDateError
                            "
                            :inputClass="'start-date'"
                            v-model="v$[rangeDates[0]].$model"
                            :disabled="true"
                            class="w-100 mt-30px have-m-width ml-auto"
                        ></bev-input>
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Last Day of Cover*'"
                            :haveError="
                                v$.endDate.$error &&
                                v$.endDate.required.$invalid
                            "
                            :errorMessage="$messages.errorMessages.endDateError"
                            :inputClass="'end-date'"
                            v-model="v$[rangeDates[1]].$model"
                            :disabled="true"
                            class="w-100 mt-30px have-m-width ml-auto"
                        ></bev-input>
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Starting Period per Day'"
                            :inputClass="'start-insure-time'"
                            v-model="startInsureTime"
                            :disabled="true"
                            class="w-100 mt-30px have-m-width ml-auto"
                        ></bev-input>
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Ending Period per Day'"
                            :errorMessage="''"
                            :inputClass="'end-insure-time'"
                            v-model="endInsureTime"
                            :disabled="true"
                            class="w-100 mt-30px have-m-width ml-auto"
                        ></bev-input>
                    </div>
                </div>
                <div class="px-4% mx-10px sm-px-10px">
                    <bev-range-slider
                        :label="'Select Insured Time Period per Day'"
                        v-model="hours"
                        :tooltipValueHandler="hourTooltipHandler"
                        :min="1"
                        :max="25"
                        :unit="''"
                        class="mt-30px w-50 sm-w-100"
                        :sliderClass="'px-20px'"
                    ></bev-range-slider>
                </div>
            </template>
        </bev-form>
        <SaveAsDraftButton :dataSaveHandler="setDuration" />
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DatePicker from "vue-datepicker-next";
import BevRangeSlider from "@/components/Common/RangeSlider";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
import add from "date-fns/add";
import "vue-datepicker-next/index.css";

const { Calendar: CalendarPanel } = DatePicker;

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        BevRangeSlider,
        CoverSummaryButton,
        SaveAsDraftButton,
        CalendarPanel,
        BuyPolicyHeadingBlock,
    },
    computed: {
        ...mapState("buyPolicy", ["buyPolicyInsuranceType", "questionBundles"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        startDefaultDate() {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            let minimumStartDate = add(currentDate, { days: 14 });
            return minimumStartDate;
        },
        endDefaultDate() {
            let currentDate = this.startDate
                ? new Date(this.startDate)
                : new Date();
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        },
        rangeDates() {
            let startDate = this.shortTermDates[0]
                ? this.formatDateValue(
                      this.shortTermDates[0],
                      "date-format",
                      "dd MMMM yyyy"
                  )
                : "";
            let endDate = this.shortTermDates[1]
                ? this.formatDateValue(
                      this.shortTermDates[1],
                      "date-format",
                      "dd MMMM yyyy"
                  )
                : "";
            return [startDate, endDate];
        },
        startInsureTime() {
            return this.$dateTime.hourLabel[this.hours[0] - 1].label;
        },
        endInsureTime() {
            return this.$dateTime.hourLabel[this.hours[1] - 1].label;
        },
        checkReset() {
            return (
                this.startDate ||
                this.endDate ||
                this.shortTermDates.length > 0 ||
                this.hours[0] !== 1 ||
                this.hours[1] !== 12
            );
        },
    },
    created() {
        this.startDate = this.questionBundles["SelectCoverDuration"]
            ?.coverStartDate
            ? this.formatDateValue(
                  this.questionBundles["SelectCoverDuration"].coverStartDate,
                  "date-format",
                  "dd MMMM yyyy"
              )
            : "";
        this.endDate = this.questionBundles["SelectCoverDuration"]?.coverEndDate
            ? this.formatDateValue(
                  this.questionBundles["SelectCoverDuration"].coverEndDate,
                  "date-format",
                  "dd MMMM yyyy"
              )
            : "";
        this.shortTermDates = this.questionBundles["SelectCoverDuration"]
            ?.coverStartDate
            ? [
                  new Date(
                      this.questionBundles["SelectCoverDuration"].coverStartDate
                  ),
                  new Date(
                      this.questionBundles["SelectCoverDuration"].coverEndDate
                  ),
              ]
            : [];
        this.shortInnerValue = this.questionBundles["SelectCoverDuration"]
            ?.coverStartDate
            ? [
                  new Date(
                      this.questionBundles["SelectCoverDuration"].coverStartDate
                  ),
                  new Date(
                      this.questionBundles["SelectCoverDuration"].coverEndDate
                  ),
              ]
            : [];
        this.setStartAndEndTime();
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            shortTermDates: [],
            shortInnerValue: [new Date(NaN), new Date(NaN)],
            hours: [1, 12],
        };
    },
    validations() {
        return {
            startDate: {
                required,
            },
            endDate: {
                required,
            },
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setBuyPolicyStep", "setQuestionBundle"]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        resetDates() {
            this.startDate = null;
            this.endDate = null;
            this.shortTermDates = [];
            this.shortInnerValue = [new Date(NaN), new Date(NaN)];
            this.hours = [1, 12];
        },
        setDuration(forNext, forBack, notSaveForDraft) {
            this.updateDataBundleWithValidations({
                bundleName: "SelectCoverDuration",
                bundleData: {
                    coverStartDate: this.startDate,
                    coverEndDate: this.endDate,
                    coverStartTime:
                        this.$dateTime.hourLabel[this.hours[0] - 1].startRange,
                    coverEndTime:
                        this.$dateTime.hourLabel[this.hours[1] - 1].endRange,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
        },
        disabledDatesForShort(date) {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            let minimumStartDate = add(currentDate, { days: 14 });
            let maximumStartDate = add(currentDate, { months: 6 });
            if (this.shortInnerValue[0]) {
                let maximumEndDate = add(this.shortInnerValue[0], { days: 6 });
                return date < minimumStartDate || date > maximumEndDate;
            } else {
                return date < minimumStartDate || date > maximumStartDate;
            }
        },
        getClasses(cellDate, currentDates, classes) {
            if (
                !/disabled|active|not-current-month/.test(classes) &&
                currentDates.length === 2 &&
                cellDate.getTime() > currentDates[0].getTime() &&
                cellDate.getTime() < currentDates[1].getTime()
            ) {
                return "in-range";
            }
            return "";
        },
        isValidDate(date) {
            return date instanceof Date && !isNaN(date);
        },
        handleSelect(date) {
            const [startValue, endValue] = this.shortInnerValue;
            if (this.isValidDate(startValue) && !this.isValidDate(endValue)) {
                if (startValue.getTime() > date.getTime()) {
                    if (startValue > add(date, { days: 6 })) {
                        this.shortInnerValue = [date, add(date, { days: 6 })];
                    } else {
                        this.shortInnerValue = [date, startValue];
                    }
                } else {
                    this.shortInnerValue = [startValue, date];
                }
                this.shortTermDates = this.shortInnerValue;
            } else {
                this.shortInnerValue = [date, null];
                this.shortTermDates = this.shortInnerValue;
            }
            this.startDate = this.shortTermDates[0]?.getTime();
            this.endDate = this.shortTermDates[1]?.getTime();
        },
        hourTooltipHandler(value) {
            return this.$dateTime.hourLabel[value - 1]?.label || "";
        },
        setStartAndEndTime() {
            if (this.questionBundles["SelectCoverDuration"]?.coverStartTime) {
                let currentHourIndex = {
                    start: this.getValueByKey(
                        this.$dateTime.hourLabel,
                        "startRange",
                        this.questionBundles["SelectCoverDuration"]
                            .coverStartTime
                    ).value,
                    end: this.getValueByKey(
                        this.$dateTime.hourLabel,
                        "endRange",
                        this.questionBundles["SelectCoverDuration"].coverEndTime
                    ).value,
                };

                this.hours = this.questionBundles["SelectCoverDuration"]
                    .coverStartTime
                    ? [
                          this.$dateTime.hourLabel[currentHourIndex.start]
                              .value + 1,
                          this.$dateTime.hourLabel[currentHourIndex.end].value +
                              1,
                      ]
                    : [1, 12];
            }
        },
    },
};
</script>

<style scoped>
label {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
}
.date-heading {
    width: 100%;
    max-width: 500px;
}
</style>
