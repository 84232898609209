// The scope of this file is for global components
// All the components that are globally accessible will be added here.

import BEVImage from "@/components/Common/Image/BasicImage.vue";
import BEVInput from "@/components/Common/Input/BasicInput.vue";
import BEVCurrencyInput from "@/components/Common/Input/CurrencyInput.vue";
import BEVSearchInput from "@/components/Common/Input/SearchInput/SearchInput.vue";
import BEVSearchInputDrop from "@/components/Common/Input/SearchInput/SearchInputWithDrop.vue";
import BEVButton from "@/components/Common/Button/BasicButton.vue";
import CircleImage from "@/components/Common/Image/CircleImage.vue";
import BEVCard from "@/components/Common/Card/InfoCard/Card.vue";
import Actions from "@/components/Common/Button/Actions.vue";
import Dialog from "@/components/Common/Dialog.vue";
import BevDropdown from "@/components/Common/Dropdown.vue";
import Stepper from "@/components/Common/Stepper.vue";
import HeadingBlock from "@/components/Common/Header/HeadingBlock.vue";
import BEVForm from "@/components/Common/Form.vue";
import InputError from "@/components/Common/InputError.vue";
import BEVCheckbox from "@/components/Common/Input/Checkbox.vue";
import Spinner from "@/components/Common/Spinner";
import { Dropdown } from "floating-vue";
import Logo from "@/components/Common/Logo";
import TextToggler from "@/components/Common/TextToggler.vue";
import SelectableTag from "@/components/Common/SelectableRadioTag.vue";
import LoadingBlock from "@/components/Common/LoadingBlock.vue";
import SlideUpDown from "vue-slide-up-down";
// Adding global components and giving them names
// We can use these names to access these components
// @example bev-button(common name) for BEVButton (component)

export const globalComponentsSetup = (app) => {
    app.component("bev-image", BEVImage);
    app.component("bev-search-input", BEVSearchInput);
    app.component("bev-search-input-drop", BEVSearchInputDrop);
    app.component("bev-input", BEVInput);
    app.component("bev-currency-input", BEVCurrencyInput);
    app.component("bev-button", BEVButton);
    app.component("bev-dropdown", BevDropdown);
    app.component("bev-dialog", Dialog);
    app.component("circle-image", CircleImage);
    app.component("bev-card", BEVCard);
    app.component("actions", Actions);
    app.component("bev-stepper", Stepper);
    app.component("heading-block", HeadingBlock);
    app.component("bev-form", BEVForm);
    app.component("v-popover", Dropdown);
    app.component("input-error", InputError);
    app.component("bev-checkbox", BEVCheckbox);
    app.component("logo", Logo);
    app.component("spinner", Spinner);
    app.component("text-toggler", TextToggler);
    app.component("bev-selectable-tag", SelectableTag);
    app.component("bev-loading-block", LoadingBlock);
    app.component("slide-up-down", SlideUpDown);
};
