<template>
    <div class="quick-quote-container position-relative">
        <SectionHeading
            headingText="Quick Quote Products"
            description="Get a quote without entering all details required for binding"
        />
        <bev-loading-block
            v-if="productLoading || productError"
            :loading="productLoading"
            :error="productError"
            :retryHandler="getAllProducts"
            :errorMessage="'Failed to load products'"
            :errorImage="$imagePath.alert"
            class="quick-quote-loading"
        />
        <div class="row w-100 mt-20px" v-if="!productLoading && !productError">
            <QuickQuoteButton
                v-for="(product, index) in products"
                :key="index"
                :product="product"
                :currentBroker="currentBroker"
            />
        </div>
    </div>
</template>

<script>
import SectionHeading from "@/components/Views/Dashboard/SectionHeading";
import QuickQuoteButton from "@/components/Views/Dashboard/QuickQuoteSection/QuickQuoteButton";
import ProductService from "@/services/ProductService";
import UserService from "@/services/UserService";
import { mapGetters, mapState } from "vuex";
export default {
    name: "QuickQuoteSection",
    components: {
        SectionHeading,
        QuickQuoteButton,
    },
    data() {
        return {
            products: [],
            productError: false,
            productLoading: true,
            currentBroker: null,
        };
    },
    computed: {
        ...mapState("auth", ["userGroups"]),
        ...mapGetters(["productRequestBody"]),
        ...mapGetters("auth", ["isBroker"]),
    },
    mounted() {
        this.getAllProducts();
    },
    methods: {
        async getAllProducts() {
            this.productLoading = true;
            this.productError = false;
            try {
                let response = await ProductService.searchProducts(
                    0,
                    1000,
                    this.productRequestBody
                );
                this.products = response.data.content.filter((p) => {
                    return (
                        p.isEnabled && p.isParent && p.productType !== "DIRECT"
                    );
                });
                if (this.isBroker) {
                    await this.getUserGroupDetail();
                } else {
                    this.productLoading = false;
                }
            } catch (err) {
                this.productError = true;
                this.productLoading = false;
            }
        },
        async getUserGroupDetail() {
            let userGroup = this.userGroups[0];
            this.productLoading = true;
            try {
                let response = await UserService.getGroupDetailByName(
                    userGroup
                );
                this.currentBroker = {
                    id: response.data[0].id,
                    attributes: {
                        brokerId: [
                            response.data[0]?.attributes?.brokerId?.[0] ||
                                this.subDomainDetail.brokerId,
                        ],
                    },
                };
                this.productLoading = false;
            } catch (err) {
                this.productError = true;
                this.productLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.quick-quote-loading {
    min-height: 100px;
}
</style>
