<template>
    <div class="dashboard">
        <main-heading></main-heading>
        <div class="row mb-20px">
            <div class="col-sm-12 col-md-6 col-lg-7 sm-mb-20px">
                <Banner />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
                <buy-cover></buy-cover>
            </div>
        </div>
        <QuickQuoteSection />
        <div class="weather-map-container">
            <WeatherMap />
        </div>
    </div>
</template>

<script>
import MainHeading from "@/components/Views/Dashboard/MainHeading";
import Banner from "@/components/Views/Dashboard/Banner/Main";
import BuyCover from "@/components/Views/Dashboard/BuyCover";
import WeatherMap from "@/components/Views/Dashboard/WeatherMap";
import QuickQuoteSection from "@/components/Views/Dashboard/QuickQuoteSection/Main";
export default {
    components: {
        MainHeading,
        Banner,
        BuyCover,
        WeatherMap,
        QuickQuoteSection,
    },
    data() {
        return {
            trigger: 0,
            startDate: null,
            dialogState: false,
            steps: [
                {
                    title: "31 Aug 21",
                    subTitle: "Policy auto claimed",
                },
                {
                    title: "2 Sep 21",
                    subTitle: "Viewed by team",
                },
                {
                    title: "5 Sep 21",
                    subTitle: "Documents Verified",
                },
                {
                    title: "16 Sep 21",
                    subTitle: "Approved & Processed",
                },
                {
                    title: "17 Sep 21",
                    subTitle: "Expected Settlement",
                },
            ],
        };
    },
    methods: {
        changeSlider(data) {
            this.trigger = Math.ceil(data);
        },
        showDialog() {
            this.dialogState = true;
        },
        closeDialog() {
            this.dialogState = false;
        },
    },
};
</script>
