<template>
    <div class="d-flex align-items-center nav-options">
        <div
            class="d-flex align-items-center mr-20px"
            v-if="subDomainDetail.additionalLogo"
        >
            <AdditionalLogo />
            <hr class="additional-logo-divider" />
        </div>
        <div class="nav-user-info text-left">
            <div class="user-name">
                {{ user.firstName }} {{ user.lastName }}
            </div>
            <div
                class="user-address"
                v-if="
                    !isObjectEmpty(userAttributes) &&
                    (userAttributes.city ||
                        userAttributes.country ||
                        userAttributes.zipCode)
                "
            >
                {{ userAttributes.city }}, {{ userAttributes.country }}
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
/**
 * Navigation Options Component for small screen devices
 **/
import { mapState, mapGetters } from "vuex";
import AdditionalLogo from "@/components/Common/AdditionalLogo.vue";
export default {
    components: {
        AdditionalLogo,
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["userAttributes"]),
    },
};
</script>

<style scoped>
.additional-logo-divider {
    transform: rotate(180deg);
    height: 60px;
    margin-left: 10px;
}
</style>
