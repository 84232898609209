<template>
    <div class="search-input-container">
        <div :class="['bev-input', inputClass]">
            <bev-image
                class="search-icon"
                :addBackground="true"
                :imageAlt="'search-icon'"
                :backgroundColor="'var(--searchBarPlaceholderColor)'"
                :imageUrl="$imagePath.search"
                :width="'15px'"
                :height="'15px'"
            ></bev-image>
            <input
                :value="modelValue"
                :type="inputType"
                @blur="inputBlur($event)"
                @input="updateSelf($event)"
                :placeholder="inputPlaceholder"
                :disabled="disabled"
            />
            <spinner
                class="center-positioned"
                v-if="loading"
                :width="'12px'"
                :height="'12px'"
            ></spinner>
        </div>
    </div>
</template>

<script>
/**
 * This is BEV custom input which will be used wherever there is form data
 *
 * @param {String} inputType use to check type of input
 * @param {String} inputPlaceholder for input placeholder
 * @param {String} modelValue for input value prop
 * @param {Boolean} disabled for input disabled prop
 * @param {String} inputClass for custom input classes
 * @param model for two way binding data to input
 * @param loading for showing loader in the input box
 **/

export default {
    props: {
        inputType: String,
        inputPlaceholder: {
            type: String,
            default: "",
        },
        modelValue: null,
        disabled: {
            type: Boolean,
            default: false,
        },
        inputClass: String,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "blur"],
    methods: {
        updateSelf(event) {
            this.$emit("update:modelValue", event.target.value);
        },
        inputBlur() {
            this.$emit("blur");
        },
    },
};
</script>

<style scoped>
.search-input-container {
    border-radius: 6px;
    background: var(--searchBarBgColor);
    box-shadow: var(--searchBarBoxShadow);
    color: var(--searchBarColor);
}

.search-input-container input {
    background: var(--searchBarBgColor);
    color: var(--searchBarColor) !important;
}

.search-input-container input::placeholder {
    color: var(--searchBarPlaceholderColor);
}
</style>
