<template>
    <bev-form
        :submitData="saveDetails"
        :showSingleButton="false"
        :submitButton="{
            text: submitButtonText,
            loading: saveLoading,
            buttonType: 'submit',
        }"
        :resetButton="{
            text: 'Cancel',
            buttonType: 'reset',
            clickHandler: closeHandler,
        }"
        noActionBottomPadding
    >
        <template v-slot:input-fields>
            <div class="dropdown-container mb-20px" v-if="!hideBrokerIdPopup">
                <bev-dropdown
                    :containerClass="'brokers'"
                    :dropDownLabel="'Select Broker'"
                    :haveError="
                        v$.selectedBroker.$error &&
                        v$.selectedBroker.required.$invalid
                    "
                    :ajax="true"
                    :apiUrl="`${config.userServiceUrl}/group/org_type/BROKER`"
                    :responseInfo="{
                        resultKey: 'items',
                        totalResultKey: 'total',
                        pagination: true,
                        uniqueKey: 'id',
                    }"
                    :errorMessage="'Please choose a Broker'"
                    v-model="v$.selectedBroker.$model"
                    :optionTextHandler="
                        (option) => {
                            return brokerNameHandler(option);
                        }
                    "
                    class="m-auto"
                ></bev-dropdown>
            </div>
            <div
                class="dropdown-container mb-20px"
                v-if="isFieldVisible('userId')"
            >
                <bev-dropdown
                    :containerClass="'users'"
                    :dropDownLabel="'Select Insured'"
                    :haveError="
                        v$.selectedClient.$error &&
                        v$.selectedClient.required.$invalid
                    "
                    :ajax="true"
                    :apiUrl="`${config.userServiceUrl}/broker/${selectedBrokerGroupId}/users`"
                    :responseInfo="{
                        resultKey: 'items',
                        totalResultKey: 'total',
                        pagination: true,
                        uniqueKey: 'id',
                    }"
                    :errorMessage="'Please choose a User'"
                    v-model="v$.selectedClient.$model"
                    :optionTextHandler="
                        (option) => {
                            return (
                                option.firstName + ' ' + (option.lastName || '')
                            );
                        }
                    "
                    :showItemCheck="showNoBroker"
                    :searchKey="'search_filter'"
                    :disabled="!selectedBrokerGroupId || fetchingGroupId"
                    sorting="firstName&order=asc"
                    class="m-auto"
                ></bev-dropdown>
                <div
                    class="create-insured-button font-14 sm-font-12"
                    :class="{ disabled: disabledCreateInsured }"
                    @click="showCreateClientDialog"
                >
                    <span>+</span>
                    <span>Create Insured</span>
                </div>
            </div>
            <div
                class="mb-20px"
                v-if="
                    isFieldVisible('event_cancellation.insuredTotalRevenueV2')
                "
            >
                <bev-currency-input
                    :inputLabel="
                        productLabelHandler('insuredTotalRevenueV2', 'label')
                    "
                    :haveError="
                        v$.insuredTotalRevenueV2.$error &&
                        v$.insuredTotalRevenueV2.required.$invalid
                    "
                    :errorMessage="'Field cannot be empty'"
                    :inputClass="'insuredTotalRevenueV2'"
                    v-model="v$.insuredTotalRevenueV2.$model"
                    :currencySymbol="getCurrencyCode()"
                ></bev-currency-input>
            </div>
            <div v-if="isFieldVisible('event_cancellation.eventEndDate')">
                <date-input
                    :haveError="
                        v$.eventEndDate.$error &&
                        v$.eventEndDate.required.$invalid
                    "
                    :errorMessage="
                        $messages.errorMessages.fieldCannotEmptyError(
                            'Event End Date'
                        )
                    "
                    :inputLabel="'Event End Date*'"
                    :disabledDate="disableDates"
                    :defaultDate="defaultEndDate"
                    :dateValueType="'timestamp'"
                    v-model="v$.eventEndDate.$model"
                    :infoImage="{
                        show: true,
                        info: $messages.infoMessages.eventDateInfo,
                    }"
                ></date-input>
            </div>
            <div v-for="(value, field) in extraFields" :key="field">
                <bev-input
                    :inputType="'text'"
                    :inputLabel="extraLableHandler(field)"
                    :modelValue="extraFields[field]"
                    @update:modelValue="(e) => updateExtraField(e, field)"
                ></bev-input>
            </div>
        </template>
    </bev-form>
    <bev-dialog
        :title="'Create Insured'"
        :description="'Please provide below information to create an insured'"
        :closeDialog="closeCreateClientDialog"
        :state="createClientDialog"
        :width="'60%'"
        :actions="{
            show: false,
        }"
    >
        <template v-slot:dialog-content>
            <ClientDetailForm
                userAction="create"
                :selectedBrokerGroupId="selectedBrokerGroupId"
                :closeCreateClientDialog="closeCreateClientDialog"
                :policyFlow="true"
                :setSelectedClient="setSelectedClient"
            />
        </template>
    </bev-dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import config from "@/envConfig.js";
import UserService from "@/services/UserService";
import { mapGetters, mapState } from "vuex";
import DateInput from "@/components/Common/Input/DateInput";
import ClientDetailForm from "@/components/Views/ClientManagement/ClientDetailForm";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        actionLoading: Boolean,
        closeHandler: Function,
        incompleteBindMandatoryFields: {
            type: Array,
            default: () => [],
        },
        details: Object,
        submitHandler: Function,
        submitButtonText: String,
        finalStatus: String,
    },
    components: { DateInput, ClientDetailForm },
    computed: {
        ...mapState("auth", ["userGroups"]),
        ...mapGetters("auth", ["isAdmin", "isUnderwriter"]),
        selectedBrokerGroupId() {
            if (this.selectedBroker && this.selectedBroker.id) {
                return this.selectedBroker.id;
            } else {
                return null;
            }
        },
        hideBrokerIdPopup() {
            return (
                !this.isFieldVisible("brokerId") ||
                (this.isFieldVisible("brokerId") &&
                    !this.isAdmin &&
                    !this.isUnderwriter)
            );
        },
        productsData() {
            if (this.details.products) {
                return JSON.parse(this.details.products);
            }
            return {};
        },
        currentDate() {
            // Compute minimum start date. It should be at least 2 days after today's date
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        },
        defaultEndDate() {
            if (!this.productsData.event_cancellation.eventStartDate) {
                return this.currentDate;
            }
            let d = new Date(
                this.productsData.event_cancellation.eventStartDate
            );
            let expectedEndDate = new Date();
            expectedEndDate.setMonth(d.getMonth());
            expectedEndDate.setFullYear(d.getFullYear());
            return expectedEndDate;
        },
        disabledCreateInsured() {
            return (this.isAdmin || this.isUnderwriter) && !this.selectedBroker;
        },
    },
    created() {
        this.config = config;
        this.setKeysInData();
        if (this.hideBrokerIdPopup) {
            this.getUserGroupDetail();
        }
    },
    data() {
        return {
            config: null,
            selectedBroker: null,
            selectedClient: null,
            insuredTotalRevenueV2: null,
            eventEndDate: "",
            fetchingGroupId: false,
            fetchGroupIdError: false,
            saveLoading: false,
            extraFields: {},
            createClientDialog: false,
        };
    },
    validations() {
        return {
            selectedClient: {
                required: this.isFieldVisible("userId") ? required : false,
            },
            selectedBroker: {
                required: !this.hideBrokerIdPopup ? required : false,
            },
            insuredTotalRevenueV2: {
                required: this.isFieldVisible(
                    "event_cancellation.insuredTotalRevenueV2"
                )
                    ? required
                    : false,
            },
            eventEndDate: {
                required: this.isFieldVisible("event_cancellation.eventEndDate")
                    ? required
                    : false,
            },
        };
    },
    methods: {
        closeCreateClientDialog() {
            this.createClientDialog = false;
        },
        setSelectedClient(user) {
            this.selectedClient = user;
        },
        showCreateClientDialog() {
            if (this.selectedBroker) {
                this.createClientDialog = true;
            }
        },
        extraLableHandler(key) {
            let keyValues = key.split(".");
            return this.productLabelHandler(
                keyValues[keyValues.length - 1],
                "label"
            );
        },
        setKeysInData() {
            let alreadyExistingKeys = [
                "userId",
                "brokerId",
                "event_cancellation.insuredTotalRevenueV2",
                "event_cancellation.eventEndDate",
            ];
            this.incompleteBindMandatoryFields.forEach((f) => {
                if (alreadyExistingKeys.indexOf(f) < 0) {
                    this.extraFields[f] = null;
                    this[f] = null;
                }
            });
        },
        updateExtraField(value, field) {
            this.extraFields[field] = value;
            this[field] = value;
        },
        showNoBroker(user) {
            return (
                this.user.email !== user.email &&
                !this.checkRole(user.roles, "broker")
            );
        },
        brokerNameHandler(option) {
            return option?.attributes?.displayName?.[0] || option.name;
        },
        isFieldVisible(field) {
            return this.incompleteBindMandatoryFields.indexOf(field) > -1;
        },
        disableDates(date) {
            // Disabled dates for Start Date
            return (
                date < this.currentDate ||
                (this.productsData.event_cancellation.eventStartDate &&
                    date <
                        new Date(
                            this.productsData.event_cancellation.eventStartDate
                        ))
            );
        },
        async getUserGroupDetail() {
            let userGroup = this.userGroups[0];
            this.fetchingGroupId = true;
            this.fetchGroupIdError = false;
            try {
                let response = await UserService.getGroupDetailByName(
                    this.details.brokerId || userGroup
                );
                this.selectedBroker = {
                    id: response.data[0].id,
                    attributes: {
                        brokerId: [
                            response.data[0]?.attributes?.brokerId?.[0] ||
                                this.subDomainDetail.brokerId,
                        ],
                    },
                };
                this.fetchingGroupId = false;
            } catch (err) {
                this.fetchingGroupId = false;
                this.fetchGroupIdError = true;
            }
        },
        async saveDetails() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.saveLoading = true;
                let details = {
                    ...this.details,
                    quoteStatus: this.finalStatus,
                };
                this.setFieldData(details);
                await this.submitHandler(
                    details,
                    false,
                    {
                        successMessage:
                            this.$messages.successMessages.quoteSuccess(
                                this.$policyValues.quoteStatus[this.finalStatus]
                                    .text
                            ),
                        errorMessage:
                            this.$messages.errorMessages.quoteMarkError(
                                this.finalStatus
                            ),
                    },
                    this.finalStatus ===
                        this.$policyValues.quoteStatus.APPROVED.value
                );
                this.saveLoading = false;
                this.closeHandler();
            }
        },
        setFieldData(details) {
            let products = { ...this.productsData };
            this.incompleteBindMandatoryFields.forEach((field) => {
                switch (field) {
                    case "brokerId":
                        details.brokerId =
                            this.selectedBroker.attributes.brokerId[0];
                        break;
                    case "userId":
                        details.userId = this.selectedClient.id;
                        details.customerName =
                            this.selectedClient.firstName +
                            " " +
                            this.selectedClient.lastName;
                        break;
                    case "event_cancellation.insuredTotalRevenueV2":
                        products["event_cancellation"].insuredTotalRevenueV2 =
                            this.insuredTotalRevenueV2;
                        details.products = JSON.stringify(products);
                        break;
                    case "event_cancellation.eventEndDate":
                        products["event_cancellation"].eventEndDate =
                            this.eventEndDate;
                        details.products = JSON.stringify(products);
                        break;
                    default:
                        this.extraFieldValueSetter(field, details);
                        break;
                }
            });
        },
        extraFieldValueSetter(field, details) {
            if (field.split(".")[1]) {
                this.productValueSetter(
                    details,
                    field.split(".")[0],
                    field.split(".")[1],
                    this.extraFields[field]
                );
            } else {
                details[field] = this.extraFields[field];
            }
        },
        productValueSetter(details, product, key, value) {
            let products = { ...this.productsData };
            products[product][key] = value;
            details.products = JSON.stringify(products);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-actions {
    padding-bottom: 10px !important;
}
.popup-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.create-insured-button {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--activeColorV1);
    margin: 10px 5px;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
</style>
