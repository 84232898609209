import defaultColors from "@/constant/defaultColors";
export default {
    computed: {
        $colors() {
            return {
                bevColors: this.bevColors,
                bevColorWithOpacity: this.bevColorWithOpacity,
            };
        },
        bevColors() {
            if (this.subDomainDetail.themeColors) {
                return {
                    ...defaultColors,
                    ...this.subDomainDetail.themeColors,
                };
            }
            return defaultColors;
        },
        bevColorWithOpacity() {
            let colorData = {};
            Object.keys(this.bevColors).forEach((color) => {
                colorData[color] = (opacity) => {
                    return this.changeColorAlpha(
                        this.bevColors[color],
                        opacity
                    );
                };
            });
            return colorData;
        },
    },
    methods: {
        changeColorAlpha(color, opacity) {
            //if it has an alpha, remove it
            if (color.length > 7) color = color.substring(0, color.length - 2);

            // coerce values so ti is between 0 and 1.
            const _opacity = Math.round(
                Math.min(Math.max(opacity, 0), 1) * 255
            );
            let opacityHex = _opacity.toString(16).toUpperCase();

            // opacities near 0 need a trailing 0
            if (opacityHex.length == 1) opacityHex = "0" + opacityHex;

            return color + opacityHex;
        },
    },
};
