<template>
    <transition name="fade">
        <div
            class="image-circle"
            :style="{
                'border-color': imageInfo.imageColor,
                width: width,
                'min-width': width,
                height: height,
            }"
        >
            <bev-image
                :addBackground="true"
                :backgroundColor="imageInfo.imageColor"
                :imageUrl="imageInfo.iconUrl"
                :width="imageInfo.width"
                :height="imageInfo.height"
            ></bev-image>
        </div>
    </transition>
</template>

<script>
/**
 * Circle Image component using basic bev-image tag
 *
 * @param {Object} imageInfo takes bev-image props like
 *  {   imageColor:String,
 *      iconUrl:String,
 *      width:String,
 *      height:String
 *  }
 * @param {String} height for circle image height
 * @param {String} width for circle image width
 *
 */
export default {
    props: {
        imageInfo: Object,
        width: {
            type: String,
            default: () => "48px",
        },
        height: {
            type: String,
            default: () => "48px",
        },
    },
};
</script>
