import config from "@/envConfig.js";
import APIService from "./APIService";

export default class ProductService extends APIService {
    static getProducts(page, size) {
        return this.get(
            `${config.productServiceUrl}/product?pageNumber=${page}&pageSize=${size}`
        );
    }
    static searchProducts(page, size, requestBody) {
        return this.post(
            `${config.productServiceUrl}/product/search?page=${page}&size=${size}`,
            requestBody
        );
    }
}
