export default {
    hourLabel: [
        {
            label: "00:00",
            value: 0,
            startRange: "0000",
            endRange: "0000",
        },
        {
            label: "01:00",
            value: 1,
            startRange: "0100",
            endRange: "0000",
        },
        {
            label: "02:00",
            value: 2,
            startRange: "0200",
            endRange: "0100",
        },
        {
            label: "03:00",
            value: 3,
            startRange: "0300",
            endRange: "0200",
        },
        {
            label: "04:00",
            value: 4,
            startRange: "0400",
            endRange: "0300",
        },
        {
            label: "05:00",
            value: 5,
            startRange: "0400",
            endRange: "0400",
        },
        {
            label: "06:00",
            value: 6,
            startRange: "0600",
            endRange: "0500",
        },
        {
            label: "07:00",
            value: 7,
            startRange: "0700",
            endRange: "0600",
        },
        {
            label: "08:00",
            value: 8,
            startRange: "0800",
            endRange: "0700",
        },
        {
            label: "09:00",
            value: 9,
            startRange: "0900",
            endRange: "0800",
        },
        {
            label: "10:00",
            value: 10,
            startRange: "1000",
            endRange: "0900",
        },
        {
            label: "11:00",
            value: 11,
            startRange: "1100",
            endRange: "1000",
        },
        {
            label: "12:00",
            value: 12,
            startRange: "1200",
            endRange: "1100",
        },
        {
            label: "13:00",
            value: 13,
            startRange: "1300",
            endRange: "1200",
        },
        {
            label: "14:00",
            value: 14,
            startRange: "1400",
            endRange: "1300",
        },
        {
            label: "15:00",
            value: 15,
            startRange: "1500",
            endRange: "1400",
        },
        {
            label: "16:00",
            value: 16,
            startRange: "1600",
            endRange: "1500",
        },
        {
            label: "17:00",
            value: 17,
            startRange: "1700",
            endRange: "1600",
        },
        {
            label: "18:00",
            value: 18,
            startRange: "1800",
            endRange: "1700",
        },
        {
            label: "19:00",
            value: 19,
            startRange: "1900",
            endRange: "1800",
        },
        {
            label: "20:00",
            value: 20,
            startRange: "2000",
            endRange: "1900",
        },
        {
            label: "21:00",
            value: 21,
            startRange: "2100",
            endRange: "2000",
        },
        {
            label: "22:00",
            value: 22,
            startRange: "2200",
            endRange: "2100",
        },
        {
            label: "23:00",
            value: 23,
            startRange: "2300",
            endRange: "2200",
        },
        {
            label: "24:00",
            value: 24,
            startRange: "2400",
            endRange: "2300",
        },
    ],
    findHourByKey(key, value, keyToGet) {
        return this.hourLabel.find((label) => {
            return label[key] === value;
        })[keyToGet];
    },
};
