import config from "@/envConfig.js";
import APIService from "./APIService";

export default class LivePricing extends APIService {
    static getPremium(requestBody, cancelToken) {
        return this.post(
            `${config.livePricingUrl}/premium`,
            requestBody,
            cancelToken
        );
    }

    static getProfile(requestBody, cancelToken) {
        return this.post(
            `${config.livePricingUrl}/profile`,
            requestBody,
            cancelToken
        );
    }

    static getTriggerLevels(requestBody) {
        return this.post(`${config.livePricingUrl}/triggerLevels`, requestBody);
    }

    static getPayoutDescription(requestBody) {
        return this.post(`${config.livePricingUrl}/stDescription`, requestBody);
    }

    static getHistoricalPayout(requestBody) {
        return this.post(
            `${config.livePricingUrl}/historicalPayout`,
            requestBody
        );
    }

    static livePricingCore(requestBody) {
        return this.post(`${config.livePricingUrl}/pricing`, requestBody);
    }
}
