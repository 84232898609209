import SockJS from "sockjs-client";
import Stomp from "stompjs";
import config from "@/envConfig.js";

export default class WebsocketService {
    constructor() {
        this.sock = null;
        this.socketClient = null;
        this.socketTimeout = null;
        this.socketRetryCounter = 0;
    }

    webhookConnect(callback, urlKey) {
        this.socketRetryCounter++;
        let transports = ["websocket", "ws", "wss"];
        this.sock = new SockJS(config[urlKey] + "/ws", null, {
            transports: transports,
        });
        this.socketClient = Stomp.over(this.sock);
        this.socketClient.reconnect_delay = 0;
        this.socketClient.debug = false;
        this.socketClient.connect({}, callback, () => {
            if (this.socketClient !== null) {
                if (this.socketRetryCounter <= 10) {
                    this.socketTimeout = setTimeout(() => {
                        this.webhookConnect(callback, urlKey);
                    }, 2000);
                } else {
                    if (this.socketTimeout) {
                        clearInterval(this.socketTimeout);
                    }
                    this.socketTimeout = null;
                    this.socketRetryCounter = 0;
                }
            }
        });
    }

    socketCloseFunction() {
        this.sock.close();
        if (this.socketClient !== null && this.socketClient.connected) {
            this.socketClient.disconnect();
        }
        this.sock = null;
        this.socketClient = null;
        if (this.socketTimeout !== null) {
            clearTimeout(this.socketTimeout);
            this.socketTimeout = null;
        }
    }
}
