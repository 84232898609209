<template>
    <div
        class="d-flex align-items-center w-100"
        :class="`justify-content-${align} ${
            switchButtonPlace ? 'flex-row-reverse' : ''
        }`"
    >
        <bev-button
            :themeType="buttonLightProperties.type || 'light'"
            :clickHandler="buttonLightProperties.clickHandler"
            :disabled="buttonLightDisabled"
            :buttonType="buttonLightProperties.buttonType"
            :loading="buttonLightProperties.loading"
            class="mx-5px"
            :class="buttonScale"
        >
            {{ buttonLightProperties.text }}
        </bev-button>
        <bev-button
            :themeType="buttonDarkProperties.type || 'dark'"
            :clickHandler="buttonDarkProperties.clickHandler"
            :loading="buttonDarkProperties.loading"
            class="mx-5px"
            :disabled="buttonDarkDisabled"
            :buttonType="buttonDarkProperties.buttonType"
            :class="buttonScale"
        >
            {{ buttonDarkProperties.text }}
        </bev-button>
    </div>
</template>

<script>
/**
 * Action buttons (Primary and secondary button)
 * 
 * @param {String} align content alignment
 * @param {String} buttonScale class
 * @param {Boolean} buttonLightDisabled secondary button disable prop
 * @param {Boolean} buttonDarkDisabled primary button disable prop
 * @param {Object<{
                    text: 'Back',
                    clickHandler: () => {},
                    buttonType: 'button'
                }>} buttonLightProperties secondary button props
 * @param {Object<{
                    text: 'Next',
                    clickHandler: () => {},
                    buttonType: 'button'
                }>} buttonDarkProperties primary button props
 * @param {Boolean} buttonLightLoading secondary button loading prop
 * @param {Boolean} buttonDarkLoading primary button loading prop
           
 */
export default {
    props: {
        align: String,
        buttonScale: {
            type: String,
            default: "",
        },
        buttonLightProperties: {
            type: Object,
            default: function () {
                return {
                    text: "Back",
                    clickHandler: () => {},
                    buttonType: "button",
                };
            },
        },
        buttonDarkProperties: {
            type: Object,
            default: function () {
                return {
                    text: "Next",
                    clickHandler: () => {},
                    buttonType: "button",
                };
            },
        },
        buttonLightDisabled: {
            type: Boolean,
            default: false,
        },
        buttonDarkDisabled: {
            type: Boolean,
            default: false,
        },
        buttonLightLoading: {
            type: Boolean,
            default: false,
        },
        buttonDarkLoading: {
            type: Boolean,
            default: false,
        },
        switchButtonPlace: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
