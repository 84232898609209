<template>
    <div
        class="px-20px w-100 overflow-auto d-flex justify-content-between flex-column position-relative"
        :class="paymentFrom !== 'buyPolicy' ? 'fix-height' : 'h-100'"
    >
        <nav-layout
            :heading="'Make Payment'"
            :description="'You may either pay for your policy now or store the quote where you may handle it at your convenience later'"
            :options="paymentOptions"
            :selectedOption="selectedPaymentOption"
            :showNavigationItems="!addingCustomer"
        >
            <template v-slot:content>
                <div class="option-container py-40px">
                    <spinner
                        v-if="addingCustomer"
                        :width="'20px'"
                        :height="'20px'"
                        class="position-absolute center-positioned"
                    ></spinner>
                    <div
                        class="card-detail d-flex align-items-center flex-column justify-content-between h-100 mx-auto"
                        v-if="!addingCustomer"
                    >
                        <Stripe
                            v-if="selectedPaymentOption === 'creditCard'"
                            :key="'credit'"
                            :submitHandler="completePayment"
                            :amount="Number(policyPremiumData.totalPremium)"
                            :paymentMode="'CREDITCARD'"
                        />
                        <Stripe
                            v-if="selectedPaymentOption === 'debitCard'"
                            :key="'debit'"
                            :submitHandler="completePayment"
                            :amount="Number(policyPremiumData.totalPremium)"
                            :paymentMode="'DEBITCARD'"
                        />
                    </div>
                </div>
            </template>
        </nav-layout>
    </div>
</template>

<script>
/**
 * BEV component for Full payment
 *
 * @param {String} paymentFrom from which page payment is being called
 */

import { mapGetters, mapState, mapMutations } from "vuex";
import PaymentService from "@/services/PaymentService";
import UserService from "@/services/UserService";
import QuoteService from "@/services/QuoteService";
import NavLayout from "@/components/Layout/LayoutWithNav";
import Stripe from "@/components/Common/Stripe";
export default {
    props: {
        paymentFrom: String,
    },
    components: {
        NavLayout,
        Stripe,
    },
    data() {
        return {
            selectedPaymentOption: "creditCard",
            paymentOptions: [
                {
                    name: "Credit card",
                    key: "creditCard",
                    clickHandler: this.changeSelectedOption.bind(
                        this,
                        "creditCard"
                    ),
                },
                {
                    name: "Debit card",
                    key: "debitCard",
                    clickHandler: this.changeSelectedOption.bind(
                        this,
                        "debitCard"
                    ),
                },
            ],
            addingCustomer: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["userAttributes"]),
        ...mapState("auth", ["user", "userId"]),
        ...mapState("buyPolicy", ["addedQuote", "policyPremiumData"]),
    },
    mounted() {
        if (!this.addedQuote || !this.policyPremiumData.totalPremium) {
            if (this.paymentFrom !== "buyPolicy") {
                this.$router.go(-1);
            }
        } else {
            if (!this.userAttributes.customerId) {
                this.addingCustomer = true;
                this.addCustomer();
            }
        }
    },
    methods: {
        ...mapMutations("auth", ["setUser"]),
        ...mapMutations("buyPolicy", ["setBuyPolicyStep", "setPolicyStatus"]),
        changeSelectedOption(option) {
            this.selectedPaymentOption = option;
        },
        async updateUserDetail(customerId) {
            let attributes = JSON.parse(JSON.stringify(this.userAttributes));
            attributes.customerId = customerId;
            try {
                let response = await UserService.updateUserDetails(
                    this.userId,
                    {
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        username: this.user.username,
                        emailVerified: true,
                        enabled: true,
                        attributes: attributes,
                    }
                );
                this.setUser({
                    ...this.user,
                    attributes: response.data.attributes,
                });
                this.addingCustomer = false;
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to save customer"
                );
            }
        },
        async addCustomer() {
            try {
                let res = await PaymentService.addCustomer({
                    name: this.user.firstName + " " + this.user.lastName,
                    phone: this.userAttributes.mobileNumber,
                    email: this.user.email,
                });
                this.updateUserDetail(res.data.id);
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to add customer"
                );
            }
        },
        async updateCurrentQuote(paymentStatus, paymentOption, paymentMode) {
            let data = {
                ...this.addedQuote,
                paymentOption: paymentOption,
                paymentMode: paymentMode,
                paymentStatus: paymentStatus,
            };
            try {
                await QuoteService.updateQuote(this.addedQuote.id, data);
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        "Failed to update quote with payment"
                );
            }
        },
        completePayment(paymentStatus, paymentOption, paymentMode, callback) {
            if (paymentStatus === this.$policyValues.paymentStatus.successful) {
                this.updateCurrentQuote(
                    paymentStatus,
                    paymentOption,
                    paymentMode
                ).then(() => {
                    this.completePaymentResponseHandler(paymentStatus);
                    callback();
                });
            } else {
                this.completePaymentResponseHandler(paymentStatus);
                callback();
            }
        },
        completePaymentResponseHandler(paymentStatus) {
            if (this.paymentFrom === "buyPolicy") {
                this.setPolicyStatus({
                    status: paymentStatus,
                    heading:
                        paymentStatus ===
                        this.$policyValues.paymentStatus.successful
                            ? "Application Successfull…!"
                            : "Payment pending",
                    description:
                        paymentStatus ===
                        this.$policyValues.paymentStatus.successful
                            ? this.$policyValues.quoteSubmissionMessage
                                  .successful
                            : this.$policyValues.quoteSubmissionMessage.pending,
                    quoteNumber: this.addedQuote.quoteNumber,
                    retry: false,
                });
                this.setBuyPolicyStep({ step: "SelectProduct", back: false });
            } else if (this.paymentFrom === "policy") {
                this.$router.push({ path: `/policies/${this.addedQuote.id}` });
            } else {
                this.$router.push({ path: `/quotes/${this.addedQuote.id}` });
            }
        },
    },
};
</script>

<style scoped>
.option-container {
    width: 680px;
    margin: auto;
    max-width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.card-detail {
    width: 420px;
    max-width: 100%;
}
.fix-height {
    height: calc(100vh - 160px);
}
</style>
