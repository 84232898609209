<template>
    <div
        class="selectable-card cursor-pointer"
        :class="{ selected: clicked, disabled: disabled }"
        @click="onClickHandler"
    >
        <slot></slot>
    </div>
</template>

<script>
/**
 * Selectable Card
 *
 * @param {Boolean} clicked is selected class
 * @param {Function} clickHandler click function
 * @param {Boolean} disabled card disbaled state
 */
export default {
    props: {
        clicked: Boolean,
        clickHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        disabled: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    methods: {
        onClickHandler() {
            if (!this.disabled) {
                this.clickHandler();
            }
        },
    },
};
</script>
