<template>
    <div class="w-100 h-auto">
        <div v-for="(product, key) in formatProductMap" :key="key">
            <Product
                :productHeading="key"
                :productInfo="product"
                :redirectToEditStep="redirectToEditStep"
                :showProductActions="true"
                :refreshProducts="formatProductSummary"
                :findInProducts="findInProducts"
                :recalculatePremium="recalculatePremium"
                :recalculatingPremiumLoading="recalculatingPremiumLoading"
                :isReferred="isReferred"
                :checkIfExistInReferred="checkIfExistInReferred"
                v-if="findInProducts(key)"
            />
        </div>
    </div>
</template>

<script>
/**
    Product Section for the Cover Summary Page in Buy Policy Flow
    * @param {Function} formatProductSummary method to format product summary
    * @param {Function} findInProducts method to find if Product Exist in the flow
    * @param {Function} recalculatePremium method to re-calculate premium (update added product data in BuyPolicy Store)
    * @param {Boolean} recalculatingPremiumLoading
**/
import { mapState, mapActions, mapMutations } from "vuex";
import Product from "@/components/Views/BuyPolicy/CoverSummary/ProductSection/Product";
export default {
    props: {
        formatProductSummary: Function,
        findInProducts: Function,
        recalculatePremium: Function,
        recalculatingPremiumLoading: Boolean,
        isReferred: {
            type: Boolean,
            default: false,
        },
        checkIfExistInReferred: Function,
    },
    components: {
        Product,
    },
    computed: {
        ...mapState("buyPolicy", [
            "productDataMap",
            "productsFlow",
            "policyProduct",
            "questionBundles",
        ]),
        // Format Product Data Map according to the tree to be shown on UI
        formatProductMap() {
            let result = JSON.parse(JSON.stringify(this.productDataMap));
            Object.keys(result).forEach((product) => {
                if (result[product].addonFor) {
                    if (result[result[product].addonFor].addOns) {
                        result[result[product].addonFor].addOns[product] =
                            result[product];
                    } else {
                        result[result[product].addonFor].addOns = {};
                        result[result[product].addonFor].addOns[product] =
                            result[product];
                    }
                    if (this.policyProduct === product || !this.policyProduct) {
                        result[result[product].addonFor].caretOpen = true;
                        result[result[product].addonFor].addOns[
                            product
                        ].caretOpen = true;
                    }
                    delete result[product];
                } else {
                    if (this.policyProduct === product) {
                        result[product].caretOpen = true;
                    }
                }
            });
            return result;
        },
    },
    methods: {
        ...mapMutations("buyPolicy", ["setPolicyProduct"]),
        ...mapActions("buyPolicy", ["takeToStep"]),
        // Redirect to respective Question Bundle page for the field which is being edited
        redirectToEditStep(component, product) {
            this.setPolicyProduct(product);
            let componentIndex = this.productsFlow.findIndex((product) => {
                return product.name === component;
            });
            if (componentIndex > -1) {
                this.takeToStep(componentIndex);
            }
        },
    },
};
</script>
