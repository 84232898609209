<template>
    <div
        class="d-flex align-items-center text-danger font-12 text-left mt-10px"
    >
        <bev-image
            :addBackground="true"
            :backgroundColor="$colors.bevColors.danger"
            :imageUrl="$imagePath.alert"
            :imageAlt="'error-icon'"
            width="15px"
            height="15px"
        ></bev-image>
        <p class="ml-10px">{{ errorMessage }}</p>
    </div>
</template>

<script>
/**
 * Display input validation error message
 *
 * @param {String} errorMessage error message
 */

export default {
    props: {
        errorMessage: {
            type: String,
            default: "Please enter a valid value",
        },
    },
};
</script>
