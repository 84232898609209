<template>
    <LayoutWithNav
        :options="routes"
        :selectedOption="selectedOption"
        :heading="'Referrals'"
        :description="'Track and view the status of all your referred quotes and policies'"
        :type="!isNormalUser ? 'left' : 'center'"
        :haveExtraHeaderItem="!isNormalUser"
    >
        <template v-slot:header-extra>
            <div class="text-right header-actions">
                <bev-search-input
                    :inputType="'text'"
                    :inputClass="'have-bg'"
                    :inputPlaceholder="'Search by Referral Number or Insured Name'"
                    class="search-input"
                    v-model="referralSearch"
                ></bev-search-input>
            </div>
        </template>
        <template v-slot:content>
            <router-view
                :referralSearch="referralSearch"
                :key="$route.name"
            ></router-view>
        </template>
    </LayoutWithNav>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutWithNav from "@/components/Layout/LayoutWithNav";
export default {
    components: {
        LayoutWithNav,
    },
    data() {
        return {
            referralSearch: "",
            routes: [
                {
                    name: "Quotes",
                    clickHandler: this.clickHandler,
                    key: "1",
                    path: "quotes",
                },
                {
                    name: "Policies",
                    clickHandler: this.clickHandler,
                    key: "2",
                    path: "policies",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("auth", ["isNormalUser", "isUnderwriter"]),
        selectedOption() {
            const currentRoute = this.routes.find(
                (route) => `/referrals/${route.path}` === this.$route.path
            );

            return currentRoute?.key || "";
        },
    },
    methods: {
        /**
         *
         * @param {{ key:String,route:String }} option
         */
        clickHandler(option) {
            this.$router.push({ path: `/referrals/${option.path}` });
        },
    },
    watch: {
        $route: function () {
            this.referralSearch = "";
        },
    },
};
</script>

<style scoped>
.search-input {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
}
.header-actions {
    margin: 0 20px;
    width: 400px;
    min-width: 400px;
    max-width: 50%;
}
@media (max-width: 1420px) {
    .header-actions {
        width: 100%;
        max-width: 100%;
    }
}
</style>
