<template>
    <bev-card class="mt-10px" :cardType="'policy'">
        <template v-slot:card-header-info>
            <div class="card-heading">
                Quote Number:
                <span class="text-activeColorV2">{{ quoteNumber }}</span>
            </div>
            <div class="card-info font-12 fst-italic">
                <div class="d-flex align-items-center flex-wrap mt-5px">
                    <p class="w-50">
                        Applied On:
                        <span class="fw-600">{{ quoteAppliedOn }}</span>
                    </p>
                </div>
                <div class="d-flex align-items-center flex-wrap mt-5px">
                    <p>
                        Products: <span class="fw-600">{{ products }}</span>
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:card-content> </template>
        <template v-slot:card-footer>
            <div class="d-flex align-items-center justify-content-end">
                <bev-button
                    :themeType="'dark'"
                    :clickHandler="secondaryClickHandler"
                    :class="'small'"
                >
                    View
                </bev-button>
            </div>
        </template>
    </bev-card>
</template>
<script>
export default {
    props: {
        id: String,
        status: String,
        products: String,
        quoteAppliedOn: String,
        quoteNumber: String,
    },
    methods: {
        secondaryClickHandler() {
            this.$router.push({ path: `/quotes/${this.id}` });
        },
    },
};
</script>

<style scoped>
.card-heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
}
</style>
