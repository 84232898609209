<template>
    <div class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 overflow-y-auto">
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Tailor Your Cover`"
            :description="headerDescription"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="setDetails.bind(this, false, false, true)"
            />
        </buy-policy-heading-block>
        <bev-form
            :submitData="setDetails.bind(this, true, false, true)"
            :showSingleButton="false"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
                loading: false,
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setDetails.bind(this, false, true, false),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <div class="row pt-20px px-4% mt-50px">
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-20px">
                        <bev-currency-input
                            :inputLabel="`Cover Amount / Day*`"
                            :haveError="
                                v$.coverageAmount.$error &&
                                (v$.coverageAmount.required.$invalid ||
                                    v$.coverageAmount.minValue.$invalid)
                            "
                            :errorMessage="
                                v$.coverageAmount.required.$invalid
                                    ? $messages.errorMessages.fieldCannotEmptyError(
                                          'Cover amount'
                                      )
                                    : $messages.errorMessages.minAmountError(
                                          `${getCurrencyCode()}2000`
                                      )
                            "
                            :inputClass="'buy_coverage_amount'"
                            :infoImage="{
                                show: true,
                                info: `Cover Amounts greater than ${getCurrencyCode()}100,000 will need to be reviewed by an Underwriter`,
                            }"
                            v-model="v$.coverageAmount.$model"
                            :disabled="gettingTriggerLevel || triggerLevelError"
                            :currencySymbol="getCurrencyCode()"
                            class="w-100 have-m-width ml-auto"
                        ></bev-currency-input>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-20px">
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Payout Type*'"
                            :inputClass="'payout_drop'"
                            :disabled="true"
                            v-model="selectedPayout"
                        ></bev-input>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-20px">
                        <bev-dropdown
                            :options="triggerLevels"
                            :dropDownLabel="'Trigger Point*'"
                            :containerClass="'trigger_level_drop'"
                            :haveError="
                                v$.selectedTriggerLevel.$error &&
                                v$.selectedTriggerLevel.required.$invalid
                            "
                            :errorMessage="
                                $messages.errorMessages
                                    .selectedTriggerLevelError
                            "
                            :dropLoading="gettingTriggerLevel"
                            v-model="v$.selectedTriggerLevel.$model"
                        ></bev-dropdown>
                    </div>
                    <div
                        class="col-sm-12 col-md-6 col-lg-4 mb-20px position-relative"
                    >
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Day(s) Insured'"
                            :inputClass="'buy_coverage_days_insured'"
                            :disabled="true"
                            v-model="daysInsured"
                            class="w-100 have-m-width ml-auto"
                        ></bev-input>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-20px">
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Time Period Insured'"
                            :inputClass="'buy_coverage_time_insured'"
                            :disabled="true"
                            v-model="timePeriodInsured"
                            class="w-100 have-m-width ml-auto"
                        ></bev-input>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-20px">
                        <bev-input
                            :inputType="'text'"
                            :inputLabel="'Description'"
                            :inputClass="'buy_payout_description_rainfall'"
                            :disabled="true"
                            :loading="gettingPayoutDescription"
                            v-model="payoutDescription"
                            :infoImage="{
                                show: true,
                                info: payoutDescriptionTooltip,
                            }"
                            class="w-100 have-m-width ml-auto description"
                        ></bev-input>
                    </div>
                </div>
                <div
                    class="row px-4% justify-content-end"
                    v-if="premiumPerDay && !calculatingPolicyDetails"
                >
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <bev-currency-input
                            :inputLabel="'Premium / Day'"
                            :inputClass="'buy_payout_description_rainfall'"
                            :disabled="true"
                            :loading="gettingPayoutDescription"
                            v-model="premiumPerDay"
                            class="w-100 have-m-width ml-auto premium-box"
                            :currencySymbol="getCurrencyCode()"
                        ></bev-currency-input>
                    </div>
                </div>
                <LivePricingGraph
                    :graphData="graphData"
                    :trigger="thresholdLevel"
                    :barData="barData"
                    :selectedPayout="selectedPayout"
                    :graphLoading="graphLoading"
                    :graphError="graphError"
                    :graphEntryLength="graphEntryLength"
                    :shortTermGraphlabels="shortTermGraphlabels"
                    :shortTermPayoutData="shortTermPayoutData"
                    :getGraphData="getGraphData"
                />
                <p
                    v-if="graphData.length > 0"
                    class="ml-30px mb-20px px-4% font-12 fst-italic"
                >
                    Graph shows the historically wettest period 2 days either
                    side of insured day and hours
                </p>
            </template>
        </bev-form>
        <SaveAsDraftButton :dataSaveHandler="setDetails" />
    </div>
</template>

<script>
/**
    Cover Details Step for Buy Policy Flow
**/
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import LivePricing from "@/services/LivePricing";
import debounce from "lodash.debounce";
import customHtml from "@/constant/customHtml";
import LivePricingGraph from "@/components/Views/BuyPolicy/CoverDetails/LivePricingGraph";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        LivePricingGraph,
        CoverSummaryButton,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
    },
    computed: {
        ...mapState("buyPolicy", [
            "questionBundles",
            "cellId",
            "buyPolicyInsuranceType",
        ]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        ...mapGetters("auth", ["isNormalUser"]),
        // Generate Premium Request Body for Parametric Weather
        getPremiumRequestBody() {
            return {
                startDate:
                    this.getFormattedDateFromMiliseconds(
                        this.questionBundles["SelectCoverDuration"]
                            .coverStartDate,
                        "date-format",
                        "yyyy-MM-dd"
                    ) || "",
                endDate:
                    this.getFormattedDateFromMiliseconds(
                        this.questionBundles["SelectCoverDuration"]
                            .coverEndDate,
                        "date-format",
                        "yyyy-MM-dd"
                    ) || "",
                threshold: this.selectedTriggerLevel
                    .replace("%", "")
                    .replace("mm", "")
                    .trim(),
                limit: this.coverageAmount,
                cellId: this.cellId,
            };
        },
        // Check if need to make call for premium
        checkForPremiumCall() {
            return (
                this.coverageAmount &&
                this.selectedTriggerLevel &&
                this.selectedPayout &&
                !this.gettingTriggerLevel &&
                !this.triggerLevelError
            );
        },
    },
    mounted() {
        this.coverageAmount =
            this.questionBundles["CoverDetails"]?.coverageAmount ?? "";
        this.thresholdLevel =
            this.questionBundles["CoverDetails"]?.thresholdLevel ?? "";
        this.payoutCap = this.questionBundles["CoverDetails"]?.payoutCap ?? "";
        this.selectedPayout =
            this.questionBundles["CoverDetails"]?.payoutType ?? "Binary";
        this.selectedTriggerLevel =
            this.questionBundles["CoverDetails"]?.triggerLevel ?? "";
        this.midPoint = this.questionBundles["CoverDetails"]?.midPoint ?? null;
        this.tickSize = this.questionBundles["CoverDetails"]?.tickSize ?? null;
        this.averageRainfall =
            this.questionBundles["CoverDetails"]?.averageRainfall ?? "";
        this.triggerLevels =
            this.questionBundles["CoverDetails"]?.triggerLevels ?? [];

        // If coverStartDate exist in SelectCoverDuration questionBundle then set daysInsured in component context
        if (this.questionBundles["SelectCoverDuration"]?.coverStartDate) {
            this.daysInsured =
                this.formatDateValue(
                    this.questionBundles["SelectCoverDuration"].coverStartDate,
                    "normal",
                    "d MMMM yyyy"
                ) +
                " - " +
                this.formatDateValue(
                    this.questionBundles["SelectCoverDuration"].coverEndDate,
                    "normal",
                    "d MMMM yyyy"
                );
        }

        // If coverStartTime exist in SelectCoverDuration questionBundle then set timePeriodInsured in component context
        if (this.questionBundles["SelectCoverDuration"].coverStartTime) {
            this.timePeriodInsured =
                this.$dateTime.findHourByKey(
                    "startRange",
                    this.questionBundles["SelectCoverDuration"].coverStartTime,
                    "label"
                ) +
                " - " +
                this.$dateTime.findHourByKey(
                    "endRange",
                    this.questionBundles["SelectCoverDuration"].coverEndTime,
                    "label"
                );
        }

        // If triggerLevels is empty after matchting with buyPolicy store then get triggerLevels from API
        if (this.triggerLevels.length === 0) {
            this.getTriggerLevels(false);
        }

        if (this.coverageAmount) {
            this.graphLoading = true;
        }

        this.headerDescription =
            customHtml.shortTermDetailHeadingDescription.replaceAll(
                "$(currency)",
                this.getCurrencyCode()
            );
    },
    data() {
        return {
            coverageAmount: "",
            thresholdLevel: "",
            premiumPerDay: "",
            daysInsured: "",
            timePeriodInsured: "",
            payouts: Object.values(this.$policyValues.payoutTypes),
            triggerLevels: [],
            selectedPayout: "Binary",
            calculatingPolicyDetails: false,
            PremiumCancelToken: axios.CancelToken,
            premiumCancel: null,
            GraphCancelToken: axios.CancelToken,
            TriggerLevelCancelToken: axios.CancelToken,
            PayoutDescriptionCancelToken: axios.CancelToken,
            triggerLevelCancel: null,
            graphCancel: null,
            payoutDescriptionCancel: null,
            graphLoading: false,
            graphEntryLength: 0,
            graphLabels: [],
            graphData: [],
            graphError: false,
            selectedTriggerLevel: "",
            payoutCap: "",
            tickSize: null,
            midPoint: null,
            barData: [],
            averageRainfall: "",
            triggerLevelError: false,
            gettingTriggerLevel: false,
            headerDescription: "",
            payoutDescription: "",
            payoutDescriptionTooltip: "",
            gettingPayoutDescription: false,
            shortTermGraphlabels: [],
            shortTermPayoutData: [],
        };
    },
    validations() {
        return {
            coverageAmount: {
                required,
                minValue: minValue(2000),
            },
            thresholdLevel: {
                required,
            },
            premiumPerDay: {
                required,
            },
            selectedPayout: {
                required,
            },
            selectedTriggerLevel: {
                required,
            },
            payoutCap: {
                required,
            },
            averageRainfall: {
                required,
            },
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setBuyPolicyStep", "setQuestionBundle"]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),

        // Get Trigger levels function
        async getTriggerLevels(callForPremium) {
            this.gettingTriggerLevel = true;
            this.triggerLevelError = false;
            let self = this;
            if (this.triggerLevelCancel) {
                this.triggerLevelCancel();
            }
            try {
                let res = await LivePricing.livePricingCore(
                    {
                        product: "parametric_weather",
                        detail: "triggerLevels",
                        startDate:
                            this.getFormattedDateFromMiliseconds(
                                this.questionBundles["SelectCoverDuration"]
                                    .coverStartDate,
                                "date-format",
                                "yyyy-MM-dd"
                            ) || "",
                        endDate:
                            this.getFormattedDateFromMiliseconds(
                                this.questionBundles["SelectCoverDuration"]
                                    .coverEndDate,
                                "date-format",
                                "yyyy-MM-dd"
                            ) || "",
                        cellId: this.cellId,
                        type: this.selectedPayout,
                        startTime:
                            this.questionBundles["SelectCoverDuration"]
                                ?.coverStartTime || "",
                        endTime:
                            this.questionBundles["SelectCoverDuration"]
                                ?.coverEndTime || "",
                        isShortTerm: true,
                        isAbsThreshold: true,
                        mode: "1",
                    },
                    new this.TriggerLevelCancelToken(function (c) {
                        self.triggerLevelCancel = c;
                    })
                );

                this.setTriggerLevels(res.data.body);

                this.gettingTriggerLevel = false;
                if (callForPremium) {
                    this.triggerCalculatePremium();
                }
            } catch (err) {
                if (!axios.isCancel(err)) {
                    this.gettingTriggerLevel = false;
                    this.triggerLevelError = true;
                    this.displayErrorMessage(
                        err?.response?.data?.message ||
                            "Failed to get Trigger Points"
                    );
                }
            }
        },
        // Set Trigger Levels
        setTriggerLevels(data) {
            this.triggerLevels = data.map((d) => {
                return d + " mm";
            });
            this.selectedTriggerLevel = this.triggerLevels[0];
        },
        // Trigger calculate premium call
        triggerCalculatePremium: debounce(function () {
            if (this.checkForPremiumCall) {
                this.calculatingPolicyDetails = true;
                this.graphLoading = true;
                if (this.graphData.length !== 0) {
                    this.graphEntryLength = 0;
                    this.graphLabels = [];
                    this.graphData = [];
                }
                this.calculatePremium().then((res) => {
                    if (!res.error) {
                        this.getGraphData();
                    } else {
                        this.graphLoading = false;
                    }
                });
            } else {
                if (!this.coverageAmount) {
                    this.resetValues();
                }
            }
        }, 300),

        // Calculate Premium Function
        async calculatePremium() {
            this.calculatingPolicyDetails = true;
            let self = this;
            if (this.premiumCancel) {
                this.premiumCancel();
            }
            try {
                let data = {
                    product: "parametric_weather",
                    detail: "premium",
                    startDate:
                        this.getFormattedDateFromMiliseconds(
                            this.questionBundles["SelectCoverDuration"]
                                .coverStartDate,
                            "date-format",
                            "yyyy-MM-dd"
                        ) || "",
                    endDate:
                        this.getFormattedDateFromMiliseconds(
                            this.questionBundles["SelectCoverDuration"]
                                .coverEndDate,
                            "date-format",
                            "yyyy-MM-dd"
                        ) || "",
                    threshold: this.selectedTriggerLevel
                        .replace("%", "")
                        .replace("mm", "")
                        .trim(),
                    limit: this.coverageAmount,
                    cellId: this.cellId,
                    type: this.selectedPayout,
                    startTime:
                        this.questionBundles["SelectCoverDuration"]
                            ?.coverStartTime || "",
                    endTime:
                        this.questionBundles["SelectCoverDuration"]
                            ?.coverEndTime || "",
                    isShortTerm: true,
                    isAbsThreshold: true,
                    mode: "1",
                };
                let res = await LivePricing.livePricingCore(
                    data,
                    new this.PremiumCancelToken(function (c) {
                        self.premiumCancel = c;
                    })
                );
                this.averageRainfall = res.data.body.dataMean;
                this.thresholdLevel = String(res.data.body.threshold);
                this.payoutCap = res.data.body.exit;
                this.midPoint = res.data.body.midPoint;
                this.tickSize = res.data.body.tickSize;
                this.premiumPerDay = this.isNormalUser
                    ? res.data.body.grossPremiumPerDay
                    : res.data.body.netPremiumPerDay;
                this.calculatingPolicyDetails = false;
                return { error: false };
            } catch (err) {
                if (!axios.isCancel(err)) {
                    this.calculatingPolicyDetails = false;
                    this.premiumPerDay = "";
                    this.displayErrorMessage(
                        err?.response?.data?.message ||
                            "Failed to calculate premium"
                    );
                }
                return { error: true };
            }
        },

        // Set Details for CoverDetails Question Bundle in BuyPolicy Store
        setDetails(forNext, forBack, notSaveForDraft) {
            this.updateDataBundleWithValidations({
                bundleName: "CoverDetails",
                bundleData: {
                    coverageAmount: this.coverageAmount,
                    thresholdLevel: this.thresholdLevel,
                    payoutType: this.selectedPayout,
                    payoutCap: this.payoutCap,
                    triggerLevel: this.selectedTriggerLevel,
                    midPoint: this.midPoint,
                    tickSize: this.tickSize,
                    averageRainfall: this.averageRainfall,
                    triggerLevels: this.triggerLevels,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
        },
        // Reset Cover Detail values
        resetValues() {
            if (this.premiumCancel) {
                this.premiumCancel();
            }
            if (this.graphCancel) {
                this.graphCancel();
            }
            if (this.triggerLevelCancel) {
                this.triggerLevelCancel();
            }
            this.calculatingPolicyDetails = false;
            this.coverageAmount = "";
            this.thresholdLevel = "";
            this.premiumPerDay = "";
            this.graphEntryLength = 0;
            this.graphLabels = [];
            this.graphData = [];
            this.payoutCap = "";
            this.tickSize = null;
            this.midPoint = null;
            this.barData = [];
            this.averageRainfall = "";
        },
        // Get Payout Description
        async getPayoutDescription() {
            this.gettingPayoutDescription = true;
            let self = this;
            if (this.payoutDescriptionCancel) {
                this.payoutDescriptionCancel();
            }
            try {
                let data = {
                    product: "parametric_weather",
                    detail: "description",
                    startDate:
                        this.getFormattedDateFromMiliseconds(
                            this.questionBundles["SelectCoverDuration"]
                                .coverStartDate,
                            "date-format",
                            "yyyy-MM-dd"
                        ) || "",
                    endDate:
                        this.getFormattedDateFromMiliseconds(
                            this.questionBundles["SelectCoverDuration"]
                                .coverEndDate,
                            "date-format",
                            "yyyy-MM-dd"
                        ) || "",
                    threshold: this.selectedTriggerLevel
                        .replace("%", "")
                        .replace("mm", "")
                        .trim(),
                    cellId: this.cellId,
                    limit: this.coverageAmount,
                    type: this.selectedPayout,
                    startTime:
                        this.questionBundles["SelectCoverDuration"]
                            ?.coverStartTime || "",
                    endTime:
                        this.questionBundles["SelectCoverDuration"]
                            ?.coverEndTime || "",
                    isShortTerm: true,
                    isAbsThreshold: true,
                    mode: "1",
                };
                let res = await LivePricing.livePricingCore(
                    data,
                    new this.PayoutDescriptionCancelToken(function (c) {
                        self.payoutDescriptionCancel = c;
                    })
                );
                this.payoutDescription = res.data.body[0];
                this.payoutDescriptionTooltip = res.data.body[1];
                this.gettingPayoutDescription = false;
            } catch (err) {
                if (!axios.isCancel(err)) {
                    this.gettingPayoutDescription = false;
                    this.displayErrorMessage(
                        err?.response?.data?.message ||
                            "Failed to get payout description"
                    );
                }
            }
        },

        // Get Graph Data
        async getGraphData() {
            let self = this;
            if (this.graphCancel) {
                this.graphCancel();
            }
            this.graphEntryLength = 0;
            this.graphLabels = [];
            this.graphData = [];
            this.graphLoading = true;
            this.graphError = false;
            let data = {
                product: "parametric_weather",
                detail: "history",
                startDate:
                    this.getFormattedDateFromMiliseconds(
                        this.questionBundles["SelectCoverDuration"]
                            .coverStartDate,
                        "date-format",
                        "yyyy-MM-dd"
                    ) || "",
                endDate:
                    this.getFormattedDateFromMiliseconds(
                        this.questionBundles["SelectCoverDuration"]
                            .coverEndDate,
                        "date-format",
                        "yyyy-MM-dd"
                    ) || "",
                threshold: this.selectedTriggerLevel
                    .replace("%", "")
                    .replace("mm", "")
                    .trim(),
                cellId: this.cellId,
                limit: this.coverageAmount,
                type: this.selectedPayout,
                startTime:
                    this.questionBundles["SelectCoverDuration"]
                        ?.coverStartTime || "",
                endTime:
                    this.questionBundles["SelectCoverDuration"]?.coverEndTime ||
                    "",
                isShortTerm: true,
                isAbsThreshold: true,
                mode: "1",
            };
            try {
                let response = await LivePricing.livePricingCore(
                    data,
                    new this.GraphCancelToken(function (c) {
                        self.graphCancel = c;
                    })
                );
                const configuredData = this.configureShortTermDataPoints(
                    response.data.body
                );
                this.graphData = configuredData.map((d) => {
                    return d[0];
                });

                this.shortTermPayoutData = configuredData.map((d) => {
                    return d[1];
                });

                this.graphEntryLength = this.graphData.length;
                this.graphLoading = false;
            } catch (err) {
                if (!axios.isCancel(err)) {
                    this.graphError = true;
                    this.graphEntryLength = 0;
                    this.graphLabels = [];
                    this.graphData = [];
                    this.graphLoading = false;
                }
            }
        },
        // Configure graph data accordin to need
        configureShortTermDataPoints(data) {
            this.shortTermGraphlabels = Object.keys(data);
            return Object.values(data);
        },
    },
    watch: {
        getPremiumRequestBody: function () {
            this.triggerCalculatePremium();
        },
        selectedPayout: function () {
            this.getTriggerLevels(true);
        },
        selectedTriggerLevel: function (value) {
            if (value) {
                this.getPayoutDescription();
            }
        },
    },
};
</script>
