import QuoteService from "@/services/QuoteService";
import PolicyService from "@/services/PolicyService";
import isEmpty from "lodash.isempty";
import policyConstant from "@/constant/policy";
const state = {
    currentStep: "",
    cellId: null,
    policyProduct: "",
    selectedCurrency: "GBP",
    questionBundles: {
        SelectClient: {},
        SelectLocation: {},
        SelectPeril: "rain",
        SelectCoverDuration: {},
        CoverDetails: {},
        EventLiability: {},
        EventDetails: {},
        EventCancellation: {},
        EventCancellationNew: {},
        Money: {},
        EventCancellationTerrorism: {},
        RegisterUser: {},
        ArtCentralExhibitor: {},
    },
    locationMapZoom: 11,
    products: [],
    productDataMap: {},
    productsFlow: [],
    policyPaymentType: "",
    policyUnderTakings: {},
    back: false,
    policyInstallmentDetails: {},
    policyStatus: {
        status: "",
        heading: "",
        description: "",
        quoteNumber: "",
        retry: false,
    },
    brokerage: null,
    addedQuote: null,
    policyUser: null,
    policyBroker: null,
    currentStepIndex: 0,
    policyProductAddon: null,
    productsJSON: [],
    referredFields: [],
    policyPremiumData: {
        totalPremium: null,
        grossPremium: null,
        ipt: null,
        brokerageAbs: null,
    },
    netPremiumByRiskCode: null,
    recommendedPremium: null,
    isEditingOld: false,
    editingFor: null,
    isDirectBuyFlow: false,
    directBuyComplete: false,
    auditReason: "",
    savedAsDraftId: "",
    quickExtensions: {},
    quickExtensionsPremium: {},
    inValidatedData: [],
    summarySnapshot: {},
    quickExtensionsPremiumLoading: [],
    endorsements: [],
    cloneFrom: null,
    invalidProducts: [],
};

const mutations = {
    addInvalidProducts(s, product) {
        s.invalidProducts = [...s.invalidProducts, product];
    },
    removeInvalidProducts(s, product) {
        s.invalidProducts = s.invalidProducts.filter((p) => p !== product);
    },
    setPolicyProduct(s, value) {
        s.policyProduct = value;
    },
    setCellId(s, value) {
        s.cellId = value;
    },
    setSelectedCurrency(s, value) {
        s.selectedCurrency = value;
        // Note for multicurrency:
        // For multiple products it is required to handle first currency input in the products flow,
        // Currently there is not any field for tracking the first currency input. Steps
        // using questionBundles is not a good idea because of default order of current bundles,
        // Solution: we will need a field for tracking the first currency input in the flow.
    },
    setBuyPolicyStep(s, value) {
        s.back = value.back;
        s.currentStep = value.step;
    },
    setPolicyPaymentType(s, value) {
        s.policyPaymentType = value;
    },
    setPolicyUnderTakings(s, value) {
        s.policyUnderTakings = value;
    },
    setPolicyInstallmentDetails(s, value) {
        s.policyInstallmentDetails = value;
    },
    setPolicyStatus(s, value) {
        s.policyStatus = value;
    },
    setAddedQuote(s, value) {
        s.addedQuote = value;
    },
    setPolicyUser(s, value) {
        if (value && !isEmpty(value)) {
            s.policyUser = value;
        } else {
            s.policyUser = null;
        }
    },
    setPolicyBroker(s, value) {
        s.policyBroker = value;
    },
    setCurrentStepIndex(s, value) {
        s.currentStepIndex = value;
    },
    setQuestionBundle(s, value) {
        s.questionBundles = value;
    },
    setProductsJSON(s, value) {
        s.productsJSON = value;
    },
    setLocationMapZoom(s, value) {
        s.locationMapZoom = value;
    },
    setProductsFlow(s, data) {
        let flow = data.flow.map((d) => {
            return {
                ...d,
                products: [data.productName],
            };
        });
        s.products = [data.productName];
        s.productsFlow = flow;
    },
    setNetPremiumByRiskCode(s, data) {
        s.netPremiumByRiskCode = data;
    },
    addQuickExtensionsPremiumLoading(s, data) {
        s.quickExtensionsPremiumLoading = [
            ...s.quickExtensionsPremiumLoading,
            data,
        ];
    },
    removeQuickExtensionsPremiumLoading(s, data) {
        s.quickExtensionsPremiumLoading =
            s.quickExtensionsPremiumLoading.filter((l) => l !== data);
    },
    resetQuickExtensionsPremiumLoading(s) {
        s.quickExtensionsPremiumLoading = [];
    },
    addToProductsFlow(s, data) {
        let flow = s.productsFlow;
        for (let item of data.flow) {
            let index = flow.findIndex((f) => {
                return f.name === item.name;
            });
            if (index < 0) {
                flow.push({
                    ...item,
                    products: [data.productName],
                });
            } else {
                flow[index].products.push(data.productName);
            }
        }
        s.productsFlow = flow;
    },
    removeProductFromFlow(s, data) {
        let flow = JSON.parse(JSON.stringify(s.productsFlow));
        flow = flow.filter((item) => {
            let filteredProducts = item.products.filter((p) => {
                return !p.includes(data);
            });
            item.products = filteredProducts;
            if (item.products.length === 0) {
                s.questionBundles[item.name] =
                    item.name === "SelectPeril" ? "" : {};
            }
            return item.products.length > 0;
        });
        s.productsFlow = flow;
    },
    setProductDataMap(s, value) {
        s.productDataMap = value;
    },
    addProduct(s, value) {
        if (s.products.indexOf(value) < 0) {
            s.products = [...s.products, value];
        }
    },
    removeProduct(s, value) {
        s.products = s.products.filter((product) => {
            return product !== value;
        });
        delete s.productDataMap[value];
        s.policyProduct = s.products[0] || "";
    },
    setPolicyProductAddon(s, value) {
        s.policyProductAddon = value;
    },
    setReferredFields(s, value) {
        s.referredFields = value;
    },
    setPolicyPremiumData(s, value) {
        s.policyPremiumData = value;
    },
    setRecommendedPremium(s, value) {
        s.recommendedPremium = value;
    },
    setDirectBuyComplete(s, value) {
        s.directBuyComplete = value;
    },
    setIsDirectBuyFlow(s, value) {
        s.isDirectBuyFlow = value;
    },
    setAuditReason(s, value) {
        s.auditReason = value;
    },
    setQuickExtensions(s, value) {
        s.quickExtensions = value;
    },
    setQuickExtensionsPremium(s, value) {
        s.quickExtensionsPremium = value;
    },
    setEndorsements(s, value) {
        s.endorsements = value;
    },
    clearPolicyValues(s, currentStep) {
        s.currentStep = currentStep;
        s.back = false;
        s.cellId = null;
        s.policyProduct = "";
        s.policyPaymentType = "";
        s.policyUnderTakings = {};
        s.policyInstallmentDetails = {};
        s.policyUser = null;
        s.policyBroker = null;
        s.endorsements = [];
        s.questionBundles = {
            SelectLocation: {},
            SelectPeril: "rain",
            SelectCoverDuration: {},
            CoverDetails: {},
            EventLiability: {},
            PublicLiability: {},
            PropertyEquipment: {},
            EventDetails: {},
            EventCancellation: {},
            EventCancellationNew: {},
            EmployersLiability: {},
            Money: {},
            EventCancellationTerrorism: {},
            RegisterUser: {},
            ArtCentralExhibitor: {},
        };
        s.policyStatus = {
            status: "",
            heading: "",
            description: "",
            quoteNumber: "",
            retry: false,
        };
        s.products = [];
        s.productDataMap = {};
        s.productsFlow = [];
        s.currentStepIndex = 0;
        s.policyProductAddon = null;
        s.referredFields = [];
        s.policyPremiumData = {
            totalPremium: null,
            grossPremium: null,
            ipt: null,
            brokerageAbs: null,
        };
        s.addedQuote = null;
        s.recommendedPremium = null;
        s.isEditingOld = false;
        s.brokerage = null;
        s.editingFor = null;
        s.directBuyComplete = false;
        s.isDirectBuyFlow = false;
        s.savedAsDraftId = "";
        s.selectedCurrency = "GBP";
        s.quickExtensions = {};
        s.quickExtensionsPremium = {};
        s.inValidatedData = [];
        s.invalidProducts = [];
        s.summarySnapshot = {};
        s.quickExtensionsPremiumLoading = [];
        s.cloneFrom = null;
    },
    setCloneFrom(s, value) {
        s.cloneFrom = value;
    },
    setIsEditingOld(s, value) {
        s.isEditingOld = value;
    },
    setBrokerage(s, value) {
        s.brokerage = value;
    },
    setEditingFor(s, value) {
        s.editingFor = value;
    },
    addIsInvalidData(s, value) {
        s.inValidatedData = [...s.inValidatedData, value];
    },
    removeIsInvalidData(s, value) {
        s.inValidatedData = s.inValidatedData.filter((d) => d !== value);
    },
    setSummarySnapshot(s) {
        s.summarySnapshot = {
            cellId: s.cellId,
            policyProduct: s.policyProduct,
            questionBundles: JSON.parse(JSON.stringify(s.questionBundles)),
            selectedCurrency: s.selectedCurrency,
            products: JSON.parse(JSON.stringify(s.products)),
            productDataMap: JSON.parse(JSON.stringify(s.productDataMap)),
            productsFlow: JSON.parse(JSON.stringify(s.productsFlow)),
            addedQuote: JSON.parse(JSON.stringify(s.addedQuote)),
            policyUser: JSON.parse(JSON.stringify(s.policyUser)),
            policyBroker: JSON.parse(JSON.stringify(s.policyBroker)),
            currentStepIndex: s.currentStepIndex,
            policyProductAddon: s.policyProductAddon,
            policyPremiumData: JSON.parse(JSON.stringify(s.policyPremiumData)),
            netPremiumByRiskCode: s.netPremiumByRiskCode,
            recommendedPremium: s.recommendedPremium,
            isEditingOld: s.isEditingOld,
            editingFor: s.editingFor,
            quickExtensions: s.quickExtensions,
            quickExtensionsPremium: s.quickExtensionsPremium,
            cloneFrom: s.cloneFrom,
            inValidatedData: JSON.parse(JSON.stringify(s.inValidatedData)),
            invalidProducts: JSON.parse(JSON.stringify(s.invalidProducts)),
        };
    },
    restoreFromSummarySnapshot(s) {
        const { summarySnapshot } = s;
        s.cellId = summarySnapshot.cellId;
        s.policyProduct = summarySnapshot.policyProduct;
        s.questionBundles = JSON.parse(
            JSON.stringify(summarySnapshot.questionBundles)
        );
        s.selectedCurrency = summarySnapshot.selectedCurrency;
        s.products = JSON.parse(JSON.stringify(summarySnapshot.products));
        s.productDataMap = JSON.parse(
            JSON.stringify(summarySnapshot.productDataMap)
        );
        s.productsFlow = JSON.parse(
            JSON.stringify(summarySnapshot.productsFlow)
        );
        s.addedQuote = JSON.parse(JSON.stringify(summarySnapshot.addedQuote));
        s.policyUser = JSON.parse(JSON.stringify(summarySnapshot.policyUser));
        s.policyBroker = JSON.parse(
            JSON.stringify(summarySnapshot.policyBroker)
        );
        s.currentStepIndex = summarySnapshot.currentStepIndex;
        s.policyProductAddon = summarySnapshot.policyProductAddon;
        s.policyPremiumData = JSON.parse(
            JSON.stringify(summarySnapshot.policyPremiumData)
        );
        s.netPremiumByRiskCode = summarySnapshot.netPremiumByRiskCode;
        s.recommendedPremium = summarySnapshot.recommendedPremium;
        s.isEditingOld = summarySnapshot.isEditingOld;
        s.editingFor = summarySnapshot.editingFor;
        s.cloneFrom = summarySnapshot.cloneFrom;
        s.quickExtensions = JSON.parse(
            JSON.stringify(summarySnapshot.quickExtensions)
        );
        s.quickExtensionsPremium = JSON.parse(
            JSON.stringify(summarySnapshot.quickExtensionsPremium)
        );
        s.inValidatedData = JSON.parse(
            JSON.stringify(summarySnapshot.inValidatedData)
        );
        s.invalidProducts = JSON.parse(
            JSON.stringify(summarySnapshot.invalidProducts)
        );
    },
};

const getters = {
    checkFlowComplete: (state) => {
        let completed = true;
        if (state.productsFlow.length === 0) {
            completed = false;
        } else {
            state.productsFlow.forEach((item) => {
                if (!state.questionBundles[item.name]) {
                    completed = false;
                } else {
                    if (state.inValidatedData.length) {
                        completed = false;
                    } else if (
                        item.name !== "SelectClient" &&
                        typeof state.questionBundles[item.name] === "object" &&
                        isEmpty(state.questionBundles[item.name])
                    ) {
                        completed = false;
                    }
                }
            });
        }
        return completed;
    },
    formattedCommonFields(state) {
        let data = {};
        policyConstant.isCommonBundle.forEach((bundle) => {
            if (!isEmpty(state.questionBundles[bundle])) {
                data = {
                    ...data,
                    ...state.questionBundles[bundle],
                };
            }
        });
        return data;
    },
    // Determines whether the currency input should be disabled in product flows
    isCurrencyInputDisabled(state) {
        const { currentStep, productsFlow } = state;
        const { currencySelectStep } = policyConstant;

        // Disable currency input if editing in "policy" mode or if the current step is not part of currency selection steps
        if (
            state.editingFor === "policy" ||
            !currencySelectStep.includes(currentStep)
        ) {
            return true;
        }

        // Find the index of the current step in the products flow
        const currentStepIndex = productsFlow.findIndex(
            (flowStep) => flowStep.name === currentStep
        );

        // Check if any previous currency selection step exists before the current step
        const hasPreviousCurrencyStep = currencySelectStep
            .filter((step) => step !== currentStep)
            .some((step) => {
                const previousStepIndex = productsFlow.findIndex(
                    (flowStep) => flowStep.name === step
                );
                return (
                    previousStepIndex !== -1 &&
                    previousStepIndex < currentStepIndex
                );
            });

        // Disable currency input if a previous step is found, otherwise enable
        return hasPreviousCurrencyStep;
    },
};

const actions = {
    updateDataBundleWithValidations({ state, commit }, component) {
        commit("setQuestionBundle", {
            ...state.questionBundles,
            [component.bundleName]: {
                ...state.questionBundles[component.bundleName],
                ...component.bundleData,
            },
        });
        component.self.v$.$touch();
        Object.keys(component.self.$options.validations()).forEach(
            (fieldName) => {
                commit("removeIsInvalidData", fieldName);
            }
        );
        Object.values(component.self.v$.$errors).forEach((invalidField) => {
            commit("addIsInvalidData", invalidField.$property);
        });
    },
    async addQuote({ state, getters, rootState, rootGetters }, data) {
        let dataBody = {
            addressLine1: state.questionBundles["SelectLocation"].addressLine1,
            addressLine2: state.questionBundles["SelectLocation"].venueName,
            city: state.questionBundles["SelectLocation"].city,
            country: state.questionBundles["SelectLocation"].country,
            zipcode: state.questionBundles["SelectLocation"].zipcode,
            region: state.questionBundles["SelectLocation"].region,
            policyName: state.questionBundles["EventDetails"].eventName || "",
            brokerId:
                state.policyBroker && state.policyBroker?.attributes?.brokerId
                    ? state.policyBroker.attributes.brokerId[0]
                    : null,
            underwriterId:
                rootState.auth.userUnderwriterId ||
                rootState.subDomainDetail.underwriterId,
            ...(!data.id && {
                ...(rootGetters["auth/userAttributes"].landlineNumber && {
                    landlineNumber:
                        rootGetters["auth/userAttributes"].landlineNumber,
                }),
                mobileNumber: rootGetters["auth/userAttributes"].mobileNumber,
                creatorUserId: rootState.auth.userId,
                ...(data.paymentOption && {
                    paymentOption: data.paymentOption,
                }),
                ...(data.noOfInstallments && {
                    noOfInstallments: data.noOfInstallments,
                }),
                ...(data.emi && { emi: data.emi }),
                ...(data.premiumPayableNow && {
                    premiumPayableNow: data.premiumPayableNow,
                }),
                ...(data.paymentMode && { paymentMode: data.paymentMode }),
                ...(data.paymentStatus && {
                    paymentStatus: data.paymentStatus,
                }),
            }),
            undertakings: data.checkAllSelected,
            cellId: state.cellId,
            brokerage: state.brokerage,
            brokerageAbs: state.policyPremiumData.brokerageAbs || null,
            referred: state.referredFields.length > 0,
            endorsements: state.endorsements,
            grossPremium: !state.inValidatedData.length
                ? state.policyPremiumData.grossPremium
                : null,
            ipt: !state.inValidatedData.length
                ? state.policyPremiumData.ipt
                : null,
            premium:
                state.referredFields.length === 0 &&
                !state.inValidatedData.length
                    ? state.policyPremiumData.totalPremium
                    : null,
            ...(state.referredFields.length > 0 && {
                recommendedPremium: !state.inValidatedData.length
                    ? state.policyPremiumData.totalPremium
                    : null,
            }),
            products: JSON.stringify({
                ...data.productsData,
                common_fields: {
                    ...getters.formattedCommonFields,
                },
            }),
            netPremiumByRiskCode: state.netPremiumByRiskCode,
            inValidatedData: state.inValidatedData,
            invalidProducts: state.invalidProducts,
            ...(rootGetters["auth/isBroker"] && {
                maximumCommission:
                    rootGetters["auth/userAttributes"]?.maximumCommission,
            }),
            referredFields: state.referredFields?.map(
                (val) => val.referredField
            ),
        };
        if (rootGetters["auth/isNormalUser"]) {
            dataBody["customerName"] = (
                (rootState.auth?.user?.firstName || "") +
                " " +
                (rootState.auth?.user?.lastName || "")
            ).trim();
            dataBody["userId"] = rootState.auth.userId;
        } else {
            dataBody["customerName"] = state.policyUser
                ? state.policyUser.firstName + " " + state.policyUser.lastName
                : "";
            dataBody["userId"] = state.policyUser ? state.policyUser.id : "";
        }
        if (data.draft) {
            dataBody["quoteStatus"] = "INCOMPLETE_SUBMISSION";
            dataBody["lastPage"] = state.currentStep || "";
        } else {
            if (dataBody["userId"]) {
                dataBody["quoteStatus"] = "GENERATED";
                if (state.referredFields.length > 0) {
                    dataBody["quoteStatus"] = "REFERRED";
                }
                dataBody["lastPage"] = "CoverSummary";
            } else {
                dataBody["quoteStatus"] = "INCOMPLETE_SUBMISSION";
                dataBody["lastPage"] = "SelectClient";
            }
        }
        if (
            state.selectedCurrency &&
            state.selectedCurrency in rootState.referenceData.currencyList
        ) {
            dataBody["currency"] = {
                currencyCode: state.selectedCurrency,
                currencySymbol:
                    rootState.referenceData.currencyList[state.selectedCurrency]
                        .symbol,
            };
        } else {
            dataBody["currency"] = { currencyCode: "GBP", currencySymbol: "£" };
        }
        if (!state.editingFor && state.cloneFrom !== null) {
            if (state.cloneFrom?.from === "quote") {
                dataBody["oldQuoteReferenceId"] = state.cloneFrom?.id || "";
            } else {
                dataBody["oldPolicyReferenceId"] = state.cloneFrom?.id || "";
            }
        }
        try {
            let response = null;
            if (data.id) {
                response = await QuoteService.updateQuote(data.id, {
                    ...state.addedQuote,
                    ...dataBody,
                });
            } else {
                response = await QuoteService.addQuote(dataBody);
            }
            if (data.draft) {
                state.savedAsDraftId = response.data.id;
                state.addedQuote = response.data;
            }
            return response;
        } catch (err) {
            return { error: true, data: err };
        }
    },
    async updatePolicy({ state, getters, rootGetters }, data) {
        let isReferred = state.referredFields.length > 0;
        let dataBody = {
            ...state.addedQuote,
            addressLine1: state.questionBundles["SelectLocation"].addressLine1,
            addressLine2: state.questionBundles["SelectLocation"].venueName,
            city: state.questionBundles["SelectLocation"].city,
            country: state.questionBundles["SelectLocation"].country,
            zipcode: state.questionBundles["SelectLocation"].zipcode,
            region: state.questionBundles["SelectLocation"].region,
            undertakings: data.checkAllSelected,
            cellId: state.cellId,
            brokerage: state.brokerage,
            brokerageAbs: state.policyPremiumData.brokerageAbs || null,
            referred: isReferred,
            grossPremium: state.policyPremiumData.grossPremium,
            ipt: state.policyPremiumData.ipt,
            premium: !isReferred ? state.policyPremiumData.totalPremium : null,
            ...(isReferred && {
                recommendedPremium: state.policyPremiumData.totalPremium,
            }),
            products: JSON.stringify({
                ...data.productsData,
                common_fields: {
                    ...getters.formattedCommonFields,
                },
            }),
            ...(state.auditReason && { auditReason: state.auditReason }),
            netPremiumByRiskCode: state.netPremiumByRiskCode,
            endorsements: state.endorsements,
            lastPage: "CoverSummary",
            brokerId:
                state.policyBroker && state.policyBroker?.attributes?.brokerId
                    ? state.policyBroker.attributes.brokerId[0]
                    : null,
            userId: state.policyUser ? state.policyUser.id : "",
            customerName: state.policyUser
                ? state.policyUser.firstName + " " + state.policyUser.lastName
                : "",
            ...(rootGetters["auth/isBroker"] && {
                maximumCommission:
                    rootGetters["auth/userAttributes"]?.maximumCommission,
            }),
            referredFields: state.referredFields?.map(
                (val) => val.referredField
            ),
        };
        if (isReferred) {
            dataBody["policyStatus"] = "REFERRED";
        }
        try {
            return await PolicyService.updatePolicy(data.id, dataBody);
        } catch (err) {
            return { error: true, data: err };
        }
    },
    takeToStep({ state, commit }, index) {
        commit("setCurrentStepIndex", index);
        commit("setBuyPolicyStep", {
            step: state.productsFlow[state.currentStepIndex].name,
            back: false,
        });
    },
    takeToNextStep({ state, rootState, commit, dispatch }) {
        if (state.currentStepIndex + 1 >= state.productsFlow.length) {
            if (!state.isDirectBuyFlow) {
                commit("setCurrentStepIndex", 0);
            }
            commit("setBuyPolicyStep", { step: "CoverSummary", back: false });
        } else {
            commit("setCurrentStepIndex", state.currentStepIndex + 1);
            if (
                rootState.auth.isAuthenticated &&
                state.productsFlow[state.currentStepIndex].name ===
                    "RegisterUser"
            ) {
                dispatch("takeToNextStep");
                // this.takeToNextStep();
            } else {
                commit("setBuyPolicyStep", {
                    step: state.productsFlow[state.currentStepIndex].name,
                    back: false,
                });
            }
        }
    },
    takeToPreviousStep({ state, commit }) {
        if (state.currentStepIndex > 0) {
            commit("setCurrentStepIndex", state.currentStepIndex - 1);
            commit("setBuyPolicyStep", {
                step: state.productsFlow[state.currentStepIndex].name,
                back: true,
            });
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
