<template>
    <div id="appContent">
        <loader v-if="$store && $store.state.isLoading" />
        <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Loader from "@/components/Common/FullScreenLoader.vue";
import WebsocketService from "@/services/WebsocketService";
export default {
    data() {
        return {
            commentWebhookHelper: null,
        };
    },
    created() {
        this.commentWebhookHelper = new WebsocketService();
    },
    mounted() {
        this.initialAuthenticatedHandler();
        this.addMetaInfo();
    },
    components: {
        Loader,
    },
    computed: {
        ...mapState("auth", ["isAuthenticated", "userId"]),
        ...mapGetters("auth", [
            "userAttributes",
            "isUnderwriter",
            "isAdmin",
            "isBroker",
            "isClaims",
        ]),
    },
    watch: {
        isAuthenticated: function () {
            this.initialAuthenticatedHandler();
        },
    },
    methods: {
        initialAuthenticatedHandler() {
            if (this.isAuthenticated) {
                this.updateToken();
                this.commentWebhookHelper.webhookConnect(
                    () =>
                        this.webhookHandler(
                            "commentWebhookHelper",
                            "/topic/commentAdded/",
                            this.commentUpdatedHelper
                        ),
                    "commentServiceUrl"
                );
            }
        },
        updateToken() {
            this.$auth.updateToken();
        },
        addMetaInfo() {
            document.title = this.subDomainDetail.name;
            document
                .querySelector('meta[name="description"]')
                .setAttribute(
                    "content",
                    `${this.subDomainDetail.name} | Special Event Insurance | Parametric Insurance | London`
                );
            document
                .querySelector('meta[property="og:image"]')
                .setAttribute(
                    "content",
                    `${
                        this.subDomainDetail.favicon ||
                        this.subDomainDetail.image
                    }`
                );
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.head.appendChild(link);
            }
            link.href = `${
                this.subDomainDetail.favicon || this.subDomainDetail.image
            }`;
        },
        commentConditionHandler(response) {
            if (this.isAdmin) {
                return true;
            } else if (this.isUnderwriter) {
                return (
                    this.subDomainDetail.underwriterId ===
                    response.underwriterId
                );
            } else if (this.isBroker) {
                return this.userAttributes.brokerId === response.brokerId;
            } else if (this.isClaims) {
                return !!response.commentPolicyId;
            } else {
                return false;
            }
        },
        commentUpdatedHelper(data) {
            let response = JSON.parse(data.body);
            if (
                this.userId !== response.commentedById &&
                this.commentConditionHandler(response)
            ) {
                let redirectPath =
                    window.location.origin +
                    `/${
                        response.commentQuoteId
                            ? "quotes/" + response.commentQuoteId
                            : "policies/" + response.commentPolicyId
                    }`;
                this.$notify({
                    group: "BEV",
                    title: "Info",
                    text: `A new comment has beed added to ${
                        response.commentQuoteId
                            ? "quote: <a href='" +
                              redirectPath +
                              "' target='_blank'>" +
                              response.commentQuoteNumber +
                              "</a>"
                            : "policy: <a href='" +
                              redirectPath +
                              "' target='_blank'>" +
                              response.commentPolicyNumber +
                              "</a>"
                    }.`,
                    type: "info",
                    duration: -1,
                });
            }
        },
    },
    beforeUnmount() {
        this.commentWebhookHelper?.socketCloseFunction();
    },
};
</script>
