<template>
    <div class="row px-15px">
        <user-detail
            v-if="details.userId"
            :class="showBrokerInfo ? 'col-lg-4' : 'col-lg-6'"
            :details="details"
            :detailFor="'policyUser'"
            :setUserDetails="setUserDetails"
            :setBrokerDetails="setBrokerDetails"
            :key="details.customerName + userUpdatedCounter"
        ></user-detail>
        <user-detail
            v-if="showBrokerInfo"
            :class="details.userId ? 'col-lg-4' : 'col-lg-6'"
            :details="details"
            :key="details.brokerId || 'brokerId'"
            :detailFor="'createdByUser'"
            :setUserDetails="setUserDetails"
            :setBrokerDetails="setBrokerDetails"
        ></user-detail>
        <policy-detail
            :class="showBrokerInfo && details.userId ? 'col-lg-4' : 'col-lg-6'"
            :quoteInfo="quoteInfo"
        ></policy-detail>
    </div>
</template>
<script>
/**
 * Quote or Policy User info block
 *
 * @param {Object} details details for Quote or Policy
 * @param {Function} setBrokerDetails funtion to set broker details
 */
import UserDetail from "@/components/Views/Details/InfoBlock/UserDetail.vue";
import PolicyDetail from "@/components/Views/Details/InfoBlock/PolicyDetail.vue";
export default {
    props: {
        details: Object,
        setUserDetails: Function,
        setBrokerDetails: Function,
        detailFor: String,
        userUpdatedCounter: Number,
    },
    components: {
        UserDetail,
        PolicyDetail,
    },
    computed: {
        showBrokerInfo() {
            return (
                this.details.creatorUserId &&
                this.details.creatorUserId !== this.details.userId
            );
        },
        quoteInfo() {
            if (this.isObjectEmpty(this.details)) {
                return {};
            } else {
                return {
                    "Quote Number": this.details.quoteNumber,
                    ...(this.details.policyNumber && {
                        "Policy Number": this.details.policyNumber,
                    }),
                    "Applied On": this.formatDateValue(
                        this.details.creationTime,
                        "normal",
                        "d MMMM yyyy"
                    ),
                    ...(this.detailFor === "quote" &&
                        this.details.expiryDate && {
                            "Expires On": this.formatDateValue(
                                this.details.expiryDate,
                                "normal",
                                "d MMMM yyyy"
                            ),
                        }),
                    ...(this.details.policyStatus && {
                        "Policy Status": Object.values(
                            this.$policyValues.policyStatus
                        ).find((status) => {
                            return status.value === this.details.policyStatus;
                        }).text,
                    }),
                };
            }
        },
    },
};
</script>
