<template>
    <div class="card-content">
        <div class="d-flex align-items-start justify-content-between">
            <div class="d-flex align-items-center w-100">
                <circle-image
                    v-if="imageInfo && !isObjectEmpty(imageInfo)"
                    :imageInfo="{
                        imageColor: imageInfo.imageColor,
                        iconUrl: imageInfo.imageUrl,
                        width: imageInfo.width,
                        height: imageInfo.height,
                    }"
                ></circle-image>
                <div class="card-info w-100">
                    <slot name="card-header-info"></slot>
                </div>
            </div>
            <bev-image
                :addBackground="true"
                :backgroundColor="'white'"
                :imageUrl="$imagePath.notificationRead"
                :imageAlt="'notification-read'"
                :width="'20px'"
                :height="'20px'"
                class="cursor-pointer"
                v-if="showNotificationIcon"
            ></bev-image>
        </div>
        <div class="description">
            <slot name="card-content"></slot>
        </div>
    </div>
</template>

<script>
/**
 * To show different cards content on BEV using card wrapper
 * 
 * @param {Boolean} showNotificationIcon to show/hide notfication icon
 * @param {Object<{
                    imageColor: 'white',
                    imageUrl: '',
                    width: '20px',
                    height: '20px'
                }>} imageInfo for circle-image props
 */
export default {
    props: {
        imageInfo: {
            type: Object,
            default: function () {
                return {
                    imageColor: "white",
                    imageUrl: "",
                    width: "20px",
                    height: "20px",
                };
            },
        },
        showNotificationIcon: Boolean,
    },
};
</script>
