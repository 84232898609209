<template>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-10px">
        <selectable-card
            :clickHandler="saveSelectedProduct.bind(this, product.name)"
            class="quick-quote-button"
        >
            <div class="d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <p class="text-activeColorV2 font-14">Quick Quote</p>
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="bevColors.activeColorV2"
                        :imageAlt="'view-more'"
                        :imageUrl="$imagePath.goToArrow"
                        :width="'20px'"
                        :height="'20px'"
                        class="ml-10px cursor-pointer"
                    ></bev-image>
                </div>
            </div>
            <circle-image
                :imageInfo="{
                    imageColor: 'var(--cardColorV2)',
                    iconUrl: $policyValues.products[product.name]?.image,
                    width: '50px',
                    height: '50px',
                }"
                :width="'85px'"
                :height="'85px'"
                class="m-auto"
            ></circle-image>
            <div class="text-center mt-20px">
                <p class="font-16 fw-600">{{ product.label }}</p>
            </div>
        </selectable-card>
    </div>
</template>

<script>
import SelectableCard from "@/components/Common/Card/SelectableCard";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
    name: "QuickQuoteButton",
    props: {
        product: Object,
        productJSON: Object,
        currentBroker: Object,
    },
    components: {
        SelectableCard,
    },
    computed: {
        ...mapState("buyPolicy", [
            "policyProduct",
            "productsFlow",
            "currentStepIndex",
        ]),
        ...mapGetters("auth", ["isNormalUser", "isBroker"]),
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setPolicyProduct",
            "setBuyPolicyStep",
            "setCurrentStepIndex",
            "setProductsFlow",
            "setPolicyBroker",
        ]),
        saveSelectedProduct(selectedProduct) {
            if (this.isBroker) {
                this.setPolicyBroker(this.currentBroker);
            }
            let filteredFlow = this.product.questionBundleSequence.filter(
                (seq) => {
                    return seq.name !== "SelectClient";
                }
            );
            this.setProductsFlow({
                flow: filteredFlow,
                productName: selectedProduct,
            });

            this.setCurrentStepIndex(0);
            this.setPolicyProduct(selectedProduct);
            this.setBuyPolicyStep({
                step: this.productsFlow[this.currentStepIndex].name,
                back: false,
            });
            this.$router.push({
                path: "buy-policy",
                query: {
                    type: "quick",
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.quick-quote-button {
    background: var(--cardBgColorV1);
    color: var(--cardColorV1);
    box-shadow: var(--cardBoxShadowV1);
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;
    p {
        transition: all 0.5s ease-in-out;
    }
    &:hover {
        border: 1px solid var(--activeColorV1);
        p {
            color: var(--activeColorV1);
        }
        .image-circle {
            border-color: var(--activeColorV1) !important;
        }
        :deep(.svg-image) {
            background: var(--activeColorV1) !important;
        }
        // .svg-image {
        //     background: var(--activeColorV1) !important;
        // }
    }
}
</style>
