import { store } from "@/store/index";
import defaultColors from "@/constant/defaultColors";
import WhiteLabellingMap from "@/whiteLabellingMap";

export default class WhiteLabelling {
    static getSubDomain() {
        var currentDomainData = window.location.host.split(".");
        if (currentDomainData[0] === "www") {
            currentDomainData.shift();
        }
        var domain = currentDomainData[2]
            ? currentDomainData[1]
            : currentDomainData[0];
        var subDomain = currentDomainData[2] ? currentDomainData[0] : false;
        if (WhiteLabellingMap[domain]) {
            var subDomainDetail = {
                ...(subDomain
                    ? WhiteLabellingMap[domain]?.subDomains?.[subDomain]
                    : WhiteLabellingMap[domain]?.whiteLabellingData || {}),
                ...(subDomain && { subDomain: subDomain }),
                domain: domain,
                ...WhiteLabellingMap[domain].props,
            };
            store.commit("setSubDomainDetail", subDomainDetail);
            let themeColors = defaultColors;
            if (subDomainDetail.themeColors) {
                themeColors = {
                    ...themeColors,
                    ...subDomainDetail.themeColors,
                };
            }
            Object.keys(themeColors).forEach((color) => {
                document.documentElement.style.setProperty(
                    `--${color}`,
                    themeColors[color]
                );
            });
        } else {
            store.commit("setSubDomainDetail", {});
        }
        return subDomain;
    }

    static getAuthUrl() {
        return `https://${store.state.subDomainDetail.authDomain}.${store.state.subDomainDetail.domain}.com/auth`;
    }
}
