// Sub Domain wise data map
export default {
    birdseyeviewtechnologies: {
        subDomains: {
            local: {
                name: "Birdseyeview Local",
                image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "nonproddev-sso",
                allowPayment: false,
                brokerId: "dev_broker",
                underwriterId: "arch",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprod-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            nonproddev: {
                name: "BirdsEyeView",
                image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "nonproddev-sso",
                allowPayment: false,
                brokerId: "dev_broker",
                underwriterId: "arch",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprod-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            staging: {
                name: "BirdsEyeView",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "staging-sso",
                allowPayment: true,
                brokerId: "staging_broker",
                underwriterId: "arch",
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            nonprodstage: {
                name: "BirdsEyeView",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "nonprodstage-sso",
                allowPayment: true,
                brokerId: "staging_broker",
                underwriterId: "arch",
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            app: {
                name: "BirdsEyeView",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "sso",
                allowPayment: true,
                brokerId: "live_broker",
                underwriterId: "arch",
                orgType: "underwriter",
                clientEmail: "ArchIQ@archinsurance.com",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            howden: {
                authDomain: "howden-sso",
                name: "Howden",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden.png",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden_favicon.png",
                allowPayment: true,
                brokerId: "howden",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                underwriterId: "arch",
                hideNormalBuy: true,
                orgType: "broker",
                clientEmail: "admin@birdseyeviewtechnologies.com",
            },
            howdens: {
                authDomain: "howdens-sso",
                name: "Howden",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden.png",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden_favicon.png",
                allowPayment: true,
                brokerId: "howden",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                underwriterId: "arch",
                hideNormalBuy: true,
                orgType: "broker",
                clientEmail: "admin@birdseyeviewtechnologies.com",
            },
            "arch-stage": {
                authDomain: "arch-stage-sso",
                name: "ArchIQ",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                hideProductDashboard: true,
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                themeColors: {
                    productHeadingBgColor: "#021b33",
                    productHeadingColor: "white",
                    gradientShade1: "#112943",
                    gradientShade2: "#021b33",
                    loaderColor: "#e1ebf7",
                    loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                    navigationItemsBgColor: "transparent",
                    navigationItemsColor: "white",
                    cardColorV1: "white",
                    cardBgColorV1: "#00274a",
                    cardBoxShadowV1: "none",
                    cardColorV2: "white",
                    cardBgColorV2: "#021b33",
                    cardBoxShadowV2: "none",
                    dashboardColor: "white",
                    headerColor: "white",
                    headerBgColor: "#112943",
                    headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                    sidebarBg: "#00274a",
                    sidebarItemColor: "white",
                    sidebarItemActiveColor: "#5bc2e7",
                    sidebarItemActiveBgColor: "transparent",
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    ntuStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    ntuStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                    endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                    commentActionBoxBgColor: "white",
                    dropdownBgColor: "#193553",
                    dropdownColor: "white",
                    borderColorOverCardV1: "white",
                    borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                    inputColor: "white",
                    contactUsTextColor: "white",
                    tooltipTextColor: "#112943",
                    selectableRadioTagBgColor: "#021b33",
                    selectableRadioTagColor: "white",
                    selectableRadioTagHoverColor: "white",
                    selectableRadioTagActiveColor: "#021b33",
                    checkBoxInputTextColor: "white",
                    checkBoxInputBorderColor: "white",
                    checkBoxInputCheckmarkColor: "#112943",
                    checkBoxInputActiveBgColor: "#5bc2e7",
                    analyticsMetricHeadingColor: "white",
                    whiteBorderedButtonBgColor: "white",
                    rangleSliderValueColor: "#112943",
                    paginationTextColor: "white",
                    paginationControllerTextColor: "white",
                    paginationHoverTextColor: "#112943",
                    summarySuggestionDescriptionTextColor: "white",
                    searchBarColor: "white",
                    searchBarLightBorder: "#fff",
                    searchBarBgColor: "#021b33",
                    searchBarBoxShadow: "#021b33",
                    searchBarPlaceholderColor: "#77828e",
                    selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                    notificationPopColor: "white",
                    notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    notificationPopBgColor: "#193553",
                },
                dashboardBannerImages: [
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            arch: {
                authDomain: "arch-sso",
                name: "ArchIQ",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                hideProductDashboard: true,
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                themeColors: {
                    productHeadingBgColor: "#021b33",
                    productHeadingColor: "white",
                    gradientShade1: "#112943",
                    gradientShade2: "#021b33",
                    loaderColor: "#e1ebf7",
                    loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                    navigationItemsBgColor: "transparent",
                    navigationItemsColor: "white",
                    cardColorV1: "white",
                    cardBgColorV1: "#00274a",
                    cardBoxShadowV1: "none",
                    cardColorV2: "white",
                    cardBgColorV2: "#021b33",
                    cardBoxShadowV2: "none",
                    dashboardColor: "white",
                    headerColor: "white",
                    headerBgColor: "#112943",
                    headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                    sidebarBg: "#00274a",
                    sidebarItemColor: "white",
                    sidebarItemActiveColor: "#5bc2e7",
                    sidebarItemActiveBgColor: "transparent",
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    ntuStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    ntuStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                    endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                    commentActionBoxBgColor: "white",
                    dropdownBgColor: "#193553",
                    dropdownColor: "white",
                    borderColorOverCardV1: "white",
                    borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                    inputColor: "white",
                    contactUsTextColor: "white",
                    tooltipTextColor: "#112943",
                    selectableRadioTagBgColor: "#021b33",
                    selectableRadioTagColor: "white",
                    selectableRadioTagHoverColor: "white",
                    selectableRadioTagActiveColor: "#021b33",
                    checkBoxInputTextColor: "white",
                    checkBoxInputBorderColor: "white",
                    checkBoxInputCheckmarkColor: "#112943",
                    checkBoxInputActiveBgColor: "#5bc2e7",
                    analyticsMetricHeadingColor: "white",
                    whiteBorderedButtonBgColor: "white",
                    rangleSliderValueColor: "#112943",
                    paginationTextColor: "white",
                    paginationControllerTextColor: "white",
                    paginationHoverTextColor: "#112943",
                    summarySuggestionDescriptionTextColor: "white",
                    searchBarColor: "white",
                    searchBarLightBorder: "#fff",
                    searchBarBgColor: "#021b33",
                    searchBarBoxShadow: "#021b33",
                    searchBarPlaceholderColor: "#77828e",
                    selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                    notificationPopColor: "white",
                    notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    notificationPopBgColor: "#193553",
                },
                dashboardBannerImages: [
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            prod: {
                authDomain: "prod-sso",
                name: "ArchIQ",
                image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                hideProductDashboard: true,
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                themeColors: {
                    productHeadingBgColor: "#021b33",
                    productHeadingColor: "white",
                    gradientShade1: "#112943",
                    gradientShade2: "#021b33",
                    loaderColor: "#e1ebf7",
                    loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                    navigationItemsBgColor: "transparent",
                    navigationItemsColor: "white",
                    cardColorV1: "white",
                    cardBgColorV1: "#00274a",
                    cardBoxShadowV1: "none",
                    cardColorV2: "white",
                    cardBgColorV2: "#021b33",
                    cardBoxShadowV2: "none",
                    dashboardColor: "white",
                    headerColor: "white",
                    headerBgColor: "#112943",
                    headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                    sidebarBg: "#00274a",
                    sidebarItemColor: "white",
                    sidebarItemActiveColor: "#5bc2e7",
                    sidebarItemActiveBgColor: "transparent",
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    ntuStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    ntuStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                    endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                    commentActionBoxBgColor: "white",
                    dropdownBgColor: "#193553",
                    dropdownColor: "white",
                    borderColorOverCardV1: "white",
                    borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                    inputColor: "white",
                    contactUsTextColor: "white",
                    tooltipTextColor: "#112943",
                    selectableRadioTagBgColor: "#021b33",
                    selectableRadioTagColor: "white",
                    selectableRadioTagHoverColor: "white",
                    selectableRadioTagActiveColor: "#021b33",
                    checkBoxInputTextColor: "white",
                    checkBoxInputBorderColor: "white",
                    checkBoxInputCheckmarkColor: "#112943",
                    checkBoxInputActiveBgColor: "#5bc2e7",
                    analyticsMetricHeadingColor: "white",
                    whiteBorderedButtonBgColor: "white",
                    rangleSliderValueColor: "#112943",
                    paginationTextColor: "white",
                    paginationControllerTextColor: "white",
                    paginationHoverTextColor: "#112943",
                    summarySuggestionDescriptionTextColor: "white",
                    searchBarColor: "white",
                    searchBarLightBorder: "#fff",
                    searchBarBgColor: "#021b33",
                    searchBarBoxShadow: "#021b33",
                    searchBarPlaceholderColor: "#77828e",
                    selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                    notificationPopColor: "white",
                    notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    notificationPopBgColor: "#193553",
                },
                dashboardBannerImages: [
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
            },
            archiq: {
                authDomain: "archiq-sso",
                name: "ArchIQ",
                image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                hideProductDashboard: true,
                themeColors: {
                    productHeadingBgColor: "#021b33",
                    productHeadingColor: "white",
                    gradientShade1: "#112943",
                    gradientShade2: "#021b33",
                    loaderColor: "#e1ebf7",
                    loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                    navigationItemsBgColor: "transparent",
                    navigationItemsColor: "white",
                    cardColorV1: "white",
                    cardBgColorV1: "#00274a",
                    cardBoxShadowV1: "none",
                    cardColorV2: "white",
                    cardBgColorV2: "#021b33",
                    cardBoxShadowV2: "none",
                    dashboardColor: "white",
                    headerColor: "white",
                    headerBgColor: "#112943",
                    headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                    sidebarBg: "#00274a",
                    sidebarItemColor: "white",
                    sidebarItemActiveColor: "#5bc2e7",
                    sidebarItemActiveBgColor: "transparent",
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    ntuStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    ntuStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                    endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                    commentActionBoxBgColor: "white",
                    dropdownBgColor: "#193553",
                    dropdownColor: "white",
                    borderColorOverCardV1: "white",
                    borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                    inputColor: "white",
                    contactUsTextColor: "white",
                    tooltipTextColor: "#112943",
                    selectableRadioTagBgColor: "#021b33",
                    selectableRadioTagColor: "white",
                    selectableRadioTagHoverColor: "white",
                    selectableRadioTagActiveColor: "#021b33",
                    checkBoxInputTextColor: "white",
                    checkBoxInputBorderColor: "white",
                    checkBoxInputCheckmarkColor: "#112943",
                    checkBoxInputActiveBgColor: "#5bc2e7",
                    analyticsMetricHeadingColor: "white",
                    whiteBorderedButtonBgColor: "white",
                    rangleSliderValueColor: "#112943",
                    paginationTextColor: "white",
                    paginationControllerTextColor: "white",
                    paginationHoverTextColor: "#112943",
                    summarySuggestionDescriptionTextColor: "white",
                    searchBarColor: "white",
                    searchBarLightBorder: "#fff",
                    searchBarBgColor: "#021b33",
                    searchBarBoxShadow: "#021b33",
                    searchBarPlaceholderColor: "#77828e",
                    selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                    notificationPopColor: "white",
                    notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    notificationPopBgColor: "#193553",
                },
                dashboardBannerImages: [
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
            },
        },
        whiteLabellingData: {
            authDomain: "staging-sso",
            name: "BirdsEyeView",
            image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
            allowPayment: false,
            brokerId: "dev_broker",
            underwriterId: "arch",
            orgType: "underwriter",
        },
        props: {},
    },
    concirrusquest: {
        subDomains: {
            raptor: {
                name: "Concirrus",
                image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/concirrus-logo-blue.png",
                favicon:
                    "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/concirrus-icon-blue.png",
                authDomain: "raptor-sso",
                allowPayment: false,
                brokerId: "dev_broker",
                underwriterId: "arch",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprod-weatheranalytix.birdseyeviewtechnologies.com/",
                hideProductDashboard: true,
                hidePoweredBy: true,
                bannerProductName: "QUEST",
                themeColors: {
                    productHeadingBgColor: "#CDEDFC",
                    productHeadingColor: "#00263a",
                    summaryHeader: "#00263a",
                    gradientShade1: "#004569",
                    gradientShade2: "#004569",
                    loaderColor: "#007fae",
                    loadingBlockColor: "#C6DAF7",
                    tableHeaderBg: "#F2FAFF",
                    tableHeaderColor: "#00263a",
                    tableBorder: "#F2FAFF",
                    tableDataColor: "#00263a",
                    tableDataBorder: "#cdedfc",
                    navigationItemsBgColor: "transparent",
                    navigationItemsColor: "#00263a",
                    cardColorV1: "#00263a",
                    cardBgColorV1: "white",
                    cardBoxShadowV1: "0 2px 6px rgba(0, 127, 174, 0.2)",
                    cardColorV2: "#00263a",
                    cardBgColorV2: "white",
                    cardBoxShadowV2: "0 2px 6px rgba(0, 127, 174, 0.2)",
                    dashboardColor: "#00263a",
                    headerColor: "#00263a",
                    headerBgColor: "white",
                    headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                    sidebarBg: "#00263a",
                    sidebarItemColor: "white",
                    sidebarItemActiveColor: "#f2faff",
                    sidebarItemActiveBgColor: "#007fae",
                    activeColorV1: "#199ADB",
                    baseDark: "#004569",
                    baseBg: "#f2faff",
                    activeColorV2: "#007fae",
                    baseLight: "#004569",
                    generatedStatus: "#009ADE",
                    incompleteStatus: "#FF9F00",
                    referredStatus: "#046588",
                    underwriterApprovedStatus: "#30AB44",
                    underwriterDeclinedStatus: "#B32C2C",
                    brokerReviewStatus: "#046588",
                    brokerReviewApprovedStatus: "#30AB44",
                    brokerReviewDeclinedStatus: "#B32C2C",
                    rejectedStatus: "#B32C2C",
                    expiredStatus: "#A1A1A1",
                    boundStatus: "#009ADE",
                    cancelledStatus: "#B32C2C",
                    ntuStatus: "#B32C2C",
                    generatedStatusText: "#ffffff",
                    incompleteStatusText: "#ffffff",
                    referredStatusText: "#ffffff",
                    underwriterApprovedStatusText: "#ffffff",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#ffffff",
                    brokerReviewApprovedStatusText: "#ffffff",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    boundStatusText: "#ffffff",
                    cancelledStatusText: "#ffffff",
                    ntuStatusText: "#ffffff",
                    inputInfoIcon: "#199ADB",
                    footerColor: "#000000",
                    linkColor: "#199ADB",
                    buttonPrimary: "#007fae",
                    buttonPrimaryHover: "#046588",
                    buttonPrimaryHoverColor: "#ffff",
                    buttonPrimaryColor: "#ffff",
                    buttonSecondary: "#F2FAFF",
                    buttonSecondaryHover: "#046588",
                    buttonSecondaryColor: "#046588",
                    buttonSecondaryHoverColor: "#ffff",
                    "modal-bg": "white",
                    modalColor: "#00263a",
                    baseShade1: "#C6DAF7",
                    baseShade2: "#CDEDFC",
                    endorsementListBorderColor: "#cdedfc",
                    premiumListBorderColor: "#cdedfc",
                    commentActionBoxBgColor: "#C6DAF7",
                    commentActionBoxHeadingBorderColor: "rgba(0, 0, 0, 0.13)",
                    dropdownBgColor: "white",
                    dropdownColor: "#00263a",
                    dropdownBoxShadow: "rgba(0, 0, 0, 0.1)",
                    borderColorOverCardV1: "#00263a",
                    borderColorOverCardV2: "lightgrey",
                    inputColor: "#00263a",
                    datePickerBg: "white",
                    datePickerBorder: "#00263a",
                    datePickerDefaultColor: "#00263a",
                    datePickerDisabledColor: "gray",
                    datePickerButtonColor: "#00263a",
                    datePickerCellActiveColor: "#ffffff",
                    datePickerCellHover: "#ffffff",
                    datePickerCellInRange: "rgba(60, 209, 186, 0.51)",
                    searchBarBgColor: "white",
                    searchBarLightBorder: "#00263a",
                    searchBarColor: "#00263a",
                    searchBarPlaceholderColor: "grey",
                    searchBarBoxShadow: "0 2px 6px rgba(0, 127, 174, 0.2)",
                    notificationPopBgColor: "white",
                    notificationPopColor: "#00263a",
                    notificationPopBoxShadow:
                        "0 2px 6px rgba(0, 127, 174, 0.2)",
                    selectLocationSidebarBgColor: "rgb(255, 255, 255)",
                    selectLocationSidebarColor: "#00263a",
                    contactUsTextColor: "white",
                    tooltipTextColor: "#007fae",
                    selectableRadioTagBgColor: "#e1ebf7",
                    selectableRadioTagColor: "#199ADB",
                    selectableRadioTagHoverColor: "#199ADB",
                    selectableRadioTagActiveColor: "#e1ebf7",
                    checkBoxInputTextColor: "#00263a",
                    checkBoxInputBorderColor: "#007fae",
                    checkBoxInputCheckmarkColor: "#007fae",
                    checkBoxInputActiveBgColor: "#ffffff",
                    analyticsMetricHeadingColor: "#cdedfc",
                    whiteBorderedButtonBgColor: "#004569",
                    rangleSliderValueColor: "white",
                    paginationTextColor: "#199ADB",
                    paginationControllerTextColor: "#007fae",
                    paginationHoverTextColor: "white",
                    summarySuggestionDescriptionTextColor: "white",
                    messageTitle: "#004569",
                    quickExtensionCardBgColor: "#F2FAFF",
                    topActionDisabledText: "rgba(0, 0, 0, 0.5)",
                    cardBgColorV3: "white",
                    cardColorV3: "#00263a",
                    mapZoomControlBgColor: "white",
                    mapZoomControlColor: "#004569",
                    navigationItemBottomBorderColor: "#F2FAFF",
                },
            },
        },
    },
    howden: {
        whiteLabellingData: {
            authDomain: "howden-sso",
            name: "Howden",
            image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden.png",
            favicon:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden_favicon.png",
            allowPayment: true,
            brokerId: "howden",
            underwriterId: "arch",
            orgType: "broker",
            clientEmail: "admin@birdseyeviewtechnologies.com",
        },
        props: {
            renderDirectBuy: true,
            renderDirectBuyLink: true,
            hideNormalBuy: true,
        },
    },
    "event-insure": {
        whiteLabellingData: {
            authDomain: "sso",
            name: "EventInsure",
            image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/event_insure.png",
            allowPayment: true,
            brokerId: "howden",
            favicon:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/event_insure_favicon.png",
            underwriterId: "arch",
            orgType: "broker",
            clientEmail: "admin@birdseyeviewtechnologies.com",
        },
        props: {
            renderDirectBuy: true,
            hideNormalBuy: true,
        },
    },
    arch: {
        whiteLabellingData: {
            authDomain: "arch-sso",
            name: "ArchIQ",
            image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
            allowPayment: true,
            brokerId: "arch_default",
            favicon:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
            underwriterId: "arch",
            orgType: "underwriter",
            welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
            getQuoteImage: {
                url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                width: "150px",
            },
            hideProductDashboard: true,
            additionalLogo:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
            themeColors: {
                productHeadingBgColor: "#021b33",
                productHeadingColor: "white",
                gradientShade1: "#112943",
                gradientShade2: "#021b33",
                loaderColor: "#e1ebf7",
                loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                navigationItemsBgColor: "transparent",
                navigationItemsColor: "white",
                cardColorV1: "white",
                cardBgColorV1: "#00274a",
                cardBoxShadowV1: "none",
                cardColorV2: "white",
                cardBgColorV2: "#021b33",
                cardBoxShadowV2: "none",
                dashboardColor: "white",
                headerColor: "white",
                headerBgColor: "#112943",
                headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                sidebarBg: "#00274a",
                sidebarItemColor: "white",
                sidebarItemActiveColor: "#5bc2e7",
                sidebarItemActiveBgColor: "transparent",
                activeColorV1: "#5bc2e7",
                baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                activeColorV2: "#449aa4",
                baseLight: "#00274a",
                generatedStatus: "#5bc2e7",
                incompleteStatus: "#ffa300",
                referredStatus: "#009ca6",
                underwriterApprovedStatus: "#99c221",
                underwriterDeclinedStatus: "#ba0c2f",
                brokerReviewStatus: "#009ca6",
                brokerReviewApprovedStatus: "#99c221",
                brokerReviewDeclinedStatus: "#ba0c2f",
                rejectedStatus: "#ba0c2f",
                expiredStatus: "#5c656d",
                ntuStatus: "#5c656d",
                boundStatus: "#5bc2e7",
                cancelledStatus: "#ba0c2f",
                generatedStatusText: "#000000",
                incompleteStatusText: "#000000",
                referredStatusText: "#000000",
                underwriterApprovedStatusText: "#000000",
                underwriterDeclinedStatusText: "#ffffff",
                brokerReviewStatusText: "#000000",
                brokerReviewApprovedStatusText: "#000000",
                brokerReviewDeclinedStatusText: "#ffffff",
                rejectedStatusText: "#ffffff",
                expiredStatusText: "#ffffff",
                ntuStatusText: "#ffffff",
                boundStatusText: "#000000",
                cancelledStatusText: "#ffffff",
                inputInfoIcon: "#449aa4",
                footerColor: "#000000",
                linkColor: "#67e8ff",
                endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                commentActionBoxBgColor: "white",
                dropdownBgColor: "#193553",
                dropdownColor: "white",
                borderColorOverCardV1: "white",
                borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                inputColor: "white",
                contactUsTextColor: "white",
                tooltipTextColor: "#112943",
                selectableRadioTagBgColor: "#021b33",
                selectableRadioTagColor: "white",
                selectableRadioTagHoverColor: "white",
                selectableRadioTagActiveColor: "#021b33",
                checkBoxInputTextColor: "white",
                checkBoxInputBorderColor: "white",
                checkBoxInputCheckmarkColor: "#112943",
                checkBoxInputActiveBgColor: "#5bc2e7",
                analyticsMetricHeadingColor: "white",
                whiteBorderedButtonBgColor: "white",
                rangleSliderValueColor: "#112943",
                paginationTextColor: "white",
                paginationControllerTextColor: "white",
                paginationHoverTextColor: "#112943",
                summarySuggestionDescriptionTextColor: "white",
                searchBarColor: "white",
                searchBarLightBorder: "#fff",
                searchBarBgColor: "#021b33",
                searchBarBoxShadow: "#021b33",
                searchBarPlaceholderColor: "#77828e",
                selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                notificationPopColor: "white",
                notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                notificationPopBgColor: "#193553",
            },
            dashboardBannerImages: [
                {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                    styles: {
                        bottom: "0px",
                        width: "100%",
                        left: "20px",
                        height: "100px",
                    },
                },
                {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                    styles: {
                        top: "40px",
                        right: "40px",
                        width: "100px",
                    },
                },
                {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                    styles: {
                        left: "40px",
                        top: "40px",
                        width: "180px",
                        maxWidth: "50%",
                    },
                },
            ],
            sidebarBgImage: {
                backgroundImage:
                    "url('https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                backgroundPosition: "0px 68%",
                backgroundSize: "115%",
            },
            clientEmail: "ArchIQ@archinsurance.com",
            termsUrl:
                "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
            privacyUrl: "https://www.archgroup.com/privacy-policy/",
            waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
        },
    },
    prod: {
        whiteLabellingData: {
            authDomain: "prod-sso",
            name: "ArchIQ",
            image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
            allowPayment: true,
            brokerId: "arch_default",
            favicon:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
            underwriterId: "arch",
            orgType: "underwriter",
            hideProductDashboard: true,
            welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
            getQuoteImage: {
                url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                width: "150px",
            },
            additionalLogo:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
            themeColors: {
                productHeadingBgColor: "#021b33",
                productHeadingColor: "white",
                gradientShade1: "#112943",
                gradientShade2: "#021b33",
                loaderColor: "#e1ebf7",
                loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                navigationItemsBgColor: "transparent",
                navigationItemsColor: "white",
                cardColorV1: "white",
                cardBgColorV1: "#00274a",
                cardBoxShadowV1: "none",
                cardColorV2: "white",
                cardBgColorV2: "#021b33",
                cardBoxShadowV2: "none",
                dashboardColor: "white",
                headerColor: "white",
                headerBgColor: "#112943",
                headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                sidebarBg: "#00274a",
                sidebarItemColor: "white",
                sidebarItemActiveColor: "#5bc2e7",
                sidebarItemActiveBgColor: "transparent",
                activeColorV1: "#5bc2e7",
                baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                activeColorV2: "#449aa4",
                baseLight: "#00274a",
                generatedStatus: "#5bc2e7",
                incompleteStatus: "#ffa300",
                referredStatus: "#009ca6",
                underwriterApprovedStatus: "#99c221",
                underwriterDeclinedStatus: "#ba0c2f",
                brokerReviewStatus: "#009ca6",
                brokerReviewApprovedStatus: "#99c221",
                brokerReviewDeclinedStatus: "#ba0c2f",
                rejectedStatus: "#ba0c2f",
                expiredStatus: "#5c656d",
                ntuStatus: "#5c656d",
                boundStatus: "#5bc2e7",
                cancelledStatus: "#ba0c2f",
                generatedStatusText: "#000000",
                incompleteStatusText: "#000000",
                referredStatusText: "#000000",
                underwriterApprovedStatusText: "#000000",
                underwriterDeclinedStatusText: "#ffffff",
                brokerReviewStatusText: "#000000",
                brokerReviewApprovedStatusText: "#000000",
                brokerReviewDeclinedStatusText: "#ffffff",
                rejectedStatusText: "#ffffff",
                expiredStatusText: "#ffffff",
                ntuStatusText: "#ffffff",
                boundStatusText: "#000000",
                cancelledStatusText: "#ffffff",
                inputInfoIcon: "#449aa4",
                footerColor: "#000000",
                linkColor: "#67e8ff",
                endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                commentActionBoxBgColor: "white",
                dropdownBgColor: "#193553",
                dropdownColor: "white",
                borderColorOverCardV1: "white",
                borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                inputColor: "white",
                contactUsTextColor: "white",
                tooltipTextColor: "#112943",
                selectableRadioTagBgColor: "#021b33",
                selectableRadioTagColor: "white",
                selectableRadioTagHoverColor: "white",
                selectableRadioTagActiveColor: "#021b33",
                checkBoxInputTextColor: "white",
                checkBoxInputBorderColor: "white",
                checkBoxInputCheckmarkColor: "#112943",
                checkBoxInputActiveBgColor: "#5bc2e7",
                analyticsMetricHeadingColor: "white",
                whiteBorderedButtonBgColor: "white",
                rangleSliderValueColor: "#112943",
                paginationTextColor: "white",
                paginationControllerTextColor: "white",
                paginationHoverTextColor: "#112943",
                summarySuggestionDescriptionTextColor: "white",
                searchBarColor: "white",
                searchBarLightBorder: "#fff",
                searchBarBgColor: "#021b33",
                searchBarBoxShadow: "#021b33",
                searchBarPlaceholderColor: "#77828e",
                selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                notificationPopColor: "white",
                notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                notificationPopBgColor: "#193553",
            },
            dashboardBannerImages: [
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                    styles: {
                        bottom: "0px",
                        width: "100%",
                        left: "20px",
                        height: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                    styles: {
                        top: "40px",
                        right: "40px",
                        width: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                    styles: {
                        left: "40px",
                        top: "40px",
                        width: "180px",
                        maxWidth: "50%",
                    },
                },
            ],
            sidebarBgImage: {
                backgroundImage:
                    "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                backgroundPosition: "0px 68%",
                backgroundSize: "115%",
            },
            clientEmail: "ArchIQ@archinsurance.com",
            termsUrl:
                "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
            privacyUrl: "https://www.archgroup.com/privacy-policy/",
        },
    },
    archiq: {
        whiteLabellingData: {
            authDomain: "archiq-sso",
            name: "ArchIQ",
            image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
            allowPayment: true,
            brokerId: "arch_default",
            favicon:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
            underwriterId: "arch",
            orgType: "underwriter",
            welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
            hideProductDashboard: true,
            getQuoteImage: {
                url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                width: "150px",
            },
            additionalLogo:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
            themeColors: {
                productHeadingBgColor: "#021b33",
                productHeadingColor: "white",
                gradientShade1: "#112943",
                gradientShade2: "#021b33",
                loaderColor: "#e1ebf7",
                loadingBlockColor: "rgba(255, 255, 255, 0.3)",
                navigationItemsBgColor: "transparent",
                navigationItemsColor: "white",
                cardColorV1: "white",
                cardBgColorV1: "#00274a",
                cardBoxShadowV1: "none",
                cardColorV2: "white",
                cardBgColorV2: "#021b33",
                cardBoxShadowV2: "none",
                dashboardColor: "white",
                headerColor: "white",
                headerBgColor: "#112943",
                headerShadow: "0 4px 6px rgba(0, 127, 174, 0.2)",
                sidebarBg: "#00274a",
                sidebarItemColor: "white",
                sidebarItemActiveColor: "#5bc2e7",
                sidebarItemActiveBgColor: "transparent",
                activeColorV1: "#5bc2e7",
                baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                activeColorV2: "#449aa4",
                baseLight: "#00274a",
                generatedStatus: "#5bc2e7",
                incompleteStatus: "#ffa300",
                referredStatus: "#009ca6",
                underwriterApprovedStatus: "#99c221",
                underwriterDeclinedStatus: "#ba0c2f",
                brokerReviewStatus: "#009ca6",
                brokerReviewApprovedStatus: "#99c221",
                brokerReviewDeclinedStatus: "#ba0c2f",
                rejectedStatus: "#ba0c2f",
                expiredStatus: "#5c656d",
                ntuStatus: "#5c656d",
                boundStatus: "#5bc2e7",
                cancelledStatus: "#ba0c2f",
                generatedStatusText: "#000000",
                incompleteStatusText: "#000000",
                referredStatusText: "#000000",
                underwriterApprovedStatusText: "#000000",
                underwriterDeclinedStatusText: "#ffffff",
                brokerReviewStatusText: "#000000",
                brokerReviewApprovedStatusText: "#000000",
                brokerReviewDeclinedStatusText: "#ffffff",
                rejectedStatusText: "#ffffff",
                expiredStatusText: "#ffffff",
                ntuStatusText: "#ffffff",
                boundStatusText: "#000000",
                cancelledStatusText: "#ffffff",
                inputInfoIcon: "#449aa4",
                footerColor: "#000000",
                linkColor: "#67e8ff",
                endorsementListBorderColor: "rgba(255,255, 255, 0.3)",
                commentActionBoxBgColor: "white",
                dropdownBgColor: "#193553",
                dropdownColor: "white",
                borderColorOverCardV1: "white",
                borderColorOverCardV2: "hsla(0,0%,100%,.14)",
                inputColor: "white",
                contactUsTextColor: "white",
                tooltipTextColor: "#112943",
                selectableRadioTagBgColor: "#021b33",
                selectableRadioTagColor: "white",
                selectableRadioTagHoverColor: "white",
                selectableRadioTagActiveColor: "#021b33",
                checkBoxInputTextColor: "white",
                checkBoxInputBorderColor: "white",
                checkBoxInputCheckmarkColor: "#112943",
                checkBoxInputActiveBgColor: "#5bc2e7",
                analyticsMetricHeadingColor: "white",
                whiteBorderedButtonBgColor: "white",
                rangleSliderValueColor: "#112943",
                paginationTextColor: "white",
                paginationControllerTextColor: "white",
                paginationHoverTextColor: "#112943",
                summarySuggestionDescriptionTextColor: "white",
                searchBarColor: "white",
                searchBarLightBorder: "#fff",
                searchBarBgColor: "#021b33",
                searchBarBoxShadow: "#021b33",
                searchBarPlaceholderColor: "#77828e",
                selectLocationSidebarBgColor: "rgba(2, 27, 51, 0.8)",
                notificationPopColor: "white",
                notificationPopBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                notificationPopBgColor: "#193553",
            },
            dashboardBannerImages: [
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                    styles: {
                        bottom: "0px",
                        width: "100%",
                        left: "20px",
                        height: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                    styles: {
                        top: "40px",
                        right: "40px",
                        width: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                    styles: {
                        left: "40px",
                        top: "40px",
                        width: "180px",
                        maxWidth: "50%",
                    },
                },
            ],
            sidebarBgImage: {
                backgroundImage:
                    "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                backgroundPosition: "0px 68%",
                backgroundSize: "115%",
            },
            clientEmail: "ArchIQ@archinsurance.com",
            termsUrl:
                "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
            privacyUrl: "https://www.archgroup.com/privacy-policy/",
        },
    },
};
