<template>
    <div class="section-heading w-100">
        <div class="heading d-flex align-items-center">
            <p>{{ headingText }}</p>
            <bev-image
                v-if="showIcon"
                :addBackground="false"
                :imageAlt="'view-more'"
                :imageUrl="$imagePath.viewMore"
                :width="'24px'"
                :height="'24px'"
                class="ml-10px cursor-pointer"
                @click="clickHandler"
            ></bev-image>
        </div>
        <p v-if="description" class="font-16 mt-10px">{{ description }}</p>
    </div>
</template>

<script>
export default {
    props: {
        headingText: String,
        description: {
            type: String,
            default: "",
        },
        showIcon: Boolean,
        clickHandler: {
            type: Function,
            default: () => {},
        },
    },
};
</script>
