<template>
    <div
        class="bev-dropdown position-relative"
        v-click-away="closeDrop"
        :class="[containerClass, disabled ? 'disabled' : '']"
    >
        <v-popover
            distance="5"
            :container="`.${containerClass}`"
            :shown="dropOpen"
            @update:shown="(val) => (dropOpen = val)"
            :disabled="listLoading || disabled"
            :placement="'bottom-end'"
            :boundary="`body`"
            :triggers="[]"
            :autoHide="false"
        >
            <button
                class="tooltip-target cursorPointer w-100"
                type="button"
                @click="targetClick"
                @focus="targetFocus"
            >
                <fieldset
                    class="drop-target"
                    :class="{ error: haveError, open: dropOpen }"
                >
                    <legend class="d-flex align-items-center w-100">
                        {{ dropDownLabel }}
                        <bev-image
                            v-if="infoImage.show"
                            :addBackground="true"
                            :backgroundColor="bevColors.inputInfoIcon"
                            :imageAlt="'info'"
                            :imageUrl="$imagePath.info"
                            width="16px"
                            height="16px"
                            v-tooltip="{
                                content: infoImage.info,
                                html: true,
                                placement: infoImage.tooltipPlacement || 'auto',
                                showTriggers: (events) => [...events, 'click'],
                                autoHide: true,
                                delay: 0,
                            }"
                            class="cursor-pointer mx-5px"
                        ></bev-image>
                    </legend>
                    <div
                        class="drop-value position-relative text-left overflow-hidden"
                    >
                        <p class="value-text">
                            {{
                                modelValue && optionTextHandler
                                    ? optionTextHandler(modelValue)
                                    : modelValue
                            }}
                        </p>
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="'var(--dropdownColor)'"
                            :imageUrl="$imagePath.caretDown"
                            :imageAlt="'caret-down'"
                            :width="'12px'"
                            :height="'7px'"
                            class="cursor-pointer position-absolute dropdown-caret-icon"
                            :class="{ open: dropOpen }"
                            v-if="!listLoading"
                        ></bev-image>
                        <spinner
                            v-if="listLoading"
                            :width="'20px'"
                            :height="'20px'"
                            class="position-absolute dropdown-caret-icon"
                        ></spinner>
                    </div>
                    <spinner
                        class="center-positioned"
                        v-if="dropLoading"
                        :width="'12px'"
                        :height="'12px'"
                    ></spinner>
                </fieldset>
            </button>
            <template #popper>
                <div class="popover-content">
                    <div
                        class="search-input-container"
                        v-if="
                            ajax &&
                            searchKey &&
                            (ajaxList.length > 0 ||
                                pageLoading ||
                                searchInput !== '')
                        "
                    >
                        <div :class="['bev-input', 'active']">
                            <bev-image
                                class="search-icon"
                                :addBackground="true"
                                :backgroundColor="'var(--searchBarPlaceholderColor)'"
                                :imageUrl="$imagePath.search"
                                :imageAlt="'search-icon'"
                                :width="'15px'"
                                :height="'15px'"
                            ></bev-image>
                            <input
                                :value="searchInput"
                                @input="searchKeyup"
                                :type="'text'"
                                :placeholder="'Search'"
                            />
                        </div>
                    </div>
                    <slot name="belowSearch"></slot>
                    <ul
                        class="popover-list"
                        :style="{
                            maxHeight: customMaxHeight || '300px',
                        }"
                        @scroll="getMoreDataOnScroll($event)"
                    >
                        <button
                            type="button"
                            class="drop-item px-0"
                            v-for="(option, index) in getResultList"
                            :key="index"
                            @click="optionChanged(option)"
                            @keydown.enter="optionChanged(option)"
                            :class="{
                                active:
                                    ajax && responseInfo.uniqueKey && modelValue
                                        ? modelValue[responseInfo.uniqueKey] ===
                                          option[responseInfo.uniqueKey]
                                        : modelValue === option,
                            }"
                            tabindex="0"
                        >
                            <div class="drop-text">
                                <p>
                                    {{
                                        optionTextHandler
                                            ? optionTextHandler(option)
                                            : option
                                    }}
                                </p>
                            </div>
                        </button>
                        <li v-if="pageLoading">
                            <spinner
                                :width="'20px'"
                                :height="'20px'"
                                class="m-auto"
                            ></spinner>
                        </li>
                        <li
                            class="drop-item static"
                            v-if="
                                getResultList.length === 0 &&
                                !pageLoading &&
                                !allowOther
                            "
                        >
                            No results found.
                        </li>
                        <button
                            type="button"
                            class="drop-item px-0"
                            @click="optionChanged('Other')"
                            @keydown.enter="optionChanged('Other')"
                            :class="{
                                active:
                                    (optionTextHandler
                                        ? optionTextHandler(modelValue)
                                        : modelValue) === 'Other',
                            }"
                            v-if="
                                getResultList.length === 0 &&
                                !pageLoading &&
                                searchInput &&
                                allowOther
                            "
                        >
                            <div class="drop-text">
                                <p>Other</p>
                            </div>
                        </button>
                    </ul>
                </div>
            </template>
        </v-popover>
        <input-error
            v-if="haveError"
            :errorMessage="errorMessage"
        ></input-error>
    </div>
</template>

<script>
/**
 * Used in places where drop down is needed
 * @param {Array<any>} options drop down options
 * @param {String} dropDownLabel drop down label
 * @param {Function} optionTextHandler handler to render label for the dropdown options
 * @param {Boolean} haveError show showing error with the dropdown
 * @param {String} errorMessage error message to show if haveError is true
 * @param {null} modelValue selected option in dropdown
 * @param {String} containerClass dropdown container class
 * @param {Boolean} ajax to set if the options are need to be fetched by ajax
 * @param {String} apiUrl if ajax, which api url to call to fetch options
 * @param {String} apiMethod which api method to use for the ajax call
 * @param {Function} apiRequestBody if method is post, request body for the ajax call
 * @param {Object} responseInfo information related to the response of the ajax, which is being used to handle response on the component
 * @param {Boolean} disabled to set if dropdown is disbaled
 * @param {Boolean} dropLoading to show loader on the dropdown
 * @param {Boolean} showSearch to show search input on the dropdown
 * @param {Function} showItemCheck function to check every item before appending to the list
 * @param {Object} infoImage image props for showing info icon
 * @param {String} sorting if sorting is being used in the ajax call
 * @param {String} otherOptionKey if Other option need to be stored as Object
 * * @param model for two way binding data to dropdown
 **/

import axios from "axios";
import debounce from "lodash.debounce";
import { mixin as clickaway } from "vue3-click-away";
export default {
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        optionTextHandler: Function,
        dropDownLabel: {
            type: String,
            default: "",
        },
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        modelValue: null,
        containerClass: {
            type: String,
            required: true,
        },
        ajax: Boolean,
        apiUrl: String,
        apiMethod: {
            type: String,
            default: () => {
                return "get";
            },
        },
        apiRequestBody: {
            type: Function,
            default: () => {
                return {};
            },
        },
        apiRequestParam: String,
        responseInfo: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
        dropLoading: {
            type: Boolean,
            default: false,
        },
        searchKey: {
            type: String,
            default: () => {
                return "";
            },
        },
        showItemCheck: {
            type: Function,
            default: () => {
                return true;
            },
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        sorting: {
            type: String,
            default: () => {
                return "";
            },
        },
        refreshAjaxCounter: {
            type: Number,
            default: () => 0,
        },
        setFirstEntryAsInitial: {
            type: Boolean,
            default: () => false,
        },
        allowOther: {
            type: Boolean,
            default: () => false,
        },
        searchInParam: {
            type: Boolean,
            default: true,
        },
        customMaxHeight: {
            type: String,
            default: "300px",
        },
        otherOptionKey: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    mixins: [clickaway],
    data() {
        return {
            dropOpen: false,
            paginationBusy: false,
            pageLoading: false,
            searchInput: "",
            pageNumber: 0,
            size: 30,
            ajaxList: [],
            totalItems: 0,
            listLoading: false,
            AjaxCancelToken: axios.CancelToken,
            ajaxCancel: null,
            searchLoading: false,
        };
    },
    computed: {
        pageCount() {
            return this.getTotalCount(this.totalItems, this.size);
        },
        getResultList() {
            let finalList;
            if (this.ajax) {
                finalList = this.ajaxList;
            } else {
                finalList = this.options.filter((option) => {
                    return this.showItemCheck(option);
                });
            }
            return finalList;
        },
        apiData() {
            return (
                this.apiUrl +
                JSON.stringify(this.apiRequestBody(this.searchInput)) +
                this.apiRequestParam
            );
        },
    },
    mounted() {
        if (this.ajax && !this.disabled) {
            this.getListItems(true);
        }
    },
    methods: {
        closeDrop() {
            this.dropOpen = false;
        },
        targetClick(e) {
            e.target.focus();
            this.dropOpen = true;
        },
        targetFocus() {
            this.dropOpen = true;
        },
        searchKeyup(e) {
            this.pageLoading = true;
            this.ajaxList = [];
            this.searchInput = e.target.value;
            this.searchResults();
        },
        searchResults: debounce(function () {
            this.getListItems(true, true);
        }, 500),
        optionChanged(option) {
            if (option === "Other" && this.otherOptionKey) {
                this.$emit("update:modelValue", {
                    [this.otherOptionKey]: option,
                });
            } else {
                this.$emit("update:modelValue", option);
            }
            this.closeDrop();
        },
        async getListItems(initial, initialForSearch) {
            if (this.ajaxCancel) {
                this.ajaxCancel();
            }
            let self = this;
            if (initial) {
                this.pageNumber = 0;
                if (!initialForSearch) {
                    this.listLoading = true;
                }
                this.ajaxList = [];
                this.totalItems = 0;
            }
            try {
                let searachKeyParam =
                    this.searchInput !== ""
                        ? `&${this.searchKey}=${this.searchInput}`
                        : "";
                let response = await axios({
                    url:
                        this.apiUrl +
                        `?page=${this.pageNumber}&size=${this.size}${
                            this.apiRequestParam
                                ? `&${this.apiRequestParam}`
                                : ""
                        }${this.sorting ? "&sort=" + this.sorting : ""}${
                            this.searchInParam ? searachKeyParam : ""
                        }`,
                    method: this.apiMethod,
                    data:
                        this.apiMethod === "post"
                            ? this.apiRequestBody(this.searchInput)
                            : {},
                    cancelToken: new this.AjaxCancelToken(function (c) {
                        self.ajaxCancel = c;
                    }),
                });
                const resultData = this.responseInfo.onRoot
                    ? response.data
                    : response.data[this.responseInfo.resultKey];
                if (this.responseInfo.pagination) {
                    if (resultData) {
                        this.appendToList(
                            resultData,
                            this.ajaxList,
                            this.responseInfo.uniqueKey,
                            this.showItemCheck
                        );
                        this.totalItems =
                            response.data[this.responseInfo.totalResultKey];
                    }
                } else {
                    this.appendToList(
                        resultData,
                        this.ajaxList,
                        this.responseInfo.uniqueKey
                    );
                }
                this.paginationBusy = false;
                this.pageLoading = false;
                this.listLoading = false;
                if (initial) {
                    if (this.setFirstEntryAsInitial) {
                        this.$emit("update:modelValue", this.ajaxList[0]);
                    }
                }
            } catch (err) {
                this.paginationBusy = false;
                this.pageLoading = false;
                this.listLoading = false;
            }
        },
        getMoreDataOnScroll(e) {
            if (this.ajax && this.apiUrl && this.responseInfo.pagination) {
                if (
                    e.target.scrollTop + e.target.clientHeight >=
                    e.target.scrollHeight
                ) {
                    if (
                        this.pageNumber + 1 < this.pageCount &&
                        !this.paginationBusy
                    ) {
                        this.pageNumber++;
                        this.paginationBusy = true;
                        this.pageLoading = true;
                        this.getListItems(false);
                    }
                }
            }
        },
    },
    watch: {
        apiData: function (value) {
            if (value && !this.pageLoading && !this.dropLoading) {
                this.pageLoading = true;
                this.getListItems(true, true);
            }
        },
        refreshAjaxCounter: function (value) {
            if (value) {
                this.$emit("update:modelValue", null);
                this.getListItems(true);
            }
        },
    },
};
</script>

<style scoped>
.popover-content {
    padding: 0px 20px;
}
.tooltip-target:focus fieldset .drop-value {
    border: solid 0.5px var(--activeColorV1);
}
.tooltip-target:focus fieldset legend {
    color: var(--activeColorV1);
}
.search-input-container {
    margin: 16px 0;
    background: var(--searchBarBgColor);
    box-shadow: var(--searchBarBoxShadow);
    border-radius: 6px;
}
.search-input-container input {
    transition: unset !important;
    background: var(--searchBarBgColor);
    color: var(--searchBarColor) !important;
}
.search-input-container input::placeholder {
    color: var(--searchBarPlaceholderColor) !important;
}
ul {
    padding: 0 20px !important;
    margin: 0 -20px !important;
}
.drop-item {
    width: 100%;
    text-align: left;
}
.drop-item:focus .drop-text {
    color: var(--activeColorV1);
}
</style>
