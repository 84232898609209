<template>
    <p class="link m-0 logout-link" @click="logout">Logout</p>
</template>

<script>
/**
 * Display logout link
 *
 */

export default {
    methods: {
        logout() {
            this.$auth.logout();
        },
    },
};
</script>
