<template>
    <bev-form
        :submitData="downloadBordereu"
        :showSingleButton="true"
        :submitButton="{
            text: 'Download',
            buttonType: 'submit',
            loading: bordereuLoading,
        }"
    >
        <template v-slot:input-fields>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-30px w-100">
                    <date-input
                        :inputLabel="'Start Date*'"
                        v-model="v$.startDate.$model"
                        :defaultDate="defaultStartDate"
                        :dateValueType="'timestamp'"
                        :haveError="
                            v$.startDate.$error &&
                            v$.startDate.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'Start Date'
                            )
                        "
                        appendToBody
                    ></date-input>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-20px w-100">
                    <date-input
                        :inputLabel="'End Date*'"
                        v-model="v$.endDate.$model"
                        :defaultDate="defaultEndDate"
                        :dateValueType="'timestamp'"
                        :haveError="
                            v$.endDate.$error && v$.endDate.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'End Date'
                            )
                        "
                        appendToBody
                    ></date-input>
                </div>
            </div>
        </template>
    </bev-form>
</template>

<script>
import PolicyService from "@/services/PolicyService";
import fileDownload from "js-file-download";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { sub, startOfMonth, endOfMonth } from "date-fns";
import DateInput from "@/components/Common/Input/DateInput";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        closeDialog: {
            type: Function,
            default: () => {},
        },
    },
    components: {
        DateInput,
    },
    data() {
        return {
            startDate: "",
            endDate: "",
            bordereuLoading: false,
        };
    },
    validations: {
        startDate: {
            required,
        },
        endDate: {
            required,
        },
    },
    created() {
        let lastMonthDate = sub(new Date(), {
            months: 1,
        });
        this.startDate = startOfMonth(lastMonthDate).getTime();
        this.endDate = endOfMonth(lastMonthDate).getTime();
    },
    computed: {
        currentDate() {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        },
        // Default start date based on end date
        defaultStartDate() {
            if (!this.endDate) {
                return this.currentDate;
            }
            let d = new Date(this.endDate);
            let expectedStartDate = new Date();
            expectedStartDate.setMonth(d.getMonth());
            expectedStartDate.setFullYear(d.getFullYear());
            return expectedStartDate;
        },
        // Default end date based on start date
        defaultEndDate() {
            if (!this.startDate) {
                return this.currentDate;
            }
            let d = new Date(this.startDate);
            let expectedEndDate = new Date();
            expectedEndDate.setMonth(d.getMonth());
            expectedEndDate.setFullYear(d.getFullYear());
            return expectedEndDate;
        },
    },
    methods: {
        // Method to trigger bordereu download
        downloadBordereu() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.downloadBordereuAPIHandler();
            }
        },
        async downloadBordereuAPIHandler() {
            this.bordereuLoading = true;
            try {
                const response = await PolicyService.downloadBordereuReport({
                    start_date: this.formatDateValue(
                        this.startDate,
                        "normal",
                        "yyyy-MM-dd"
                    ),
                    end_date: this.formatDateValue(
                        this.endDate,
                        "normal",
                        "yyyy-MM-dd"
                    ),
                });
                if (response.data) {
                    fileDownload(response.data, "generated-report.xlsx");
                }
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: "Bordereau Report downloaded",
                    type: "success",
                });
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        "Failed to download bordereu report"
                );
            } finally {
                this.bordereuLoading = false;
                this.closeDialog();
            }
        },
    },
    watch: {
        // Watch for changes in start date
        startDate: function (value) {
            if (value) {
                // Reset end date if start date is greater than end date
                if (this.endDate && value > this.endDate) {
                    this.endDate = "";
                }
            }
        },
        // Watch for changes in end date
        endDate: function (value) {
            if (value) {
                // Reset start date if end date is smaller than start date
                if (this.startDate && value < this.startDate) {
                    this.startDate = "";
                }
            }
        },
    },
};
</script>
