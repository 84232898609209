<template>
    <div
        class="d-flex align-items-center justify-content-between dashboard-main-heading w-100"
    >
        <div class="welcome-message d-flex">
            <b>Hi {{ user && user.firstName }}</b
            >,
            <p
                class="ml-5px"
                v-html="subDomainDetail.welcomeMessage || 'Welcome..!'"
            ></p>
        </div>
        <div class="time-day">
            <p class="time">{{ currentDateDay.time }}</p>
            <p class="day">{{ currentDateDay.day }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentDateDay: {
                time: "",
                day: "",
            },
            timeInterval: null,
        };
    },
    mounted() {
        this.setCurrentTimeDay();
        this.timeInterval = setInterval(() => {
            this.setCurrentTimeDay();
        }, 1000);
    },
    methods: {
        setCurrentTimeDay() {
            let dateString = this.formatDateValue(new Date(), "day-time");
            this.currentDateDay = {
                day: dateString.split("|")[0],
                time: dateString.split("|")[1],
            };
        },
    },
    beforeUnmount() {
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
    },
};
</script>

<style lang="scss" scoped>
.dashboard-main-heading {
    ::v-deep(sup) {
        font-size: 12px;
        position: absolute;
    }
}
</style>
