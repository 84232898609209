<template>
    <div class="w-100 h-100">
        <l-map
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            :maxZoom="20"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            @ready="readyFunction"
            ref="map"
            class="z-index-1"
        >
            <l-tile-layer
                :url="url"
                :options="{
                    maxNativeZoom: 19,
                    maxZoom: 20,
                }"
                :attribution="attribution"
            />
            <l-marker
                :lat-lng="markerLatLng"
                :icon="markerIcon"
                v-if="markerLatLng"
            ></l-marker>
        </l-map>
    </div>
</template>

<script>
import L, { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { mapMutations, mapState } from "vuex";

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
    },
    props: {
        currentLocation: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parentMarkerLatLng: {
            type: Object,
            default: () => {
                return {};
            },
        },
        setReverseGeocodingLoader: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        reverseGeocodingLoading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["searchLatLng"],
    computed: {
        ...mapState("buyPolicy", ["questionBundles", "locationMapZoom"]),
    },
    mounted() {
        this.Leaflet = window.L;
        this.editableLayer = new this.Leaflet.FeatureGroup();
        this.markerLatLng =
            this.questionBundles["SelectLocation"].coordinates || null;
        this.zoom = this.locationMapZoom || 11;
        this.center = this.questionBundles["SelectLocation"].coordinates
            ? latLng(
                  this.questionBundles["SelectLocation"].coordinates.lat,
                  this.questionBundles["SelectLocation"].coordinates.lng
              )
            : latLng(51.509865, -0.118092);
        this.currentCenter = this.center;
    },
    data() {
        return {
            zoom: 11,
            center: latLng(51.509865, -0.118092),
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            currentZoom: 11.5,
            attribution:
                '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            currentCenter: latLng(51.509865, -0.118092),
            mapOptions: {},
            rect: null,
            editableLayer: null,
            drawControl: null,
            Leaflet: null,
            map: null,
            markerLatLng: null,
            markerIcon: icon({
                iconUrl: this.$imagePath.locationBlue,
                iconSize: [30, 30],
                iconAnchor: [15, 30],
            }),
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setLocationMapZoom"]),
        setMarkerLatLng(parentMarkerLatLng) {
            if (parentMarkerLatLng.lng && parentMarkerLatLng.lat) {
                this.$emit("searchLatLng", {
                    dataFilled: true,
                    ...latLng(parentMarkerLatLng.lat, parentMarkerLatLng.lng),
                });
                this.markerLatLng = latLng(
                    parentMarkerLatLng.lat,
                    parentMarkerLatLng.lng
                );
            }
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
            this.setLocationMapZoom(zoom);
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        readyFunction(map) {
            this.map = map;
            map.zoomControl.setPosition("bottomright");
            if (this.questionBundles["SelectLocation"].coordinates) {
                map.panTo(
                    new L.LatLng(
                        this.questionBundles["SelectLocation"].coordinates.lat,
                        this.questionBundles["SelectLocation"].coordinates.lng
                    )
                );
            }
            map.on("click", (e) => {
                if (!this.reverseGeocodingLoading) {
                    this.setReverseGeocodingLoader(true);
                    this.$emit("searchLatLng", e.latlng.wrap());
                    this.markerLatLng = e.latlng;
                }
            });
        },
    },
    watch: {
        currentLocation: function (val) {
            if (val.viewport) {
                let boundsArr = [
                    [val.viewport.southwest.lat, val.viewport.southwest.lng],
                    [val.viewport.northeast.lat, val.viewport.northeast.lng],
                ];
                const bounds = this.Leaflet.latLngBounds(
                    boundsArr[0],
                    boundsArr[1]
                );
                this.map.fitBounds(bounds);
            }
        },
        parentMarkerLatLng: function (val) {
            this.setMarkerLatLng(val);
        },
    },
};
</script>
