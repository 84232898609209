<template>
    <div class="adjustments">
        <SingleAdjustment
            v-for="(adjustment, index) in adjustments"
            :key="index"
            :adjustment="adjustment"
            :selectedOption="selectedOption"
        />
    </div>
</template>

<script>
import SingleAdjustment from "@/components/Views/Details/MTA/Adjustments/SingleAdjustment";
export default {
    props: {
        adjustments: Array,
        selectedOption: String,
    },
    components: {
        SingleAdjustment,
    },
};
</script>
