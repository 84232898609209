<template>
    <div class="center-layout register-page">
        <div class="center-content">
            <MergedLogo
                showPoweredBy
                :logoWidth="'100px'"
                :logoMaxWidth="'100px'"
                class="mx-auto mb-30px bev-logo w-max-content"
            />
            <div class="bev-card-v3 px-30px py-20px">
                <heading-block
                    :align="'center'"
                    :heading="'Registration'"
                    :description="'We require just a little more information to get you all signed up'"
                    :headingSizeClass="'font-40 sm-font-18'"
                    :descriptionSizeClass="'font-16 sm-font-10'"
                ></heading-block>
                <steps></steps>
                <router-view></router-view>
            </div>
            <logout-link></logout-link>
        </div>
    </div>
</template>

<script>
import LogoutLink from "@/components/Common/LogoutLink";
import Steps from "@/components/Views/Registration/Steps";
import MergedLogo from "@/components/Common/MergedLogo.vue";
export default {
    components: {
        LogoutLink,
        Steps,
        MergedLogo,
    },
};
</script>
