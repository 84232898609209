const state = {
    newCommentAdded: false,
    addedComment: null,
};

const mutations = {
    setAddedComment(state, comment) {
        state.addedComment = comment;
    },
    setNewCommentAdded(state, added) {
        state.newCommentAdded = added;
    },
};

export default {
    state,
    mutations,
    namespaced: true,
};
