<template>
    <div
        class="pb-30px pt-50px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Money`"
            :description="'Unpredictable things happen in large crowds. Relax knowing you\'re money is covered.'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="setMoney.bind(this, false, false, true)"
            />
        </buy-policy-heading-block>
        <div>
            <bev-form
                class="money-form"
                :submitData="setMoney.bind(this, true, false, true)"
                :submitButton="{
                    text: 'Next',
                    buttonType: 'submit',
                }"
                :resetButton="{
                    text: 'Back',
                    clickHandler: setMoney.bind(this, false, true, false),
                    buttonType: 'button',
                }"
            >
                <template v-slot:input-fields>
                    <div class="money-form-content pt-20px mt-30px">
                        <div class="row mt-30px">
                            <div class="col-sm-12">
                                <bev-currency-input
                                    :inputLabel="
                                        productLabelHandler(
                                            'valueOfMoney',
                                            'label'
                                        ) + '*'
                                    "
                                    :inputClass="'value_of_money'"
                                    :haveError="
                                        v$.valueOfMoney.$error &&
                                        v$.valueOfMoney.required.$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.fieldCannotEmptyError(
                                            'Value'
                                        )
                                    "
                                    v-model="v$.valueOfMoney.$model"
                                    :infoImage="{
                                        show: true,
                                        info: getCashInTransitTooltip,
                                        tooltipPlacement: 'right',
                                    }"
                                    :currencySymbol="getCurrencyCode()"
                                ></bev-currency-input>
                            </div>
                        </div>
                    </div>
                </template>
            </bev-form>
            <SaveAsDraftButton :dataSaveHandler="setMoney" />
        </div>
    </div>
</template>

<script>
/**
 * QuestionBundle component for Money
 */
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import customHtml from "@/constant/customHtml";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        CoverSummaryButton,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
    },
    validations() {
        return {
            // Validations for every form field
            valueOfMoney: {
                required,
            },
        };
    },
    created() {
        this.valueOfMoney = this.questionBundles["Money"]?.valueOfMoney || null;
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        getCashInTransitTooltip() {
            return this.cashInTransitTooltip.replaceAll(
                "$(currency)",
                this.getCurrencyCode()
            );
        },
    },
    data() {
        return {
            valueOfMoney: null,
            cashInTransitTooltip: customHtml.cashInTransitTooltip,
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setQuestionBundle", "setBuyPolicyStep"]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        setMoney(forNext, forBack, notSaveForDraft) {
            // Handler to take every filled data and set into Money questionBundle
            this.updateDataBundleWithValidations({
                bundleName: "Money",
                bundleData: {
                    valueOfMoney: this.valueOfMoney,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.money-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.money-form-content {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>
