<template>
    <div class="comment-action px-20px">
        <bev-form
            class="comment-form d-flex align-items-center"
            :submitData="addComment.bind(this, true)"
            :showSingleButton="true"
            :submitButton="{
                text: 'Send',
                buttonType: 'submit',
                loading: commenting,
            }"
        >
            <template v-slot:input-fields>
                <div class="comment-form-content flex-1 pr-10px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="''"
                        :haveError="
                            v$.comment.$error && v$.comment.required.$invalid
                        "
                        :errorMessage="''"
                        :inputClass="'comment'"
                        :fullWidthInput="false"
                        v-model="comment"
                    ></bev-input>
                </div>
            </template>
        </bev-form>
    </div>
</template>

<script>
/**
 * Send comment action block
 */
import CommentService from "@/services/CommentService";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapMutations } from "vuex";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            comment: "",
            commenting: false,
        };
    },
    validations: {
        // Validations for every form field
        comment: {
            required,
        },
    },
    props: {
        commentOnNumber: String,
    },
    methods: {
        ...mapMutations("comment", ["setNewCommentAdded", "setAddedComment"]),
        async addComment() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.commenting = true;
                try {
                    const response = await CommentService.addComment({
                        commentOn: this.commentOnNumber,
                        commentContent: this.comment,
                    });
                    this.setNewCommentAdded(true);
                    this.setAddedComment(response.data);
                } catch (err) {
                    this.displayErrorMessage(
                        err?.response?.data?.message || "Failed to add comment"
                    );
                } finally {
                    this.commenting = false;
                    this.comment = "";
                    this.v$.$reset();
                }
            }
        },
    },
};
</script>

<style>
.comment-action {
    height: 100px;
    background-color: var(--cardBgColorV1);
    color: var(--cardColorV1);
}
.comment-action .form-actions {
    width: auto !important;
}
</style>
