<template>
    <bev-dropdown
        containerClass="currency_list"
        dropDownLabel="Currency*"
        :ajax="true"
        :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
        apiRequestParam="entityType=currency&tenant=arch"
        :apiRequestBody="currencyRequestBody"
        :searchInParam="false"
        apiMethod="post"
        :responseInfo="{
            resultKey: 'content',
            totalResultKey: 'totalElements',
            pagination: true,
        }"
        searchKey="searchVal"
        :optionTextHandler="currencyDropdownTextRenderer"
        :haveError="haveError"
        :errorMessage="errorMessage"
        :modelValue="modelValue"
        @update:modelValue="handleCurrencyUpdate"
        :fullWidthInput="false"
        :disabled="disabled"
    ></bev-dropdown>
</template>
<script>
import config from "@/envConfig.js";
import { mapMutations } from "vuex/dist/vuex.cjs.js";
export default {
    name: "CurrencyDropdown",
    props: {
        modelValue: null,
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            config: null,
        };
    },
    created() {
        this.config = config;
    },
    methods: {
        ...mapMutations("buyPolicy", ["setSelectedCurrency"]),
        currencyRequestBody(searchVal) {
            return {
                metaDataFilters: [
                    {
                        key: "code",
                        value: searchVal,
                        mandatory: false,
                    },
                    {
                        key: "name",
                        value: searchVal,
                        mandatory: false,
                    },
                ],
            };
        },
        handleCurrencyUpdate(selectedCurrency) {
            this.setSelectedCurrency(selectedCurrency.value);
            this.$emit("update:modelValue", selectedCurrency);
        },
    },
};
</script>
