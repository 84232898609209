<template>
    <div class="input-wrapper" :class="{ 'w-100': fullWidth }">
        <fieldset class="normal-input" :class="{ error: haveError }">
            <legend
                class="d-flex justify-content-between w-100"
                :class="{ active: inputActive }"
                v-if="inputLabel"
            >
                <div class="d-flex align-items-center w-100">
                    <p class="input-label">{{ inputLabel }}</p>
                    <bev-image
                        v-if="infoImage.show"
                        :addBackground="true"
                        :backgroundColor="$colors.bevColors.inputInfoIcon"
                        :imageUrl="$imagePath.info"
                        :imageAlt="'info-icon'"
                        width="16px"
                        height="16px"
                        v-tooltip="{
                            content: infoImage.info,
                            html: true,
                            placement: infoImage.tooltipPlacement || 'auto',
                            showTriggers: (events) => [...events, 'click'],
                            autoHide: true,
                            delay: 0,
                        }"
                        class="cursor-pointer mx-5px"
                    ></bev-image>
                </div>
                <p
                    class="font-12 fw-normal"
                    :class="extraInfoTextClass"
                    v-if="extraInfoText"
                >
                    {{ extraInfoText }}
                </p>
            </legend>
            <slot></slot>
        </fieldset>
        <input-error
            v-if="haveError && errorMessage"
            :errorMessage="errorMessage"
        ></input-error>
    </div>
</template>

<script>
/**
 * This is BEV input wrapper which wraps all inputs
 *
 * @param {String} inputLabel for input label prop
 * @param {Boolean} haveError for showing error with the input
 * @param {String} errorMessage error message to show if haveError is true
 * @param {Boolean} fullWidth for showing a full width input
 * @param {Object} infoImage image props for showing info icon
 * @param {Boolean} inputActive prop to set active class on legend
 * @param {String} extraInfoText extra info to be shown in the input label
 * @param model for two way binding data to input
 **/

export default {
    props: {
        inputLabel: String,
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        fullWidth: {
            type: Boolean,
            default: true,
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        inputActive: {
            type: Boolean,
            default: false,
        },
        extraInfoText: {
            type: String,
            default: "",
        },
        extraInfoTextClass: {
            type: String,
            default: "text-activeColorV2",
        },
    },
};
</script>

<style scoped>
.input-label {
    line-height: 1.4;
}
</style>
