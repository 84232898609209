<template>
    <input-wrapper
        :inputLabel="inputLabel"
        :haveError="haveError"
        :errorMessage="errorMessage"
        :fullWidth="fullWidthInput"
    >
        <div class="d-flex align-items-center justify-content-between">
            <p class="font-14">
                <span v-if="!file">No file choosen</span>
                <span v-else>{{ file.name }}</span>
            </p>
            <bev-button
                :themeType="'dark'"
                :clickHandler="chooseFile"
                :class="'small'"
            >
                Choose file
            </bev-button>
        </div>
        <input
            ref="fileInput"
            class="file-input"
            type="file"
            @blur="$emit('blur')"
            @input="updateFile($event)"
        />
    </input-wrapper>
</template>

<script>
/**
 * This is BEV custom input which will be used wherever there is file input
 *
 * @param {String} inputLabel for input label prop
 * @param {String} modelValue for input value prop
 * @param {Boolean} haveError for showing error with the input
 * @param {String} errorMessage error message to show if haveError is true
 * @param {Boolean} fullWidthInput for showing a full width input (used in InputWrapper)
 * @param model for two way binding data to input
 **/
import InputWrapper from "@/components/Common/Input/InputWrapper";
export default {
    props: {
        inputLabel: {
            type: String,
            default: "",
        },
        modelValue: null,
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        fullWidthInput: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            file: null,
        };
    },
    emits: ["update:modelValue"],
    components: {
        InputWrapper,
    },
    methods: {
        updateFile(e) {
            this.file = e.target.files[0];
            this.$emit("update:modelValue", e.target.files[0]);
        },
        chooseFile() {
            this.$refs.fileInput.click();
        },
    },
};
</script>

<style scoped>
.normal-input {
    position: relative;
}
.file-input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
}
button {
    position: relative;
    z-index: 2;
}
</style>
