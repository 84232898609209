<template>
    <div
        class="pt-50px sm-pt-30px px-20px w-100 h-100 d-flex justify-content-between flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Select Peril`"
            :description="'Don’t wait for bad weather to strike! Our parametric policies are hassle free with immediate payout. No fuss or paperwork! All cover is fully customisable.'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="mb-20px less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="saveSelectedPeril.bind(this, false)"
            />
        </buy-policy-heading-block>
        <div class="d-flex flex-column justify-content-between h-100">
            <div class="row perils-container mx-0 justify-content-center">
                <div
                    class="col-sm-12 col-md-6 col-lg-4 mb-30px"
                    v-for="(peril, index) in $policyValues.perils"
                    :key="index"
                >
                    <selectable-card
                        :clicked="selectedPeril === peril.key"
                        :disabled="peril.key !== 'rain'"
                        :clickHandler="selectPeril.bind(this, peril.key)"
                        class="peril-card w-100 h-100"
                    >
                        <card-content
                            :imageInfo="{
                                imageUrl: peril.image,
                                width: '30px',
                                height: '30px',
                            }"
                            :heading="peril.text"
                            :description="peril.description"
                            :selected="selectedPeril === peril.key"
                        ></card-content>
                    </selectable-card>
                </div>
            </div>
            <div
                class="d-flex align-items-center w-100 justify-content-center pb-30px"
            >
                <actions
                    :align="'center'"
                    :buttonScale="'normal'"
                    :buttonLightProperties="{
                        text: 'Back',
                        clickHandler: takeToPreviousStep,
                    }"
                    :buttonDarkProperties="{
                        text: 'Next',
                        clickHandler: saveSelectedPeril.bind(this, true),
                    }"
                    :buttonDarkDisabled="!selectedPeril"
                    class="mt-30px w-auto"
                ></actions>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import SelectableCard from "@/components/Common/Card/SelectableCard";
import CardContent from "@/components/Views/BuyPolicy/SelectPeril/CardContent";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    components: {
        SelectableCard,
        CardContent,
        CoverSummaryButton,
        BuyPolicyHeadingBlock,
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
    },
    created() {
        this.selectedPeril = this.questionBundles["SelectPeril"] || "";
    },
    data() {
        return {
            selectedPeril: "",
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setBuyPolicyStep", "setQuestionBundle"]),
        ...mapActions("buyPolicy", ["takeToNextStep", "takeToPreviousStep"]),
        selectPeril(key) {
            this.selectedPeril = key;
        },
        saveSelectedPeril(forNext) {
            this.setQuestionBundle({
                ...this.questionBundles,
                SelectPeril: this.selectedPeril,
            });
            if (this.checkFlowComplete && !forNext) {
                this.setBuyPolicyStep({ step: "CoverSummary", back: false });
            } else {
                this.takeToNextStep();
            }
        },
    },
};
</script>

<style scoped>
.peril-name {
    text-align: center;
    font-size: 24px;
}
.perils-container {
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.peril-card {
    min-width: 40%;
    display: inline-block;
}
</style>
