<template>
    <div
        class="d-flex justify-content-end align-items-center flex-wrap gap-10px mr-10px sm-mr-0"
    >
        <bev-button
            themeType="light"
            :clickHandler="cancelUpdate"
            class="small"
            v-if="isEditingOld"
        >
            Cancel
        </bev-button>
        <bev-button
            :themeType="'white-bordered'"
            :clickHandler="clickHandler"
            class="small"
            v-if="checkFlowComplete"
        >
            Save & Go To Summary
        </bev-button>
        <div v-if="isDirectBuyFlow" class="step-count">
            {{ currentStepIndex + 1 }}
        </div>
        <DiscardChangesButton />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DiscardChangesButton from "@/components/Views/BuyPolicy/DiscardButton.vue";
export default {
    components: {
        DiscardChangesButton,
    },
    props: {
        clickHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
    },
    computed: {
        ...mapState("buyPolicy", [
            "isDirectBuyFlow",
            "currentStepIndex",
            "isEditingOld",
            "editingFor",
            "addedQuote",
        ]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
    },
    methods: {
        cancelUpdate() {
            this.$router.push({
                path: `/${
                    this.editingFor === "policy" ? "policies" : "quotes"
                }/${this.addedQuote.id}`,
            });
        },
    },
};
</script>

<style scoped>
.gap-10px {
    gap: 10px !important;
}
.step-count {
    border: 1px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--baseLight);
}
</style>
