import config from "@/envConfig.js";
import { store } from "@/store/index";
import APIService from "./APIService";

export default class UserService extends APIService {
    static addUser(payload) {
        return this.post(`${config.userServiceUrl}/user`, payload);
    }

    static getUserDetails(userId) {
        return this.get(`${config.userServiceUrl}/user/${userId}`);
    }

    static updateUserDetails(userId, payload) {
        return this.put(`${config.userServiceUrl}/user/${userId}`, payload);
    }

    static getUsersByBroker(brokerGroupId, page, size, sorting, search, role) {
        return this.get(
            `${config.userServiceUrl}/broker/${brokerGroupId}/users?page=${page}&size=${size}${sorting}&role=${role}&search_filter=${search}`
        );
    }

    static checkIfTermsAccepted() {
        let attributes = store.getters["auth/userAttributes"];
        return JSON.parse(attributes.terms_and_conditions_accepted || "false");
    }

    static checkIfRegisteredHandler(next, to) {
        let attributes = store.getters["auth/userAttributes"];
        let registered = JSON.parse(
            attributes.first_login_completed || "false"
        );
        if (registered) {
            if (to.path !== "/terms" && !to.path.includes("register")) {
                this.checkAdminHandler(next, to);
            } else {
                next({ path: "/dashboard" });
            }
        } else {
            if (!to.path.includes("register")) {
                next({ path: "/register/personal-detail" });
            } else {
                next();
            }
        }
    }

    static checkPermissionAndRoute(next = Function, to) {
        if (this.checkIfTermsAccepted()) {
            this.checkIfRegisteredHandler(next, to);
        } else {
            if (to.path !== "/terms") {
                next({ path: "/terms" });
            } else {
                next();
            }
        }
    }

    static checkAdminHandler(next, to) {
        if (to.meta.requireEditRights) {
            store.getters["auth/isReadOnly"]
                ? next({ path: "/policies" })
                : next();
        } else if (to.meta.requireNotNormal) {
            store.getters["auth/isNormalUser"]
                ? next({ path: "/dashboard" })
                : next();
        } else if (to.meta.requireBroker) {
            store.getters["auth/isAdmin"] || store.getters["auth/isBroker"]
                ? next()
                : next({ path: "/dashboard" });
        } else if (to.meta.requireUnderwriter) {
            store.getters["auth/isAdmin"] || store.getters["auth/isUnderwriter"]
                ? next()
                : next({ path: "/dashboard" });
        } else {
            next();
        }
    }

    static getGroupDetailByName(groupName) {
        return this.get(`${config.userServiceUrl}/group/${groupName}`);
    }

    static getBrokersList(page, size) {
        return this.get(
            `${config.userServiceUrl}/group/org_type/BROKER?page=${page}&size=${size}`
        );
    }

    static getCountries(page, size) {
        return this.get(
            `${config.userServiceUrl}/countries?page=${page}&size=${size}`
        );
    }

    static createQuickBuyLink(requestBody) {
        return this.post(
            `${config.userServiceUrl}/rapidBuy/create_link`,
            requestBody
        );
    }

    static quickBuyKeycloakToken(tokenId) {
        return this.get(
            `${config.userServiceUrl}/rapidBuy/get_keycloak_token?rapid_buy_id=${tokenId}`
        );
    }
}
