<template>
    <transition name="fade">
        <div id="loading" v-if="isLoading">
            <div class="wrap">
                <MergedLogo />
                <div class="progress">
                    <div
                        class="progress-bar active progress-bar-striped progress-bar-animated w-100"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="0"
                    >
                        <span class="sr-only">Loading</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/**
 * Showing loader on full screen
 *
 */
import { mapState } from "vuex";
import MergedLogo from "@/components/Common/MergedLogo.vue";
export default {
    name: "FullScreenLoader",
    components: {
        MergedLogo,
    },
    computed: {
        ...mapState(["isLoading"]),
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
  .fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
