<template>
    <div class="metrics-data position-relative">
        <bev-loading-block
            v-if="analysingMetric || analyseMetricError || !allFiltersApplied"
            :loading="analysingMetric || !allFiltersApplied"
            :error="analyseMetricError"
            :retryHandler="analyseMetric"
            :errorMessage="this.$messages.errorMessages.metricError('analyse')"
            :showRetry="true"
        />
        <component
            v-if="!analysingMetric && !analyseMetricError && allFiltersApplied"
            :is="metricMetaData.dataComponent"
            :metricData="metricData"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import analytics from "@/constant/analytics";
import AnalyticsService from "@/services/AnalyticsService";
import AggregateLimit from "@/components/Views/Analytics/Metrics/MetricsData/AggregateLimit.vue";
import TotalPremium from "@/components/Views/Analytics/Metrics/MetricsData/TotalPremium.vue";
import KeyValue from "@/components/Views/Analytics/Metrics/MetricsData/KeyValue.vue";
export default {
    props: {
        metrics: Object,
    },
    components: {
        AggregateLimit,
        TotalPremium,
        KeyValue,
    },
    data() {
        return {
            metricComponentMapping: analytics.metricComponentMapping,
            metricData: null,
            analysingMetric: false,
            analyseMetricError: false,
        };
    },
    computed: {
        ...mapState("analytics", ["analyseFilters"]),
        apiRequestBody() {
            return {
                id: this.metrics.id,
                name: this.metrics.name,
                mandatoryPlaceholders: this.metricfilters,
                entity: this.metrics.entity,
            };
        },
        metricfilters() {
            let returnObj = {};
            Object.keys(this.analyseFilters).forEach((filter) => {
                if (this.analyseFilters[filter] === "/*") {
                    returnObj[filter] = "";
                } else {
                    returnObj[filter] = this.analyseFilters[filter];
                }
            });
            return returnObj;
        },
        allFiltersApplied() {
            if (this.metrics.mandatoryFields) {
                if (!this.isObjectEmpty(this.analyseFilters)) {
                    return (
                        Object.keys(this.analyseFilters).findIndex((filter) => {
                            return this.analyseFilters[filter] === null;
                        }) < 0
                    );
                }
                return false;
            }
            return true;
        },
        metricMetaData() {
            return this.metrics.metadata
                ? JSON.parse(this.metrics.metadata)
                : {};
        },
    },
    mounted() {
        this.analyseMetricHandler();
    },
    methods: {
        analyseMetricHandler() {
            if (this.allFiltersApplied) {
                this.analyseMetric();
            }
        },
        async analyseMetric() {
            this.analysingMetric = true;
            this.analyseMetricError = false;
            try {
                const response = await AnalyticsService.analyseMetric(
                    this.apiRequestBody
                );
                this.metricData = response.data.result;
            } catch (err) {
                this.analyseMetricError = true;
            } finally {
                this.analysingMetric = false;
            }
        },
    },
    watch: {
        analyseFilters: function (val, oldValue) {
            if (JSON.stringify(val) !== JSON.stringify(oldValue)) {
                this.analyseMetricHandler();
            }
        },
    },
};
</script>

<style scoped>
.metrics-data {
    padding: 20px;
    min-height: 150px;
}
</style>
