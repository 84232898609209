<template>
    <div
        class="pt-50px px-20px sm-pt-30px w-100 h-100 d-flex justify-content-between flex-column overflow-y-auto"
    >
        <heading-block
            :align="'center'"
            :heading="`Select Product`"
            :description="'Select the product you’d like to purchase first. Add, tailor, and top-up your cover later.'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="mb-30px less-width-description"
        ></heading-block>
        <div class="d-flex flex-column justify-content-between h-100 my-20px">
            <div class="row products-container m-0 justify-content-center">
                <div
                    class="col-sm-12 col-md-6 col-lg-4 mb-20px"
                    v-for="(product, index) in mainProducts"
                    :key="index"
                >
                    <selectable-card
                        :clicked="isProductSelected(product.name)"
                        :clickHandler="selectProduct.bind(this, product)"
                        class="product-card w-100 h-100"
                        :class="{ disabled: !product.isEnabled }"
                    >
                        <card-content
                            :imageInfo="{
                                imageUrl:
                                    $policyValues.products[product.name].image,
                                width: '30px',
                                height: '30px',
                            }"
                            :heading="product.label"
                            :description="product.description"
                            :isEnabled="product.isEnabled"
                            :points="
                                $policyValues.products[product.name].points
                            "
                            :selected="isProductSelected(product.name)"
                        ></card-content>
                    </selectable-card>
                </div>
            </div>
            <div
                class="d-flex align-items-center justify-content-center mt-30px"
            >
                <bev-button
                    :themeType="'dark'"
                    :clickHandler="confirmProductSelection"
                    :disabled="selectedProducts.length === 0"
                    :class="'large'"
                >
                    Next
                </bev-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import SelectableCard from "@/components/Common/Card/SelectableCard";
import CardContent from "@/components/Views/BuyPolicy/SelectProduct/CardContent";
export default {
    components: {
        SelectableCard,
        CardContent,
    },
    computed: {
        ...mapState("buyPolicy", [
            "policyProduct",
            "productsFlow",
            "currentStepIndex",
            "productsJSON",
        ]),
        ...mapGetters("auth", ["isBroker", "isAdmin", "isNormalUser"]),
        mainProducts() {
            return this.productsJSON.filter((product) => {
                // Need to correct this because isParent is used to render product for initial selection
                return product.isParent && product.productType !== "DIRECT";
            });
        },
        singleEnabledProduct() {
            let enabledProducts = this.productsJSON.filter(
                (p) => p.isEnabled && p.isParent && p.productType !== "DIRECT"
            );
            if (enabledProducts.length === 1) {
                return enabledProducts[0].name;
            } else {
                return "";
            }
        },
        // Temporary change, It will be reviewed in future (for arch, Event Cancellation will be selected as default)
        enableEventCancellationForArch() {
            if (this.subDomainDetail.underwriterId === "arch") {
                return "event_cancellation";
            }
            return "";
        },
    },
    data() {
        return {
            selectedProducts: [],
        };
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setPolicyProduct",
            "setBuyPolicyStep",
            "setCurrentStepIndex",
            "setProductsFlow",
            "removeProductFromFlow",
            "addToProductsFlow",
            "addProduct",
            "removeProduct",
        ]),
        isProductSelected(productName) {
            return this.selectedProducts.indexOf(productName) > -1;
        },
        selectProduct(product) {
            if (this.selectedProducts.indexOf(product.name) > -1) {
                this.removeProductFromFlow(product.name);
                this.removeProduct(product.name);
                this.selectedProducts = this.selectedProducts.filter(
                    (p) => p !== product.name
                );
            } else {
                if (product.isEnabled) {
                    this.selectedProducts.push(product.name);
                    this.saveSelectedProduct(product.name);
                }
            }
        },
        saveSelectedProduct(productName) {
            let selectedProductData = this.productsJSON.find((p) => {
                return p.name === productName;
            });
            this.addProduct(productName);
            let filteredFlow =
                selectedProductData.questionBundleSequence.filter((seq) => {
                    if (this.isNormalUser) {
                        return seq.name !== "SelectClient";
                    }
                    return true;
                });
            this.addToProductsFlow({
                flow: filteredFlow,
                productName: productName,
            });
        },
        confirmProductSelection() {
            this.setCurrentStepIndex(0);
            this.setPolicyProduct(this.selectedProducts[0]);
            this.setBuyPolicyStep({
                step: this.productsFlow[this.currentStepIndex].name,
                back: false,
            });
        },
    },
};
</script>

<style scoped>
.product-name {
    text-align: center;
    font-size: 24px;
}
.products-container {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}
.product-card {
    display: inline-block;
    min-width: 40%;
}
.product-card.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
