export default {
    payoutDescriptionTooltip: `
        <div class="payout-description-tooltip">
            <ul>
                <li><b>Heavy Rain for 2-5 Hours</b></li>
                <li><b>Light Drizzle:</b> Light Rain for 30 - 40 minutes or Moderate Rain for 10 Minutes or Heavy Rain for 5 minutes</li>
                <li><b>Moderate Rain:</b> Moderate Rain for 1-2 Hours or Heavy Rain for 30 – 40 Minutes</li>
                <li><b>Downpour:</b> Heavy Rain for 2-5 Hours</li>
            </ul>
        </div>
    `,
    shortTermDetailHeadingDescription: `<span>Let us know the amount of Rainfall (mm) at which you want your policy to trigger and the Cover Amount ($(currency)) / Day you would like once your policy is triggered.</span>
        <br/>
        <br/>
        <i>Fact: In December 2015, 342.4 mm of Rainfall was recorded just in 1 day in Cumbria, United Kingdom.</i>`,
    longTermDetailHeadingDescription: `<span>Please specify the amount in $(currency) you would like to insure, the type of payout mode, and the trigger level in percentages above or below average rainfall at which the policy will start paying out for an Excess-Rainfall or Drought policy, respectively.</span>
        <br/>
        <span>Our risk model calculates the exact premium appropriate for your individual location and time period of interest, based on your inputs.</span>`,
    dangerousActivityTooltip: `<div>
            <p class="mb-10px">Any activity organised by you or on your behalf or for which you are responsible, including but not limited to:</p>
            <ol class="m-0 text-left dangerousActivityTooltipList">
                <li>Fireworks, bonfires or pyrotechnical devices</li>
                <li>Inflatable play equipment</li>
                <li>Fairground rides or mechanically propelled rides of any kind</li>
                <li>Ballooning</li>
                <li>Quad bikes, go-karts or motor spot of any kind</li>
                <li>Trampolines for gymnastics apparatus of any kind</li>
                <li>Circus acts of stunt acts</li>
                <li>Shooting ranges for guns or archery</li>
                <li>Save that bouncy castles, inflatable play equipment, slides or rides (mechanical or otherwise) which are set up, operated and taken down by a bona fide sub contractor who has provided you with evidence of their current public liability insurance shall not be classed as a hazardous activities</li>
            </ol>
        </div>
    `,
    totalExpenseOrRevenueTooltip: `<div class="text-left">
        <p><strong>Revenue:</strong> Please enter the total income for the event. If the event has occurred before then enter all income secured in advance of the event. I.e. pre-sold tickets and advertising income.</p>
        <p><strong>Expenses:</strong> Total costs and expenses incurred for the event</p>
        </div>
    `,
    propertyValueOfEquipmentTooltip: `<div class="text-left">
        <b>Technical Equipment</b>
        <ul class="simple-list">
            <li>Any film, video, projection, sound, recording, lighting, grip, generators, staging post production and ancillary equipment</li>
            <li>Any property used in connection with conferences, events, festivals and product launches</li>
            <li>Property as more fully described in the Specification (if applicable)</li>
        </ul>
        <b>Event Property</b> 
        <p>Any rigging, trusses, seating, fencing, signage, sales stock, staging, exhibition shell, materials and work in progress which will become part of sales stock, stationery and any other property declared and agreed by Insurers in writing or described in the Schedule. Event property does not include any Technical Equipment.</p>
    </div>`,
    higherLimitContHireChargesTooltip: `<div class="text-left">
        <b>Continuing Hire Charges</b> 
        <p>Legal liability under the terms of the hiring agreement to pay continuing hire charges incurred under the terms of the hiring in agreement in consequence of Damage to hired-in Property Insured for which the Insurer has  admitted liability.</p>
    </div>`,
    cashInTransitTooltip: `
        <div class="text-left">
            <p><b>Carryings Condition</b></p>
            <p>It is an Important Condition that when Money (other than Non-Negotiable Money) is in transit the following conditions are met:</p>
            <ul class="simple-list">
                <li>
                    <p>by the Insured’s own Employees:</p>
                    <ul class="simple-list">
                        <li>up to $(currency)5,000 (five thousand pounds sterling), it shall be accompanied at all times by at least 1 (one) responsible able-bodied Employee;</li>
                        <li>up to $(currency)10,000 (ten thousand pounds sterling) but in excess of $(currency)5,000 (five thousand pounds sterling), it shall be accompanied at all times by at least 2 (two) responsible able-bodied Employees; and</li>
                        <li>up to $(currency)15,000 (fifteen thousand pounds sterling) but in excess of $(currency)10,000 (ten thousand pounds sterling), it shall be accompanied at all times by at least 3 (three) responsible able-bodied Employees; and</li>
                    </ul>
                </li>
                <li>in excess of $(currency)15,000 (fifteen thousand pounds sterling), it shall be carried by a professional security company.</li>
            </ul>
        </div>
    `,
    eventCancellationTooltipText: `
        <div class="text-left">
            <p><b>Event Cancellation: Product  Information</b></p>
            <br>
            <p>Event cancellation insurance is a type of insurance policy that is designed to protect the insured from financial losses that may arise due to the cancellation, postponement, or abandonment of a planned event. The specific coverage provided by event cancellation insurance may vary depending on the terms and conditions of the policy, but typically includes the following:</p>
            <ul class="simple-list">
                <li>Cancellation due to unforeseen circumstances: If an event is cancelled due to unforeseen circumstances such as extreme weather, natural disasters, strikes, terrorism, or other unexpected events, the insured will be reimbursed for any non-recoverable expenses that have been incurred in connection with the event.</li>
                <li>Postponement or rescheduling: If an event is postponed or rescheduled due to unforeseen circumstances, the insured will be reimbursed for any additional expenses incurred as a result of the delay, such as rental fees, catering costs, or advertising expenses.</li>
                <li>Abandonment: If an event is abandoned after it has started due to unforeseen circumstances such as power outages, equipment failure, or severe weather, the insured will be reimbursed for any expenses incurred up to the time of abandonment.</li>
                <li>Non-appearance: If a key performer, speaker, or participant fails to appear at an event due to illness, injury, or other unforeseen circumstances, the insured will be reimbursed for any additional expenses incurred in connection with finding a replacement or rescheduling the event.</li>
                <li>It is important to note that event cancellation insurance typically does not cover losses due to poor ticket sales, lack of interest, or other business-related factors. Additionally, the specific terms and conditions of the policy may vary depending on the insurer and the nature of the event, so it is important to carefully review the policy before purchasing to ensure that the coverage provided meets the needs of the insured.</li>
            </ul>
        </div>
    `,
    customerTypeTooltip: `
        <ul class="p-0 text-left">
            <li>
                <b>Consumer - </b>
                Any natural person acting for purposes outside his trade, business or profession.
            </li>
            <li>
                <b>Sole Trader - </b>
                Not a consumer. Owner and the business are one combined legal and financial entity.
            </li>
            <li>
                <b>Micro - </b>
                Under 10 Employees, Under $(currency)2m Turnover or Annual Balance Sheet.
            </li>
            <li>
                <b>Small - </b>
                Not a Micro and Under 50 Employees, Under $(currency)10m Turnover or Annual Balance Sheet.
            </li>
            <li>
                <b>Medium - </b>
                Not a Small and Under 250 Employees, Under $(currency)50m Turnover or Annual Balance Sheet.
            </li>
            <li>
                <b>Large - </b>
                Not a Medium and Over 250 Employees, Over $(currency)50m Turnover or Annual Balance Sheet.
            </li>
        </ul>
    `,
    coverTypeV2Tooltip: `
        <ul class="p-0 text-left">
            <li class="mb-10px">
                <b>Indemnity - </b>
                We will reimburse the insured for their net loss of gross revenue or expenses (based on limit insured) less revenue retained and savings made.
            </li>
            <li>
                <b>Agreed Value - </b>
                We will pay the insured up to the agreed sum insured without the requirement for any claim assessment. It is important that the sum insured is a true and accurate value. If the sum insured is too low, the insured may not have adequate insurance. If the sum insured is too high, we may refuse to pay a claim, not pay in full or adjust the terms. We will not pay more than the actual financial loss suffered by the Insured.
            </li>
        </ul>
    `,
    moderateRainFallTooltip: `
    <div class="p-0 text-left">
        <b>Moderate Rainfall: </b>
        6mm of rainfall in an hour or less causing muddy puddles that do not disappear easily.
    </div>
    `,
    noOfEmployeeELTooltip: `
        <div>
            This number should include Volunteers, Labour Only Sub-Contractors and not Bona-Fide Sub-Contractors. (Bona-fide sub-contractors are generally deemed to be contractors who work without direction from the Insured, hold their own insurance and usually provide their own materials and tools.)
        </div>
    `,
    limitOfIndemnityTooltip: `
        <div>
            Our standard limit basis is Any One Claim. If your event refers, we may change the limit basis to an Aggregate limit.
        </div>
    `,
    manualLightEmployeeSplitELTooltip: `
        <div>
            Light Manual - Non-desk based (Stewards, Venue dressing (tables chairs etc..), Artist care, No work at Height/Depth or lifting items over 25kg).
        </div>
    `,
    manualHeavyEmployeeSplitELTooltip: `
        <div>
            Heavy Manual - Non-desk based (Rigging of staging/lighting, Heavy lifting (items over 25kg), Security, Working at Height/Depth, Driving any motorised vehicle(s) for the purposes of the event).
        </div>
    `,
    noHazardousActivityELTooltip: `
        <div>
            Hazardous activities include but are not limited to; operation of fairground ride, inflatables, use of plant equipment, working at height/depths of over 10m, working with heat (furnaces, cooking equipment)
        </div>
    `,
    nonUkEmployeeNotification: `Non-UK employees will not be covered under this EL product and cover should be under a policy in their country of domicile (i.e. workers comp in US)`,
    extensionsInfo: {
        terrorism: `
            <ul class="p-0 text-left">
                <li class="mb-10px">
                    <b>Act of terrorism - </b>
                    Within the country of the event and within 30 days of the insured event.
                </li>
                <li>
                    <b>Threat of terrorism - </b>
                    Within 30 days of the Insured Event(s) which is confirmed by any Competent Authority as having posed, or appeared to pose, a real risk of damage to or the destruction of the venue, or actual bodily injury or death to those attending or working at the venue.
                </li>
            </ul>`,
        terrorismFull: `
            <ul class="p-0 text-left">
                <li class="mb-10px">
                    <b>Act of terrorism - </b>
                    Within the country of the event and within the policy period.
                </li>
                <li>
                    <b>Threat of terrorism - </b>
                    Within the Period of Insurance which is confirmed by any Competent Authority as having posed, or appeared to pose, a real risk of damage to or the destruction of the venue, or actual bodily injury or death to those attending or working at the venue.
                </li>
            </ul>
        `,
        civilCommotion: `<div class="text-left">
            <p>Within 50 miles of Venue and 30 days of Event which causes damage or denial of access to the Venue.</p>
            </div>
        `,
        computerSystemFailure: `<div class="text-left">
            <p>Provides cover for failure of any Computer System under the insured’s or any Participant’s direct operational control. There is no cover for malicious cyber / cyber act.</p>
            </div>
        `,
        nationalMourningWithCoverDate: `<div class="text-left">
            <p>Extension for King Charles III and Queen Camilla for declared state, 10 days prior to or during the Event. Wording includes cover for date of death or date of funeral coinciding with event.</p>
            </div>
        `,
        nationalMourning: `<div class="text-left">
            <p>Extension for King Charles III and Queen Camilla, declared state, where date of death or date of funeral coincides with the open dates of the Event.</p>
            </div>
        `,
        keySpeakerCover: `<div class="text-left">
            <p>Death, accidental bodily injury, illness or travel delay of a Key Person.</p>
            </div>
        `,
        catNonAppearance: `<div class="text-left">
            <p>Simultaneous non-appearance of 33.33% or more of a participating team.</p>
            </div>
        `,
    },
};
