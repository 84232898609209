<template>
    <div class="d-flex flex-column justify-content-between">
        <div
            class="list-container row ml-0 mr-0 mt-20px mb-20px px-5px"
            v-if="policies.length > 0 && !policyLoading && !policyError"
        >
            <div
                v-for="(policy, index) in policies"
                :key="index"
                class="col-sm-12 col-md-4 col-lg-4 px-5px"
            >
                <PolicyCard
                    :status="policyStatus"
                    @toggleDialog="toggleDialog"
                    :products="
                        policy.products
                            ? formatProductsString(policy.products)
                            : '----'
                    "
                    :policyAppliedOn="
                        formatDateValue(
                            policy.creationTime,
                            'normal',
                            'dd MMMM yyyy'
                        )
                    "
                    :id="policy.id"
                    :policyNumber="
                        policyStatus ===
                        $policyValues.policyStatus.INPROGRESS.value
                            ? policy.quoteNumber
                            : policy.policyNumber
                    "
                />
            </div>
        </div>
        <bev-loading-block
            class="position-relative w-100 list-container-loading"
            v-if="policies.length === 0 || policyLoading || policyError"
            :loading="policyLoading"
            :error="policyError"
            :retryHandler="getPolicies"
            :errorMessage="
                policyError ? 'Failed to load policies' : 'No policies yet'
            "
            :errorImage="policyError ? $imagePath.alert : $imagePath.covers"
        />
        <Pagination
            class="mx-auto mb-20px"
            :totalRecords="totalData"
            :recordsPerPage="paginationSize"
            :pageNumber="pageNumber"
            @pageChanged="pageChangeHandler"
            v-if="totalPages > 1"
        />
    </div>
</template>

<script>
import Pagination from "@/components/Common/Pagination.vue";
import PolicyCard from "@/components/Views/Policies/PolicyCard.vue";
export default {
    components: {
        Pagination,
        PolicyCard,
    },
    props: {
        policies: Array,
        policyLoading: {
            type: Boolean,
            default: false,
        },
        policyError: {
            type: Boolean,
            default: false,
        },
        toggleDialog: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        getPolicies: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        policyStatus: String,
        totalData: {
            type: Number,
            default: 0,
        },
        paginationSize: {
            type: Number,
            default: 10,
        },
        pageNumber: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 0,
        },
        pageChangeHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
    },
    methods: {
        formatProductsString(products) {
            return Object.keys(JSON.parse(products))
                .map((p) => {
                    return this.$policyValues.productLabels[p];
                })
                .join(", ");
        },
    },
};
</script>

<style scoped>
.list-container {
    min-height: 390px;
    overflow: hidden;
}
.list-container-loading {
    height: 50vh;
}
</style>
