<template>
    <div class="quick-extensions">
        <h4>Quote Extensions</h4>
        <Extension
            v-for="(extension, index) in productExtensions"
            :key="index"
            :extensionIndex="index"
            :extension="extension"
            :productHeading="productHeading"
            :checkIfExistInReferred="checkIfExistInReferred"
            :isReferred="isReferred"
            :isEntityStatusReferred="isEntityStatusReferred"
            @extensionPremiumUpdated="
                (data) => $emit('extensionPremiumUpdated', data)
            "
        />
    </div>
</template>

<script>
import Extension from "./Extension.vue";
export default {
    name: "QuickExtension",
    components: {
        Extension,
    },
    props: {
        productHeading: String,
        productExtensions: {
            type: Array,
            default: () => [],
        },
        checkIfExistInReferred: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        isReferred: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        isEntityStatusReferred: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.quick-extensions {
    border-radius: 8px;
    background-color: var(--quickExtensionCardBgColor);
    h4 {
        margin: 0;
        padding: 20px;
    }
}
</style>
