<template>
    <div class="position-relative">
        <BevTable
            :tableHeight="'100vh - 430px'"
            :headers="policyHeaders"
            :data="formatPolicyTableData"
            :tableLoading="policyLoading"
            :tableError="policyError"
            :retryHandler="getPolicies.bind(this, pageNumber, false)"
            :allowPagination="true"
            :totalData="totalElements"
            :paginationSize="size"
            :pageNumber="pageNumber"
            :totalPages="totalPages"
            :pageChangeHandler="pageChangeHandler"
            :tableItemClickHandler="tableItemClickHandler"
            :errorMessage="policyListErrorMessage"
            :appliedSortColumn="sortColumn"
            @sortingChanged="sortingChanged"
            v-if="!isNormalUser"
        />
        <NormalUserViewer
            :policies="policies"
            :policyLoading="policyLoading"
            :policyError="policyError"
            :toggleDialog="toggleDialog"
            :getPolicies="getPolicies"
            :policyStatus="policyStatus"
            :totalData="totalElements"
            :paginationSize="size"
            :pageNumber="pageNumber"
            :totalPages="totalPages"
            :pageChangeHandler="pageChangeHandler"
            v-else
        />
        <bev-dialog
            :title="dialogProps.title"
            :description="dialogProps.description"
            :closeDialog="() => toggleDialog({ state: false })"
            :actions="{ show: false }"
            :state="dialogProps.state"
        >
            <template v-slot:dialog-content>
                <bev-stepper
                    :steps="stepperSteps"
                    :currentStep="1"
                    class="mt-40px"
                ></bev-stepper>
            </template>
        </bev-dialog>
    </div>
</template>
<script>
import PolicyService from "@/services/PolicyService";
import QuoteService from "@/services/QuoteService";
import { mapState, mapGetters } from "vuex";
import debounce from "lodash.debounce";
import BevTable from "@/components/Common/Table/Main.vue";
import NormalUserViewer from "@/components/Views/Policies/NormalUserView.vue";
export default {
    components: {
        BevTable,
        NormalUserViewer,
    },
    props: {
        policyStatus: String,
        policySearch: String,
        newOne: String,
    },
    mounted() {
        this.getPolicies(0, true);
    },
    data() {
        return {
            dialogProps: {},
            policies: [],
            stepperSteps: [
                {
                    title: "31 Aug 21",
                    subTitle: "Policy Triggered",
                },
                {
                    title: "2 Sep 21",
                    subTitle: "Claim Reviewed",
                },
                {
                    title: "5 Sep 21",
                    subTitle: "Claim Approved",
                },
                {
                    title: "15 Sep 21",
                    subTitle: "Payment Made",
                },
            ],
            pageNumber: 0,
            size: 20,
            totalElements: 0,
            totalPages: 0,
            sortColumn: "creationTime",
            sortOrder: "DESC",
            policyLoading: false,
            policyError: false,
            policyErrorMessage: "",
            routeChanged: false,
            policyHeaders: [
                {
                    key: "policyNumber",
                    label:
                        this.policyStatus ===
                        this.$policyValues.policyStatus.INPROGRESS.value
                            ? "Quote Number"
                            : "Policy Number",
                    sorting: "",
                },
                {
                    key: "customerName",
                    label: "Insured Name",
                    sorting: "",
                },
                {
                    key: "policyName",
                    label: "Event Name",
                    sorting: "",
                },
                {
                    key: "policyStatus",
                    label: "Policy Status",
                    sorting: "",
                },
                {
                    key: "products",
                    label: "Products",
                    sorting: "",
                },
                {
                    key: "creationTime",
                    label: "Applied On",
                    sorting: "DESC",
                },
            ],
        };
    },
    computed: {
        ...mapState("auth", ["userId", "userUnderwriterId"]),
        ...mapGetters("auth", [
            "isAdmin",
            "isBroker",
            "isNormalUser",
            "isUnderwriter",
            "userAttributes",
        ]),
        formatPolicyTableData() {
            if (this.policies.length > 0) {
                return this.policies.map((policy) => {
                    return {
                        id: policy.id,
                        policyNumber:
                            this.policyStatus ===
                            this.$policyValues.policyStatus.INPROGRESS.value
                                ? policy.quoteNumber
                                : policy.policyNumber,
                        customerName: policy.customerName || "----",
                        policyName: policy.policyName || "----",
                        policyStatus: {
                            type: "badge",
                            class: this.$policyValues.policyStatus[
                                policy.policyStatus
                            ]?.statusColor,
                            data: policy.policyStatus
                                ? this.$policyValues.policyStatus[
                                      policy.policyStatus
                                  ]?.text
                                : "----",
                        },
                        products: policy.products
                            ? this.formatProductsString(policy.products)
                            : "----",
                        creationTime: this.formatDateValue(
                            policy.creationTime,
                            "normal",
                            "dd MMMM yyyy"
                        ),
                    };
                });
            } else {
                return [];
            }
        },
        policyListErrorMessage() {
            if (this.policyError) {
                return this.policyErrorMessage;
            } else {
                if (this.policySearch) {
                    return this.$messages.errorMessages.notFoundWithFiltersError(
                        "policy"
                    );
                } else {
                    return this.$messages.errorMessages.notFoundError(
                        "policies"
                    );
                }
            }
        },
    },
    methods: {
        sortingChanged(data) {
            let headerItemIndex = this.policyHeaders.findIndex(
                (header) => header.key === data.column
            );
            if (headerItemIndex > -1) {
                this.policyHeaders[headerItemIndex].sorting = data.order;
            }
            this.sortColumn = data.appliedColumn;
            this.sortOrder = data.order;
            this.getPolicies(0, true);
        },
        searchFilterHandler: debounce(function () {
            this.getPolicies(0, true);
        }, 200),
        toggleDialog(props) {
            this.dialogProps = props;
        },
        async setGetPolicyUrl(page, status) {
            let response = null;
            let sorting = "";
            if (this.sortColumn) {
                sorting = `${this.sortColumn},${this.sortOrder}`;
            }
            if (status === this.$policyValues.policyStatus.INPROGRESS.value) {
                let requestBody = {
                    ...(this.policySearch && {
                        quoteNumber: this.policySearch.trim(),
                        customerName: this.policySearch.trim(),
                    }),
                    userId: this.userId,
                };
                response = await QuoteService.searchQuote(
                    page,
                    this.size,
                    requestBody,
                    sorting
                );
            } else {
                let statuses = [status];
                if (status === this.$policyValues.policyStatus.EXPIRED.value) {
                    statuses.push("OFFCOVER");
                } else if (
                    status === this.$policyValues.policyStatus.GENERATED.value
                ) {
                    statuses.push(
                        this.$policyValues.policyStatus.UNDERWRITER_APPROVED
                            .value
                    );
                } else if (
                    status === this.$policyValues.policyStatus.CANCELLED.value
                ) {
                    statuses.push(
                        this.$policyValues.policyStatus.CANCELLATION_REQUESTED
                            .value
                    );
                }
                let requestBody = {
                    policyStatus: statuses,
                    ...(this.policySearch && {
                        policyNumber: this.policySearch.trim(),
                        customerName: this.policySearch.trim(),
                    }),
                };
                if (this.isBroker) {
                    requestBody.brokerId =
                        this.userAttributes.brokerId ||
                        this.subDomainDetail.brokerId;
                } else if (this.isNormalUser) {
                    requestBody.userId = this.userId;
                }
                response = await PolicyService.getPolicies(
                    page,
                    this.size,
                    requestBody,
                    sorting
                );
            }
            return response;
        },
        async getPolicies(page, initial) {
            if (initial) {
                this.totalElements = 0;
                this.totalPages = 0;
                this.dialogProps = {};
                this.policies = [];
            }
            this.policyLoading = true;
            this.policyError = false;
            this.policyErrorMessage = "";
            this.pageNumber = page;
            try {
                let res = await this.setGetPolicyUrl(page, this.policyStatus);
                this.policies = res.data.content;
                this.totalElements = res.data.totalElements;
                this.totalPages = res.data.totalPages;
                this.policyLoading = false;
                this.routeChanged = false;
            } catch (err) {
                this.policyError = true;
                this.policyErrorMessage =
                    err?.response?.data?.message ||
                    this.$messages.errorMessages.loadFailError("policies");
                this.policyLoading = false;
                this.routeChanged = false;
            }
        },
        pageChangeHandler(number) {
            this.getPolicies(number, false);
        },
        tableItemClickHandler(id) {
            if (
                this.policyStatus ===
                this.$policyValues.policyStatus.INPROGRESS.value
            ) {
                this.$router.push({ path: `/quotes/${id}` });
            } else {
                this.$router.push(id);
            }
        },
        formatProductsString(products) {
            return Object.keys(JSON.parse(products))
                .filter((p) => p !== "common_fields")
                .map((p) => {
                    return this.$policyValues.productLabels[p];
                })
                .join(", ");
        },
    },
    watch: {
        $route: function () {
            this.routeChanged = true;
            this.getPolicies(0, true);
        },
        policySearch: function () {
            if (!this.routeChanged) {
                this.policyLoading = true;
                this.searchFilterHandler();
            }
        },
    },
};
</script>

<style scoped>
.list-container {
    height: calc(100vh - 356px);
    overflow: auto;
}
.list-container-loading {
    height: 50vh;
}
</style>
