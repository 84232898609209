<template>
    <div class="bev-container buy-policy position-relative">
        <bev-loading-block
            class="loading-block"
            v-if="productsError || productsLoading"
            :loading="productsLoading"
            :error="productsError"
            :retryHandler="getAllProducts"
            errorMessage="Failed to load Products"
        />
        <div class="row h-100 w-100 overflow-x-hidden position-relative m-0">
            <div
                class="col-sm-12 col-lg-4 d-flex align-items-center justify-content-center bg-baseLight z-index-2"
                v-if="showProductInfo"
            >
                <DirectBuyProductInfo />
            </div>
            <div
                :class="`col-sm-12 p-0 ${
                    showProductInfo ? 'col-lg-8' : 'col-lg-12'
                }`"
                v-if="mainProducts.length > 0"
            >
                <div
                    class="stepComponents w-100 h-100"
                    v-if="!productsLoading && !productsError"
                >
                    <transition :name="back ? 'slideback' : 'slide'">
                        <div
                            v-if="currentStepComponentData.component"
                            class="buy-policy-step"
                            :key="currentStepComponentData.key"
                        >
                            <component
                                :is="currentStepComponentData.component"
                                v-bind="currentStepComponentData.props"
                            />
                            <div class="contact-us-link" v-if="showContactUs">
                                Have any questions?
                                <a
                                    :href="`mailto:${subDomainDetail.clientEmail}`"
                                    >Contact Us</a
                                >
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <bev-loading-block
                v-if="
                    !productsLoading &&
                    !productsError &&
                    mainProducts.length === 0
                "
                :loading="false"
                :error="false"
                :errorMessage="'No product available'"
                :errorImage="$imagePath.insurance"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import ProductService from "@/services/ProductService";
import DirectBuyProductInfo from "@/components/Views/BuyPolicy/DirectBuyProductInfo";
import SelectClient from "@/components/Views/BuyPolicy/SelectClient/Main.vue";
import SelectLocation from "@/components/Views/BuyPolicy/SelectLocation/Main.vue";
import SelectCoverDuration from "@/components/Views/BuyPolicy/SelectCoverDuration/Main.vue";
import CoverDetails from "@/components/Views/BuyPolicy/CoverDetails/Main.vue";
import CoverSummary from "@/components/Views/BuyPolicy/CoverSummary/Main.vue";
import Installment from "@/components/Common/Payment/Installment/Main.vue";
import FullPayment from "@/components/Common/Payment/FullPayment/Main.vue";
import SelectProduct from "@/components/Views/BuyPolicy/SelectProduct/Main.vue";
import SelectPeril from "@/components/Views/BuyPolicy/SelectPeril/Main.vue";
import EventDetails from "@/components/Views/BuyPolicy/EventDetails/Main.vue";
import EventCancellation from "@/components/Views/BuyPolicy/EventCancellation/Main.vue";
import EventLiability from "@/components/Views/BuyPolicy/EventLiability/Main.vue";
import Money from "@/components/Views/BuyPolicy/Money/Main.vue";
import Confirmation from "@/components/Views/BuyPolicy/Confirmation/Main.vue";
import ApplicationStatus from "@/components/Common/ApplicationStatus.vue";
import RegisterUser from "@/components/Views/BuyPolicy/RegisterUser/Main.vue";
import ArtCentralExhibitor from "@/components/Views/BuyPolicy/ArtCentralExhibitor/Main.vue";
import EventCancellationTerrorism from "@/components/Views/BuyPolicy/EventCancellationTerrorism/Main.vue";
import EventCancellationNew from "@/components/Views/BuyPolicy/EventCancellationNew/Main.vue";
export default {
    props: {
        isPublic: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DirectBuyProductInfo,
        SelectClient,
        SelectLocation,
        SelectCoverDuration,
        CoverDetails,
        CoverSummary,
        Installment,
        FullPayment,
        SelectProduct,
        SelectPeril,
        EventDetails,
        EventCancellation,
        EventLiability,
        Money,
        Confirmation,
        ApplicationStatus,
        RegisterUser,
        ArtCentralExhibitor,
        EventCancellationTerrorism,
        EventCancellationNew,
    },
    mounted() {
        if (!this.isPublic) {
            this.getAllProducts();
        } else {
            this.productsLoading = false;
        }
    },
    data() {
        return {
            productsLoading: true,
            productsError: false,
            savingDraft: false,
            allSteps: [
                {
                    step: "SelectClient",
                    component: "SelectClient",
                },
                {
                    step: "SelectLocation",
                    component: "SelectLocation",
                },
                {
                    step: "SelectCoverDuration",
                    component: "SelectCoverDuration",
                },
                {
                    step: "CoverDetails",
                    component: "CoverDetails",
                },
                {
                    step: "SelectProduct",
                    component: "SelectProduct",
                },
                {
                    step: "SelectPeril",
                    component: "SelectPeril",
                },
                {
                    step: "EventDetails",
                    component: "EventDetails",
                },
                {
                    step: "EventCancellation",
                    component: "EventCancellation",
                },
                {
                    step: "EventLiability",
                    component: "EventLiability",
                },
                {
                    step: "Money",
                    component: "Money",
                },
                {
                    step: "EventCancellationTerrorism",
                    component: "EventCancellationTerrorism",
                },
                {
                    step: "RegisterUser",
                    component: "RegisterUser",
                },
                {
                    step: "ArtCentralExhibitor",
                    component: "ArtCentralExhibitor",
                },
                {
                    step: "EventCancellationNew",
                    component: "EventCancellationNew",
                },
                {
                    step: "CoverSummary",
                    component: "CoverSummary",
                },
                {
                    step: "Confirmation",
                    component: "Confirmation",
                },
                {
                    step: "Installment",
                    component: "Installment",
                },
                {
                    step: "FullPayment",
                    component: "FullPayment",
                    props: {
                        paymentFrom: "buyPolicy",
                    },
                },
                {
                    step: "ApplicationStatus",
                    component: "ApplicationStatus",
                    props: {
                        paymentStatus: "policyStatus.status",
                        heading: "policyStatus.heading",
                        description: "policyStatus.description",
                        quoteNumber: "policyStatus.quoteNumber",
                        extraInfo: "",
                        statusColor: "policyStatus.policyColor",
                    },
                },
            ],
        };
    },
    computed: {
        ...mapState("buyPolicy", [
            "currentStep",
            "back",
            "policyStatus",
            "productsJSON",
            "isEditingOld",
            "directBuyComplete",
            "currentStepIndex",
            "productsFlow",
            "isDirectBuyFlow",
        ]),
        ...mapState(["productSubDomainDetail"]),
        ...mapGetters(["productRequestBody"]),
        currentStepComponentData() {
            let currentIndex = this.allSteps.findIndex((step) => {
                return step.step === this.currentStep;
            });
            if (currentIndex > -1) {
                return {
                    ...this.allSteps[currentIndex],
                    key: currentIndex,
                };
            }
            return {};
        },
        showProductInfo() {
            return this.productSubDomainDetail?.name && this.isDirectBuyFlow;
        },
        mainProducts() {
            return this.productsJSON.filter((product) => {
                // Need to correct this because isParent is used to render product for initial selection
                if (!this.isPublic && !this.isDirectBuyFlow) {
                    return product.isParent && product.productType !== "DIRECT";
                }
                return product.isParent;
            });
        },
        singleEnabledProduct() {
            let enabledProducts = this.productsJSON.filter(
                (p) => p.isEnabled && p.isParent && p.productType !== "DIRECT"
            );
            if (enabledProducts.length === 1) {
                return enabledProducts[0].name;
            } else {
                return "";
            }
        },
        showContactUs() {
            return (
                this.currentStepComponentData.step &&
                this.currentStepComponentData.step !== "CoverSummary" &&
                this.currentStepComponentData.step !== "Confirmation"
            );
        },
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "clearPolicyValues",
            "setProductsJSON",
            "setPolicyProduct",
            "setBuyPolicyStep",
            "setCurrentStepIndex",
            "setProductsFlow",
        ]),
        saveSelectedProduct() {
            let selectedProductData = this.productsJSON.find((p) => {
                return p.name === this.singleEnabledProduct;
            });
            let filteredFlow =
                selectedProductData.questionBundleSequence.filter((seq) => {
                    if (this.isNormalUser) {
                        return seq.name !== "SelectClient";
                    }
                    return true;
                });
            this.setProductsFlow({
                flow: filteredFlow,
                productName: this.singleEnabledProduct,
            });
            this.setCurrentStepIndex(0);
            this.setPolicyProduct(this.singleEnabledProduct);
            this.setBuyPolicyStep({
                step: this.productsFlow[this.currentStepIndex].name,
                back: false,
            });
        },
        async getAllProducts() {
            this.productsLoading = true;
            this.productsError = false;
            try {
                const response = await ProductService.searchProducts(
                    0,
                    1000,
                    this.productRequestBody
                );
                this.setProductsJSON(response.data.content);
                if (!this.$route.query.type) {
                    if (this.singleEnabledProduct) {
                        this.saveSelectedProduct();
                    }
                }
                this.productsLoading = false;
            } catch (err) {
                this.productsLoading = false;
                this.productsError = true;
            }
        },
        resetBuyFlow() {
            if (!this.isPublic || (this.isPublic && !this.directBuyComplete)) {
                this.clearPolicyValues(this.isPublic ? "" : "SelectProduct");
            }
        },
        resetFlowOnUnload() {
            if (this.isPublic && !this.directBuyComplete) {
                this.resetBuyFlow();
            }
        },
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.resetFlowOnUnload);
    },
    beforeUnmount() {
        this.resetBuyFlow();
        window.removeEventListener("beforeunload", this.resetFlowOnUnload);
    },
};
</script>

<style>
.stepComponents {
    overflow: hidden;
}

.stepComponents .buy-policy-step {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slider-slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slider-slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slider-slide-fade-enter, .slider-slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(900px);
    opacity: 0;
}
.contact-us-link {
    position: absolute;
    right: 0;
    bottom: 0;
    background: var(--baseDark);
    padding: 10px;
    border-top-left-radius: 6px;
    font-size: 14px;
    z-index: 1;
    color: var(--contactUsTextColor);
}
</style>
