import config from "@/envConfig.js";
import APIService from "./APIService";

export default class QuoteService extends APIService {
    static addQuote(requestBody) {
        return this.post(`${config.policyServiceUrl}/quotes`, requestBody);
    }

    static updateQuote(quoteId, requestBody) {
        return this.put(
            `${config.policyServiceUrl}/quotes/${quoteId}`,
            requestBody
        );
    }

    static getQuotes(page, size, paramKey, paramVal) {
        return this.get(
            `${config.policyServiceUrl}/quotes?page=${page}&size=${size}&key=${paramKey}&val=${paramVal}&sort=creationTime,DESC`
        );
    }

    static getAllQuotes(page, size) {
        return this.get(
            `${config.policyServiceUrl}/quotes?page=${page}&size=${size}&sort=creationTime,DESC`
        );
    }

    static getSingleQuote(quoteId, params) {
        return this.get(
            `${config.policyServiceUrl}/quotes/${quoteId}${params}`
        );
    }

    static searchQuote(page, size, requestBody, sorting) {
        return this.post(
            `${config.policyServiceUrl}/quotes/search?page=${page}&size=${size}&sort=${sorting}`,
            requestBody
        );
    }

    static reopenQuote(quoteId) {
        return this.get(`${config.policyServiceUrl}/quotes/reopen/${quoteId}`);
    }

    static cloneQuote(quoteId) {
        return this.get(`${config.policyServiceUrl}/quotes/clone/${quoteId}`);
    }
}
