<template>
    <div class="px-20px">
        <bev-image
            :addBackground="false"
            :imageUrl="productSubDomainDetail.image"
            width="100%"
            height="auto"
            :imageAlt="'product_image'"
        ></bev-image>
        <p class="fw-bold font-20 mt-20px text-uppercase text-activeColorV1">
            {{ productSubDomainDetail.eventLocation || "" }}
        </p>
        <p class="fw-bold font-28 mt-10px">
            {{ eventDates }}
        </p>
    </div>
</template>

<script>
import { format } from "date-fns";
export default {
    computed: {
        eventDates() {
            try {
                let dates = this.productSubDomainDetail.eventDates.split("/");
                return `${format(
                    new Date(dates[0].replace(/-/g, "/")),
                    "do"
                )} - ${format(
                    new Date(dates[1].replace(/-/g, "/")),
                    "do MMMM yyyy"
                )}`;
            } catch (err) {
                return "";
            }
        },
    },
};
</script>
