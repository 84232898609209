const state = {
    access_token: "",
    refresh_token: "",
    id_token: "",
    isAuthenticated: false,
    user: {},
    roles: [],
    userId: "",
    userGroups: [],
    userUnderwriterId: "",
};

const getters = {
    userAttributes(state) {
        let formatted = {};
        if (state.user.attributes) {
            for (let [key, value] of Object.entries(state.user.attributes)) {
                if (Array.isArray(value)) {
                    formatted[key] = value[0] || null;
                } else {
                    formatted[key] = value;
                }
            }
        }
        return formatted;
    },
    isAdmin(state) {
        return state.roles.indexOf("admin") > -1;
    },
    isBroker(state) {
        return state.roles.indexOf("broker") > -1;
    },
    isUnderwriter(state) {
        return state.roles.indexOf("underwriter") > -1;
    },
    isNormalUser(state) {
        return (
            state.roles.indexOf("admin") < 0 &&
            state.roles.indexOf("broker") < 0 &&
            state.roles.indexOf("underwriter") < 0 &&
            state.roles.indexOf("claims") < 0
        );
    },
    isClaims(state) {
        return state.roles.indexOf("claims") > -1;
    },
    isReadOnly(state) {
        return state.roles.indexOf("claims") > -1;
    },
};

const mutations = {
    setAccessToken(state, token) {
        state.access_token = token;
    },
    setRefreshToken(state, token) {
        state.refresh_token = token;
    },
    setIdToken(state, token) {
        state.id_token = token;
    },
    setAuthenticated(state, auth) {
        state.isAuthenticated = auth;
    },
    setUser(state, user) {
        state.user = user;
    },
    setUserGroups(state, groups) {
        state.userGroups = groups;
    },
    setRoles(state, roles) {
        state.roles = roles;
    },
    setUserId(state) {
        let decodedToken = JSON.parse(atob(state.access_token.split(".")[1]));
        state.userId = decodedToken.sub;
    },
    setUserUnderwriterId(state, value) {
        state.userUnderwriterId = value;
    },
};

const actions = {
    setTokens: ({ commit }, data) => {
        commit("setAccessToken", data.accessToken);
        commit("setRefreshToken", data.refreshToken);
        commit("setIdToken", data.idToken);
    },
    setUserInfo: ({ commit }, data) => {
        commit("setUser", data.user);
        commit("setRoles", data.roles);
        commit("setUserGroups", data.groups || []);
        commit("setUserUnderwriterId", data.underwriterId || "");
    },
    logout: ({ commit }) => {
        commit("setAccessToken", "");
        commit("setRefreshToken", "");
        commit("setIdToken", "");
        commit("setAuthenticated", false);
        commit("setUser", {});
        commit("setRoles", []);
        commit("setUserGroups", []);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
