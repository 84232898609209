<template>
    <div class="bev-container buy-policy">
        <div
            class="pb-30px pt-50px px-20px w-100 h-100 overflow-auto d-flex flex-column"
        >
            <heading-block
                :align="'center'"
                :heading="'Analytics'"
                :headingSizeClass="'font-22 sm-font-16'"
                :descriptionSizeClass="'font-16 sm-font-12'"
            />

            <bev-form
                class="analytics-form"
                :submitData="addMetricsHandler"
                :submitButton="{
                    text: 'Add Metric',
                    buttonType: 'submit',
                    loading: addingMetrics,
                }"
                showSingleButton
            >
                <template v-slot:input-fields>
                    <div class="analytics-form-content pt-20px mt-30px">
                        <div class="form-fields">
                            <div class="dropdown-container">
                                <bev-dropdown
                                    :containerClass="'metrices'"
                                    :dropDownLabel="'Select Metric'"
                                    :haveError="
                                        v$.selectedMetrics.$error &&
                                        v$.selectedMetrics.required.$invalid
                                    "
                                    :ajax="true"
                                    :apiUrl="`${config.analyticsServiceUrl}/metric`"
                                    :responseInfo="{
                                        resultKey: 'content',
                                        totalResultKey: 'totalElements',
                                        pagination: true,
                                        uniqueKey: 'id',
                                    }"
                                    :errorMessage="'Please choose a metrics'"
                                    :apiRequestParam="`userId=${userId}`"
                                    v-model="selectedMetrics"
                                    :optionTextHandler="
                                        (option) => {
                                            return option.name;
                                        }
                                    "
                                    :refreshAjaxCounter="
                                        refreshAllMetricCounter
                                    "
                                    class="m-auto"
                                ></bev-dropdown>
                            </div>
                        </div>
                    </div>
                </template>
            </bev-form>
            <MetricsContainer
                :updateGettingUserMetricsCounter="
                    updateGettingUserMetricsCounter
                "
            />
        </div>
    </div>
</template>

<script>
/**
 * Analytics Dashboard
 */
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapMutations, mapState } from "vuex";
import config from "@/envConfig.js";
import AnalyticsService from "@/services/AnalyticsService";
import MetricsContainer from "@/components/Views/Analytics/MetricsContainer";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    validations: {
        selectedMetrics: {
            required,
        },
    },
    components: {
        MetricsContainer,
    },
    data() {
        return {
            selectedMetrics: null,
            addingMetrics: false,
            gettingMetrices: false,
            metricesError: false,
            updateGettingUserMetricsCounter: 0,
        };
    },
    computed: {
        ...mapState("auth", ["userId"]),
        ...mapState("analytics", ["userMetrices", "refreshAllMetricCounter"]),
        ...mapGetters("auth", ["isAdmin", "isBroker", "isUnderwriter"]),
    },
    created() {
        this.config = config;
    },
    methods: {
        ...mapMutations("analytics", [
            "incrementRefreshAllMetricCounter",
            "resetMetricFilter",
        ]),
        addMetricsHandler() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.addMetrics();
            }
        },
        async addMetrics() {
            this.addingMetrics = true;
            try {
                await AnalyticsService.addUserMetric({
                    userId: this.userId,
                    metricId: this.selectedMetrics?.id,
                });
                this.updateGettingUserMetricsCounter++;
                this.incrementRefreshAllMetricCounter();
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    text: this.$messages.successMessages.metricSuccess("added"),
                    type: "success",
                });
            } catch (err) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.metricError("add")
                );
            } finally {
                this.addingMetrics = false;
            }
        },
    },
    beforeUnmount() {
        this.resetMetricFilter();
    },
    watch: {
        refreshAllMetricCounter: function () {
            this.v$.$reset();
        },
    },
};
</script>

<style scoped>
.analytics-form {
    display: flex;
    flex-direction: column;
}

.form-fields {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
/* .product-dashboard-form-content {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
} */
</style>
