<template>
    <div :class="{ 'opacity-7': disabled }">
        <label
            class="d-flex align-items-end font-16 sm-font-12 fw-600 justify-content-between"
        >
            <div>
                {{ question }}
                <bev-image
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.inputInfoIcon"
                    :imageUrl="$imagePath.info"
                    width="16px"
                    height="14.7px"
                    v-tooltip="{
                        content: infoImage.info,
                        html: true,
                        placement: infoImage.tooltipPlacement || 'auto',
                        showTriggers: (events) => [...events, 'click'],
                        autoHide: true,
                        delay: 0,
                    }"
                    class="cursor-pointer mx-5px"
                    v-if="infoImage.show"
                    :imageAlt="'info-icon'"
                ></bev-image>
            </div>
            <p
                class="extra-info-text text-activeColorV2 fst-italic fw-normal font-12"
                v-if="extraInfoText"
            >
                {{ extraInfoText }}
            </p>
        </label>
        <div class="d-flex align-items-center mt-20px flex-wrap">
            <bev-selectable-tag
                v-for="(option, index) in options"
                :key="index"
                :tagText="option"
                :clickHandler="selectHandler.bind(this, option)"
                :selected="selectedOption === option"
                class="mr-10px small"
                :disabled="disabled"
            >
                <p>{{ option }}</p>
            </bev-selectable-tag>
        </div>
        <input-error v-if="error" :errorMessage="errorMessage"></input-error>
    </div>
</template>

<script>
export default {
    props: {
        question: String,
        selectHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        error: Boolean,
        errorMessage: String,
        selectedOption: String,
        options: Array,
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        extraInfoText: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.section-label {
    font-size: 16px;
    font-weight: 600;
}
.opacity-7 {
    opacity: 0.7;
    pointer-events: none;
}
</style>
