<template>
    <bev-card class="mt-10px" :cardType="'policy'">
        <template v-slot:card-header-info>
            <div class="card-heading mb-20px">
                Quote Number:
                <span
                    :class="
                        'text-' + $policyValues.policyStatus[status].statusColor
                    "
                    >{{ policyNumber }}</span
                >
            </div>
            <div class="card-info font-12 fst-italic">
                <div class="d-flex align-items-center flex-wrap mt-5px">
                    <p class="w-50">
                        Applied On:
                        <span class="fw-600">{{ policyAppliedOn }}</span>
                    </p>
                </div>
                <div class="d-flex align-items-center flex-wrap mt-5px">
                    <p>
                        Products: <span class="fw-600">{{ products }}</span>
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:card-content>
            <span class="font-16">{{
                $policyValues.policyStatus[status].cardInfo
            }}</span>
        </template>
        <template v-slot:card-footer>
            <div class="d-flex align-items-center justify-content-end">
                <bev-button
                    :themeType="'dark'"
                    :clickHandler="secondaryClickHandler"
                    :class="'small'"
                >
                    View
                </bev-button>
            </div>
        </template>
    </bev-card>
</template>
<script>
export default {
    props: {
        id: String,
        status: String,
        products: String,
        policyNumber: String,
        policyAppliedOn: String,
    },
    emits: ["toggleDialog"],
    methods: {
        primaryClickHandler() {
            if (
                this.status === this.$policyValues.policyStatus.INPROGRESS.value
            ) {
                this.$emit("toggleDialog", {
                    state: true,
                    title: `Claim Status - Quote Number: ${this.id}`,
                    description:
                        "Your Excess-Rainfall policy was triggered on the 31st August 2021. Once a claim is triggered, we can make a payout in under 24 hours. You can track the status of your claim settlement below",
                });
            }
        },
        secondaryClickHandler() {
            if (
                this.status === this.$policyValues.policyStatus.INPROGRESS.value
            ) {
                this.$router.push({ path: `/quotes/${this.id}` });
            } else {
                this.$router.push(this.id);
            }
        },
    },
};
</script>

<style scoped>
.card-heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
}
</style>
