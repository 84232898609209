<template>
    <bev-form
        :submitData="actionHandler"
        :showSingleButton="false"
        :submitButton="{
            text: 'Submit',
            loading: actionLoading,
            buttonType: 'submit',
        }"
        :resetButton="{
            text: 'Cancel',
            buttonType: 'reset',
            clickHandler: closeHandler,
        }"
        noActionBottomPadding
    >
        <template v-slot:input-fields>
            <p class="popup-heading">
                Are you sure? If yes, please provide a {{ actionText }} reason.
            </p>
            <bev-dropdown
                v-if="showDefaultReasons"
                :dropDownLabel="`${inputLabel} Reason`"
                :containerClass="`${inputLabel}-reason-container`"
                :ajax="dropDownDetails.ajax"
                :apiUrl="dropDownDetails.apiUrl"
                :apiRequestParam="dropDownDetails.apiRequestParam"
                :apiMethod="dropDownDetails.apiMethod"
                :responseInfo="dropDownDetails.responseInfo"
                :optionTextHandler="dropDownDetails.optionTextHandler"
                :haveError="
                    v$.defaultReason.$error &&
                    v$.defaultReason.required.$invalid
                "
                :errorMessage="
                    $messages.errorMessages.dropdownSelectError('an option')
                "
                v-model="v$.defaultReason.$model"
                :fullWidthInput="false"
            ></bev-dropdown>
            <basic-textarea
                v-if="showTextInput"
                :class="`${actionText}-reason-container mt-10px`"
                :inputLabel="`Enter ${
                    !showDefaultReasons ? inputLabel : ''
                } Reason`"
                :inputClass="`${inputLabel}-reason`"
                :haveError="v$.reason.$error && v$.reason.required.$invalid"
                :errorMessage="
                    $messages.errorMessages.fieldCannotEmptyError(
                        `${inputLabel} Reason`
                    )
                "
                v-model="v$.reason.$model"
            ></basic-textarea>
        </template>
    </bev-form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BasicTextarea from "@/components/Common/Input/BasicTextarea";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        policyAction: Function,
        actionLoading: Boolean,
        closeHandler: Function,
        actionText: String,
        inputLabel: String,
        rejectStatus: String,
        showDefaultReasons: {
            type: Boolean,
            default: false,
        },
        dropDownDetails: {
            type: Object,
            default: () => {},
        },
        reasonRequired: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            reason: "",
            defaultReason: {},
        };
    },
    components: {
        BasicTextarea,
    },
    validations() {
        return {
            defaultReason: {
                required:
                    this.reasonRequired && this.showDefaultReasons
                        ? required
                        : false,
            },
            reason: {
                required:
                    (!this.showDefaultReasons ||
                        this.defaultReason.value === "Other") &&
                    this.reasonRequired
                        ? required
                        : false,
            },
        };
    },
    computed: {
        showTextInput() {
            return (
                (this.defaultReason && this.defaultReason.value === "Other") ||
                !this.showDefaultReasons
            );
        },
        reasonToSend() {
            if (
                this.showDefaultReasons &&
                this.defaultReason.value !== "Other"
            ) {
                return this.defaultReason.value;
            }

            if (this.reason || !this.reasonRequired) {
                return this.reason;
            }

            return "";
        },
    },
    methods: {
        actionHandler() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.policyAction(this.rejectStatus, this.reasonToSend);
            }
        },
    },
};
</script>

<style scoped>
.form-actions {
    padding-bottom: 10px !important;
}
.popup-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
</style>
