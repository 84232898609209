<template>
    <div class="position-relative">
        <BevTable
            :tableHeight="'100vh - 400px'"
            :headers="quoteHeaders"
            :data="formatQuoteTableData"
            :tableLoading="quoteLoading"
            :tableError="quoteError"
            :retryHandler="getQuotes.bind(this, pageNumber)"
            :allowPagination="true"
            :totalData="totalElements"
            :paginationSize="size"
            :pageNumber="pageNumber"
            :totalPages="totalPages"
            :pageChangeHandler="pageChangeHandler"
            :tableItemClickHandler="tableItemClickHandler"
            :errorMessage="quoteListErrorMessage"
            :appliedSortColumn="sortColumn"
            @sortingChanged="sortingChanged"
            v-if="!isNormalUser"
        />
        <NormalUserViewer
            :quotes="quotes"
            :quoteLoading="quoteLoading"
            :quoteError="quoteError"
            :getQuotes="getQuotes"
            :totalData="totalElements"
            :paginationSize="size"
            :pageNumber="pageNumber"
            :totalPages="totalPages"
            :pageChangeHandler="pageChangeHandler"
            v-else
        />
    </div>
</template>
<script>
import QuoteService from "@/services/QuoteService";
import { mapGetters, mapState } from "vuex";
import debounce from "lodash.debounce";
import BevTable from "@/components/Common/Table/Main.vue";
import NormalUserViewer from "@/components/Views/Quotes/NormalUserView.vue";
export default {
    components: {
        BevTable,
        NormalUserViewer,
    },
    props: {
        quoteStatus: String,
        quoteSearch: String,
    },
    mounted() {
        this.getQuotes(0, true);
    },
    computed: {
        ...mapGetters("auth", ["isNormalUser", "isBroker", "userAttributes"]),
        ...mapState("auth", ["userId"]),
        formatQuoteTableData() {
            if (this.quotes.length > 0) {
                return this.quotes.map((quote) => {
                    return {
                        id: quote.id,
                        quoteNumber: quote.quoteNumber,
                        customerName: quote.customerName || "----",
                        policyName: quote.policyName || "----",
                        quoteStatus: {
                            type: "badge",
                            class: this.$policyValues.quoteStatus[
                                quote.quoteStatus
                            ]?.statusColor,
                            data: quote.quoteStatus
                                ? this.$policyValues.quoteStatus[
                                      quote.quoteStatus
                                  ]?.text
                                : "----",
                        },
                        products: quote.products
                            ? this.formatProductsString(quote.products)
                            : "----",
                        creationTime: this.formatDateValue(
                            quote.creationTime,
                            "normal",
                            "dd MMMM yyyy"
                        ),
                    };
                });
            } else {
                return [];
            }
        },
        quoteListErrorMessage() {
            if (this.quoteError) {
                return this.quoteErrorMessage;
            } else {
                if (this.quoteSearch) {
                    return this.$messages.errorMessages.notFoundWithFiltersError(
                        "quote"
                    );
                } else {
                    return this.$messages.errorMessages.notFoundError("quotes");
                }
            }
        },
    },
    data() {
        return {
            quotes: [],
            pageNumber: 0,
            size: 12,
            totalElements: 0,
            totalPages: 0,
            quoteLoading: false,
            quoteError: false,
            quoteErrorMessage: "",
            sortColumn: "creationTime",
            sortOrder: "DESC",
            routeChanged: false,
            quoteHeaders: [
                {
                    key: "quoteNumber",
                    label: "Quote Number",
                    sorting: "",
                },
                {
                    key: "customerName",
                    label: "Insured Name",
                    sorting: "",
                },
                {
                    key: "policyName",
                    label: "Event Name",
                    sorting: "",
                },
                {
                    key: "quoteStatus",
                    label: "Quote Status",
                    sorting: "",
                },
                {
                    key: "products",
                    label: "Products",
                    sorting: "",
                },
                {
                    key: "creationTime",
                    label: "Applied On",
                    sorting: "DESC",
                },
            ],
        };
    },
    methods: {
        sortingChanged(data) {
            let headerItemIndex = this.quoteHeaders.findIndex(
                (header) => header.key === data.column
            );
            if (headerItemIndex > -1) {
                this.quoteHeaders[headerItemIndex].sorting = data.order;
            }
            this.sortColumn = data.column;
            this.sortOrder = data.order;
            this.getQuotes(0, true);
        },
        searchFilterHandler: debounce(function () {
            this.getQuotes(0, true);
        }, 400),
        async getQuotes(page, initial) {
            if (initial) {
                this.totalElements = 0;
                this.totalPages = 0;
                this.quotes = [];
            }
            this.quoteLoading = true;
            this.quoteError = false;
            this.quoteErrorMessage = "";
            this.pageNumber = page;
            let statuses;
            if (this.quoteStatus === "ACTIVE") {
                statuses = Object.keys(this.$policyValues.quoteStatus).filter(
                    (status) => {
                        return !this.$policyValues.quoteStatus[status]
                            .isFinalState;
                    }
                );
            } else {
                statuses = Object.keys(this.$policyValues.quoteStatus).filter(
                    (status) => {
                        return this.$policyValues.quoteStatus[status]
                            .isFinalState;
                    }
                );
            }
            let requestBody = {
                ...(this.quoteSearch && {
                    quoteNumber: this.quoteSearch.trim(),
                    customerName: this.quoteSearch.trim(),
                }),
                ...(this.isBroker && {
                    brokerId:
                        this.userAttributes.brokerId ||
                        this.subDomainDetail.brokerId,
                }),
                quoteStatus: statuses,
            };
            let sorting = "";
            if (this.sortColumn) {
                sorting = `${this.sortColumn},${this.sortOrder}`;
            }
            try {
                let res = await QuoteService.searchQuote(
                    page,
                    this.size,
                    requestBody,
                    sorting
                );
                this.quotes = res.data.content;
                this.totalElements = res.data.totalElements;
                this.totalPages = res.data.totalPages;
                this.quoteLoading = false;
                this.routeChanged = false;
            } catch (err) {
                this.quoteError = true;
                this.quoteErrorMessage =
                    err?.response?.data?.message ||
                    this.$messages.errorMessages.loadFailError("quotes");
                this.quoteLoading = false;
                this.routeChanged = false;
            }
        },
        pageChangeHandler(number) {
            this.getQuotes(number, false);
        },
        tableItemClickHandler(id) {
            this.$router.push({ path: `/quotes/${id}` });
        },
        formatProductsString(products) {
            return Object.keys(JSON.parse(products))
                .filter((p) => p !== "common_fields")
                .map((p) => {
                    return this.$policyValues.productLabels[p];
                })
                .join(", ");
        },
    },
    watch: {
        $route: function () {
            this.routeChanged = true;
            this.getQuotes(0, true);
        },
        quoteSearch: function () {
            if (!this.routeChanged) {
                this.quoteLoading = true;
                this.searchFilterHandler();
            }
        },
    },
};
</script>

<style scoped>
.list-container {
    height: calc(100vh - 356px);
    overflow: auto;
}
.list-container-loading {
    height: 50vh;
}
</style>
