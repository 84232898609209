<template>
    <div
        class="d-flex sm-flex-column align-items-center sm-align-items-start justify-content-between suggestion-item"
    >
        <div>
            <div class="suggestion-heading font-18 sm-font-14">
                {{ selectedSuggestionData.label }}
            </div>
            <div class="suggestion-description font-14 sm-font-12">
                {{ selectedSuggestionData.description }}
            </div>
        </div>
        <bev-button
            :themeType="'white'"
            :clickHandler="addSuggestion.bind(this, suggestion)"
            :buttonType="'button'"
            :loading="false"
            class="small sm-mt-20px"
        >
            Add
        </bev-button>
    </div>
</template>

<script>
/**
 * Single suggestion block
 * @param {Object} suggestion data for a single suggestion
 * @param {Function} addSuggestion handler to add suggestion
 */
import { mapState } from "vuex";
export default {
    props: {
        suggestion: Object,
        addSuggestion: Function,
    },
    computed: {
        ...mapState("buyPolicy", ["productsJSON"]),
        selectedSuggestionData() {
            return this.productsJSON.find((product) => {
                return product.name === this.suggestion.name;
            });
        },
    },
};
</script>

<style scoped>
.suggestion-item {
    padding: 24px 0;
    border-top: 0.5px solid white;
}
.suggestion-item:nth-child(2) {
    border-top: 0.5px solid transparent;
}
.suggestion-heading {
    font-weight: 600;
    word-spacing: 1px;
    margin-bottom: 12px;
    color: var(--veryLightSecondary);
}
.suggestion-description {
    color: var(--summarySuggestionDescriptionTextColor);
}
.svg-image:hover {
    background: var(--activeColorV1) !important;
}
</style>
