import { mapMutations, mapState } from "vuex";
import LivePricing from "@/services/LivePricing";

export default {
    data() {
        return {
            gettingEventCancellationV2CoverTypes: false,
            eventCancellationV2CoverTypes: [],
        };
    },
    computed: {
        ...mapState("buyPolicy", ["products"]),
        eventCancellationEnabled() {
            return this.products.indexOf("event_cancellation") > -1;
        },
        getQuestionResponses() {
            const eventHeldBeforeV2 =
                this.questionBundles["EventCancellationNew"]
                    .eventHeldBeforeV2 || "";
            const insuredTotalExpensesV2 =
                this.questionBundles["EventCancellationNew"]
                    .insuredTotalExpensesV2 || "";
            const insuredTotalRevenueV2 =
                this.questionBundles["EventCancellationNew"]
                    .insuredTotalRevenueV2 || "";
            if (
                this.eventCancellationEnabled &&
                eventHeldBeforeV2 &&
                insuredTotalExpensesV2 &&
                insuredTotalRevenueV2
            ) {
                return {
                    eventHeldBeforeV2: eventHeldBeforeV2,
                    insuredTotalExpensesV2: insuredTotalExpensesV2,
                    insuredTotalRevenueV2: insuredTotalRevenueV2,
                };
            }
            return {};
        },
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setQuestionBundle",
            "addIsInvalidData",
            "removeIsInvalidData",
            "addInvalidProducts",
            "removeInvalidProducts",
        ]),
        // Add or remove the product in invalid product list, based on cover types
        validateProducts() {
            this.removeInvalidProducts("event_cancellation");
            if (!this.eventCancellationV2CoverTypes.length) {
                this.addInvalidProducts("event_cancellation");
            }
        },
        // Fetch the cover types for event cancellation
        async getEventCancellationV2CoverTypes() {
            this.gettingEventCancellationV2CoverTypes = true;
            this.addIsInvalidData("gettingEventCancellationV2CoverTypes");
            this.removeIsInvalidData("eventCancellationV2CoverTypes");
            try {
                let res = await LivePricing.livePricingCore({
                    product: "arch_contingency",
                    query: "partial_rating",
                    productConfig: {
                        productName: "event_cancellation",
                        eventDetails: {
                            eventStartDate: this.startDate,
                            eventType: this.eventTypeOptionHandler(
                                this.eventType
                            ),
                            eventSetting: this.eventSetting,
                        },
                        specifics: {
                            ...this.getQuestionResponses,
                        },
                    },
                });
                if (res.data?.body?.keys) {
                    this.eventCancellationV2CoverTypes = res.data.body;
                }
                this.gettingEventCancellationV2CoverTypes = false;
                this.removeIsInvalidData(
                    "gettingEventCancellationV2CoverTypes"
                );
                if (
                    this.eventCancellationEnabled &&
                    this.eventCancellationV2CoverTypes.indexOf(
                        this.questionBundles["EventCancellationNew"].coverTypeV2
                    ) < 0
                ) {
                    this.setQuestionBundle({
                        ...this.questionBundles,
                        EventCancellationNew: {
                            ...this.questionBundles["EventCancellationNew"],
                            coverTypeV2: "",
                        },
                    });
                    this.addIsInvalidData("eventCancellationV2CoverTypes");
                }
            } catch (err) {
                this.removeIsInvalidData(
                    "gettingEventCancellationV2CoverTypes"
                );
                this.addIsInvalidData("eventCancellationV2CoverTypes");
                this.displayErrorMessage(
                    "Failed to fetch cover types for Event Cancellation V2. Please try reloading"
                );
            }
        },
    },
    watch: {
        eventSetting: function (val) {
            if (val) {
                if (this.eventCancellationEnabled) {
                    this.removeIsInvalidData("proceedInSustainWindSpeedV2");
                    if (val === "Indoor") {
                        if (
                            this.questionBundles["EventCancellationNew"]
                                ?.proceedInSustainWindSpeedV2
                        ) {
                            this.setQuestionBundle({
                                ...this.questionBundles,
                                EventCancellationNew: {
                                    ...this.questionBundles[
                                        "EventCancellationNew"
                                    ],
                                    proceedInSustainWindSpeedV2: null,
                                },
                            });
                        }
                    } else {
                        if (
                            !this.questionBundles["EventCancellationNew"]
                                .proceedInSustainWindSpeedV2
                        ) {
                            this.addIsInvalidData(
                                "proceedInSustainWindSpeedV2"
                            );
                        }
                    }
                }
            }
        },
    },
};
