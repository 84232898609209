<template>
    <div class="text-left">
        <p>
            This document describes the Terms and Conditions between the
            customer ("you" or "your") and BirdsEyeView Technologies Limited
            ("we" or "our") when you use our website
            (www.birdseyeviewtechnologies.com).
        </p>
        <br />

        <strong> WHO WE ARE </strong>
        <p class="mt-10px">
            BirdsEyeView Technologies Limited is an insurance intermediary
            authorised and regulated by the Financial Conduct Authority. The
            company is entered on the FCA register (www.fca.org.uk/register)
            under reference 922017. BirdsEyeView Technologies Limited is
            registered in England and Wales Registration No. 12341808. The
            registered office is at Copenhagen House, 5-10 Bury Street, London,
            EC3A 5AT.
        </p>
        <br />

        <strong> LAW AND JURISDICTION </strong>
        <p class="mt-10px">
            These terms of business shall be governed by and construed in
            accordance with English law. In relation to any legal action or
            proceedings arising out of or in connection with these terms of
            business we both irrevocably submit to the exclusive jurisdiction of
            the English Courts.
        </p>

        <br />

        <strong> AMENDMENTS TO OUR TERMS </strong>
        <p class="mt-10px">
            We may revise these terms of use at any time by amending this page.
            The amended terms will be effective from the date they are posted.
            You are expected to check this page from time to time to take notice
            of any changes we made, as they are binding on you. Some of the
            provisions contained in these terms of use may also be superseded by
            provisions or notices published elsewhere on our service. We will
            however, always aim to notify you of important changes to any terms
            of use.
        </p>
        <br />
        <p>© 2019 BirdsEyeView Technologies Ltd</p>
    </div>
</template>
