import config from "@/envConfig.js";
import APIService from "./APIService";

export default class AnalyticsService extends APIService {
    static getAvailableMetrices() {
        return this.get(`${config.analyticsServiceUrl}/metric`);
    }

    static addUserMetric(requestBody, cancelToken) {
        return this.post(
            `${config.analyticsServiceUrl}/user-metrics`,
            requestBody,
            cancelToken
        );
    }

    static getUserMetrices(page, size, userId) {
        return this.get(
            `${config.analyticsServiceUrl}/user-metrics/user/${userId}?page=${page}&size=${size}`
        );
    }

    static getSingleMetric(metricId) {
        return this.get(`${config.analyticsServiceUrl}/metric/${metricId}`);
    }

    static deleteUserMetric(userId, metricId) {
        return this.delete(
            `${config.analyticsServiceUrl}/user-metrics/user/${userId}/metric/${metricId}`
        );
    }

    static analyseMetric(requestBody, cancelToken) {
        return this.post(
            `${config.analyticsServiceUrl}/analyse`,
            requestBody,
            cancelToken
        );
    }
}
