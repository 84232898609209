<template>
    <div class="endorsements-container mt-20px">
        <p class="heading font-22 sm-font-16">Endorsements</p>
        <AddEndorsement :addEndorsement="addEndorsement" v-if="isEditable" />
        <EndorsementList
            :endorsements="endorsements"
            :details="details"
            :deleteEndorsement="deleteEndorsement"
            :isEditable="isEditable"
            :updateQuote="updateQuote"
            :updatingQuote="updatingQuote"
        />
    </div>
</template>

<script>
import AddEndorsement from "@/components/Views/Details/Endorsements/AddEndorsement";
import EndorsementList from "@/components/Views/Details/Endorsements/EndorsementList";
export default {
    props: {
        endorsements: {
            type: Array,
            default: () => [],
        },
        details: Object,
        addEndorsement: Function,
        deleteEndorsement: Function,
        isEditable: {
            type: Boolean,
            default: false,
        },
        updateQuote: Function,
        updatingQuote: Boolean,
    },
    components: {
        AddEndorsement,
        EndorsementList,
    },
};
</script>
