<template>
    <notifications
        group="BEV"
        :position="'top right'"
        :duration="4000"
        :max="2"
        :closeOnClick="true"
        animation-name="v-fade-right"
    >
        <template v-slot:body="props">
            <div
                class="vue-notification d-flex align-items-center justify-content-between"
                :class="props.item.type"
            >
                <div
                    class="d-flex align-items-center justify-content-between w-100"
                >
                    <div class="d-flex align-items-start">
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="notificationColor(props)"
                            :imageUrl="getSrc(props)"
                            :imageAlt="'status-icon'"
                            width="30px"
                            height="30px"
                            class="notification-icon mr-15px notification-icon"
                        ></bev-image>
                        <div>
                            <div class="notification-title">
                                <p class="m-0">{{ props.item.title }}</p>
                            </div>
                            <div
                                v-html="props.item.text"
                                class="notification-text"
                            ></div>
                        </div>
                    </div>
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="'var(--notificationPopColor)'"
                        :imageUrl="$imagePath.cross"
                        :clickHandler="props.close"
                        :imageAlt="'close-icon'"
                        width="15px"
                        height="15px"
                        class="ml-15px close-icon cursor-pointer"
                    ></bev-image>
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
/**
 * Showing global notification
 *
 */
export default {
    data() {
        return {
            animation: {
                enter() {
                    /*
                     *  "element" - is a notification element
                     *    (before animation, meaning that you can take it's initial height, width, color, etc)
                     */

                    return {
                        // Animates from 0 to random opacity (in range between 0.5 and 1)
                        opacity: [1, 0],
                    };
                },
                leave: {
                    opacity: 0,
                },
            },
        };
    },
    methods: {
        getSrc(props) {
            if (props.item.type === "error" || props.item.type === "warning") {
                return this.$imagePath.alert;
            } else if (props.item.type === "success") {
                return this.$imagePath.checkMark;
            } else {
                return this.$imagePath.info;
            }
        },
        notificationColor(props) {
            switch (props.item.type) {
                case "error":
                    return this.$colors.bevColors.danger;
                case "warning":
                    return this.$colors.bevColors.yellow;
                case "success":
                    return this.$colors.bevColors.activeColorV1;
                default:
                    return this.$colors.bevColors.activeColorV2;
            }
        },
    },
};
</script>

<style>
.v-fade-right-enter-active,
.v-fade-right-leave-active,
.v-fade-right-move {
    transition: all 0.5s;
}

.v-fade-right-enter,
.v-fade-right-leave-to {
    opacity: 0;
    transform: translateX(500px) scale(0.2);
}
</style>

<style scoped>
.vue-notification-group .vue-notification {
    box-shadow: var(--notificationPopBoxShadow);
    border-radius: 8px;
    background: var(--notificationPopBgColor) !important;
}

.vue-notification {
    border: none !important;
}

.notification-title {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}

.vue-notification-group .vue-notification.success .notification-title {
    color: var(--activeColorV1);
}

.vue-notification-group .vue-notification.error .notification-title {
    color: var(--danger);
}

.vue-notification-group .vue-notification.success .notification-title {
    color: var(--activeColorV1);
}

.vue-notification-group .vue-notification.info .notification-title {
    color: var(--activeColorV2);
}

.vue-notification-group .vue-notification.warning .notification-title {
    color: var(--yellow);
}

.notification-text {
    color: var(--notificationPopColor);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    overflow-wrap: anywhere;
}

.notification-icon {
    min-width: 30px;
}

.close-icon {
    min-width: 15px;
}
</style>
