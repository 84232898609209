<template>
    <div
        class="d-flex align-items-end justify-content-center flex-column p-10px"
    >
        <img
            :src="subDomainDetail.additionalLogo || ''"
            alt="Main Logo"
            :style="{
                width: width,
                maxWidth: width,
                height: height,
                maxWidth: maxWidth,
            }"
            class="mx-auto logo-image"
            v-if="subDomainDetail.additionalLogo"
            @load="setImageLoaded"
        />
    </div>
</template>

<script>
/**
 * Display Additional logo of the BEV
 *
 * @param {String} width defines the width of logo
 *
 */
export default {
    name: "AdditionalLogo",
    props: {
        width: {
            type: String,
            default: "100px",
        },
        height: {
            type: String,
            default: "auto",
        },
        maxWidth: {
            type: String,
            default: "100px",
        },
    },
    data() {
        return {
            imageLoaded: false,
        };
    },
    methods: {
        setImageLoaded() {
            this.imageLoaded = true;
        },
    },
};
</script>

<style scoped>
.logo-image {
    max-height: 100%;
    object-fit: contain;
}
</style>
