<template>
    <div
        class="comment text-left px-20px py-10px bg-veryLightSecondary br-10px mb-20px"
    >
        <p class="text-messageTitle font-12 fw-bold mb-5px">
            {{ commentedBy }}
        </p>
        <div
            class="d-flex align-items-end justify-space-between text-baseDark font-14"
        >
            <p class="mw-80">{{ message }}</p>
            <span class="font-10 ml-10px">{{ commentDateTime }}</span>
        </div>
    </div>
</template>

<script>
/**
 * Single comment component
 * @param {String} commentedBy username of the user of the comment
 * @param {String} message message of the comment
 * @param {String} commentTime time of comment
 */
export default {
    props: {
        commentedBy: String,
        message: String,
        commentTime: String,
    },
    computed: {
        commentDateTime() {
            return this.formatDateValue(new Date(this.commentTime), "day-time");
        },
    },
};
</script>

<style scoped>
.comment {
    max-width: 100%;
    width: max-content;
}
</style>
