<template>
    <bev-input
        :inputType="'text'"
        :inputLabel="inputLabel ? currencyInputLabel : ''"
        :haveError="haveError"
        :errorMessage="errorMessage"
        :validationType="'currency'"
        :inputClass="inputClass"
        :infoImage="infoImage"
        :disabled="disabled"
        :loading="loading"
        :inputPlaceholder="inputPlaceholder"
        :fullWidthInput="fullWidthInput"
        :extraInfoText="extraInfoText"
        @blur="currencyInputBlur"
        @update:modelValue="(e) => $emit('update:modelValue', e)"
        :modelValue="modelValue"
    ></bev-input>
</template>

<script>
/**
 * This is BEV custom currency input which will be used wherever there is form data
 *
 * @param {String} inputPlaceholder for input placeholder
 * @param {String} inputLabel for input label prop
 * @param {String} modelValue for input value prop
 * @param {Boolean} disabled for input disabled prop
 * @param {String} validationType custom validation of currency or number
 * @param {String} inputClass for custom input classes
 * @param {Boolean} haveError for showing error with the input
 * @param {String} errorMessage error message to show if haveError is true
 * @param {Boolean} fullWidthInput for showing a full width input (used in InputWrapper)
 * @param {Boolean} loading for showing a loader on the input box
 * @param {Object} infoImage image props for showing info icon (used in InputWrapper)
 * @param {String} extraInfoText extra info to be shown in the input label (used in InputWrapper)
 * @param model for two way binding data to input
 **/
export default {
    props: {
        inputPlaceholder: {
            type: String,
            default: "",
        },
        inputLabel: {
            type: String,
            default: "",
        },
        modelValue: null,
        disabled: {
            type: Boolean,
            default: false,
        },
        inputClass: String,
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        fullWidthInput: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        extraInfoText: {
            type: String,
            default: "",
        },
        currencySymbol: {
            type: String,
            default: "£",
        },
    },
    emits: ["update:modelValue", "blur"],
    computed: {
        currencyInputLabel() {
            return this.inputLabel + " (" + this.currencySymbol + ")";
        },
    },
    methods: {
        currencyInputBlur(e) {
            this.$emit("blur", e);
        },
    },
};
</script>
