<template>
    <div class="peril-card-content">
        <circle-image
            :imageInfo="{
                imageColor: imageColor,
                iconUrl: imageInfo.imageUrl,
                width: imageInfo.width,
                height: imageInfo.height,
            }"
            :width="'65px'"
            :height="'65px'"
            class="mb-15px"
        ></circle-image>
        <div class="heading text-center sm-font-16">{{ heading }}</div>
        <div class="description text-center sm-font-12">{{ description }}</div>
    </div>
</template>

<script>
export default {
    props: {
        imageInfo: {
            type: Object,
            default: function () {
                return {
                    imageUrl: "",
                    width: "20px",
                    height: "20px",
                };
            },
        },
        heading: String,
        description: String,
        selected: Boolean,
    },
    computed: {
        imageColor() {
            if (this.selected) {
                return this.$colors.bevColors.activeColorV1;
            } else {
                return "white";
            }
        },
    },
};
</script>

<style scoped lang="scss">
.peril-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .description {
        margin-top: 12px;
    }
}
</style>
