<template>
    <div class="step-container">
        <div
            v-for="(step, index) in steps"
            :key="index"
            :class="{
                direction,
                active: index < currentStep || index === currentStep,
            }"
            :style="[index !== steps.length - 1 ? { flex: 1 } : {}]"
        >
            <div class="d-flex step">
                <div class="d-flex align-items-center flex-col circle">
                    <div class="step-outer">
                        <div class="step-inner"></div>
                    </div>
                </div>
                <div
                    v-if="index !== steps.length - 1"
                    class="hr"
                    :class="{
                        'completed-step-hr': index < currentStep,
                        'current-step-hr':
                            index > currentStep || index === currentStep,
                    }"
                ></div>
            </div>
            <div class="step-info text-center">
                <p class="title">{{ step.title }}</p>
                <div class="sub-title">{{ step.subTitle }}</div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * BEV Stepper component
 *
 * @param {Array<{title:String,subTitle:String}>} steps total steps
 * @param {String} direction either row or col
 * @param {Number} currentStep current step index
 */

export default {
    data() {
        return {};
    },
    props: {
        steps: Array,
        direction: {
            default: "row",
            type: String,
        },
        currentStep: {
            default: 0,
            type: Number,
        },
    },
};
</script>
<style scoped>
.active .step-inner {
    padding: 4px;
    background-color: var(--activeColorV1) !important;
    margin: 4px;
}
.step-inner {
    width: 24px;
    height: 24px;
    border-radius: 12px;
}
.step {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row;
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.step-outer {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.active .step-outer {
    border: 2px solid var(--activeColorV1);
    border-radius: 18px;
}
.step-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 52px;
    align-items: flex-start;
}
.completed-step-hr {
    border-top: 2px solid var(--activeColorV1);
}
.current-step-hr {
    border-top: 2px dashed white;
}
.hr {
    margin: 0px 8px;
    flex: 1;
}
.title {
    font-size: 14px;
    color: white;
    margin-top: 16px;
}
.sub-title {
    font-size: 12px;
    color: white;
    margin-top: 8px;
}
.active .title {
    color: var(--activeColorV1);
}
.active .sub-title {
    color: var(--activeColorV1);
}
.flex-1 {
    flex: 1;
}

.direction {
    display: flex;
    flex-direction: column;
}

.step-info {
    width: 140px;
    margin-top: 6px;
    margin-left: -52px;
}

@media screen and (max-width: 1180px) {
    .step-container {
        flex-direction: column;
        padding: 0;
    }
    .direction {
        flex-direction: row;
        flex: unset !important;
    }
    .completed-step-hr {
        border-top: unset;
        border-left: 2px solid var(--activeColorV1);
    }
    .current-step-hr {
        border-top: unset;
        border-left: 2px dashed white;
    }
    .step {
        flex-direction: column !important;
        flex: unset !important;
        height: 120px;
    }
    .step:last-child {
        height: auto;
    }
    .step-info {
        margin-left: 10px;
    }

    .step-outer {
        margin: 6px;
    }

    .step-info p {
        margin: 0;
    }
}
</style>
