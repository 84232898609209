<template>
    <div
        class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Register`"
            :description="''"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="createClient.bind(this, false, false)"
            />
        </buy-policy-heading-block>
        <bev-form
            :submitData="createClient.bind(this, true, false)"
            :showSingleButton="false"
            :submitButton="{
                text: 'Next',
                loading: creatingUser,
                buttonType: 'submit',
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: createClient.bind(this, false, true),
                buttonType: 'button',
            }"
            class="register-form"
        >
            <template v-slot:input-fields>
                <div class="register-form-content">
                    <div class="row mt-30px">
                        <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px">
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="'Business Name*'"
                                :haveError="
                                    v$.customerName.$error &&
                                    v$.customerName.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Name'
                                    )
                                "
                                :inputClass="'first-name'"
                                v-model="v$.customerName.$model"
                            ></bev-input>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <bev-input
                                :inputType="'email'"
                                :inputLabel="'Business Email*'"
                                :haveError="
                                    v$.email.$error &&
                                    v$.email.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.emailError
                                "
                                :inputClass="'email'"
                                v-model="v$.email.$model"
                            ></bev-input>
                        </div>
                    </div>
                    <div class="row mt-30px">
                        <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px">
                            <tel-input
                                :inputLabel="'Contact Number*'"
                                :inheritedHaveError="
                                    v$.contactNumber.$error &&
                                    v$.contactNumber.required.$invalid
                                "
                                :inheritedErrorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Contact Number'
                                    )
                                "
                                @checkValid="
                                    (value) => (contactNumberValid = value)
                                "
                                @setCountryDetails="
                                    handleContactNumberCountryDetails
                                "
                                :selectedCountryCode="
                                    contactNumberCountryDetails?.countryCode
                                "
                                v-model="v$.contactNumber.$model"
                            >
                            </tel-input>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="'Address Line 1*'"
                                :haveError="
                                    v$.address1.$error &&
                                    v$.address1.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.addressError
                                "
                                :inputClass="'address-1'"
                                v-model="v$.address1.$model"
                            ></bev-input>
                        </div>
                    </div>
                    <div class="row mt-30px">
                        <div class="col-sm-12 col-md-6 col-lg-6 sm-mt-30px">
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="'Address Line 2'"
                                :inputClass="'address-2'"
                                v-model="address2"
                            ></bev-input>
                        </div>
                        <div
                            class="col-sm-12 col-md-6 col-lg-6 position-relative"
                        >
                            <bev-dropdown
                                containerClass="countries_drop"
                                dropDownLabel="Country*"
                                :ajax="true"
                                :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                                :apiRequestParam="`entityType=country&tenant=${getTenantType}`"
                                apiMethod="post"
                                :responseInfo="{
                                    resultKey: 'content',
                                    totalResultKey: 'totalElements',
                                    pagination: true,
                                }"
                                searchKey="searchVal"
                                :optionTextHandler="
                                    (option) => {
                                        return option.value;
                                    }
                                "
                                :haveError="
                                    v$.selectedCountry.$error &&
                                    v$.selectedCountry.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.countryError
                                "
                                v-model="v$.selectedCountry.$model"
                            ></bev-dropdown>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 mt-30px">
                            <bev-dropdown
                                containerClass="cities_drop"
                                dropDownLabel="City*"
                                :ajax="true"
                                :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                                apiRequestParam="entityType=city"
                                apiMethod="post"
                                :apiRequestBody="cityDropRequestBody"
                                :responseInfo="{
                                    resultKey: 'content',
                                    totalResultKey: 'totalElements',
                                    pagination: true,
                                }"
                                searchKey="searchVal"
                                :optionTextHandler="
                                    (option) => {
                                        return option.value;
                                    }
                                "
                                :haveError="
                                    v$.selectedCity.$error &&
                                    v$.selectedCity.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.cityError
                                "
                                :disabled="!selectedCountry.value"
                                otherOptionKey="value"
                                allowOther
                                v-model="v$.selectedCity.$model"
                            ></bev-dropdown>
                        </div>
                        <div
                            class="col-sm-12 col-md-6 col-lg-6 mt-30px"
                            v-if="selectedCity.value === 'Other'"
                        >
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="`Enter city*`"
                                :inputClass="'city_input'"
                                :haveError="
                                    v$.otherCityValue.$error &&
                                    v$.otherCityValue.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'City'
                                    )
                                "
                                v-model="v$.otherCityValue.$model"
                            ></bev-input>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 mt-30px">
                            <bev-input
                                :inputType="'text'"
                                :inputLabel="'Postcode*'"
                                :haveError="postcodeErrorHandler.haveError"
                                :errorMessage="
                                    postcodeErrorHandler.errorMessage
                                "
                                :inputClass="'zipcode'"
                                :disabled="
                                    !selectedCountry.value ||
                                    !selectedCity.value
                                "
                                v-model="v$.zipcode.$model"
                            ></bev-input>
                        </div>
                    </div>
                </div>
            </template>
        </bev-form>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import UserService from "@/services/UserService";
import config from "@/envConfig.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ComplyAdvantageService from "@/services/ComplyAdvantageService";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import TelInput from "@/components/Common/Input/TelInput";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            config: null,
            customerName: "",
            contactNumber: null,
            contactNumberCountryDetails: null,
            address1: "",
            address2: "",
            zipcode: "",
            selectedCity: {},
            selectedCountry: {},
            contactNumberValid: false,
            creatingUser: false,
            email: "",
            otherCityValue: "",
        };
    },
    beforeMount() {
        if (this.isAuthenticated) {
            this.takeToNextStep();
        }
    },
    components: {
        CoverSummaryButton,
        TelInput,
        BuyPolicyHeadingBlock,
    },
    validations() {
        return {
            customerName: {
                required,
            },
            email: {
                required,
                email,
            },
            contactNumber: {
                required,
            },
            address1: {
                required,
            },
            selectedCity: {
                required,
            },
            selectedCountry: {
                required,
            },
            zipcode: {
                required,
                customPostCodeValidation: helpers.withAsync(async (value) => {
                    if (!value) {
                        return true;
                    } else {
                        const isValid = await this.validatePostalCode(
                            this.selectedCountry.value,
                            this.selectedCity.value === "Other"
                                ? this.otherCityValue
                                : this.selectedCity.value,
                            value
                        );
                        return isValid;
                    }
                }),
            },
            otherCityValue: {
                required:
                    this.selectedCity.value === "Other" ? required : false,
            },
        };
    },
    created() {
        this.config = config;
        this.customerName =
            (this.questionBundles["RegisterUser"]?.firstName || "") +
            (this.questionBundles["RegisterUser"]?.lastName || "");
        this.email = this.questionBundles["RegisterUser"]?.email || "";
        this.address1 =
            this.questionBundles["RegisterUser"]?.addressLine1 || "";
        this.address2 =
            this.questionBundles["RegisterUser"]?.addressLine2 || "";
        this.selectedCountry.value =
            this.questionBundles["RegisterUser"]?.country || "";
        this.selectedCity.value =
            this.questionBundles["RegisterUser"]?.city || "";
        this.otherCityValue =
            this.questionBundles["RegisterUser"]?.otherCityValue || "";
        this.zipcode = this.questionBundles["RegisterUser"]?.zipCode || "";
        this.contactNumber =
            this.questionBundles["RegisterUser"]?.mobileNumber || "";
        this.contactNumberCountryDetails =
            JSON.parse(
                JSON.stringify(
                    this.questionBundles["RegisterUser"]
                        .contactNumberCountryDetails
                )
            ) || null;
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles", "policyBroker"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        ...mapState("auth", ["isAuthenticated"]),
        ...mapGetters(["getTenantType"]),
        postcodeErrorHandler() {
            if (this.v$.zipcode.$error) {
                let errorMessage = "";
                if (this.v$.zipcode.required.$invalid) {
                    errorMessage = this.$messages.errorMessages.zipcodeError;
                } else if (this.v$.zipcode.customPostCodeValidation.$invalid) {
                    errorMessage = this.$messages.errorMessages.postcodeError;
                }
                return {
                    haveError: true,
                    errorMessage: errorMessage,
                };
            }
            return {
                haveError: false,
                errorMessage: "",
            };
        },
    },
    methods: {
        ...mapMutations("buyPolicy", ["setBuyPolicyStep", "setQuestionBundle"]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        cityDropRequestBody() {
            return {
                metaDataFilters: [
                    {
                        key: "country",
                        value: this.selectedCountry.value,
                        mandatory: true,
                    },
                ],
            };
        },
        async addUser(forNext) {
            let [firstName, ...lastName] = this.customerName.trim().split(" ");
            let data = {
                firstName: firstName,
                lastName: lastName.join(" "),
                email: this.email,
                username: this.email,
                enabled: true,
                emailVerified: true,
                attributes: {
                    mobileNumber: this.contactNumber,
                    contactNumberCountryCode:
                        this.contactNumberCountryDetails?.countryCode,
                    contactNumberDialCode:
                        this.contactNumberCountryDetails?.dialCode,
                    addressLine1: this.address1,
                    ...(this.address2 && { addressLine2: this.address2 }),
                    country: this.selectedCountry.value,
                    city:
                        this.selectedCity.value === "Other"
                            ? this.otherCityValue
                            : this.selectedCity.value,
                    zipCode: this.zipcode,
                    first_login_completed: true,
                    terms_and_conditions_accepted: true,
                    customerType: "Business",
                    brokerId: this.policyBroker.id,
                },
            };
            try {
                await UserService.addUser(data);
                this.creatingUser = false;
                this.setQuestionBundle({
                    ...this.questionBundles,
                    RegisterUser: {
                        ...this.questionBundles["RegisterUser"],
                        firstName: firstName,
                        lastName: lastName.join(" "),
                        email: this.email,
                        mobileNumber: this.contactNumber,
                        contactNumberCountryDetails:
                            this.contactNumberCountryDetails,
                        addressLine1: this.address1,
                        ...(this.address2 && { addressLine2: this.address2 }),
                        country: this.selectedCountry.value,
                        city: this.selectedCity.value,
                        otherCityValue:
                            this.selectedCity.value === "Other"
                                ? this.otherCityValue
                                : "",
                        zipCode: this.zipcode,
                        brokerId: this.policyBroker.id,
                    },
                });
                this.$notify({
                    group: "BEV",
                    title: "Success",
                    type: "success",
                    text: `User registered with ${this.email} email. Your password is being sent to your email. Please use that moving forward.`,
                });
                if (this.checkFlowComplete && !forNext) {
                    this.setBuyPolicyStep({
                        step: "CoverSummary",
                        back: false,
                    });
                } else {
                    this.takeToNextStep();
                }
            } catch (err) {
                this.creatingUser = false;
                if (
                    err?.response?.data?.detail?.message.includes(
                        "User already exists and has a logged in history"
                    )
                ) {
                    let userData = err?.response?.data?.detail?.user_data || {};
                    this.setQuestionBundle({
                        ...this.questionBundles,
                        RegisterUser: {
                            ...this.questionBundles["RegisterUser"],
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            email: userData.email,
                            mobileNumber:
                                userData?.attributes?.mobileNumber[0] || "",
                            addressLine1:
                                userData?.attributes?.addressLine1[0] || "",
                            ...(userData?.attributes?.addressLine2[0] && {
                                addressLine2:
                                    userData?.attributes?.addressLine2[0],
                            }),
                            country: userData?.attributes?.country[0],
                            city: userData?.attributes?.city[0],
                            zipCode: userData?.attributes?.zipCode[0],
                            brokerId: userData?.attributes?.brokerId[0],
                        },
                    });
                    this.$notify({
                        group: "BEV",
                        title: "Success",
                        type: "success",
                        text: `User already existed with ${this.email} email. Please use this email moving forward.`,
                    });
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                } else {
                    this.displayErrorMessage(
                        err?.response?.data?.message ||
                            this.$messages.errorMessages.userCreationError
                    );
                }
            }
        },
        handleContactNumberCountryDetails(value) {
            this.contactNumberCountryDetails = JSON.parse(
                JSON.stringify(value)
            );
        },

        async checkComplyAdvantage(forNext) {
            this.creatingUser = true;
            try {
                const response =
                    await ComplyAdvantageService.checkComplyAdvantageStatus(
                        this.customerName
                    );
                const data = JSON.parse(response.data.rawBody)?.content.data;
                if (data.total_matches > 0) {
                    this.creatingUser = false;
                    this.displayErrorMessage(
                        "We are unable to process your request as we may require more information. Please contact admin@birdseyeviewtechnologies.com for futher assistance."
                    );
                } else {
                    this.addUser(forNext);
                }
            } catch (err) {
                this.creatingUser = false;
                this.displayErrorMessage(
                    err?.response?.data?.message || "Something went wrong"
                );
            }
        },

        async createClient(forNext, forBack) {
            let [firstName, ...lastName] = this.customerName.trim().split(" ");
            this.updateDataBundleWithValidations({
                bundleName: "RegisterUser",
                bundleData: {
                    firstName: firstName,
                    lastName: lastName.join(" "),
                    email: this.email,
                    mobileNumber: this.contactNumber,
                    contactNumberCountryDetails:
                        this.contactNumberCountryDetails,
                    addressLine1: this.address1,
                    ...(this.address2 && { addressLine2: this.address2 }),
                    country: this.selectedCountry.value,
                    city: this.selectedCity.value,
                    otherCityValue:
                        this.selectedCity.value === "Other"
                            ? this.otherCityValue
                            : "",
                    zipCode: this.zipcode,
                    brokerId: this.policyBroker.id,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else {
                if (!this.v$.$invalid && this.contactNumberValid) {
                    this.checkComplyAdvantage(forNext);
                }
            }
        },
    },
    watch: {
        selectedCountry: function (country) {
            if (country.value) {
                this.zipcode = "";
            }
        },
        selectedCity: function (city) {
            if (city.value) {
                this.zipcode = "";
            }
        },
        otherCityValue: function (value) {
            if (value) {
                this.zipcode = "";
            }
        },
    },
};
</script>

<style scoped>
.register-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.register-form-content {
    width: 1200px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>
