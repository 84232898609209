<template>
    <div
        class="pb-30px pt-50px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            heading="Event Cancellation Terrorism"
            description="event_cancellation_terrorism"
            headingSizeClass="font-22 sm-font-16"
            descriptionSizeClass="font-16 sm-font-12"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="setTerrorism.bind(this, false, false, true)"
            />
        </buy-policy-heading-block>
        <div>
            <bev-form
                class="terrorism-form"
                :submitData="setTerrorism.bind(this, true, false, true)"
                :submitButton="{
                    text: 'Next',
                    buttonType: 'submit',
                }"
                :resetButton="{
                    text: 'Back',
                    clickHandler: setTerrorism.bind(this, false, true, false),
                    buttonType: 'button',
                }"
            >
                <template v-slot:input-fields>
                    <div class="terrorism-form-content pt-20px mt-30px">
                        <div class="row mt-30px">
                            <option-selector
                                class="col-sm-12"
                                :question="
                                    productLabelHandler(
                                        'eventCancellationTerrorism',
                                        'label'
                                    )
                                "
                                :selectHandler="setTerrorismtConfirmation"
                                :error="
                                    v$.terrorism.$error &&
                                    v$.terrorism.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :selectedOption="terrorism"
                                :options="['Yes', 'No']"
                                :infoImage="{
                                    show: true,
                                    info: `<b>Insured Limit:</b> ${getTotalExpensesOrRevenue}`,
                                }"
                            ></option-selector>
                        </div>
                    </div>
                </template>
            </bev-form>
            <SaveAsDraftButton :dataSaveHandler="setTerrorism" />
        </div>
    </div>
</template>

<script>
/**
 * QuestionBundle component for EventCancellationTerrorism
 */
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import OptionSelector from "@/components/Views/BuyPolicy/OptionSelector";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        OptionSelector,
        CoverSummaryButton,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
    },
    validations() {
        return {
            // Validations for every form field
            terrorism: {
                required,
            },
        };
    },
    created() {
        this.terrorism =
            this.questionBundles["EventCancellationTerrorism"].terrorism ||
            null;
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles", "productsFlow"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        getTotalExpensesOrRevenue() {
            return this.thousandMask(
                this.questionBundles["EventCancellation"]
                    .totalExpensesOrRevenue,
                "currency"
            );
        },
    },
    data() {
        return {
            terrorism: null,
        };
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setQuestionBundle",
            "setBuyPolicyStep",
            "removeProductFromFlow",
            "clearPolicyValues",
            "removeProduct",
            "setPolicyProductAddon",
            "setPolicyProduct",
        ]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        setTerrorismtConfirmation(choice) {
            this.terrorism = choice;
        },
        setTerrorism(forNext, forBack, notSaveForDraft) {
            // Handler to take every filled data and set into EventCancellationTerrorism questionBundle
            this.updateDataBundleWithValidations({
                bundleName: "EventCancellationTerrorism",
                bundleData: {
                    terrorism: this.terrorism,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            }
            if (this.terrorism !== "Yes" && !this.v$.$invalid) {
                this.removeEventCancellationTerrorism();
            }
        },
        removeEventCancellationTerrorism() {
            // Remove EventCancellationTerrorism from the flow
            this.setQuestionBundle({
                ...this.questionBundles,
                EventCancellationTerrorism: {},
            });
            this.removeProductFromFlow("event_cancellation_terrorism");
            this.removeProduct("event_cancellation_terrorism");
            this.setPolicyProductAddon(null);
            this.setPolicyProduct("event_cancellation");
            if (this.productsFlow.length === 0) {
                this.clearPolicyValues("SelectProduct");
            } else {
                this.setBuyPolicyStep({ step: "CoverSummary", back: false });
            }
        },
    },
};
</script>

<style scoped>
.terrorism-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.terrorism-form-content {
    width: 550px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>
