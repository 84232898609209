<template>
    <div
        class="mt-20px br-10px p-20px text-break font-14 sm-font-12 client-info"
    >
        <p class="mb-10px fw-bold font-18 sm-font-16">Insured Information</p>
        <p>
            <span class="fw-600">First Name: </span
            >{{ client.firstName + " " + client.lastName }}
        </p>
        <p>
            <span class="fw-600">Email: </span
            >{{ attributesRenderer("clientEmail") }}
        </p>
        <p>
            <span class="fw-600">Contact Number: </span
            >{{ attributesRenderer("mobileNumber") }}
        </p>
        <p>
            <span class="fw-600">Address: </span
            >{{ attributesRenderer("addressLine1") }}
        </p>
        <p>
            <span class="fw-600">Country: </span
            >{{ attributesRenderer("country") }}
        </p>
        <p>
            <span class="fw-600">Zipcode: </span
            >{{ attributesRenderer("zipCode") }}
        </p>
        <p>
            <span class="fw-600">City: </span>{{ attributesRenderer("city") }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        client: Object,
    },
    methods: {
        attributesRenderer(key) {
            return (
                (this.client?.attributes
                    ? this.client.attributes[key]?.join(",")
                    : this.client[key]) || "----"
            );
        },
    },
};
</script>

<style scoped>
.client-info {
    border: solid 0.5px var(--borderColorOverCardV1);
    line-height: 25px;
}
</style>
