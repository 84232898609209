<template>
    <div class="metrics">
        <div class="metrics-header">
            <div
                class="metrics-heading d-flex align-items-center justify-content-between"
            >
                <h4 class="font-18 fw-600 m-0">
                    {{ metrics.name }}
                </h4>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.danger"
                    :imageUrl="$imagePath.delete"
                    :imageAlt="'bin-icon'"
                    width="20px"
                    height="20px"
                    :tooltipText="'Delete Metric'"
                    :clickHandler="deleteMetric.bind(this, metrics.id)"
                    v-if="deletingMetricId !== metrics.id"
                ></bev-image>
                <spinner
                    v-if="deletingMetricId === metrics.id"
                    :width="'12px'"
                    :height="'12px'"
                ></spinner>
            </div>
        </div>
        <MetricsData :metrics="metrics" />
    </div>
</template>

<script>
import MetricsData from "@/components/Views/Analytics/Metrics/MetricsData/Main.vue";
export default {
    props: {
        metrics: Object,
        deleteMetric: Function,
        deletingMetricId: String,
    },
    components: {
        MetricsData,
    },
    computed: {
        getFilterFields() {
            return this.metrics?.mandatoryFields?.split(",");
        },
    },
};
</script>

<style scoped>
.metrics {
    border-radius: 8px;
    border: 1px solid transparent;
    transition: 0.4s all ease-in-out;
    background-color: var(--cardBgColorV2);
    color: var(--cardColorV2);
    box-shadow: var(--cardBoxShadowV2);
}
.metrics .metrics-header {
    padding: 20px 30px;
    border-bottom: 1px solid var(--borderColorOverCardV2);
}
</style>
