<template>
    <div>
        <ConfirmationDeclarations
            :products="products"
            :undertakings="undertakings"
            :checkAllSelected="true"
            :acknowledgeAll="acknowledgeAll"
            confirmationMessage="We just need to confirm a few final things before you can bind your quote"
            @acknowledge="(e) => (acknowledgeAll = e)"
        />
        <div class="d-flex align-items-center justify-content-center">
            <bev-button
                :themeType="'dark'"
                :clickHandler="confirmHandler"
                :loading="actionLoading"
                :disabled="!acknowledgeAll"
            >
                Confirm
            </bev-button>
        </div>
    </div>
</template>

<script>
import ConfirmationDeclarations from "@/components/Common/ConfirmationDeclarations/Main.vue";
export default {
    name: "BindDeclarations",
    props: {
        confirmHandler: Function,
        details: Object,
        actionLoading: Boolean,
    },
    components: {
        ConfirmationDeclarations,
    },
    data() {
        return {
            acknowledgeAll: false,
            products: [],
            undertakings: {
                common: this.$policyValues.declarations.common,
            },
        };
    },
    mounted() {
        this.products = Object.keys(JSON.parse(this.details.products));
        this.products.forEach((product) => {
            if (this.$policyValues.declarations[product]) {
                this.undertakings[product] = JSON.parse(
                    JSON.stringify(this.$policyValues.declarations[product])
                );
            }
        });
    },
};
</script>
