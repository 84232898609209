<template>
    <div
        class="row"
        v-for="(field, index) in extensionField.value"
        :key="index"
    >
        <div
            class="col-sm-12 col-md-12 col-lg-6 mb-10px"
            v-for="(innerField, i) in field"
            :key="i"
        >
            <component
                :is="fieldRendererComponentName(innerField)"
                v-bind="fieldRendererComponentProps(innerField)"
                v-on="localFieldRendererComponentEvents(innerField, index, i)"
            />
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 align-self-end mb-10px">
            <bev-image
                v-if="index === extensionField?.value.length - 1"
                :addBackground="true"
                :backgroundColor="bevColors.inputInfoIcon"
                :imageAlt="'add'"
                :imageUrl="$imagePath.add"
                width="20px"
                height="20px"
                v-tooltip="{
                    content: 'Add',
                    html: true,
                    placement: 'auto',
                    showTriggers: (events) => [...events, 'click'],
                    autoHide: true,
                    delay: 0,
                }"
                @click="addEntry(index)"
                class="cursor-pointer mx-5px"
            ></bev-image>
            <bev-image
                v-if="extensionField?.value.length > 1"
                :addBackground="true"
                :backgroundColor="bevColors.danger"
                :imageAlt="'add'"
                :imageUrl="$imagePath.delete"
                width="20px"
                height="20px"
                v-tooltip="{
                    content: 'Delete',
                    html: true,
                    placement: 'auto',
                    showTriggers: (events) => [...events, 'click'],
                    autoHide: true,
                    delay: 0,
                }"
                class="cursor-pointer mx-5px"
                @click="deleteEntry(index)"
            ></bev-image>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        extensionField: Object,
        fieldRendererComponentName: Function,
        fieldRendererComponentProps: Function,
        parentHandleInputFunction: Function,
        parentHandleAddFunction: Function,
        parentHandleDeleteFunction: Function,
    },
    computed: {
        emptyValue() {
            return (
                this.extensionField?.value?.[0]?.map((f) => {
                    return {
                        ...f,
                        value: null,
                    };
                }) || []
            );
        },
    },
    methods: {
        localFieldRendererComponentEvents(field, parentIndex, index) {
            return {
                "update:modelValue": (value) => {
                    this.handleInputFunction(field, parentIndex, value, index);
                },
            };
        },
        handleInputFunction(field, parentIndex, value, index) {
            let fieldIndex = this.extensionField.value[parentIndex].findIndex(
                (f) => {
                    return f.key === field.key;
                }
            );
            if (fieldIndex > -1) {
                this.parentHandleInputFunction(field, value, {
                    parentIndex: parentIndex,
                    childFieldIndex: index,
                    parentField: this.extensionField,
                });
            }
        },
        addEntry() {
            this.parentHandleAddFunction({
                parentField: this.extensionField,
                data: this.emptyValue,
            });
        },
        deleteEntry(index) {
            this.parentHandleDeleteFunction({
                parentField: this.extensionField,
                deleteIndex: index,
            });
        },
    },
};
</script>
