import config from "@/envConfig.js";
import APIService from "./APIService";

export default class ReferenceDataService extends APIService {
    static getProductKeyMap() {
        return this.post(
            `${config.referenceDataServiceUrl}/values/search?entityType=label&tenant=all&page=0&size=10000`
        );
    }

    static getPolicyWorkflow(role, status, type, tenant) {
        return this.post(
            `${config.referenceDataServiceUrl}/values/search?entityType=workflow&tenant=${tenant}&searchVal=${role}_${type}_${status}`
        );
    }

    static getCurrencyList() {
        return this.post(
            `${config.referenceDataServiceUrl}/values/search?entityType=currency&tenant=arch&page=0&size=10000`
        );
    }

    static validateCountry(requestBody) {
        return this.post(
            `${config.referenceDataServiceUrl}/values/search?tenant=arch&entityType=whiteLabelCountries&page=0&size=10000`,
            requestBody
        );
    }
}
