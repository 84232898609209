import config from "@/envConfig.js";
import APIService from "./APIService";

export default class GridService extends APIService {
    static validatePostalCode(country, city, postalCode) {
        return this.get(
            `${config.gridManagerServiceUrl}/location/validate-postal-code?country=${country}&city=${city}&postalCode=${postalCode}`
        );
    }
}
