<template>
    <div class="payment-card-content">
        <bev-image
            :addBackground="true"
            :backgroundColor="imageColor"
            :imageAlt="'payment-type'"
            :imageUrl="imageUrl"
            :width="'68px'"
            :height="'70px'"
            class="m-auto"
        ></bev-image>
        <div class="heading text-center mt-30px mb-10px">{{ heading }}</div>
        <div class="description text-center">{{ description }}</div>
    </div>
</template>

<script>
/**
    Payment Option Card for the Confirmation page
    * @param {String} imageUrl image url for the payment option
    * @param {String} heading heading text for the payment option
    * @param {String} description description text for the payment option
    * @param {Boolean} selected check if payment option is selected
**/
export default {
    props: {
        imageUrl: String,
        heading: String,
        description: String,
        selected: Boolean,
    },
    computed: {
        imageColor() {
            if (this.selected) {
                return this.$colors.bevColors.activeColorV1;
            } else {
                return "white";
            }
        },
    },
};
</script>
