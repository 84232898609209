<template>
    <button
        :class="['bev-btn', themeClass]"
        :type="buttonType"
        @click="clickHandler"
        :disabled="disabled"
    >
        <spinner
            v-if="loading"
            :spinnerColor="
                themeType === 'white' || themeType === 'light'
                    ? bevColors.buttonPrimary
                    : bevColors.buttonSecondary
            "
            :width="'20px'"
            :height="'20px'"
            class="m-auto"
        ></spinner>
        <slot v-if="!loading"></slot>
    </button>
</template>

<script>
/**
 * Theme button
 *
 * @param {String} themeType for changing theme type dark/light
 * @param {Function} clickHandler default onclick handler
 * @param {Boolean} disabled default button disable
 * @param {String} buttonType default button type prop like submit,button,etc
 * @param {Boolean} loading button loading prop
 */
export default {
    props: {
        themeType: String,
        clickHandler: {
            type: Function,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        buttonType: {
            type: String,
            default: "button",
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        themeClass() {
            switch (this.themeType) {
                case "dark":
                    return "bev-btn-primary";
                case "light":
                    return "bev-btn-secondary";
                case "bordered":
                    return "bev-btn-bordered";
                case "white-bordered":
                    return "bev-btn-white-bordered";
                case "white":
                    return "bev-btn-white";
                default:
                    return "";
            }
        },
    },
};
</script>
