<template>
    <div class="checkbox" :class="{ disabled: disabled }">
        <label
            class="checkbox-container"
            :class="[centerAligned ? 'aligned-center' : '', extraClass]"
        >
            <p v-html="inputLabel" v-if="inputLabel"></p>
            <slot name="label" v-if="!inputLabel"></slot>
            <input
                id="rememberMe"
                name="rememberMe"
                type="checkbox"
                :checked="modelValue"
                @input="checkFunc"
                :disabled="disabled"
            />
            <span class="checkmark"></span>
        </label>
    </div>
</template>

<script>
/**
 * This is BEV checkbox which will be used wherever there is form data
 *
 * @param {String} inputLabel for checkbox label prop
 * @param {String} modelValue for checkbox value prop
 * @param {String} extraClass for custom checkbox classes
 * @param {Boolean} centerAligned for center align the checkbox
 * @param model for two way binding data to input
 **/

export default {
    props: {
        inputLabel: {
            type: String,
            default: "",
        },
        modelValue: null,
        extraClass: {
            type: String,
            default: "large",
        },
        centerAligned: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
    methods: {
        checkFunc(e) {
            if (!this.disabled) {
                this.$emit("update:modelValue", e.target.checked);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.form-horizontal {
    .form-group {
        margin-bottom: 0px;
    }
}

.checkbox {
    &.disabled {
        opacity: 0.5;
        .checkbox-container {
            cursor: not-allowed;
        }
    }
    .checkbox-container {
        display: block;
        position: relative;
        padding-left: 32px !important;
        cursor: pointer;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: var(--checkBoxInputTextColor);
        text-align: left;
        &.aligned-center {
            width: max-content;
            margin: auto;
        }
        &.terms {
            padding-left: 0 !important;
        }
        &.large {
            font-size: 18px;
            .checkmark {
                height: 22px;
                width: 22px;
                &:after {
                    left: 7px;
                    top: 3px;
                    width: 7px;
                    height: 12px;
                    border-width: 0 3px 3px 0;
                }
            }
        }

        &.medium {
            font-size: 16px;
            .checkmark {
                height: 16px;
                width: 16px;
                &:after {
                    left: 5px;
                    top: 2px;
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                }
            }
        }

        &.small {
            font-size: 14px;
            .checkmark {
                height: 12px;
                width: 12px;
                &:after {
                    left: 3.5px;
                    top: 1px;
                    width: 4px;
                    height: 7px;
                    border-width: 0 2px 2px 0;
                }
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: white;
                &:after {
                    display: block;
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 6px;
            left: 0;
            background-color: transparent;
            border-radius: 3px;
            border: 0.5px solid var(--checkBoxInputBorderColor);
            &:after {
                content: "";
                position: absolute;
                display: none;
                border: solid var(--checkBoxInputCheckmarkColor);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    &.activeColorV1 {
        p {
            font-weight: 600;
        }
        .checkbox-container {
            color: var(--activeColorV1);
            input {
                &:checked ~ .checkmark {
                    background-color: var(--checkBoxInputActiveBgColor);
                }
            }
            .checkmark {
                border: 0.5px solid var(--activeColorV1);
            }
        }
    }
}
</style>
