<template>
    <bev-form
        :submitData="policyAction"
        :showSingleButton="false"
        :submitButton="{
            text: 'Confirm',
            loading: actionLoading,
            buttonType: 'submit',
        }"
        :resetButton="{
            text: 'Cancel',
            buttonType: 'reset',
            clickHandler: closeHandler,
        }"
        noActionBottomPadding
    >
        <template v-slot:input-fields>
            <p class="popup-heading text-center">
                Please confirm there are no material changes to information.
            </p>
        </template>
    </bev-form>
</template>

<script>
export default {
    props: {
        policyAction: Function,
        actionLoading: Boolean,
        closeHandler: Function,
    },
};
</script>

<style scoped>
.form-actions {
    padding-bottom: 10px !important;
}
.popup-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
</style>
