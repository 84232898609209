<template>
    <bev-form
        :submitData="saveDetails"
        :showSingleButton="true"
        :submitButton="{
            text: 'Submit',
            loading: updatingPersonalDetails,
            buttonType: 'submit',
        }"
    >
        <template v-slot:input-fields>
            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'User Type'"
                        :inputClass="'customer_type'"
                        :disabled="true"
                        v-model="customerType"
                        class="full-opacity"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 ss-mt-30px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'User Name*'"
                        :haveError="
                            v$.customerName.$error &&
                            v$.customerName.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'Name'
                            )
                        "
                        :inputClass="'first-name'"
                        v-model="v$.customerName.$model"
                    ></bev-input>
                </div>
            </div>
            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <tel-input
                        :inputLabel="'Contact Number'"
                        @checkValid="(value) => (mobileNumberValid = value)"
                        @setCountryDetails="handleContactNumberCountryDetails"
                        :selectedCountryCode="
                            contactNumberCountryDetails?.countryCode
                        "
                        v-model="mobileNumber"
                    >
                    </tel-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 ss-mt-30px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Address Line 1*'"
                        :haveError="
                            v$.address1.$error && v$.address1.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.addressError"
                        :inputClass="'address-1'"
                        v-model="v$.address1.$model"
                    ></bev-input>
                </div>
            </div>
            <div class="row mt-30px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Address Line 2'"
                        :inputClass="'address-2'"
                        v-model="address2"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 ss-mt-30px">
                    <bev-dropdown
                        containerClass="countries_drop"
                        dropDownLabel="Country*"
                        :ajax="true"
                        :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                        :apiRequestParam="`entityType=country&tenant=${getTenantType}`"
                        apiMethod="post"
                        :responseInfo="{
                            resultKey: 'content',
                            totalResultKey: 'totalElements',
                            pagination: true,
                        }"
                        searchKey="searchVal"
                        :optionTextHandler="
                            (option) => {
                                return option.value;
                            }
                        "
                        :haveError="
                            v$.selectedCountry.$error &&
                            v$.selectedCountry.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.countryError"
                        v-model="v$.selectedCountry.$model"
                    ></bev-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 mt-30px">
                    <bev-dropdown
                        containerClass="cities_drop"
                        dropDownLabel="City*"
                        :ajax="true"
                        :apiUrl="`${config.referenceDataServiceUrl}/values/search`"
                        apiRequestParam="entityType=city"
                        apiMethod="post"
                        :apiRequestBody="
                            () => {
                                return {
                                    metaDataFilters: [
                                        {
                                            key: 'country',
                                            value: selectedCountry.value,
                                            mandatory: false,
                                        },
                                    ],
                                };
                            }
                        "
                        :responseInfo="{
                            resultKey: 'content',
                            totalResultKey: 'totalElements',
                            pagination: true,
                        }"
                        searchKey="searchVal"
                        :optionTextHandler="
                            (option) => {
                                return option.value;
                            }
                        "
                        :haveError="
                            v$.selectedCity.$error &&
                            v$.selectedCity.required.$invalid
                        "
                        :errorMessage="$messages.errorMessages.cityError"
                        :disabled="!selectedCountry.value"
                        otherOptionKey="value"
                        allowOther
                        v-model="v$.selectedCity.$model"
                    ></bev-dropdown>
                </div>
                <div
                    class="col-sm-12 col-md-6 col-lg-6 mt-30px"
                    v-if="selectedCity.value === 'Other'"
                >
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="`Enter city*`"
                        :inputClass="'city_input'"
                        :haveError="
                            v$.otherCityValue.$error &&
                            v$.otherCityValue.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'City'
                            )
                        "
                        v-model="v$.otherCityValue.$model"
                    ></bev-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mt-30px">
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Postcode*'"
                        :haveError="postcodeErrorHandler.haveError"
                        :errorMessage="postcodeErrorHandler.errorMessage"
                        :inputClass="'zipcode'"
                        :disabled="
                            !selectedCountry.value || !selectedCity.value
                        "
                        v-model="v$.zipcode.$model"
                    ></bev-input>
                </div>
            </div>
        </template>
    </bev-form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { mapState, mapMutations, mapGetters } from "vuex";
import UserService from "@/services/UserService";
import config from "@/envConfig.js";
import TelInput from "@/components/Common/Input/TelInput";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            config: null,
            customerName: "",
            customerType: "Business",
            customerTypes: ["Consumer", "Business"],
            mobileNumber: null,
            contactNumberCountryDetails: null,
            address1: "",
            address2: "",
            zipcode: "",
            selectedCity: {},
            selectedCountry: {},
            mobileNumberValid: false,
            updatingPersonalDetails: false,
            otherCityValue: "",
        };
    },
    computed: {
        ...mapState("auth", ["userId"]),
        ...mapGetters(["getTenantType"]),
        postcodeErrorHandler() {
            if (this.v$.zipcode.$error) {
                let errorMessage = "";
                if (this.v$.zipcode.required.$invalid) {
                    errorMessage = this.$messages.errorMessages.zipcodeError;
                } else if (this.v$.zipcode.customPostCodeValidation.$invalid) {
                    errorMessage = this.$messages.errorMessages.postcodeError;
                }
                return {
                    haveError: true,
                    errorMessage: errorMessage,
                };
            }
            return {
                haveError: false,
                errorMessage: "",
            };
        },
    },
    components: {
        TelInput,
    },
    validations() {
        return {
            customerName: {
                required,
            },
            address1: {
                required,
            },
            selectedCity: {
                required,
            },
            selectedCountry: {
                required,
            },
            zipcode: {
                required,
                customPostCodeValidation: helpers.withAsync(async (value) => {
                    if (!value) {
                        return true;
                    } else {
                        const isValid = await this.validatePostalCode(
                            this.selectedCountry.value,
                            this.selectedCity.value === "Other"
                                ? this.otherCityValue
                                : this.selectedCity.value,
                            value
                        );
                        return isValid;
                    }
                }),
            },
            customerType: {
                required,
            },
            otherCityValue: {
                required:
                    this.selectedCity.value === "Other" ? required : false,
            },
        };
    },
    created() {
        this.config = config;
        this.mapDataOnMount();
    },
    methods: {
        ...mapMutations("auth", ["setUser"]),
        mapDataOnMount() {
            this.customerName =
                (this.user?.firstName || "") +
                " " +
                (this.user?.lastName || "");
            this.selectedCity.value = this.userAttributes?.city || "";
            this.selectedCountry.value = this.userAttributes?.country || "";
            this.address1 = this.userAttributes?.addressLine1 || "";
            this.address2 = this.userAttributes?.addressLine2 || "";
            this.customerType = this.userAttributes?.customerType || "Business";
            if (this.userAttributes.mobileNumber) {
                this.mobileNumberValid = true;
                this.mobileNumber = this.userAttributes.mobileNumber;
                this.contactNumberCountryDetails = {
                    countryCode:
                        this.userAttributes.contactNumberCountryCode || null,
                    dialCode: this.userAttributes.contactNumberDialCode || null,
                };
            } else {
                this.mobileNumber = null;
            }
            this.zipcode = this.userAttributes?.zipCode || "";
        },
        async updatePersonalDetails() {
            this.updatingPersonalDetails = true;
            let user = JSON.parse(JSON.stringify(this.user));
            let [firstName, ...lastName] = this.customerName.trim().split(" ");
            user.firstName = firstName;
            user.lastName = lastName.join(" ");
            let attributes = JSON.parse(JSON.stringify(this.userAttributes));
            attributes.mobileNumber = this.mobileNumber;
            attributes.contactNumberCountryCode =
                this.contactNumberCountryDetails?.countryCode;
            attributes.contactNumberDialCode =
                this.contactNumberCountryDetails?.dialCode;
            attributes.addressLine1 = this.address1;
            attributes.addressLine2 = this.address2;
            attributes.country = this.selectedCountry.value;
            attributes.city =
                this.selectedCity.value === "Other"
                    ? this.otherCityValue
                    : this.selectedCity.value;
            attributes.zipCode = this.zipcode;
            attributes.customerType = this.customerType;
            attributes.first_login_completed = true;
            try {
                let response = await UserService.updateUserDetails(
                    this.userId,
                    {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        username: user.username,
                        emailVerified: true,
                        enabled: true,
                        attributes: attributes,
                    }
                );
                this.setUser({
                    ...user,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    attributes: response.data.attributes,
                });
                this.updatingPersonalDetails = false;
                this.$router.push({ name: "Dashboard" });
                this.$notify({
                    group: "BEV",
                    title: "",
                    text: this.$messages.successMessages.registrationSuccess,
                    type: "success",
                });
            } catch (err) {
                this.updatingPersonalDetails = false;
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        this.$messages.errorMessages.registrationError
                );
            }
        },

        handleContactNumberCountryDetails(value) {
            this.contactNumberCountryDetails = JSON.parse(
                JSON.stringify(value)
            );
        },

        async saveDetails() {
            this.v$.$touch();
            if (this.mobileNumber === null) {
                this.mobileNumberValid = true;
                this.contactNumberCountryDetails = null;
            }
            if (!this.v$.$invalid && this.mobileNumberValid) {
                this.updatePersonalDetails();
            }
        },
    },
    watch: {
        selectedCountry: function (country) {
            if (country.value) {
                this.zipcode = "";
            }
        },
        selectedCity: function (city) {
            if (city.value) {
                this.zipcode = "";
            }
        },
        otherCityValue: function (value) {
            if (value) {
                this.zipcode = "";
            }
        },
    },
};
</script>
