<template>
    <!-- The scope of this template is to provide inner navigation to the policies route and display tabs for route change of children of policies -->
    <div>
        <LayoutWithNav
            v-if="!checkDetailRoute"
            :options="routes"
            :selectedOption="selectedOption"
            :heading="'Policy Tracker'"
            :description="'Track and view the status of all your live, historical, and in-progress policies'"
            :type="!isNormalUser ? 'left' : 'center'"
            :haveExtraHeaderItem="!isNormalUser"
        >
            <template v-slot:header-extra>
                <div class="text-right header-actions">
                    <bev-search-input
                        :inputType="'text'"
                        :inputClass="'have-bg'"
                        :inputPlaceholder="'Search by Policy Number or Insured Name'"
                        class="search-input"
                        v-model="policySearch"
                    ></bev-search-input>
                    <bev-button
                        v-if="isUnderwriter"
                        :themeType="'dark'"
                        :clickHandler="handleBordereauDialog"
                        class="downloadBtn small"
                    >
                        Download Bordereau
                    </bev-button>
                </div>
                <bev-dialog
                    :title="bordereauDialogProps.title"
                    :description="''"
                    :closeDialog="() => toggleDialogBordereau({ state: false })"
                    :actions="{ show: false }"
                    :state="bordereauDialogProps.state"
                    :width="'30%'"
                >
                    <template v-slot:dialog-content>
                        <DownloadBordereuContent
                            :closeDialog="
                                toggleDialogBordereau.bind(this, {
                                    state: false,
                                })
                            "
                        />
                    </template>
                </bev-dialog>
            </template>
            <template v-slot:content>
                <router-view :policySearch="policySearch"></router-view>
            </template>
        </LayoutWithNav>
        <router-view v-else></router-view>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import LayoutWithNav from "@/components/Layout/LayoutWithNav";
import DownloadBordereuContent from "@/components/Views/Policies/DownloadBordereuContent";
export default {
    components: {
        LayoutWithNav,
        DownloadBordereuContent,
    },
    data() {
        return {
            bordereauDialogProps: {},
            policySearch: "",
            routes: [
                {
                    name: "Referred",
                    clickHandler: this.clickHandler,
                    key: "1",
                    path: "referred",
                },
                {
                    name: "Mid-Term Quote",
                    clickHandler: this.clickHandler,
                    key: "2",
                    path: "broker-review",
                },
                {
                    name: "On Cover",
                    clickHandler: this.clickHandler,
                    key: "3",
                    path: "on-cover",
                },
                {
                    name: "Expired",
                    clickHandler: this.clickHandler,
                    key: "4",
                    path: "expired",
                },
                {
                    name: "Claimed",
                    clickHandler: this.clickHandler,
                    key: "5",
                    path: "claimed",
                },
                {
                    name: "Cancelled",
                    clickHandler: this.clickHandler,
                    key: "6",
                    path: "cancelled",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("auth", ["isNormalUser", "isUnderwriter"]),
        selectedOption() {
            const currentRoute = this.routes.find(
                (route) => `/policies/${route.path}` === this.$route.path
            );

            return currentRoute?.key || "";
        },
        checkDetailRoute() {
            return (
                this.$route.name === "PolicyDetail" ||
                this.$route.name === "PolicyPayment"
            );
        },
    },
    created() {
        this.routes.unshift({
            name: this.isNormalUser ? "Application In Progress" : "Bound",
            clickHandler: this.clickHandler,
            key: "0",
            path: this.isNormalUser ? "in-progress" : "bound",
        });
    },
    methods: {
        /**
         *
         * @param {{ key:String,route:String }} option
         */
        clickHandler(option) {
            this.$router.push({ path: `/policies/${option.path}` });
        },
        toggleDialogBordereau(props) {
            this.bordereauDialogProps = props;
        },
        handleBordereauDialog() {
            this.toggleDialogBordereau({
                state: true,
                title: "Bordereau Report",
                description:
                    "aaaaa bbbbbbb ccccccc dddddddd eeeeeeeeee ffffffff iiiiiiiiiiii hhhhhhhhhhhh",
            });
        },
    },
    watch: {
        $route: function () {
            this.policySearch = "";
        },
    },
};
</script>

<style scoped>
.search-input {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
}
.header-actions {
    margin: 0 20px;
    width: 400px;
    min-width: 400px;
    max-width: 50%;
}
@media (max-width: 1420px) {
    .header-actions {
        width: 100%;
        max-width: 100%;
    }
}
</style>
