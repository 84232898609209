<template>
    <canvas :id="chartId" class="w-100 h-100"></canvas>
</template>

<script>
/**
 * Render a Bar Graph with a Slider
 *
 * @param {String} chartId Id for the chart canvas
 * @param {String} xAxesLabel Label for the X-Axis
 * @param {String} yAxesLabel Label for the Y-Axis
 * @param {String} gridColor Color for the Grid lines on the graph
 * @param {String} graphFontColor Font Color for the graph text
 * @param {String} pointerColor Color for the pointer of the Slider
 * @param {String} pointerColorLight Light Color Shade for the pointer of the Slider
 * @param {Array} labels Labels for the Graph
 * @param {Array} barBgColor Background Colors list for the bars shown on the graph
 * @param {Array} barLightBgColor Light Background Colors list for the bars shown on the graph when bar are below the slide point
 * @param {Array} graphData Data plot points for the graph
 * @param {Array} payoutData Bar tooltip label data
 * @param {Number} barThickness Thickness of the bar
 * @param {null} sliderPoint Point at which we need to render the slider
 */

import { markRaw } from "vue";
import Chart from "chart.js/auto";
export default {
    props: {
        chartId: String,
        xAxesLabel: String,
        yAxesLabel: String,
        gridColor: String,
        graphColor: String,
        pointerColor: String,
        pointerColorLight: String,
        labels: {
            type: Array,
            default: () => {
                return [];
            },
        },
        barBgColor: {
            type: String,
            default: () => {
                return "white";
            },
        },
        barLightBgColor: {
            type: String,
            default: () => {
                return "white";
            },
        },
        graphData: {
            type: Array,
            default: () => {
                return [];
            },
        },
        payoutData: {
            type: Array,
            default: () => {
                return [];
            },
        },
        barThickness: Number,
        sliderPoint: null,
    },
    mounted() {
        Chart.defaults.font.family = "'Open Sans', sans-serif";
        Chart.defaults.color = this.graphColor;
        Chart.defaults.font.size = 14;
        this.renderChart();
    },
    data() {
        return {
            chart: null,
        };
    },
    methods: {
        appendArrayItems(val, count) {
            let finalArray = [];
            for (let i = 1; i <= count; i++) {
                finalArray.push(Number(val));
            }
            return finalArray;
        },
        appendBackgroundColor(count) {
            let finalArray = [];
            for (let i = 1; i <= count; i++) {
                if (this.graphData[i] > this.sliderPoint) {
                    finalArray.push(this.barBgColor);
                } else {
                    finalArray.push(this.barLightBgColor);
                }
            }
            return finalArray;
        },
        renderChart() {
            let ctx = document.getElementById(this.chartId).getContext("2d");
            if (this.chart !== null) {
                this.chart.destroy();
            }
            let data = {
                datasets: [
                    {
                        type: "line",
                        label: "Line Dataset",
                        data: this.appendArrayItems(
                            this.sliderPoint,
                            this.labels.length
                        ),
                        borderColor: this.pointerColor,
                        borderWidth: 2,
                        backgroundColor: "transparent",
                        pointRadius: [
                            ...this.appendArrayItems(0, this.labels.length - 1),
                            8,
                        ],
                        pointHoverRadius: [
                            ...this.appendArrayItems(0, this.labels.length - 1),
                            8,
                        ],
                        pointBackgroundColor: this.pointerColor,
                        pointBorderColor: this.pointerColorLight,
                        pointHoverBackgroundColor: this.pointerColor,
                        pointHoverBorderColor: this.pointerColorLight,
                        pointBorderWidth: 16,
                        pointHoverBorderWidth: 16,
                        pointHitRadius: 0,
                        showTooltips: false,
                    },
                    {
                        type: "bar",
                        label: "Bar Dataset",
                        data: this.graphData,
                        backgroundColor: [
                            0,
                            ...this.appendBackgroundColor(
                                this.labels.length - 2
                            ),
                            0,
                        ],
                        maxBarThickness: [
                            0,
                            ...this.appendArrayItems(
                                20,
                                this.labels.length - 2
                            ),
                            0,
                        ],
                    },
                ],
                labels: [...this.labels],
            };
            const chart = new Chart(ctx, {
                type: "bar",
                data: data,
                options: {
                    layout: {
                        padding: {
                            left: 0,
                            right: 25,
                            top: 20,
                            bottom: 0,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        x: {
                            display: true,
                            ticks: {
                                font: {
                                    size: 14,
                                },
                                color: this.graphColor,
                            },
                            min: this.labels[1],
                            max: this.labels[this.labels.length],
                            title: {
                                display: true,
                                text: this.xAxesLabel,
                            },
                            grid: {
                                display: false,
                                drawBorder: false,
                                color: this.gridColor,
                            },
                        },
                        xbar: {
                            display: false,
                        },
                        y: {
                            id: "y-axis-0",
                            display: true,
                            type: "linear",
                            ticks: {
                                beginAtZero: true,
                                color: this.graphColor,
                            },
                            title: {
                                display: true,
                                text: this.yAxesLabel,
                            },
                            grid: {
                                display: true,
                                borderDash: [10, 5],
                                color: this.gridColor,
                                zeroLineColor: this.gridColor,
                            },
                        },
                    },
                },
            });

            this.chart = markRaw(chart);

            for (let i = 0; i <= data.datasets[0].data.length - 2; i++) {
                this.chart.data.labels[this.chart.data.labels.length - 1] = "";
            }

            let titleHandler = (data, tooltipItem) => {
                return (
                    "Rainfall: " +
                    data[tooltipItem[0].dataIndex].toFixed(2) +
                    " mm"
                );
            };
            let labelHandler = (data) => {
                return (
                    "Payout: " +
                    this.thousandMask(
                        this.payoutData[data.dataIndex],
                        "currency"
                    )
                );
            };
            let tooltipFilter = (tooltipItem) => {
                return tooltipItem.datasetIndex === 1;
            };
            this.setChartProperties(
                this.chart,
                titleHandler,
                labelHandler,
                {
                    bgColor: this.$colors.bevColors.baseDark,
                    borderColor: this.barBgColor,
                    shadowColor: this.$colors.bevColors.shadowColor,
                },
                true,
                tooltipFilter,
                16
            );
        },
    },
};
</script>
