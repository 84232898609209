<template>
    <input-wrapper
        :inputLabel="inputLabel"
        :haveError="haveError"
        :errorMessage="errorMessage"
        :fullWidth="fullWidthInput"
        :infoImage="infoImage"
        :inputActive="inputActive"
    >
        <date-picker
            :type="pickerType"
            :value="modelValue"
            :appendToBody="appendToBody"
            :clearable="showClear"
            :valueType="dateValueType"
            :format="dateFormat"
            :disabled-date="disabledDate"
            :default-value="defaultDate"
            :editable="false"
            :inline="false"
            class="date-picker-popup"
            @calendar-change="calendarChange"
            @change="dateChange"
            @open="updateHighlightDates"
            @close="inputActive = false"
        >
            <template v-slot:icon-calendar>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="'var(--inputColor)'"
                    :imageUrl="$imagePath.calender"
                    :imageAlt="'calender-icon'"
                    width="15px"
                    height="15px"
                    class="cursor-pointer"
                ></bev-image>
            </template>
            <template v-slot:icon-clear>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="'var(--inputColor)'"
                    :imageUrl="$imagePath.cross"
                    :imageAlt="'cross-icon'"
                    width="15px"
                    height="15px"
                    class="cursor-pointer"
                    v-if="showClear"
                ></bev-image>
            </template>
        </date-picker>
    </input-wrapper>
</template>

<script>
/**
 * This is BEV custom input which will be used wherever there is date input
 *
 * @param {String} inputLabel for input label prop
 * @param {String} modelValue for input value prop
 * @param {Function} disabledDate for disable dates in the datepicker
 * @param {Date} defaultDate for default date in the datepicker
 * @param {Boolean} haveError for showing error with the input
 * @param {String} errorMessage error message to show if haveError is true
 * @param {Boolean} fullWidthInput for showing a full width input (used in InputWrapper)
 * @param {Object} infoImage image props for showing info icon (used in InputWrapper)
 * @param model for two way binding data to input
 **/

import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import InputWrapper from "@/components/Common/Input/InputWrapper";
import { format } from "date-fns";
export default {
    props: {
        inputLabel: {
            type: String,
            default: "",
        },
        pickerType: {
            type: String,
            default: "date",
        },
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        modelValue: null,
        fullWidthInput: {
            type: Boolean,
            default: true,
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        disabledDate: {
            type: Function,
            default: () => {
                return false;
            },
        },
        defaultDate: {
            type: Date,
            default: () => {
                return new Date();
            },
        },
        dateFormat: {
            type: String,
            default: () => {
                return "DD MMMM YYYY";
            },
        },
        dateValueType: {
            type: String,
            default: () => {
                return "format";
            },
        },
        appendToBody: {
            type: Boolean,
            default: false,
        },
        showClear: {
            type: Boolean,
            default: true,
        },
        higlightDates: {
            type: Array,
            default: () => [],
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            inputActive: false,
            formattedHighlightDates: [],
        };
    },
    mounted() {
        this.formatHighlightDates();
    },
    components: {
        InputWrapper,
        DatePicker,
    },
    methods: {
        dateChange(e) {
            this.$emit("update:modelValue", e ? this.formatToUtcDate(e) : null);
        },
        calendarChange() {
            this.updateHighlightDates();
        },
        updateHighlightDates() {
            this.inputActive = true;
            this.$nextTick(() => {
                const cells = this.$el.querySelectorAll("td");
                cells.forEach((cell) => {
                    cell.classList.remove("grey_date");
                    if (
                        this.formattedHighlightDates.length &&
                        this.formattedHighlightDates.includes(
                            cell.getAttribute("title")
                        )
                    ) {
                        cell.classList.add("grey_date");
                    }
                });
            });
        },
        formatHighlightDates() {
            if (this.higlightDates.length) {
                let higlightedDates = this.getDatesInRange(
                    this.higlightDates[0],
                    this.higlightDates[1]
                );
                this.formattedHighlightDates = higlightedDates.map((d) => {
                    return format(d, "yyyy-MM-dd");
                });
            }
        },
        getDatesInRange(startDate, endDate) {
            const date = new Date(startDate.getTime());
            const dates = [];
            while (date <= endDate) {
                dates.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            return dates;
        },
    },
};
</script>
