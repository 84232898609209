<template>
    <div>
        <heading-block
            :align="'center'"
            :heading="`Confirmation`"
            :description="confirmationMessage"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
        ></heading-block>
        <div class="pt-20px px-4% mt-30px">
            <UndertakingList
                :undertakings="undertakings"
                :checkAllSelected="checkAllSelected"
            >
                <bev-checkbox
                    class="activeColorV1"
                    :modelValue="acknowledgeAll"
                    @update:modelValue="(e) => $emit('acknowledge', e)"
                    :extraClass="'medium'"
                >
                    <template v-slot:label>
                        <p>
                            You confirm, as agent of the Proposed Insured, that
                            you have read, understand and agree with all of the
                            above-stated declarations, and the Terms &
                            Conditions as set out in the policy.
                        </p>
                    </template>
                </bev-checkbox>
            </UndertakingList>
            <CallUs />
        </div>
    </div>
</template>

<script>
import CallUs from "@/components/Common/ConfirmationDeclarations/CallUs";
import UndertakingList from "@/components/Common/ConfirmationDeclarations/UndertakingList";
export default {
    name: "ConfirmationDeclarations",
    props: {
        products: Array,
        undertakings: Object,
        checkAllSelected: Boolean,
        acknowledgeAll: Boolean,
        confirmationMessage: String,
    },
    components: {
        CallUs,
        UndertakingList,
    },
};
</script>
